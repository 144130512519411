export const loginSportShaskiySamiti5 = (session) => {
  return {
    type: "LOGIN_SPORT_SHASKIY_SAMITI5",
    payload: session,
  };
};

export const logoutSportShaskiySamiti5 = () => {
  return {
    type: "LOGOUT_SPORT_SHASKIY_SAMITI5",
  };
};

export const setSportShaskiysamiti5Dashboard = (dashboard) => {
  return {
    type: "UPDATE_SPORT_SHASKIY5_DASHBOARD",
    payload: dashboard,
  };
};

export const clearSportShaskiysamiti5Dashboard = () => {
  return {
    type: "CLEAR_SPORT_SHASKIY5_DASHBOARD",
  };
};
