import React from 'react';
import Schemethreeapprovedapplications from '../../Administration/SchemeThree/Schemethreeapprovedapplications';

const Clerk2SchemeThreeApprovedApplications = () => {
  return (
    <div><Schemethreeapprovedapplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeThreeApprovedApplications;
