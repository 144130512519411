import React from "react";
import SchemeNineTotalApplications from "../../Administration/schemeNine/SchemeNineTotalApplications";

const Chanani2SchemeNineTotalApplications = () => {
  return (
    <div>
      <SchemeNineTotalApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeNineTotalApplications;
