import React from "react";
import SchemeThreeTotalApplications from "../../Administration/SchemeThree/SchemeThreeTotalApplications";

const ShaskiySamiti4SchemeThreeTotalApplications = () => {
    return (
        <div>
            <SchemeThreeTotalApplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeThreeTotalApplications;
