import React from "react";
import SchemeTenRejectedApplications from "../../Administration/SchemeTen/SchemeTenRejectedApplications";

const AdditionalCommSchemeTenRejectedApplications = () => {
  return (
    <div>
      <SchemeTenRejectedApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeTenRejectedApplications;
