export const loginChananisamiti3 = (session) => {
  return {
    type: "LOGIN_CHANANI_SAMITY3",
    payload: session,
  };
};

export const logoutChananisamiti3 = () => {
  return {
    type: "LOGOUT_CHANANI_SAMITY3",
  };
};

export const setChanani3Dashboard = (dashboard) => {
  return {
    type: "UPDATE_CHANANI3_DASHBOARD",
    payload: dashboard,
  };
};

export const clearChanani3Dashboard = () => {
  return {
    type: "CLEAR_CHANANI3_DASHBOARD",
  };
};
