// import React from "react";
// import { Navigate, Outlet } from "react-router-dom";
// import { useSelectAccess } from "../store/stateFunctions";
// import DeputyCommissionerSidebar from "../deputycommissioner/DeputyCommissionerSidebar";
// // import { Outlet } from "react-router-dom";

// const DeputyCommissionerContainer = () => {
//   document.title = "Deputy Commissioner";
//   const deputycomm = useSelectAccess("Deputy Commissioner");

//   return (
//     <>
//       <div className="row ">
//         <DeputyCommissionerSidebar role="Deputy Commissioner" />
//         <div className="container">
//           {deputycomm ? <Outlet /> : <Navigate to="/deputy-commissioner" />}
//           {/* <Outlet /> */}
//         </div>
//       </div>
//     </>
//   );
// };

// export default DeputyCommissionerContainer;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getCookie, server } from "../common";
import configContext from "../configContext/configContext";

import DeputyCommissionerSidebar from "../deputycommissioner/DeputyCommissionerSidebar";
import { updateDashboardState, useSelectAccess } from "../store/stateFunctions";

export default function DeputyCommissionerContainer() {
  const navigate = useNavigate();
  const location = useLocation();
  const deputy = useSelectAccess("Deputy Commissioner");
  const { setProgress } = useContext(configContext);

  const [processing, setProcessing] = useState(false);
  const fetched = useRef(false);
  const setFetched = (value) => (fetched.current = value);

  useEffect(() => {
    // Navigate to "/deputy" if deputy access is not available
    if (!deputy) {
      navigate("/deputy-commissioner", {
        state: { from: location },
        replace: true,
      });
      return;
    }

    // If deputy is valid, fetch admin profile
    setProcessing(true);
    setProgress(10);
    server
      .get("/admin/adminprofile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: deputy.authToken,
        },
      })
      .then((response) => {
        updateDashboardState("Deputy Commissioner", response.data);
        setProgress(100);
        setProcessing(false);
        setFetched(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          navigate("/deputy-commissioner", {
            state: { from: location },
            replace: true,
          });
        } else {
          setProgress(100);
          setProcessing(false);
          console.error(
            "error",
            error.message,
            error.response?.status,
            error.response?.data,
            error
          );
        }
      });

    document.title = "Additional Commissioner Dashboard";
  }, [deputy, navigate, location, setProgress]);

  if (deputy)
    return (
      <>
        <DeputyCommissionerSidebar
          username={deputy.dashboard && deputy.dashboard.name}
        />
        {deputy.dashboard && <Outlet />}
      </>
    );

  return null;
}
