import React from 'react';
import SchemeSevenTotalApplications from '../../Administration/SchemeSeven/SchemeSevenTotalApplications';

const Clerk3SchemeSevenTotalApplications = () => {
  return (
    <div><SchemeSevenTotalApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeSevenTotalApplications;
