export const loginShaskiySamiti3 = (session) => {
  return {
    type: "LOGIN_SHASKIY_SAMITI3",
    payload: session,
  };
};

export const logoutShaskiySamiti3 = () => {
  return {
    type: "LOGOUT_SHASKIY_SAMITI3",
  };
};

export const setShaskiysamiti3Dashboard = (dashboard) => {
  return {
    type: "UPDATE_SHASKIY3_DASHBOARD",
    payload: dashboard,
  };
};

export const clearShaskiysamiti3Dashboard = () => {
  return {
    type: "CLEAR_SHASKIY3_DASHBOARD",
  };
};
