import React from "react";
import SchemeOneTotalApplications from "../../Administration/SchemeOne/SchemeOneTotalApplications";

const ShaskiySamiti5SchemeOneTotalApplications = () => {
    return (
        <div>
            <SchemeOneTotalApplications role="Shaskiy Samiti5" />
        </div>
    );
};

export default ShaskiySamiti5SchemeOneTotalApplications;
