import React from "react";
import { useTranslation } from "react-i18next";

const News = () => {
  const { t } = useTranslation();

  return (
    <div style={styles.newsContainer}>
      <h2 style={styles.heading}>{t("Latestnews.latestNews")}</h2>
      <div style={styles.cardsContainer}>
        <div style={styles.card} className="row">
          <div className="col-md-3">
            <img
              src="https://img.freepik.com/free-vector/live-breaking-news-template-style_23-2148507229.jpg"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-9">
            <div style={styles.cardContent}>
              <h3 style={styles.newsTitle}>
                {t("Latestnews.newsItems.title1")}
              </h3>
              <p style={styles.newsDescription}>
                <p>{t("Latestnews.newsItems.description1")}</p>
              </p>
            </div>
          </div>
        </div>

        <div style={styles.card} className="row">
          <div className="col-md-3">
            <img
              src="https://img.freepik.com/free-vector/live-breaking-news-template-style_23-2148507229.jpg"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-9">
            <div style={styles.cardContent}>
              <h3 style={styles.newsTitle}>
                {t("Latestnews.newsItems.title1")}
              </h3>
              <p style={styles.newsDescription}>
                <p>{t("Latestnews.newsItems.description1")}</p>
              </p>
            </div>
          </div>
        </div>

        <div style={styles.card} className="row">
          <div className="col-md-3">
            <img
              src="https://img.freepik.com/free-vector/live-breaking-news-template-style_23-2148507229.jpg"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-md-9">
            <div style={styles.cardContent}>
              <h3 style={styles.newsTitle}>
                {t("Latestnews.newsItems.title1")}
              </h3>
              <p style={styles.newsDescription}>
                <p>{t("Latestnews.newsItems.description1")}</p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  newsContainer: {
    padding: "20px",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  },
  heading: {
    fontSize: "1.5em",
    fontWeight: "bold",
    marginBottom: "15px",
    textAlign: "center",
  },
  cardsContainer: {
    display: "flex",
    flexDirection: "column", // Stack cards vertically
    alignItems: "center",
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    margin: "15px 0", // Space between cards
    width: "90%", // Adjust width for responsiveness
    overflow: "hidden",
    transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  },
  cardContent: {
    padding: "15px",
  },
  newsTitle: {
    fontSize: "1.2em",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  newsDescription: {
    fontSize: "1em",
    color: "#555",
    lineHeight: "1.4",
  },
};

export default News;
