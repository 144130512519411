import React from "react";
import SchemeNineapprovedapplications from "../../Administration/schemeNine/SchemeNineapprovedapplications";

const ShaskiySamiti1SchemeNineApprovedApplications = () => {
    return (
        <div>
            <SchemeNineapprovedapplications role="Shaskiy Samiti1" />
        </div>
    );
};

export default ShaskiySamiti1SchemeNineApprovedApplications;
