import React from 'react';
import SchemeTwoTotalApplications from '../../Administration/SchemeTwo/SchemeTwoTotalApplications';

const Clerk3SchemeTwoTotalApplications = () => {
  return (
    <div><SchemeTwoTotalApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeTwoTotalApplications;
