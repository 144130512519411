import React from "react";
import SchemeSixRejectedApplications from "../../Administration/SchemeSix/SchemeSixRejectedApplications";

const SuperAdminSchemeSixRejectedApplications = () => {
  return (
    <div>
      <SchemeSixRejectedApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeSixRejectedApplications;
