import React from "react";
import SchemeFiveTotalApplications from "../../Administration/SchemeFive/SchemeFiveTotalApplications";

const ShaskiySamiti1SchemeFiveTotalApplications = () => {
    return (
        <div>
            <SchemeFiveTotalApplications role="Shaskiy Samiti1" />
        </div>
    );
};

export default ShaskiySamiti1SchemeFiveTotalApplications;
