import React from "react";
import SchemeEightTotalApplications from "../../Administration/SchemeEight/SchemeEightTotalApplications";

const Chanani3SchemeEightTotalApplications = () => {
  return (
    <div>
      <SchemeEightTotalApplications role="Chanani Samiti3" />
    </div>
  );
};

export default Chanani3SchemeEightTotalApplications;
