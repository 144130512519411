import React from "react";
import Graphdashboard from "../../Administration/dashboard/GraphDashboard/Graphdashboard";

const SuperAdminGraphs = () => {
  return (
    <div>
      <Graphdashboard role="Super Admin" />
    </div>
  );
};

export default SuperAdminGraphs;
