import React from "react";
import SchemeSixRejectedApplications from "../../Administration/SchemeSix/SchemeSixRejectedApplications";

const ShaskiySamiti1SchemeSixRejectedApplications = () => {
    return (
        <div>
            <SchemeSixRejectedApplications role="Shaskiy Samiti1" />
        </div>
    );
};

export default ShaskiySamiti1SchemeSixRejectedApplications;
