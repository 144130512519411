import React from "react";
import SchemeFourapprovedapplications from "../../Administration/SchemeFour/SchemeFourapprovedapplications";

const ClerkSchemeFourApprovedApplications = () => {
  return (
    <div>
      <SchemeFourapprovedapplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeFourApprovedApplications;
