import React from "react";
import SchemeTwoRejectedApplications from "../../Administration/SchemeTwo/SchemeTwoRejectedApplications";

const ShaskiySamiti4SchemeTwoRejectedApplications = () => {
    return (
        <div>
            <SchemeTwoRejectedApplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeTwoRejectedApplications;
