import React from "react";
import SchemeTenapprovedapplications from "../../Administration/SchemeTen/SchemeTenapprovedapplications";

const ShaskiySamiti1SchemeTenApprovedApplications = () => {
    return (
        <div>
            <SchemeTenapprovedapplications role="Shaskiy Samiti1" />
        </div>
    );
};

export default ShaskiySamiti1SchemeTenApprovedApplications;
