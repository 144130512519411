import React from "react";
import SchemeSixPendingApplications from "../../Administration/SchemeSix/SchemeSixPendingApplications";

const ShaskiySamiti2SchemeSixPendingApplications = () => {
    return (
        <div>
            <SchemeSixPendingApplications role="Shaskiy Samiti2" />
        </div>
    );
};

export default ShaskiySamiti2SchemeSixPendingApplications;
