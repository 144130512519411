import React from "react";
import SchemeOneRejectedApplications from "../../Administration/SchemeOne/SchemeOneRejectedApplications";

const ShaskiySamiti1SchemeOneRejectedApplications = () => {
    return (
        <div>
            <SchemeOneRejectedApplications role="Shaskiy Samiti1" />
        </div>
    );
};

export default ShaskiySamiti1SchemeOneRejectedApplications;
