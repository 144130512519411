import React from 'react';
import SchemeFiveRejectedApplications from '../../Administration/SchemeFive/SchemeFiveRejectedApplications';

const Clerk2SchemeFiveRejectedApplications = () => {
  return (
    <div><SchemeFiveRejectedApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeFiveRejectedApplications;
