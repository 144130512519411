import React from "react";
import SchemeSixTotalApplications from "../../Administration/SchemeSix/SchemeSixTotalApplications";

const Chanani1SchemeSixTotalApplications = () => {
    return (
        <div>
            <SchemeSixTotalApplications role="Chanani Samiti1" />
        </div>
    );
};

export default Chanani1SchemeSixTotalApplications;
