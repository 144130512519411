import React from "react";
import SportSchemeTwoRejectedApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoRejectedApplications";

const SportChanani4SchemeTwoRejectedApplications = () => {
  return (
    <div>
      <SportSchemeTwoRejectedApplications role="Sport Chanani4" />
    </div>
  );
};

export default SportChanani4SchemeTwoRejectedApplications;
