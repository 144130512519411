import React from "react";
import SportSchemeTwoApprovedApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoApprovedApplications";

const SportChanani3SchemeTwoApprovedApplications = () => {
  return (
    <div>
      <SportSchemeTwoApprovedApplications role="Sport Chanani3" />
    </div>
  );
};

export default SportChanani3SchemeTwoApprovedApplications;
