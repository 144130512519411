import React from "react";
import SchemeOneTotalApplications from "../../Administration/SchemeOne/SchemeOneTotalApplications";

const Chanani1SchemeOneTotalApplications = () => {
    return (
        <div>
            <SchemeOneTotalApplications role="Chanani Samiti1" />
        </div>
    );
};

export default Chanani1SchemeOneTotalApplications;
