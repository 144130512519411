import React from "react";
import SchemeEightTotalApplications from "../../Administration/SchemeEight/SchemeEightTotalApplications";

const Chanani2SchemeEightTotalApplications = () => {
  return (
    <div>
      <SchemeEightTotalApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeEightTotalApplications;
