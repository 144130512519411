import React from 'react';
import SchemeNinePendingApplications from '../../Administration/schemeNine/SchemeNinePendingApplications';

const Clerk2SchemeNinePendingApplications = () => {
  return (
    <div><SchemeNinePendingApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeNinePendingApplications;
