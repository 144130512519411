import React from "react";
import SportSchemeThreeTotalApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeTotalApplications";

const SportCommissionerSchemeThreeTotalApplications = () => {
  return (
    <div>
      <SportSchemeThreeTotalApplications role="Sport AddComm" />
    </div>
  );
};

export default SportCommissionerSchemeThreeTotalApplications;
