import React from "react";
import SchemeThreeRejectedApplications from "../../Administration/SchemeThree/SchemeThreeRejectedApplications";

const Chanani4SchemeThreeRejectedApplications = () => {
    return (
        <div>
            <SchemeThreeRejectedApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeThreeRejectedApplications;
