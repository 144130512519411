import React from 'react';
import SchemeOneRejectedApplications from '../../Administration/SchemeOne/SchemeOneRejectedApplications';

const Clerk3SchemeOneRejectedApplications = () => {
  return (
    <div><SchemeOneRejectedApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeOneRejectedApplications;
