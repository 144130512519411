import React, { useEffect, useState } from "react";
import { Tabs, Tab, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import UserProfileSidebar from "./UserProfileSidebar";
import { Button } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { server } from "../../common";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { useSelectAccess } from "../../store/stateFunctions";

const TotalApplications = () => {
  const [key, setKey] = useState("approved");
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const user = useSelectAccess("User");

  useEffect(() => {
    getData();
    getRejectedData();
  }, []);

  // Get
  const getData = () => {
    server
      .get("/dashboard/approvedappl", {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else {
          toast.error(error.response?.data?.error || "An error occurred");
        }
      });
  };

  // Get
  const getRejectedData = () => {
    server
      .get("/dashboard/rejectedappl", {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200 || response.status === 201) {
          setDatas(response.data);
        }
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else {
          toast.error(error.response?.data?.error || "An error occurred");
        }
      });
  };

  return (
    <div className="container-fluid mt-4">
      <div className="row">
        <div>
          <div>
            <h3>Application Status</h3>
            <Alert variant="info" className="mt-2">
              <ul>
                <li>
                  Cancel Option: Only available for cancelling the Scheme
                  Application.
                </li>
                <li>
                  Can be cancelled only if the application is pending for
                  scrutiny at the Institute Clerk or has been sent back for
                  modification.
                </li>
                <li>
                  Scheme Cancellation: Cancelling a scheme application will
                  automatically change scheme preferences.
                </li>
                <li>
                  Application Details: Click on the Application ID to view
                  scrutiny status and details.
                </li>
                <li>
                  OTP Verification: OTP verification of the registered mobile
                  number is mandatory to cancel the scheme.
                </li>
              </ul>
            </Alert>
          </div>

          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab
              eventKey="approved"
              title="Approved Applications"
              className="text-black"
            >
              <Alert>
                <table className="table table-bordered table-striped ">
                  <thead>
                    <tr>
                      <th>Application ID</th>
                      <th>Scheme Name</th>
                      <th>Applicant Name</th>
                      <th>Application Status</th>
                      <th>View Applications</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((app, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{app.id}</td>
                        <td>{app.fullname}</td>
                        <td>{app.adhar_number}</td>
                        <td>{app.mobile}</td>
                        <td>{app.ward_no}</td>
                        <td>
                          <button
                            className="btn btn-primary"
                            // onClick={() => handleShow(app.id)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Alert>
            </Tab>

            <Tab
              eventKey="rejected"
              title="Rejected Applications"
              className="text-black"
            >
              <Alert>
                <table className="table table-bordered table-stripped">
                  <thead>
                    <tr>
                      <th>Application ID</th>
                      <th>Scheme Name</th>

                      <th>Applicant Name</th>
                      <th>Application Status</th>
                      <th>View Applications</th>
                    </tr>
                  </thead>
                  <tbody>
                    {datas.map((app, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{app.id}</td>
                        <td>{app.fullname}</td>
                        <td>{app.adhar_number}</td>
                        <td>{app.mobile}</td>
                        <td>{app.ward_no}</td>
                        <td>
                          <button
                            className="btn btn-primary"
                            // onClick={() => handleShow(app.id)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Alert>
            </Tab>

            {/* <Tab
              eventKey="scrutiny"
              title="Under Scrutiny Applications"
              className="text-black"
            >
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Application ID</th>
                    <th>Scheme Name</th>

                    <th>Applicant Name</th>
                    <th>Application Status</th>
                    <th>View Applications</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Name of the scheme </td>

                    <td>John Doe</td>
                    <td>
                      <Button variant="contained" color="error">
                        Pending
                      </Button>
                    </td>
                    <td>
                      <Button
                        variant="contained"
                        color="warning"
                        startIcon={<RemoveRedEyeIcon />}
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Tab> */}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TotalApplications;
