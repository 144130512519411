import React from "react";
import SportSchemeThreeTotalApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeTotalApplications";

const SportShaskiySamiti5SchemeThreeTotalApplications = () => {
  return (
    <div>
      <SportSchemeThreeTotalApplications role="Sport Shaskiy5" />
    </div>
  );
};

export default SportShaskiySamiti5SchemeThreeTotalApplications;
