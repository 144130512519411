import React from "react";
import SportSchemeOneApprovedApplications from "../../../Administration/SportSchemeOne/SportSchemeOneApprovedApplications";

const SportChanani1SchemeOneApprovedApplications = () => {
  return (
    <div>
      <SportSchemeOneApprovedApplications role="Sport Chanani1" />
    </div>
  );
};

export default SportChanani1SchemeOneApprovedApplications;
