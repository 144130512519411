import React from "react";
import SchemeSevenTotalApplications from "../../Administration/SchemeSeven/SchemeSevenTotalApplications";

const Chanani1SchemeSevenTotalApplications = () => {
    return (
        <div>
            <SchemeSevenTotalApplications role="Chanani Samiti1" />
        </div>
    );
};

export default Chanani1SchemeSevenTotalApplications;
