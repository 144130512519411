import React from "react";
import SchemeFiveApprovedApplications from "../../Administration/SchemeFive/SchemeFiveApprovedApplications";

const SuperAdminSchemeFiveApprovedApplications = () => {
  return (
    <div>
      <SchemeFiveApprovedApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeFiveApprovedApplications;
