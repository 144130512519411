import React from 'react';
import SchemeTenPendingApplications from '../../Administration/SchemeTen/SchemeTenPendingApplications';

const Clerk3SchemeTenPendingApplications = () => {
  return (
    <div><SchemeTenPendingApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeTenPendingApplications;
