import React from "react";
import SchemeNineApprovedApplications from "../../Administration/schemeNine/SchemeNineapprovedapplications";

const SuperAdminSchemeNineApprovedApplications = () => {
  return (
    <div>
      <SchemeNineApprovedApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeNineApprovedApplications;
