import React from "react";
import SchemeFourTotalApplications from "../../Administration/SchemeFour/SchemeFourTotalApplications";

const ClerkSchemeFourTotalApplications = () => {
  return (
    <div>
      <SchemeFourTotalApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeFourTotalApplications;
