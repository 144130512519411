import React from "react";
import SchemeTwoRejectedApplications from "../../Administration/SchemeTwo/SchemeTwoRejectedApplications";

const ClerkSchemeTwoRejectedApplications = () => {
  return (
    <div>
      <SchemeTwoRejectedApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeTwoRejectedApplications;
