import React, { useState } from "react";
import { Button } from "@mui/material";
import { useEffect } from "react";
import { server } from "../common";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useSelectAccess } from "../store/stateFunctions";
import DataTable from "react-data-table-component";

const swalalert = withReactContent(Swal);

const ContactEntries = ({ role }) => {
  const [data, setData] = useState([]);
  const user = useSelectAccess(role);

  useEffect(() => {
    getData();
  }, []);

  // Get data
  const getData = () => {
    server
      .get("/contact/getallcontact", {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        if (error instanceof AxiosError && error.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.response?.data?.error || "An error occurred");
        }
      });
  };

  // Delete data
  const DeleteData = (app) => {
    swalalert
      .fire({
        title: "Delete Confirmation!",
        text: `Are You Sure That You Want To Delete Application ${app.id} ?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          server
            .delete(`/contact/deletecontact/${app.id}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: user.authToken,
              },
            })
            .then((response) => {
              if (response.status === 200 || response.status === 201) {
                swalalert.fire(
                  "Deleted!",
                  "Application has been deleted.",
                  "success"
                );
                getData();
              }
            })
            .catch((error) => {
              console.error("Delete error:", error);
              toast.error(
                error.response?.data?.message || "Failed to delete application"
              );
            });
        } else {
          swalalert.fire(
            "Cancelled",
            "Your application was not deleted",
            "info"
          );
        }
      });
  };

  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Message",
      selector: (row) => row.message,
    },
    {
      name: "Action",
      selector: (row) => (
        <button className="btn btn-danger" onClick={() => DeleteData(row)}>
          Delete
        </button>
      ),
    },
  ];

  return (
    <div>
      {/* <div className="container-fluid mt-4">
        <div className="card mb-3">
          <div className="card-header">Contact Entries</div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th>Sr.No.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Message</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((entry, index) => (
                    <tr key={entry.id}>
                      <td>{index + 1}</td>
                      <td>{entry.name}</td>
                      <td>{entry.email}</td>
                      <td>{entry.message}</td>
                      <td>
                        <div
                          className="btn btn-danger"
                          onClick={() => DeleteData(entry)}
                        >
                          Delete
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}
      <div className="container">
        <DataTable columns={columns} data={data} pagination></DataTable>
      </div>
    </div>
  );
};

export default ContactEntries;
