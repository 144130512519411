import React from "react";
import SchemeEightPendingApplications from "../../Administration/SchemeEight/SchemeEightPendingApplications";

const AdditionalCommSchemeEightPendingApplications = () => {
  return (
    <div>
      <SchemeEightPendingApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeEightPendingApplications;
