import React from "react";
import SchemeFourPendingApplications from "../../Administration/SchemeFour/SchemeFourPendingApplications";

const ShaskiySamiti3SchemeFourPendingApplications = () => {
    return (
        <div>
            <SchemeFourPendingApplications role="Shaskiy Samiti3" />
        </div>
    );
};

export default ShaskiySamiti3SchemeFourPendingApplications;
