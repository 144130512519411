import React from "react";
import SchemeSevenapprovedapplications from "../../Administration/SchemeSeven/SchemeSevenapprovedapplications";

const AdditionalCommSchemeSevenApprovedApplications = () => {
  return (
    <div>
      <SchemeSevenapprovedapplications role="Additional Commissioner" />{" "}
    </div>
  );
};

export default AdditionalCommSchemeSevenApprovedApplications;
