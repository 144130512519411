import React from 'react';
import SchemeSevenapprovedapplications from '../../Administration/SchemeSeven/SchemeSevenapprovedapplications';

const Clerk3SchemeSevenApprovedApplications = () => {
  return (
    <div><SchemeSevenapprovedapplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeSevenApprovedApplications;
