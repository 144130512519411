import React from "react";
import SchemeFivePendingApplications from "../../Administration/SchemeFive/SchemeFivePendingApplications";

const Chanani3SchemeFivePendingApplications = () => {
    return (
        <div>
            <SchemeFivePendingApplications role="Chanani Samiti3" />
        </div>
    );
};

export default Chanani3SchemeFivePendingApplications;
