import React from "react";
import SchemeSixPendingApplications from "../../Administration/SchemeSix/SchemeSixPendingApplications";

const ShaskiySamiti1SchemeSixPendingApplications = () => {
    return (
        <div>
            <SchemeSixPendingApplications role="Shaskiy Samiti1" />
        </div>
    );
};

export default ShaskiySamiti1SchemeSixPendingApplications;
