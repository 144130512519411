export const loginSportDepuComm = (session) => {
  return {
    type: "LOGIN_SPORT_DEPU_COMM",
    payload: session,
  };
};
export const logoutSportDepuComm = () => {
  return {
    type: "LOGOUT_SPORT_DEPU_COMM",
  };
};

export const setSportDeputyDashboard = (dashboard) => {
  return {
    type: "UPDATE_SPORT_DEPUTY_DASHBOARD",
    payload: dashboard,
  };
};

export const clearSportDeputyDashboard = () => {
  return {
    type: "CLEAR_SPORT_DEPUTY_DASHBOARD",
  };
};
