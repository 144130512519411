import React from "react";
import SchemeFiveApprovedApplications from "../../Administration/SchemeFive/SchemeFiveApprovedApplications";

const Chanani2SchemeFiveApprovedApplications = () => {
  return (
    <div>
      <SchemeFiveApprovedApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeFiveApprovedApplications;
