import React from 'react';
import SchemeOneApprovedApplications from '../../Administration/SchemeOne/SchemeOneApprovedApplications';

const Clerk3SchemeOneApprovedApplications = () => {
  return (
    <div><SchemeOneApprovedApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeOneApprovedApplications;
