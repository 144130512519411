import React from "react";
import SchemeEightRejectedApplications from "../../Administration/SchemeEight/SchemeEightRejectedApplications";

const ClerkSchemeEightRejectedApplications = () => {
  return (
    <div>
      <SchemeEightRejectedApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeEightRejectedApplications;
