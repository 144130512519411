import axios from "axios";
import * as XLSX from "xlsx";

import {
  BACKEND_SERVER_URL,
  userTokenName,
  superAdminTokenName,
  deputyCommTokenName,
  AddCommiTokenName,
  shaskiysamityTokenName,
  chananisamitiTokenName,
  chananisamitiTokenName1,
  chananisamitiTokenName2,
  chananisamitiTokenName3,
  chananisamitiTokenName4,
  shaskiysamityTokenName1,
  shaskiysamityTokenName2,
  shaskiysamityTokenName3,
  shaskiysamityTokenName4,
  shaskiysamityTokenName5,
  clerkUserTokenName1,
  clerkUserTokenName2,
  clerkUserTokenName3,
  sportclerkTokenName1,
  sportclerkTokenName2,
  sportchananiTokenName1,
  sportchananiTokenName2,
  sportchananiTokenName3,
  sportchananiTokenName4,
  sportshaskiysamityTokenName1,
  sportshaskiysamityTokenName2,
  sportshaskiysamityTokenName3,
  sportshaskiysamityTokenName4,
  sportshaskiysamityTokenName5,
  sportchananiTokenName5,
  sportdeputyCommTokenName,
  sportAddCommiTokenName,
} from "./config";

export const server = axios.create({
  baseURL: BACKEND_SERVER_URL,
  proxy: BACKEND_SERVER_URL,
});

export const removeUserToken = () => {
  return sessionStorage.removeItem(userTokenName);
};

export const removeSuperAdminToken = () => {
  return sessionStorage.removeItem(superAdminTokenName);
};

export const removeAddCommiUserToken = () => {
  return sessionStorage.removeItem(AddCommiTokenName);
};

export const removeDeputyCommToken = () => {
  return sessionStorage.removeItem(deputyCommTokenName);
};

export const removeChananisamitiUserToken = () => {
  return sessionStorage.removeItem(chananisamitiTokenName);
};

export const removeChananisamiti1UserToken = () => {
  return sessionStorage.removeItem(chananisamitiTokenName1);
};

export const removeChananisamiti2UserToken = () => {
  return sessionStorage.removeItem(chananisamitiTokenName2);
};
export const removeChananisamiti3UserToken = () => {
  return sessionStorage.removeItem(chananisamitiTokenName3);
};
export const removeChananisamiti4UserToken = () => {
  return sessionStorage.removeItem(chananisamitiTokenName4);
};

export const removeShaskiySamitiToken = () => {
  return sessionStorage.removeItem(shaskiysamityTokenName);
};

export const removeShaskiySamiti1Token = () => {
  return sessionStorage.removeItem(shaskiysamityTokenName1);
};

export const removeShaskiySamiti2Token = () => {
  return sessionStorage.removeItem(shaskiysamityTokenName2);
};

export const removeShaskiySamiti3Token = () => {
  return sessionStorage.removeItem(shaskiysamityTokenName3);
};

export const removeShaskiySamiti4Token = () => {
  return sessionStorage.removeItem(shaskiysamityTokenName4);
};

export const removeShaskiySamiti5Token = () => {
  return sessionStorage.removeItem(shaskiysamityTokenName5);
};

export const removeClerk1UserToken = () => {
  return sessionStorage.removeItem(clerkUserTokenName1);
};

export const removeClerk2UserToken = () => {
  return sessionStorage.removeItem(clerkUserTokenName2);
};

export const removeClerk3UserToken = () => {
  return sessionStorage.removeItem(clerkUserTokenName3);
};

// Sport users

export const removeSportClerk1UserToken = () => {
  return sessionStorage.removeItem(sportclerkTokenName1);
};

export const removeSportClerk2UserToken = () => {
  return sessionStorage.removeItem(sportclerkTokenName2);
};

export const removeSportChanani1UserToken = () => {
  return sessionStorage.removeItem(sportchananiTokenName1);
};

export const removeSportChanani2UserToken = () => {
  return sessionStorage.removeItem(sportchananiTokenName2);
};
export const removeSportChanani3UserToken = () => {
  return sessionStorage.removeItem(sportchananiTokenName3);
};
export const removeSportChanani4UserToken = () => {
  return sessionStorage.removeItem(sportchananiTokenName4);
};
export const removeSportChanani5UserToken = () => {
  return sessionStorage.removeItem(sportchananiTokenName5);
};
export const removeSportShaskiy1UserToken = () => {
  return sessionStorage.removeItem(sportshaskiysamityTokenName1);
};
export const removeSportShaskiy2UserToken = () => {
  return sessionStorage.removeItem(sportshaskiysamityTokenName2);
};
export const removeSportShaskiy3UserToken = () => {
  return sessionStorage.removeItem(sportshaskiysamityTokenName3);
};
export const removeSportShaskiy4UserToken = () => {
  return sessionStorage.removeItem(sportshaskiysamityTokenName4);
};
export const removeSportShaskiy5UserToken = () => {
  return sessionStorage.removeItem(sportshaskiysamityTokenName5);
};

export const removeSpordeputyUserToken = () => {
  return sessionStorage.removeItem(sportdeputyCommTokenName);
};
export const removeSportaddcommUserToken = () => {
  return sessionStorage.removeItem(sportAddCommiTokenName);
};
// export const getIndustryUserToken = () => {
//   return sessionStorage.setItem(industryUserTokenName);
// };

export const blobToFile = (data) => {
  return data ? window.URL.createObjectURL(data) : null;
};

export const truncateText = (text, limit) => {
  const words = text.split(" ");
  if (words.length > limit) {
    return words.slice(0, limit).join(" ") + " ...";
  }
  return text;
};

// admin panel user models
export const statusMessage = (app, role) => {
  switch (role) {
    case "Clerk1":
      return app?.statusbyclerk;
    case "Clerk2":
      return app?.statusbyclerk;
    case "Clerk3":
      return app?.statusbyclerk;
    case "Deputy Commissioner":
      return app?.statusbydeputy;
    case "Chanani Samiti1":
      return app?.statusbychanani1;
    case "Chanani Samiti2":
      return app?.statusbychanani2;
    case "Chanani Samiti3":
      return app?.statusbychanani3;
    case "Chanani Samiti4":
      return app?.statusbychanani4;
    case "Additional Commissioner":
      return app?.statusbyadditional;
    case "Shaskiy Samiti1":
      return app?.statusbyshaskiya1;
    case "Shaskiy Samiti2":
      return app?.statusbyshaskiya2;
    case "Shaskiy Samiti3":
      return app?.statusbyshaskiya3;
    case "Shaskiy Samiti4":
      return app?.statusbyshaskiya4;
    case "Shaskiy Samiti5":
      return app?.statusbyshaskiya5;
    case "Super Admin":
      return app?.permission_status;
    default:
      return "No Status Available";
  }
};

export const statusMessageforButton = (data, role) => {
  switch (role) {
    case "Clerk1":
      return data?.statusbyclerk === "Approved" ? "Approved" : "Approve";
    case "Clerk2":
      return data?.statusbyclerk === "Approved" ? "Approved" : "Approve";
    case "Clerk3":
      return data?.statusbyclerk === "Approved" ? "Approved" : "Approve";
    case "Deputy Commissioner":
      return data?.statusbydeputy === "Approved" ? "Approved" : "Approve";
    case "Chanani Samiti1":
      return data?.statusbychanani1 === "Approved" ? "Approved" : "Approve";
    case "Chanani Samiti2":
      return data?.statusbychanani2 === "Approved" ? "Approved" : "Approve";
    case "Chanani Samiti3":
      return data?.statusbychanani3 === "Approved" ? "Approved" : "Approve";
    case "Chanani Samiti4":
      return data?.statusbychanani4 === "Approved" ? "Approved" : "Approve";
    case "Additional Commissioner":
      return data?.statusbyadditional === "Approved" ? "Approved" : "Approve";
    case "Shaskiy Samiti1":
      return data?.statusbyshaskiya1 === "Approved" ? "Approved" : "Approve";
    case "Shaskiy Samiti2":
      return data?.statusbyshaskiya2 === "Approved" ? "Approved" : "Approve";
    case "Shaskiy Samiti3":
      return data?.statusbyshaskiya3 === "Approved" ? "Approved" : "Approve";
    case "Shaskiy Samiti4":
      return data?.statusbyshaskiya4 === "Approved" ? "Approved" : "Approve";
    case "Shaskiy Samiti5":
      return data?.statusbyshaskiya5 === "Approved" ? "Approved" : "Approve";
    case "Super Admin":
      return data?.permission_status === "Approved" ? "Approved" : "Approve";
    default:
      return "No Status Available";
  }
};

// myapplide schemes
export const isRejected = (app) =>
  app.statusbyclerk === "Rejected" ||
  app.statusbychanani1 === "Rejected" ||
  app.statusbychanani2 === "Rejected" ||
  app.statusbychanani3 === "Rejected" ||
  app.statusbychanani4 === "Rejected" ||
  app.statusbydeputy === "Rejected" ||
  app.statusbyshaskiya1 === "Rejected" ||
  app.statusbyshaskiya2 === "Rejected" ||
  app.statusbyshaskiya3 === "Rejected" ||
  app.statusbyshaskiya4 === "Rejected" ||
  app.statusbyshaskiya5 === "Rejected" ||
  app.statusbyadditional === "Rejected" ||
  app.permission_status === "Rejected";
