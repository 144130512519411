import React from 'react';
import SchemeThreeRejectedApplications from '../../Administration/SchemeThree/SchemeThreeRejectedApplications';

const Clerk2SchemeThreeRejectedApplications = () => {
  return (
    <div><SchemeThreeRejectedApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeThreeRejectedApplications;
