import React from 'react';
import SchemeEightapprovedapplications from '../../Administration/SchemeEight/SchemeEightapprovedapplications';

const Clerk2SchemeEightApprovedApplications = () => {
  return (
    <div><SchemeEightapprovedapplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeEightApprovedApplications;
