import React from 'react';
import SchemeTwoPendingApplications from '../../Administration/SchemeTwo/SchemeTwoPendingApplications';

const Clerk2SchemeTwoPendingApplications = () => {
  return (
    <div><SchemeTwoPendingApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeTwoPendingApplications;
