import React from "react";
import { Typography, Card, CardContent, Divider, Button } from "@mui/material";

const SchemeTwoDetails = () => {
  return (
    <div>
      <div
        className="container-fluid"
        style={{
          height: "100px",
          backgroundColor: "#98c1d9",
          color: "black",
          fontSize: "27px",
          fontWeight: "500",
          padding: "0px 20px",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        राष्ट्रीय क्रीडा शिष्यवृत्ती योजना
      </div>

      <div style={{ padding: "20px", backgroundColor: "#eaf6ff" }}>
        <Card style={{ marginBottom: "20px" }}>
          <CardContent>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              प्रस्तावना
            </Typography>
            <Divider style={{ margin: "10px 0" }} />
            <Typography variant="body1"  style={{ color: "#1a1a1a" }}>
              नाशिक महानगरपालिका, क्रीडा विभागाच्या माध्यमातून राष्ट्रीय क्रीडा स्पर्धांमध्ये उल्लेखनीय यश मिळविणाऱ्या खेळाडूंना आर्थिक सहाय्य देण्यासाठी "राष्ट्रीय क्रीडा शिष्यवृत्ती योजना" उपलब्ध करून दिली आहे. या योजनेअंतर्गत खेळाडूंना त्यांच्या क्रीडा कौशल्याचा विकास करण्यासाठी आर्थिक मदत दिली जाते.
            </Typography>
          </CardContent>
        </Card>

        <Card style={{ marginBottom: "20px" }}>
          <CardContent>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              लाभ
            </Typography>
            <Divider style={{ margin: "10px 0" }} />
            <ul style={{ paddingLeft: "20px" }}>
              <li>प्रथम स्थान: रु. 50,000/- आर्थिक सहाय्य</li>
              <li>द्वितीय स्थान: रु. 30,000/- आर्थिक सहाय्य</li>
              <li>तृतीय स्थान: रु. 25,000/- आर्थिक सहाय्य</li>
              <li>क्रीडा साहित्य खरेदीसाठी मदत</li>
              <li>क्रीडा कौशल्यांचा विकास आणि प्रोत्साहन</li>
            </ul>
          </CardContent>
        </Card>

        <Card style={{ marginBottom: "20px" }}>
          <CardContent>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              अटी व शर्तीं
            </Typography>
            <Divider style={{ margin: "10px 0" }} />
            <ul style={{ paddingLeft: "20px" }}>
             <li>मान्यताप्राप्त खेळ: ही शिष्यवृत्ती भारतीय ऑलिम्पिक, पॅरा ऑलिम्पिक, अथवा स्पोर्ट्स अथॉरिटी ऑफ इंडिया (स्पोर्ट मिनिस्ट्री) द्वारे मान्यता प्राप्त खेळांकरिता लागू आहे.</li>
              <li>महाराष्ट्र शासन मान्यता: महाराष्ट्र शासनाद्वारे मान्यता प्राप्त शालेय, महाविद्यालयीन, राज्यस्तरीय, राष्ट्रीय, व आंतरराष्ट्रीय क्रीडा स्पर्धांमध्ये प्राविण्य मिळवलेल्या खेळाडूंसाठी शिष्यवृत्ती उपलब्ध आहे.</li>
              <li>स्थानिक खेळाडू: ही शिष्यवृत्ती फक्त नाशिक महानगरपालिका कार्यक्षेत्रातील खेळाडूंसाठी उपलब्ध आहे. यासाठी किमान दोन वर्षांचा रहिवासी पुरावा आवश्यक आहे (जसे की आधार कार्ड, रेशन कार्ड, मालमत्ता कर पावती, वीज बिल इ.).</li>
              <li>अधिकृत प्रमाणपत्र: अर्जदाराने संबंधित असोसिएशन/संघटना द्वारे प्रमाणित केलेले प्राविण्य प्रमाणपत्र सादर करणे आवश्यक आहे.</li>
              <li>खासगी स्पर्धांचे अपात्रता: खासगी/आमंत्रित स्पर्धांमध्ये मिळवलेल्या प्राविण्याचे प्रमाणपत्र ग्राह्य धरले जाणार नाही. </li>
              <li> अर्जाची मुदत: शिष्यवृत्तीसाठी अर्ज प्रमाणपत्र मिळाल्यानंतर एक वर्षाच्या आत दाखल करणे आवश्यक आहे. </li>
              <li>हक्काचा दावा नाही: शिष्यवृत्ती देणे हे समितीच्या अधिकारात असून खेळाडूस शिष्यवृत्तीवर हक्क सांगता येणार नाही.</li>
              <li>तपासणी आणि निवड: सर्व अर्जांचे तपासणी समिती करतील आणि त्यांचा निर्णय अंतिम असेल. पात्र ठरलेल्या लाभार्थ्यांच्या खात्यात शिष्यवृत्तीची रक्कम जमा केली जाईल.</li>
              <li> प्रारंभ कालावधी: ही शिष्यवृत्ती एप्रिल २०२३ पासून लागू आहे.</li>
              
              <li>या अटी व शर्तींच्या अधीन रहून शिष्यवृत्ती लाभ मिळवता येईल.</li>
            </ul>
          </CardContent>
        </Card>

        <Card style={{ marginBottom: "20px" }}>
          <CardContent>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              आवश्यक कागदपत्रे
            </Typography>
            <Divider style={{ margin: "10px 0" }} />
            <ul style={{ paddingLeft: "20px" }}>
              <li>आधार कार्ड किंवा पॅन कार्ड</li>
              <li>शाळेचे माणप किंवा डीएसओ माणप</li>
              <li>रहिवासाचा पुरावा (जसे की मालमता कर पावती, लाईट बिल)</li>
              <li>बँक खाते तपशील</li>
              <li>खेळातील यशाचे प्रमाणपत्रे</li>
            </ul>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              अर्ज प्रक्रिया
            </Typography>
            <Divider style={{ margin: "10px 0" }} />
            <ul style={{ paddingLeft: "20px" }}>
              <li>अर्ज सादरीकरण:  सदरचा अर्ज समक्ष अथवा रजिष्टर पोस्टाने  मा. उप आयुक्त (समाज कल्याण ) क्रीडा विभाग, तळ मजला, राजीव गांधी भवन, 
                नाशिक महानगरपालिका, शरणपूर रोड नाशिक  यांच्याकडे सर्व दस्तऐवजासह पाठविण्यात यावा.  
                अपूर्ण  कागदपत्र असलेल्या अर्जाचा विचार केला जाणार नाही </li>
              <li>सर्व आवश्यक कागदपत्रांसह अर्ज जमा करणे आवश्यक</li>
              <li>अर्ज नाशिक महानगरपालिकेच्या क्रीडा विभागात सादर करावा</li>
              <li>पात्रता तपासणीनंतर आर्थिक सहाय्य बँक खात्यावर थेट जमा केले जाईल</li>
            </ul>

            <div style={{ marginTop: "20px" }}>
              <Button
                variant="contained"
                className="bluebutton"
                href="/path-to-brochure.pdf"
                download
                style={{ marginRight: "10px" }}
              >
                Download Brochure
              </Button>
              <Button
                variant="contained"
                className="redbutton"
                color="secondary"
              >
                Apply Now
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default SchemeTwoDetails;
