import React from "react";
import SchemeSixapprovedapplications from "../../Administration/SchemeSix/SchemeSixapprovedapplications";

const ShaskiySamiti2SchemeSixApprovedApplications = () => {
    return (
        <div>
            <SchemeSixapprovedapplications role="Shaskiy Samiti2" />
        </div>
    );
};

export default ShaskiySamiti2SchemeSixApprovedApplications;
