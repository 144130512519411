import React from "react";
import SchemeNinePendingApplications from "../../Administration/schemeNine/SchemeNinePendingApplications";

const AdditionalCommSchemeNinePendingApplications = () => {
  return (
    <div>
      <SchemeNinePendingApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeNinePendingApplications;
