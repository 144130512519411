import { removeSportShaskiy3UserToken } from "../../common";
import { sportshaskiysamityTokenName3 } from "../../config";

const getshaskiysamitiFromLocalStorage = () => {
  try {
    const sportshaskiysamiti3 = JSON.parse(
      sessionStorage.getItem(sportshaskiysamityTokenName3)
    );
    if (
      sportshaskiysamiti3 &&
      sportshaskiysamiti3.authToken &&
      sportshaskiysamiti3.loggedInTime
    ) {
      console.log("Returning Internal User:", sportshaskiysamiti3);
      return sportshaskiysamiti3;
    } else {
      removeSportShaskiy3UserToken();
      return null;
    }
  } catch (error) {
    removeSportShaskiy3UserToken();
    return null;
  }
};

const getInitial = () => {
  return getshaskiysamitiFromLocalStorage();
};

const sportshaskiySamity3Reducer = (state = getInitial(), action) => {
  switch (action.type) {
    case "LOGIN_SPORT_SHASKIY_SAMITI3":
      state = {};
      const time = action.payload.loggedInTime;
      if (time) {
        state.loggedInTime = time;
      } else {
        state.loggedInTime = Date.now();
      }
      state.authToken = action.payload.authToken;
      sessionStorage.setItem(
        sportshaskiysamityTokenName3,
        JSON.stringify(state)
      );
      return state;
    case "LOGOUT_SPORT_SHASKIY_SAMITI3":
      removeSportShaskiy3UserToken();
      return null;
    case "UPDATE_SPORT_SHASKIY3_DASHBOARD":
      if (state) {
        state = {
          ...state,
          dashboard: {
            ...state.dashboard,
            ...action.payload,
          },
        };
      }
      return state;
    case "CLEAR_SPORT_SHASKIY3_DASHBOARD":
      state.dashboard = null;
    default:
      return state;
  }
};

export default sportshaskiySamity3Reducer;
