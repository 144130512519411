import React from 'react';
import SchemeSixRejectedApplications from '../../Administration/SchemeSix/SchemeSixRejectedApplications';

const Clerk3SchemeSixRejectedApplications = () => {
  return (
    <div><SchemeSixRejectedApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeSixRejectedApplications;
