import React from "react";
import SportSchemeTwoTotalApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoTotalApplications";

const SportClerk2SchemeTwoTotalApplications = () => {
  return (
    <div>
      <SportSchemeTwoTotalApplications role="Sport Clerk2" />
    </div>
  );
};

export default SportClerk2SchemeTwoTotalApplications;
