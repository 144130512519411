import React from "react";
import SchemeSixRejectedApplications from "../../Administration/SchemeSix/SchemeSixRejectedApplications";

const Chanani4SchemeSixApprovedApplications = () => {
    return (
        <div>
            <SchemeSixRejectedApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeSixApprovedApplications;
