import React from "react";
import SportSchemeOneRejectedApplications from "../../../Administration/SportSchemeOne/SportSchemeOneRejectedApplications";

const SportClerk1SchemeOneRejectedApplications = () => {
  return (
    <div>
      <SportSchemeOneRejectedApplications role="Sport Clerk1" />
    </div>
  );
};

export default SportClerk1SchemeOneRejectedApplications;
