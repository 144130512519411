import React from 'react'
import ContactEntries from "../Administration/ContactEntries";


const ClerkContactEnquiries = () => {
  return (
    <div>  <ContactEntries /></div>
  )
}

export default ClerkContactEnquiries