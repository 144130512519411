import React from "react";
import SportSchemeTwoApprovedApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoApprovedApplications";

const SportChanani1SchemeTwoApprovedApplications = () => {
  return (
    <div>
      <SportSchemeTwoApprovedApplications role="Sport Chanani1" />
    </div>
  );
};

export default SportChanani1SchemeTwoApprovedApplications;
