import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import { server } from "../../common";
import { toast } from "react-toastify";
import { useSelectAccess } from "../../store/stateFunctions";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import UserApprovalTable from "../../Administration/UserApprovalTable";

const initialValues = {
  rejection_reason: "",
};

function SchemeNineUserModal({ show, handleClose, id, role }) {
  const [data, setData] = useState(null);
  const user = useSelectAccess("Super Admin");
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const [customReason, setCustomReason] = useState(""); // State to manage custom reason
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setIsOtherSelected(selectedValue === "इतर ");
    if (selectedValue !== "इतर ") {
      formik.setFieldValue("rejection_reason", selectedValue); // Update Formik value
    } else {
      setCustomReason(""); // Reset custom reason when "इतर " is selected
    }
  };

  const handleCustomReasonChange = (e) => {
    const value = e.target.value;
    setCustomReason(value);
    formik.setFieldValue("rejection_reason", value); // Update Formik with custom input
  };

  useEffect(() => {
    if (show && id) {
      getData(id);
    }
  }, [show, id]);

  const getData = (applicationId) => {
    server
      .get(`/nineappl/nineapplication/${applicationId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        console.log("API response", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else {
          toast.error(error.response?.data?.error || "An error occurred");
        }
      });
  };

  // Update Status function
  const updateStatus = async (status) => {
    try {
      await server.post(
        `/nineappl/ninefinalperm/${id}`,
        { permission_status: status },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: user.authToken,
          },
        }
      );
      toast.success(`Application Status ${status} successfully`);
      getData(id); // Reload data after status update
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data?.message)
        toast.error(error.response.data.message);
      else if (error.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Failed to connect to the server");
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      rejection_reason: Yup.string()
        .max(250)
        .required("Please select an image"),
    }),
    onSubmit: (values, action) => {
      server
        .post(`/nineappl/rejectionreason/${id}`, values, {
          headers: {
            "Content-Type": "application/json",
            Authorization: user.authToken,
          },
        })
        .then(function (response) {
          if (response.status === 200 || response.status === 201) {
            toast.success(response.data.message);
            formik.resetForm();
            handleCloseModal();
            updateStatus("Rejected");
          }
        })
        .catch(function (error) {
          if (error.response?.data?.message)
            toast.error(error.response.data.message);
          else if (error.response?.data?.error) {
            toast.error(error.response.data.error);
          } else toast.error("Failed to connect to server");
        });
    },
  });

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            Application Details for मतीमंद तसेच बहुविकलांग जे ७० ते १००% वरील
            दिव्यांग आहेत त्यांना अर्थसहाय्य योजना. <br />
            (Application No. {data?.id})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data ? (
            <>
              <Table bordered striped>
                <thead>
                  <tr>
                    <th colSpan="4">Application Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="fw-bold">
                      <td className="fw-bold">अँप्लिकेशन क्रमांक</td>
                    </td>
                    <td>{data.id}</td>
                    <td className="fw-bold">संपर्क </td>
                    <td>{data.mobile}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">अर्जदाराचे संपूर्ण नाव</td>
                    <td>{data.Fullname}</td>
                    <td className="fw-bold">अर्जदार/ पाल्याची जन्मतारीख</td>
                    <td>{data.bod}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">मोबाईल क्रमांक </td>
                    <td>{data.mobile}</td>
                    <td className="fw-bold">आधार क्रमांक </td>
                    <td>{data.adhar_number}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">संपूर्ण पत्ता </td>
                    <td>{data.address}</td>
                    <td className="fw-bold">प्रभाग </td>
                    <td>{data.division}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold">वार्ड/विभाग क्रमांक</td>
                    <td>{data.ward_no}</td>
                    <td className="fw-bold">किती वर्षापासून रहिवासी आहात</td>
                    <td>{data.resident_duration_years}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold">
                      योजने अंतर्गत आवश्यक चलन वलन साहित्याचे नाव 
                    </td>
                    <td>{data.exchange_equipment_list}</td>
                    <td className="fw-bold">कुटुंबाचे वार्षिक उत्पन्न </td>
                    <td>{data.family_annualincome}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">
                      अर्जदार शिकत असलेला शिक्षण संस्थेचे नाव
                    </td>
                    <td>{data.applicant_schoolname}</td>
                    <td className="fw-bold">
                      शिक्षण संस्थेचा दूरध्वनी क्रमांक
                    </td>
                    <td>{data.family_annualincome}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold"> चलनवलन साहित्याची रक्‍कम</td>
                    <td>{data.currency_literature_amount}</td>
                    <td className="fw-bold">अपंगत्वाचा प्रकार  </td>
                    <td>{data.school_mobileno}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">अर्जदार शिकत असलेला वर्ग</td>
                    <td>{data.child_study_classroom}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">मॉडयूल नंबर</td>
                    <td>{data.module_number}</td>
                    <td className="fw-bold">कंपनी नाव </td>
                    <td>{data.company_name}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">
                      सदयस्थितीत चलन वलन साहित्य वापरत आहे का ?
                    </td>
                    <td>{data.using_conversion_materials}</td>
                    <td className="fw-bold">
                      {" "}
                      चलन वलन साहित्य सुस्थितीत आहे का ?  
                    </td>
                    <td>{data.currency_literature_condition}</td>
                  </tr>
                  <tr>
                    <td className="fw-bold">बँकेचे नावं</td>
                    <td>{data.bankname}</td>
                    <td className="fw-bold">बँक शाखा</td>
                    <td>{data.branch}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold">खातेदाराचे नाव</td>
                    <td>{data.ac_holder_name}</td>
                    <td className="fw-bold">खाते क्रमांक</td>
                    <td>{data.account_no}</td>
                  </tr>

                  <tr>
                    <td className="fw-bold">IFSC Code</td>
                    <td>{data.ifsc_code}</td>
                    <td className="fw-bold">MICR Code</td>
                    <td>{data.micr_code}</td>
                  </tr>
                </tbody>
              </Table>

              <Table bordered striped>
                <thead>
                  <tr>
                    <th colSpan="5" className="text-dark">
                      Document Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>शारिरीक अपंगत्व दर्शविणारा स्वसाक्षांकीत फोटो</td>
                    <td>
                      <a
                        href={data.passport_photo}
                        target="_blank"
                        className="btn btn-success btn-sm"
                      >
                        View
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>आधार कार्ड / पावती</td>
                    <td>
                      <a
                        href={data.upload_adhar}
                        target="_blank"
                        className="btn btn-success btn-sm"
                      >
                        View
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>पॅन कार्ड</td>
                    <td>
                      <a
                        href={data.upload_pancard}
                        target="_blank"
                        className="btn btn-success btn-sm"
                      >
                        View
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>बँक पासबुक</td>
                    <td>
                      <a
                        href={data.upload_bankpassbook}
                        target="_blank"
                        className="btn btn-success btn-sm"
                      >
                        View
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>अपांगत्वाचे प्रमाणपत्र</td>
                    <td>
                      <a
                        href={data.certificate_of_disability}
                        target="_blank"
                        className="btn btn-success btn-sm"
                      >
                        View
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td>जन्माचा दाखला अथवा वयाचा पुरावा</td>
                    <td>
                      <a
                        href={data.birth_or_age_proof}
                        target="_blank"
                        className="btn btn-success btn-sm"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>उत्पन्नाचा दाखला</td>
                    <td>
                      <a
                        href={data.income_certificate}
                        target="_blank"
                        className="btn btn-success btn-sm"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>स्वयंघोषणापत्र</td>
                    <td>
                      <a
                        href={data.self_declaration}
                        target="_blank"
                        className="btn btn-success btn-sm"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>स्वयं साक्षांकनासाठी स्वयं घोषणापत्र</td>
                    <td>
                      <a
                        href={data.self_attestation_declaration}
                        target="_blank"
                        className="btn btn-success btn-sm"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      यापैकी कोणतेही दोन पुरावे (पाणी बिल / वीजबिल / मालमत्ता कर
                      पावती / वास्तव्याचा करारनामा / रेशनकार्ड)
                    </td>
                    <td>
                      <a
                        href={data.proof1}
                        target="_blank"
                        className="btn btn-success btn-sm"
                      >
                        View
                      </a>
                      <br />
                      <a
                        href={data.proof2}
                        target="_blank"
                        className="btn btn-success btn-sm mt-2"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                </tbody>
              </Table>

              <UserApprovalTable data={data} />

              <div className="form-check my-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="termsCheck"
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="termsCheck">
                  वरील अर्जदाराची सर्व माहिती व कागदपत्रे योग्य आहेत. मी याबाबत
                  सर्व तपशीलांची पडताळणी केली आहे. तरी सदर अर्ज पुढील कारवाईसाठी
                  सादर करीत आहे.
                </label>
              </div>

              <button
                className="btn btn-info"
                onClick={() => updateStatus("Approved")}
                disabled={
                  !isChecked ||
                  data?.permission_status === "Approved" ||
                  data?.permission_status === "Rejected"
                }
              >
                {data.permission_status === "Raised" ? "Approve" : "Approved"}
              </button>
              <button
                className="btn btn-danger ms-2"
                onClick={handleShowModal}
                disabled={
                  !isChecked ||
                  data?.permission_status === "Rejected" ||
                  data?.permission_status === "Approved"
                }
              >
                Reject
              </button>
            </>
          ) : (
            "Loading..."
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for file upload */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Please Give Reject Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            {/* <input
              type="text"
              name="rejection_reason"
              className="form-control"
              onChange={formik.handleChange}
              value={formik.values.rejection_reason}
            /> */}
            <div>
              <select
                name="rejection_reason"
                onChange={handleSelectChange}
                value={
                  isOtherSelected ? "इतर " : formik.values.rejection_reason
                }
                className="form-control"
              >
                <option value="राशनकार्डमध्ये खाडाखोड आहे.">
                  राशनकार्डमध्ये खाडाखोड आहे.
                </option>
                <option value="दिव्यांग व्यक्तींचे नाव राशनकार्डमध्ये समाविष्ट नाही.">
                  दिव्यांग व्यक्तींचे नाव राशनकार्डमध्ये समाविष्ट नाही.
                </option>
                <option value="राशनकार्ड मनपा हद्दीबाहेरचे आहे.">
                  राशनकार्ड मनपा हद्दीबाहेरचे आहे.
                </option>
                <option value="वीजबिलाच्या नावात तफावत आहे.">
                  वीजबिलाच्या नावात तफावत आहे.
                </option>
                <option value="रहिवासी पुरावे अपुरे आहेत किंवा एकच पुरावा आहे.">
                  रहिवासी पुरावे अपुरे आहेत किंवा एकच पुरावा आहे.
                </option>
                <option value="वास्तव्याच्या करारनाम्याची मुदत संपलेली आहे.">
                  वास्तव्याच्या करारनाम्याची मुदत संपलेली आहे.
                </option>
                <option value="करारनामा नोटरीद्वारे प्रमाणित नाही.">
                  करारनामा नोटरीद्वारे प्रमाणित नाही.
                </option>
                <option value="आधारकार्ड उपलब्ध नाही.">
                  आधारकार्ड उपलब्ध नाही.
                </option>
                <option value="यु.डी.आय.डी. कार्ड किंवा दिव्यांग प्रमाणपत्र उपलब्ध नाही.">
                  यु.डी.आय.डी. कार्ड किंवा दिव्यांग प्रमाणपत्र उपलब्ध नाही.
                </option>
                <option value="दिव्यांग प्रमाणपत्र कालबाह्य आहे.">
                  दिव्यांग प्रमाणपत्र कालबाह्य आहे.
                </option>
                <option value="वयाचा पुरावा उपलब्ध नाही.">
                  वयाचा पुरावा उपलब्ध नाही.
                </option>
                <option value="बँक पासबुकची प्रत अस्पष्ट आहे.">
                  बँक पासबुकची प्रत अस्पष्ट आहे.
                </option>
                <option value="पॅनकार्ड उपलब्ध नाही.">
                  पॅनकार्ड उपलब्ध नाही.
                </option>
                <option value="अर्जदार स्थानिक रहिवासी नाही.">
                  अर्जदार स्थानिक रहिवासी नाही.
                </option>
                <option value="बोनाफाईड प्रमाणपत्र उपलब्ध नाही.">
                  बोनाफाईड प्रमाणपत्र उपलब्ध नाही.
                </option>
                <option value="फॉर्मवर शाळेची सही आणि शिक्का नाही.">
                  फॉर्मवर शाळेची सही आणि शिक्का नाही.
                </option>
                <option value="अर्जदाराचे वय निकषात बसत नाही.">
                  अर्जदाराचे वय निकषात बसत नाही.
                </option>
                <option value="अर्जदाराचा पत्ता मनपा कार्यक्षेत्राबाहेर आहे.">
                  अर्जदाराचा पत्ता मनपा कार्यक्षेत्राबाहेर आहे.
                </option>
                <option value="अर्जदाराला मिळणारा लाभ मनपा कार्यक्षेत्रापुरता मर्यादित आहे.">
                  अर्जदाराला मिळणारा लाभ मनपा कार्यक्षेत्रापुरता मर्यादित आहे.
                </option>
                <option value="इतर ">इतर</option>
              </select>
              {isOtherSelected && (
                <>
                  <h6 className="m-2">Add Other Remark</h6>
                  <textarea
                    type="text"
                    name="custom_reason"
                    className="form-control mt-2"
                    placeholder="कृपया कारण लिहा"
                    value={customReason}
                    onChange={handleCustomReasonChange}
                  />
                </>
              )}
              {formik.errors.rejection_reason ? (
                <p className="text-danger">{formik.errors.rejection_reason}</p>
              ) : null}
            </div>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                // onClick={() => updateStatus("REJECTED")}
              >
                Submit
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SchemeNineUserModal;
