import React from "react";
import SchemeFiveTotalApplications from "../../Administration/SchemeFive/SchemeFiveTotalApplications";

const Chanani2SchemeFiveTotalApplications = () => {
  return (
    <div>
      <SchemeFiveTotalApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeFiveTotalApplications;
