import React from 'react';
import SchemeFourRejectedApplications from '../../Administration/SchemeFour/SchemeFourRejectedApplications';

const Clerk3SchemeFourRejectedApplications = () => {
  return (
    <div><SchemeFourRejectedApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeFourRejectedApplications;
