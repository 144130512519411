import React from 'react';
import SchemeThreePendingApplications from '../../Administration/SchemeThree/SchemeThreePendingApplications';

const Clerk2SchemeThreePendingApplications = () => {
  return (
    <div><SchemeThreePendingApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeThreePendingApplications;
