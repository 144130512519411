import React from "react";
import SchemeSixapprovedapplications from "../../Administration/SchemeSix/SchemeSixapprovedapplications";

const Chanani2SchemeSixApprovedApplications = () => {
  return (
    <div>
      <SchemeSixapprovedapplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeSixApprovedApplications;
