import React, { useContext, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { TextField } from "@mui/material";
import { AxiosError } from "axios";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { server } from "../../common";
import { useFormik } from "formik";
import { NavLink } from "react-router-dom";
import configContext from "../../configContext/configContext";

const initialValues = {
  email: "",
};

const ForgotPassword = () => {
  const [processing, setProcessing] = useState(false);
  const { setProgress } = useContext(configContext);

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Enter a valid email")
        .required("Please Enter Your Email"),
    }),
    onSubmit: (values, action) => {
      setProcessing(true);
      setProgress(10);
      console.log(values);
      server
        .post("/user/resetlink", values, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          console.log("api response", response.data);
          if (response.status === 200 || response.status === 201) {
            if (response.data) {
              toast.success(
                "Reset Link Successfully Sent to your Email Please Check Your Email"
              );
              setProgress(100);
            }
            setProcessing(false);
          }
        })
        .catch(function (error) {
          if (error instanceof AxiosError && error.response?.data?.message)
            toast.error(error.response.data.message);
          else if (error.response?.data?.error) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Failed to connect to server");
          }
          setProcessing(false);
          setProgress(100);
        });
    },
  });

  return (
    <>
      <div>
        <section className="vh-100">
          <div className="container py-3 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                <div className="card shadow-sm">
                  <div className="card-body p-4 text-center">
                    <NavLink to="/login" className="my-4">
                      <AiOutlineArrowLeft /> Back to Login
                    </NavLink>
                    <h4 className="mb-3">Forget Password</h4>
                    <form onSubmit={handleSubmit}>
                      <div className="form-outline mb-4">
                        <TextField
                          name="email"
                          margin="dense"
                          value={values.email}
                          onChange={handleChange}
                          type="email"
                          placeholder="LoremIpsum@gmail.com"
                          variant="outlined"
                          label="Email Id"
                          fullWidth
                          required
                        ></TextField>
                      </div>

                      <button
                        type="submit"
                        className="btn bluebutton text-white w-100 mb-2"
                      >
                        Submit
                      </button>
                      <p>
                        <i>
                          We will send an reset password link to your registered
                          email. You can follow and complete the given steps to
                          reset your account password
                        </i>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ForgotPassword;
