import React from "react";
import SchemeNineapprovedapplications from "../../Administration/schemeNine/SchemeNineapprovedapplications";

const Chanani1SchemeNineApprovedApplications = () => {
    return (
        <div>
            <SchemeNineapprovedapplications role="Chanani Samiti1" />
        </div>
    );
};

export default Chanani1SchemeNineApprovedApplications;
