// import React from "react";
// import { useSelector } from "react-redux";
// import { Navigate, Outlet } from "react-router-dom";
// import { Button } from "@mui/material";
// import SuperAdminSidebarTwo from "../superadmin/SuperAdminSidebarTwo";

// const SuperAdminContainer = () => {
//   document.title = "Commissioner ";
//   const superadmin = useSelector((store) => store.superadmin);

//   return (
//     <>
//       <div className="row ">
//         <SuperAdminSidebarTwo role="PWD Department" />
//         <div className="container">
//           {superadmin ? <Outlet /> : <Navigate to="/super-admin" />}
//         </div>
//       </div>
//     </>
//   );
// };

// export default SuperAdminContainer;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getCookie, server } from "../common";
import configContext from "../configContext/configContext";

// import Sidebar from "../Administration/Sidebar";
import SuperAdminSidebarTwo from "../superadmin/SuperAdminSidebarTwo";
import { updateDashboardState, useSelectAccess } from "../store/stateFunctions";

export default function SuperAdminSessionContainer() {
  const navigate = useNavigate();
  document.title = "Super Admin Panel";
  const superadmin = useSelectAccess("Super Admin");
  const [processing, setProcessing] = useState(false);
  const fetched = useRef(false);
  const setFetched = (value) => (fetched.current = value);

  const location = useLocation();
  const { setProgress } = useContext(configContext);

  useEffect(() => {
    // Navigate to "/superadmin" if superadmin access is not available
    if (!superadmin) {
      navigate("/super-admin", { state: { from: location }, replace: true });
      return;
    }

    // If superadmin is valid, fetch admin profile
    setProcessing(true);
    setProgress(10);
    server
      .get("/admin/adminprofile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: superadmin.authToken,
        },
      })
      .then((response) => {
        updateDashboardState("Super Admin", response.data);
        setProgress(100);
        setProcessing(false);
        setFetched(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          navigate("/super-admin", {
            state: { from: location },
            replace: true,
          });
        } else {
          setProgress(100);
          setProcessing(false);
          console.error(
            "error",
            error.message,
            error.response?.status,
            error.response?.data,
            error
          );
        }
      });

    document.title = "Clerk Dashboard";
  }, [
    // superadmin,
    navigate,
    location,
    setProgress,
  ]);

  if (superadmin)
    return (
      <>
        <SuperAdminSidebarTwo
          role="Super Admin"
          username={superadmin.dashboard && superadmin.dashboard.name}
        />
        {superadmin.dashboard && <Outlet />}
        {!superadmin.dashboard && fetched.current && (
          <h4 className="text-center">Cannot connect to server</h4>
        )}
        {!superadmin.dashboard && !fetched.current && (
          <h4 className="text-center">Loading Super Admin Panel</h4>
        )}
      </>
    );

  return null;
}
