import React from "react";
import SportSchemeTwoRejectedApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoRejectedApplications";

const SportdeputycommissionerSchemeTwoRejectedApplications = () => {
  return (
    <div>
      <SportSchemeTwoRejectedApplications role="Sport Deputy" />
    </div>
  );
};

export default SportdeputycommissionerSchemeTwoRejectedApplications;
