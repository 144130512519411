import React from "react";
import SchemeEightapprovedapplications from "../../Administration/SchemeEight/SchemeEightapprovedapplications";

const Chanani2SchemeEightApprovedApplications = () => {
  return (
    <div>
      <SchemeEightapprovedapplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeEightApprovedApplications;
