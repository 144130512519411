import React from "react";
import SportSchemeOneApprovedApplications from "../../../Administration/SportSchemeOne/SportSchemeOneApprovedApplications";

const SportShaskiySamiti3SchemeOneApprovedApplications = () => {
  return (
    <div>
      <SportSchemeOneApprovedApplications role="Sport Shaskiy3" />
    </div>
  );
};

export default SportShaskiySamiti3SchemeOneApprovedApplications;
