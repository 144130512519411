import React from "react";
import SchemeEightapprovedapplications from "../../Administration/SchemeEight/SchemeEightapprovedapplications";

const Chanani3SchemeEightApprovedApplications = () => {
  return (
    <div>
      <SchemeEightapprovedapplications role="Chanani Samiti3" />
    </div>
  );
};

export default Chanani3SchemeEightApprovedApplications;
