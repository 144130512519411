import React from "react";
import SchemeTwoTotalApplications from "../../Administration/SchemeTwo/SchemeTwoTotalApplications";

const AdditionalCommSchemeTwoTotalApplications = () => {
  return (
    <div>
      <SchemeTwoTotalApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeTwoTotalApplications;
