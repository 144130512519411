import React from "react";
import SportSchemeThreeTotalApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeTotalApplications";

const SportShaskiySamiti4SchemeThreeTotalApplications = () => {
  return (
    <div>
      <SportSchemeThreeTotalApplications role="Sport Shaskiy4" />
    </div>
  );
};

export default SportShaskiySamiti4SchemeThreeTotalApplications;
