import React from "react";
import SportSchemeThreeRejectedApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeRejectedApplications";

const SportShaskiySamiti4SchemeThreeRejectedApplications = () => {
  return (
    <div>
      <SportSchemeThreeRejectedApplications role="Sport Shaskiy4" />
    </div>
  );
};

export default SportShaskiySamiti4SchemeThreeRejectedApplications;
