import React from 'react';
import SchemeFiveApprovedApplications from '../../Administration/SchemeFive/SchemeFiveApprovedApplications';

const Clerk2SchemeFiveApprovedApplications = () => {
  return (
    <div><SchemeFiveApprovedApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeFiveApprovedApplications;
