import React from "react";
import SchemeSixPendingApplications from "../../Administration/SchemeSix/SchemeSixPendingApplications";

const SuperAdminSchemeSixPendingApplications = () => {
  return (
    <div>
      <SchemeSixPendingApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeSixPendingApplications;
