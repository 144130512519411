import React from "react";
import SportSchemeOnePendingApplications from "../../../Administration/SportSchemeOne/SportSchemeOnePendingApplications";

const SportChanani3SchemeOnePendingApplications = () => {
  return (
    <div>
      <SportSchemeOnePendingApplications role="Sport Chanani3" />
    </div>
  );
};

export default SportChanani3SchemeOnePendingApplications;
