import React from 'react';
import SchemeTenapprovedapplications from '../../Administration/SchemeTen/SchemeTenapprovedapplications';

const Clerk2SchemeTenApprovedApplications = () => {
  return (
    <div><SchemeTenapprovedapplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeTenApprovedApplications;
