import React from "react";
import SchemeOneTotalApplications from "../../Administration/SchemeOne/SchemeOneTotalApplications";

const AdditionalCommSchemeOneTotalApplications = () => {
  return (
    <div>
      <SchemeOneTotalApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeOneTotalApplications;
