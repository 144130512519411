import React from "react";
import SchemeSevenPendingApplications from "../../Administration/SchemeSeven/SchemeSevenPendingApplications";

const Chanani4SchemeSevenPendingApplications = () => {
    return (
        <div>
            <SchemeSevenPendingApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeSevenPendingApplications;
