import Graphdashboard from "../Administration/dashboard/GraphDashboard/Graphdashboard";

const AdditionalCommissionerGraph = () => {
  return (
    <>
      <Graphdashboard role="Additional Commissioner" />
    </>
  );
};

export default AdditionalCommissionerGraph;
