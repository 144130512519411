import React from 'react';
import SchemeSixPendingApplications from '../../Administration/SchemeSix/SchemeSixPendingApplications';

const Clerk3SchemeSixPendingApplications = () => {
  return (
    <div><SchemeSixPendingApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeSixPendingApplications;
