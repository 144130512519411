import React from 'react';
import SchemeSevenRejectedApplications from '../../Administration/SchemeSeven/SchemeSevenRejectedApplications';

const Clerk2SchemeSevenRejectedApplications = () => {
  return (
    <div><SchemeSevenRejectedApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeSevenRejectedApplications;
