import React from "react";
import SchemeNineRejectedApplications from "../../Administration/schemeNine/SchemeNineRejectedApplications";

const AdditionalCommSchemeNineRejectedApplications = () => {
  return (
    <div>
      <SchemeNineRejectedApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeNineRejectedApplications;
