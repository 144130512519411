import React from "react";
import SchemeEightRejectedApplications from "../../Administration/SchemeEight/SchemeEightRejectedApplications";

const AdditionalCommSchemeEightRejectedApplications = () => {
  return (
    <div>
      <SchemeEightRejectedApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeEightRejectedApplications;
