import React from 'react';
import SchemeNineTotalApplications from '../../Administration/schemeNine/SchemeNineTotalApplications';

const Clerk2SchemeNineTotalApplications = () => {
  return (
    <div><SchemeNineTotalApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeNineTotalApplications;
