import { removeClerk3UserToken } from "../../common";
import { clerkUserTokenName3 } from "../../config";

const getTrafficFromLocalStorage = () => {
  try {
    const clerk = JSON.parse(sessionStorage.getItem(clerkUserTokenName3));
    if (clerk && clerk.authToken && clerk.loggedInTime) {
      return clerk;
    } else {
      removeClerk3UserToken();
      return null;
    }
  } catch (error) {
    removeClerk3UserToken();
    return null;
  }
};

const getInitial = () => {
  return getTrafficFromLocalStorage();
};

const clerk3UserReducers = (state = getInitial(), action) => {
  switch (action.type) {
    case "LOGIN_CLERK3":
      const { loggedInTime, authToken } = action.payload;
      const newState = {
        loggedInTime: loggedInTime || Date.now(),
        authToken: authToken,
      };
      sessionStorage.setItem(clerkUserTokenName3, JSON.stringify(newState));
      return newState;
    case "LOGOUT_CLERK3":
      removeClerk3UserToken();
      return null;
    case "UPDATE_CLERK3_DASHBOARD":
      if (state) {
        state = {
          ...state,
          dashboard: {
            ...state.dashboard,
            ...action.payload,
          },
        };
      }
      return state;
    case "CLEAR_CLERK3_DASHBOARD":
      state.dashboard = null;
      return state;
    default:
      return state;
  }
};

export default clerk3UserReducers;
