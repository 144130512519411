import React from "react";
import SchemeThreeTotalApplications from "../../Administration/SchemeThree/SchemeThreeTotalApplications";

const ShaskiySamiti2SchemeThreeTotalApplications = () => {
    return (
        <div>
            <SchemeThreeTotalApplications role="Shaskiy Samiti2" />
        </div>
    );
};

export default ShaskiySamiti2SchemeThreeTotalApplications;
