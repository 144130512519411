export const loginclerk2 = (session) => {
  return {
    type: "LOGIN_CLERK2",
    payload: session,
  };
};
export const logoutclerk2 = () => {
  return {
    type: "LOGOUT_CLERK2",
  };
};

export const setClerk2Dashboard = (dashboard) => {
  return {
    type: "UPDATE_CLERK2_DASHBOARD",
    payload: dashboard,
  };
};

export const clearClerk2Dashboard = () => {
  return {
    type: "CLEAR_CLERK2_DASHBOARD",
  };
};
