import React from "react";
import SchemeTwoRejectedApplications from "../../Administration/SchemeTwo/SchemeTwoRejectedApplications";

const Chanani3SchemeTwoRejectedApplications = () => {
    return (
        <div>
            <SchemeTwoRejectedApplications role="Chanani Samiti3" />
        </div>
    );
};

export default Chanani3SchemeTwoRejectedApplications;
