export const loginShaskiySamiti2 = (session) => {
  return {
    type: "LOGIN_SHASKIY_SAMITI2",
    payload: session,
  };
};

export const logoutShaskiySamiti2 = () => {
  return {
    type: "LOGOUT_SHASKIY_SAMITI2",
  };
};

export const setShaskiysamiti2Dashboard = (dashboard) => {
  return {
    type: "UPDATE_SHASKIY2_DASHBOARD",
    payload: dashboard,
  };
};

export const clearShaskiysamiti2Dashboard = () => {
  return {
    type: "CLEAR_SHASKIY2_DASHBOARD",
  };
};
