import React from "react";
import SchemeTwoTotalApplications from "../../Administration/SchemeTwo/SchemeTwoTotalApplications";

const Chanani2SchemeTwoTotalApplications = () => {
  return (
    <div>
      <SchemeTwoTotalApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeTwoTotalApplications;
