import React, { useState } from "react";
import {
  TextField,
  Button,
  Alert,
  CircularProgress,
  Box,
  Grid,
  Typography,
} from "@mui/material";

const AadhaarVerification = ({ onVerify }) => {
  const [aadhaarNumber, setAadhaarNumber] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <Box sx={{ maxWidth: 400, mx: "auto", mt: 5, p: 2 }}>
      <Typography variant="h6" align="center" gutterBottom>
        Verify Your Aadhaar Number
      </Typography>
      <Typography
        variant="body2"
        align="center"
        style={{ color: "black" }}
        sx={{ mb: 2 }}
      >
        Please verify your Aadhaar number before applying.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Aadhaar Number"
            variant="outlined"
            type="text"
            fullWidth
            // value={aadhaarNumber}
            // onChange={(e) => setAadhaarNumber(e.target.value)}
            error={error}
            helperText={error ? "Invalid Aadhaar number!" : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            // onClick={handleVerify}
            variant="contained"
            className="bluebutton"
            fullWidth
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Verify"}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AadhaarVerification;
