export const loginclerk3 = (session) => {
  return {
    type: "LOGIN_CLERK3",
    payload: session,
  };
};
export const logoutclerk3 = () => {
  return {
    type: "LOGOUT_CLERK3",
  };
};

export const setClerk3Dashboard = (dashboard) => {
  return {
    type: "UPDATE_CLERK3_DASHBOARD",
    payload: dashboard,
  };
};

export const clearClerk3Dashboard = () => {
  return {
    type: "CLEAR_CLERK3_DASHBOARD",
  };
};
