import React from "react";
import SchemeSixRejectedApplications from "../../Administration/SchemeSix/SchemeSixRejectedApplications";

const ShaskiySamiti5SchemeSixRejectedApplications = () => {
    return (
        <div>
            <SchemeSixRejectedApplications role="Shaskiy Samiti5" />
        </div>
    );
};

export default ShaskiySamiti5SchemeSixRejectedApplications;
