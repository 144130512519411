import React from 'react';
import SchemeSixapprovedapplications from '../../Administration/SchemeSix/SchemeSixapprovedapplications';

const Clerk2SchemeSixApprovedApplications = () => {
  return (
    <div><SchemeSixapprovedapplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeSixApprovedApplications;
