import React from 'react';
import SchemeTwoApprovedApplications from '../../Administration/SchemeTwo/SchemeTwoApprovedApplications';

const Clerk3SchemeTwoApprovedApplications = () => {
  return (
    <div><SchemeTwoApprovedApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeTwoApprovedApplications;
