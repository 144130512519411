import React from 'react';
import SchemeTwoApprovedApplications from '../../Administration/SchemeTwo/SchemeTwoApprovedApplications';

const Clerk2SchemeTwoApprovedApplications = () => {
  return (
    <div><SchemeTwoApprovedApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeTwoApprovedApplications;
