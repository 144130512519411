import React from "react";
import SchemeOnePendingApplications from "../../Administration/SchemeOne/SchemeOnePendingApplications";

const ShaskiySamiti5SchemeOnePendingApplications = () => {
    return (
        <div>
            <SchemeOnePendingApplications role="Shaskiy Samiti5" />
        </div>
    );
};

export default ShaskiySamiti5SchemeOnePendingApplications;
