import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "../Card/InfoCard.css";
import { Button } from "@mui/material";
import image from "../../assets/images/infocard.png";
import { useTranslation } from "react-i18next";

const InfoCard = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);
  const { t } = useTranslation();

  return (
    <section className="info-card-section">
      <div
        className="container-fluid info-card-container"
        data-aos="fade-up"
        style={{
          backgroundSize: "cover",
          backgroundPosition: "center",

          borderRadius: "15px",
        }}
      >
        <div className="info-card">
          <Row className="align-items-center">
            <Col xs={12} md={6} className="image-column mt-4 mt-md-0">
              <img
                src={image}
                alt="Schemes illustration"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: "10px",
                }}
              />
            </Col>
            <Col xs={12} md={6} className="text-content">
              <h5
                className="fw-bold"
                style={{
                  color: "#2c3e50",
                  fontSize: "24px",
                  marginBottom: "20px",
                }}
              >
                {t("infocard.cardheader")}
              </h5>
              <p
                className="text-muted mb-4"
                style={{ fontSize: "18px", lineHeight: "1.6" }}
              >
                {t("infocard.cardinfo")}
              </p>
              <NavLink to="/allschemespage">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#3498db",
                    color: "#fff",
                    fontWeight: "bold",
                    padding: "10px 20px",
                    borderRadius: "50px",
                  }}
                >
                  {t("infocard.cardbutton")}
                </Button>
              </NavLink>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default InfoCard;
