import React from "react";
import SchemeTwoTotalApplications from "../../Administration/SchemeTwo/SchemeTwoTotalApplications";

const ShaskiySamiti4SchemeTwoTotalApplications = () => {
    return (
        <div>
            <SchemeTwoTotalApplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeTwoTotalApplications;
