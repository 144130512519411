import React from "react";
import SchemeNineRejectedApplications from "../../Administration/schemeNine/SchemeNineRejectedApplications";

const Chanani3SchemeNineRejectedApplications = () => {
    return (
        <div>
            <SchemeNineRejectedApplications role="Chanani Samiti3" />
        </div>
    );
};

export default Chanani3SchemeNineRejectedApplications;
