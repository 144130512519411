import React from "react";
import SchemeTenPendingApplications from "../../Administration/SchemeTen/SchemeTenPendingApplications";

const ClerkSchemeTenPendingApplications = () => {
  return (
    <div>
      <SchemeTenPendingApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeTenPendingApplications;
