import React from "react";
import SchemeOneRejectedApplications from "../../Administration/SchemeOne/SchemeOneRejectedApplications";

const ClerkSchemeOneRejectedApplications = () => {
  return (
    <div>
      <SchemeOneRejectedApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeOneRejectedApplications;
