import React from "react";
import SchemeTwoRejectedApplications from "../../Administration/SchemeTwo/SchemeTwoRejectedApplications";

const Chanani1SchemeTwoRejectedApplications = () => {
    return (
        <div>
            <SchemeTwoRejectedApplications role="Chanani Samiti1" />
        </div>
    );
};

export default Chanani1SchemeTwoRejectedApplications;
