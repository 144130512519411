import React from 'react';
import SchemeSixPendingApplications from '../../Administration/SchemeSix/SchemeSixPendingApplications';

const Clerk2SchemeSixPendingApplications = () => {
  return (
    <div><SchemeSixPendingApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeSixPendingApplications;
