import React from "react";
import SchemeFiveApprovedApplications from "../../Administration/SchemeFive/SchemeFiveApprovedApplications";

const Chanani1SchemeFiveApprovedApplications = () => {
    return (
        <div>
            <SchemeFiveApprovedApplications role="Chanani Samiti1" />
        </div>
    );
};

export default Chanani1SchemeFiveApprovedApplications;
