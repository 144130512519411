export const loginSportShaskiySamiti1 = (session) => {
  return {
    type: "LOGIN_SPORT_SHASKIY_SAMITI1",
    payload: session,
  };
};

export const logoutSportShaskiySamiti1 = () => {
  return {
    type: "LOGOUT_SPORT_SHASKIY_SAMITI1",
  };
};

export const setSportShaskiysamiti1Dashboard = (dashboard) => {
  return {
    type: "UPDATE_SPORT_SHASKIY1_DASHBOARD",
    payload: dashboard,
  };
};

export const clearSportShaskiysamiti1Dashboard = () => {
  return {
    type: "CLEAR_SPORT_SHASKIY1_DASHBOARD",
  };
};
