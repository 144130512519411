// import React from "react";
// import { useSelector } from "react-redux";
// import { Navigate, Outlet } from "react-router-dom";
// import { useSelectAccess } from "../store/stateFunctions";
// import ShaskiySamiti5Sidebar from "../shaskiysamiti5/ShaskiySamiti5Sidebar";
// // import { Outlet } from "react-router-dom";

// const ShaskiySamiti5Container = () => {
//   document.title = "Shaskiy Samiti";
//   const shaskiysamiti5 = useSelectAccess("Shaskiy Samiti5");

//   return (
//     <>
//       <div className="row ">
//         <ShaskiySamiti5Sidebar role="Shaskiy Samiti5" />
//         <div className="container">
//           {shaskiysamiti5 ? <Outlet /> : <Navigate to="/shaskiy-samiti5" />}
//           {/* <Outlet /> */}
//         </div>
//       </div>
//     </>
//   );
// };

// export default ShaskiySamiti5Container;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getCookie, server } from "../common";
import configContext from "../configContext/configContext";
import ShaskiySamiti5Sidebar from "../shaskiysamiti5/ShaskiySamiti5Sidebar";
import { updateDashboardState, useSelectAccess } from "../store/stateFunctions";

export default function ShaskiySamiti5Container() {
  const navigate = useNavigate();
  const location = useLocation();
  const shaskiy5 = useSelectAccess("Shaskiy Samiti5");
  const { setProgress } = useContext(configContext);
  const [processing, setProcessing] = useState(false);
  const fetched = useRef(false);
  const setFetched = (value) => (fetched.current = value);

  useEffect(() => {
    if (!shaskiy5) {
      navigate("/shaskiy-samiti5", {
        state: { from: location },
        replace: true,
      });
      return;
    }

    // If shaskiy5 is valid, fetch admin profile
    setProcessing(true);
    setProgress(10);
    server
      .get("/admin/adminprofile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: shaskiy5.authToken,
        },
      })
      .then((response) => {
        updateDashboardState("Shaskiy Samiti5", response.data);
        setProgress(100);
        setProcessing(false);
        setFetched(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          navigate("/shaskiy-samiti5", {
            state: { from: location },
            replace: true,
          });
        } else {
          setProgress(100);
          setProcessing(false);
          console.error(
            "error",
            error.message,
            error.response?.status,
            error.response?.data,
            error
          );
        }
      });

    document.title = "Shaskiya Samiti Dashboard";
  }, [shaskiy5, navigate, location, setProgress]);

  if (shaskiy5)
    return (
      <>
        <ShaskiySamiti5Sidebar
          username={shaskiy5.dashboard && shaskiy5.dashboard.name}
        />
        {shaskiy5.dashboard && <Outlet />}
      </>
    );

  return null;
}
