import React from 'react';
import SchemeThreePendingApplications from '../../Administration/SchemeThree/SchemeThreePendingApplications';

const Clerk3SchemeThreePendingApplications = () => {
  return (
    <div><SchemeThreePendingApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeThreePendingApplications;
