import React from 'react';
import SchemeEightRejectedApplications from '../../Administration/SchemeEight/SchemeEightRejectedApplications';

const Clerk2SchemeEightRejectedApplications = () => {
  return (
    <div><SchemeEightRejectedApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeEightRejectedApplications;
