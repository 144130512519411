import React from "react";
import SchemeFourRejectedApplications from "../../Administration/SchemeFour/SchemeFourRejectedApplications";

const Chanani1SchemeFourRejectedApplications = () => {
  return (
    <div>
      <SchemeFourRejectedApplications role="Chanani Samiti1" />
    </div>
  );
};

export default Chanani1SchemeFourRejectedApplications;
