export const loginShaskiySamiti5 = (session) => {
  return {
    type: "LOGIN_SHASKIY_SAMITI5",
    payload: session,
  };
};

export const logoutShaskiySamiti5 = () => {
  return {
    type: "LOGOUT_SHASKIY_SAMITI5",
  };
};

export const setShaskiysamiti5Dashboard = (dashboard) => {
  return {
    type: "UPDATE_SHASKIY5_DASHBOARD",
    payload: dashboard,
  };
};

export const clearShaskiysamiti5Dashboard = () => {
  return {
    type: "CLEAR_SHASKIY5_DASHBOARD",
  };
};
