import React, { useEffect, useState } from "react";
import { TextField, Button } from "@mui/material";
import * as XLSX from "xlsx";
import { server, statusMessage } from "../../common";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { Card, Modal } from "react-bootstrap";
import SchemeNineUserModal from "./SchemeNineUserModal";
import { useSelectAccess } from "../../store/stateFunctions";

const SchemeNineRejectedApplications = ({ role }) => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const user = useSelectAccess(role);
  const [searchValue, setSearchValue] = useState("");

  const handleShow = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getData(currentPage); // Initial data fetch
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Get all data with pagination
  const getData = (page) => {
    server
      .get(`/nineappl/rejected?page=${page}&limit=10`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        console.log("API Response:", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data.results);
          setTotalPages(response.data.totalPages);
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError && error.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error(
            error.response?.data?.error || "Failed to connect to server"
          );
        }
      });
  };

  // search
  const SearchData = (searchValue) => {
    server
      .get(`/nineappl/searchrejectedbyid?search=${searchValue}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data.data);
          setTotalPages(response.data.totalPages);
        }
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  // search
  const FilterData = (searchValue) => {
    server
      .get(`/nineappl/searchnineapplbydivision?search=${searchValue}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  const handleDivisionChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    FilterData(value); // Call the API with the selected division
  };

  const exportToExcel = () => {
    // Map over the data to include only the visible columns
    const exportData = data.map((app, index) => ({
      "Sr. No.": index + 1,
      "Application No.": app.id,
      "Application Date/Time": app.created_at,
      "Applicant Name": app.Fullname,
      "Aadhar Number": app.adhar_number,
      "Contact No.": app.mobile,
      "Ward No": app.ward_no,
      Division: app.division,
      Status: statusMessage(app, role),
    }));

    // Create Excel sheet
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Total Applications");
    XLSX.writeFile(workbook, "Total_Applications.xlsx");
  };

  return (
    <>
      <div className="container-fluid mt-4">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h3>
            योजना क्र.९ : मतिमंद तसेच बहुविकलांग जे ७० ते १०० टक्के दिव्यांग
            आहेत व पूर्णत: अंथरूणास खेळून आहेत अशा दिव्यांगाना अर्थसहाय्य योजना.
          </h3>
          <h4>Rejected Applications</h4>
        </div>
        <div className="card mb-3">
          <div className="card-header">Search Application</div>
          <div className="card-body">
            <div className="row">
              <div className="form-group col-md-4">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Application Id / Name"
                  onChange={(e) => SearchData(e.target.value)}
                />
              </div>

              <div className="form-group col-md-4">
                <select id="divisionFilter" className="form-control">
                  <option value="">All Divisions</option>
                  <option value="North">Division 1</option>
                  <option value="South">Division 2</option>
                  <option value="North">Division 3</option>
                  <option value="North">Division 4</option>
                  <option value="North">Division 5</option>
                  <option value="North">Division 6</option>
                  <option value="North">Division 7</option>
                </select>
              </div>
              <div className="col-md-2"></div>
              <Button
                className="btn col-md-2 btn-sm"
                onClick={exportToExcel}
                variant="contained"
              >
                Export
              </Button>
            </div>
          </div>
        </div>
        <div className="card mb-3">
          <div className="card-header">Rejected Application</div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th>Sr.No.</th>
                    <th>Application No.</th>
                    <th>Application Date/Time</th>
                    <th>Applicant Name</th>
                    <th>Adhar Number</th>
                    <th>Contact No.</th>
                    <th>Ward No</th>
                    <th>Division</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((app, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{app.id}</td>
                      <td>{new Date(app.created_at).toLocaleString()}</td>{" "}
                      <td>{app.fullname}</td>
                      <td>{app.adhar_number}</td>
                      <td>{app.mobile}</td>
                      <td>{app.ward_no}</td>
                      <td>{app.division}</td>
                      <td>{statusMessage(app, role)}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => handleShow(app.id)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            {data?.length === 0 && (
              <p>No applications found for the selected division.</p>
            )}
          </div>

          {/* <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Previous
                </button>
              </li>

              {Array.from(
                { length: Math.min(10, totalPages) }, // Show up to 10 pages at a time
                (_, index) => {
                  const startPage = Math.max(1, currentPage - 5); // Dynamically calculate start page
                  const page = startPage + index;

                  if (page <= totalPages) {
                    return (
                      <li
                        key={page}
                        className={`page-item ${
                          currentPage === page ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(page)}
                        >
                          {page}
                        </button>
                      </li>
                    );
                  } else {
                    return null;
                  }
                }
              )}

              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav> */}
        </div>
        <SchemeNineUserModal
          show={showModal}
          handleClose={handleClose}
          id={selectedId}
          role={role}
        />
      </div>
    </>
  );
};

export default SchemeNineRejectedApplications;
