import React from "react";
import SchemeFiveRejectedApplications from "../../Administration/SchemeFive/SchemeFiveRejectedApplications";

const AdditionalCommSchemeFiveRejectedApplications = () => {
  return (
    <div>
      <SchemeFiveRejectedApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeFiveRejectedApplications;
