import React, { useState } from "react";
import { Typography, Card, CardContent, Divider, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
// import brochure from "../../assets/documents/Cochlear Implant Surgery Funding Scheme.pdf";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelectAccess } from "../../store/stateFunctions";
import { toast } from "react-toastify";

const SchemeOnedetails = () => {
  const { t } = useTranslation();
  const user = useSelectAccess("User");
  const isLoggedIn = user && user;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleApplyNowClick = (e) => {
    if (!isLoggedIn) {
      e.preventDefault(); // Prevent navigation
      toast.info("Please log in to apply for the Scheme");
      navigate("/login");
    } else {
      setShow(true);
    }
  };

  return (
    <div>
      <div
        className="container-fluid"
        style={{
          height: "100px",
          backgroundColor: "#98c1d9",
          color: "black",
          fontSize: "27px",
          fontWeight: "500",
          padding: "0px 20px",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        {t("viewdetails.scheme1.schemename")}
      </div>

      <div style={{ padding: "20px", backgroundColor: "#eaf6ff" }}>
        <Card style={{ marginBottom: "20px" }}>
          <CardContent>
            <li variant="h5" style={{ fontWeight: "bold" }}>
              {t("viewdetails.scheme1.introductiontitle")}
            </li>
            <Divider style={{ margin: "10px 0" }} />

            <ul>
              <li>{t("viewdetails.scheme1.introductiondetails1")}</li>
              <li>{t("viewdetails.scheme1.introductiondetails2")}</li>
              <li>{t("viewdetails.scheme1.introductiondetails3")}</li>
            </ul>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <li variant="h5" style={{ fontWeight: "bold" }}>
              {t("viewdetails.scheme1.benefitstitle")}
            </li>
            <Divider style={{ margin: "10px 0" }} />
            <ul style={{ paddingLeft: "20px" }}>
              <li>{t("viewdetails.scheme1.benefits1")}</li>
              <li>{t("viewdetails.scheme1.benefits2")}</li>
              <li>{t("viewdetails.scheme1.benefits3")}</li>
              <li>{t("viewdetails.scheme1.benefits4")}</li>
              <ul>
                <li>{t("viewdetails.scheme1.benefits5")}</li>
                <li>{t("viewdetails.scheme1.benefits6")}</li>
              </ul>
            </ul>
          </CardContent>
        </Card>

        <Card className="mt-4">
          <CardContent>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              {t("viewdetails.scheme1.eligibletitle")}
            </Typography>
            <Divider style={{ margin: "10px 0" }} />
            <ul style={{ paddingLeft: "20px" }}>
              <li>{t("viewdetails.scheme1.eligible1")}</li>
              <li>{t("viewdetails.scheme1.eligible2")}</li>
            </ul>
          </CardContent>
        </Card>
        <Card className="mt-4">
          <CardContent>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              {t("TermsandConditions")}
            </Typography>
            <Divider style={{ margin: "10px 0" }} />
            <ul style={{ paddingLeft: "20px" }}>
              <li>{t("ViewDetailsSchemeOne.1")}</li>
              <li>{t("ViewDetailsSchemeOne.2")}</li>
              <li>{t("ViewDetailsSchemeOne.3")}</li>
              <li>{t("ViewDetailsSchemeOne.4")}</li>
              <li>{t("ViewDetailsSchemeOne.5")}</li>
              <li>{t("ViewDetailsSchemeOne.6")}</li>
              <li>{t("ViewDetailsSchemeOne.7")}</li>
            </ul>
            <NavLink to="/schemeone" onClick={handleApplyNowClick}>
              {" "}
              <Button
                variant="contained"
                className="redbutton"
                color="secondary"
              >
                {t("viewdetails.scheme1.applynow")}
              </Button>
            </NavLink>
          </CardContent>
        </Card>

        {/* <Card className="mt-4">
          <CardContent>
            <Typography variant="h5" style={{ fontWeight: "bold" }}>
              {t("viewdetails.scheme1.additionalinfo")}
            </Typography>
            <Divider style={{ margin: "10px 0" }} />
            <ul style={{ paddingLeft: "20px" }}>
              <li>{t("viewdetails.scheme1.additionalinfo1")}</li>
              <li> {t("viewdetails.scheme1.additionalinfo2")}</li>
              <li>{t("viewdetails.scheme1.additionalinfo3")}</li>
              <li>{t("viewdetails.scheme1.additionalinfo4")}</li>
            </ul>

            <div>
             
            </div>
          </CardContent>
        </Card> */}
      </div>
    </div>
  );
};

export default SchemeOnedetails;
