import React from "react";
import SportSchemeTwoRejectedApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoRejectedApplications";

const SportChanani2SchemeTwoRejectedApplications = () => {
  return (
    <div>
      <SportSchemeTwoRejectedApplications role="Sport Chanani2" />
    </div>
  );
};

export default SportChanani2SchemeTwoRejectedApplications;
