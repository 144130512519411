import React from "react";
import SchemeThreePendingApplications from "../../Administration/SchemeThree/SchemeThreePendingApplications";

const Chanani3SchemeThreePendingApplications = () => {
    return (
        <div>
            <SchemeThreePendingApplications role="Chanani Samiti3" />
        </div>
    );
};

export default Chanani3SchemeThreePendingApplications;
