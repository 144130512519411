import React from "react";
import SchemeSixapprovedapplications from "../../Administration/SchemeSix/SchemeSixapprovedapplications";

const Chanani1SchemeSixApprovedApplications = () => {
  return (
    <div>
      <SchemeSixapprovedapplications role="Chanani Samiti1" />
    </div>
  );
};

export default Chanani1SchemeSixApprovedApplications;
