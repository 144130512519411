export const loginsportchanani5 = (session) => {
  return {
    type: "LOGIN_SPORT_CHANANI5",
    payload: session,
  };
};
export const logoutsportchanani5 = () => {
  return {
    type: "LOGOUT_SPORT_CHANANI5",
  };
};
