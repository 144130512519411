import { removeSpordeputyUserToken } from "../../common";
import { sportdeputyCommTokenName } from "../../config";

const getPoliceFromLocalStorage = () => {
  try {
    const sportdeputycomm = JSON.parse(
      sessionStorage.getItem(sportdeputyCommTokenName)
    );
    if (
      sportdeputycomm &&
      sportdeputycomm.authToken &&
      sportdeputycomm.loggedInTime
    ) {
      return sportdeputycomm;
    } else {
      removeSpordeputyUserToken();
      return null;
    }
  } catch (error) {
    removeSpordeputyUserToken();
    return null;
  }
};

const getInitial = () => {
  return getPoliceFromLocalStorage();
};

const sportdeputyCommReducers = (state = getInitial(), action) => {
  switch (action.type) {
    case "LOGIN_SPORT_DEPU_COMM":
      const { loggedInTime, authToken } = action.payload;
      const newState = {
        loggedInTime: loggedInTime || Date.now(),
        authToken: authToken,
      };
      sessionStorage.setItem(
        sportdeputyCommTokenName,
        JSON.stringify(newState)
      );
      return newState;
    case "LOGOUT_SPORT_DEPU_COMM":
      removeSpordeputyUserToken();
      return null;
    case "UPDATE_SPORT_DEPUTY_DASHBOARD":
      if (state) {
        state = {
          ...state,
          dashboard: {
            ...state.dashboard,
            ...action.payload,
          },
        };
      }
      return state;
    case "CLEAR_SPORT_DEPUTY_DASHBOARD":
      state.dashboard = null;
    default:
      return state;
  }
};

export default sportdeputyCommReducers;
