// import React from "react";
// import { useSelector } from "react-redux";
// import { Navigate, Outlet } from "react-router-dom";
// import { useSelectAccess } from "../store/stateFunctions";
// import ChananiSamiti3Sidebar from "../chananisamiti3/ChananiSamiti3Sidebar";

// const ChananiSamiti3Container = () => {
//   document.title = "Chanani Samiti";
//   const chananisamiti3 = useSelectAccess("Chanani Samiti3");

//   return (
//     <>
//       <div className="row ">
//         <ChananiSamiti3Sidebar role="Chanani Samiti3" />
//         <div className="container">
//           {chananisamiti3 ? <Outlet /> : <Navigate to="/chanani-samiti3" />}
//           {/* <Outlet /> */}
//         </div>
//       </div>
//     </>
//   );
// };

// export default ChananiSamiti3Container;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getCookie, server } from "../common";
import configContext from "../configContext/configContext";

import ChananiSamiti3Sidebar from "../chananisamiti3/ChananiSamiti3Sidebar";
import { updateDashboardState, useSelectAccess } from "../store/stateFunctions";

export default function ChananiSamiti3Container() {
  const navigate = useNavigate();
  const location = useLocation();
  const chanani3 = useSelectAccess("Chanani Samiti3");
  const { setProgress } = useContext(configContext);

  const [processing, setProcessing] = useState(false);
  const fetched = useRef(false);
  const setFetched = (value) => (fetched.current = value);

  useEffect(() => {
    // Navigate to "/chanani2" if chanani2 access is not available
    if (!chanani3) {
      navigate("/chanani-samiti3", {
        state: { from: location },
        replace: true,
      });
      return;
    }

    // If chanani3 is valid, fetch admin profile
    setProcessing(true);
    setProgress(10);
    server
      .get("/admin/adminprofile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: chanani3.authToken,
        },
      })
      .then((response) => {
        updateDashboardState("Chanani Samiti3", response.data);
        setProgress(100);
        setProcessing(false);
        setFetched(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          navigate("/chanani-samiti3", {
            state: { from: location },
            replace: true,
          });
        } else {
          setProgress(100);
          setProcessing(false);
          console.error(
            "error",
            error.message,
            error.response?.status,
            error.response?.data,
            error
          );
        }
      });

    document.title = "Chanani Samiti Dashboard";
  }, [chanani3, navigate, location, setProgress]);

  if (chanani3)
    return (
      <>
        <ChananiSamiti3Sidebar
          username={chanani3.dashboard && chanani3.dashboard.name}
        />
        {chanani3.dashboard && <Outlet />}
      </>
    );

  return null;
}
