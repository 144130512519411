import React from "react";
import SportSchemeThreePendingApplications from "../../../Administration/SportSchemeThree/SportSchemeThreePendingApplications";

const SportChanani1SchemeThreePendingApplications = () => {
  return (
    <div>
      <SportSchemeThreePendingApplications role="Sport Chanani1" />
    </div>
  );
};

export default SportChanani1SchemeThreePendingApplications;
