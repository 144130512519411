import React from "react";
import SchemeTenapprovedapplications from "../../Administration/SchemeTen/SchemeTenapprovedapplications";

const Chanani3SchemeTenApprovedApplications = () => {
    return (
        <div>
            <SchemeTenapprovedapplications role="Chanani Samiti3" />
        </div>
    );
};

export default Chanani3SchemeTenApprovedApplications;
