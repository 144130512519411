import React from "react";
import SchemeEightapprovedapplications from "../../Administration/SchemeEight/SchemeEightapprovedapplications";

const AdditionalCommSchemeEightApprovedApplications = () => {
  return (
    <div>
      <SchemeEightapprovedapplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeEightApprovedApplications;
