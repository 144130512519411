import React from "react";
import SchemeNinePendingApplications from "../../Administration/schemeNine/SchemeNinePendingApplications";

const SuperAdminSchemeNinePendingApplications = () => {
  return (
    <div>
      <SchemeNinePendingApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeNinePendingApplications;
