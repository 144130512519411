import React from "react";
import SchemeThreeRejectedApplications from "../../Administration/SchemeThree/SchemeThreeRejectedApplications";

const ShaskiySamiti2SchemeThreeRejectedApplications = () => {
    return (
        <div>
            <SchemeThreeRejectedApplications role="Shaskiy Samiti2" />
        </div>
    );
};

export default ShaskiySamiti2SchemeThreeRejectedApplications;
