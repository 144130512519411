import React from "react";
import SportSchemeThreePendingApplications from "../../../Administration/SportSchemeThree/SportSchemeThreePendingApplications";

const SportShaskiySamiti5SchemeThreePendingApplications = () => {
  return (
    <div>
      <SportSchemeThreePendingApplications role="Sport Shaskiy5" />
    </div>
  );
};

export default SportShaskiySamiti5SchemeThreePendingApplications;
