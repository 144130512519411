import React from "react";
import SportSchemeOneTotalApplications from "../../../Administration/SportSchemeOne/SportSchemeOneTotalApplications";

const SportShaskiySamiti2SchemeOneTotalApplications = () => {
  return (
    <div>
      <SportSchemeOneTotalApplications role="Sport Shaskiy2" />
    </div>
  );
};

export default SportShaskiySamiti2SchemeOneTotalApplications;
