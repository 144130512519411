import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Container,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
  Typography,
  Alert,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import doc1 from "../../assets/doc/declaration1.pdf";
import doc2 from "../../assets/doc/selfdeclaration1.pdf";
import { Card, Col, Row } from "react-bootstrap";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import Grid from "@mui/material/Grid2";

import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios, { AxiosError } from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { server } from "../../common";
import Loader from "../../Loader";
import { useSelectAccess } from "../../store/stateFunctions";
import { useNavigate, useParams } from "react-router-dom";
const swalalert = withReactContent(Swal);

const initialValues = {
  fullname: "",
  mobile: "",
  adhar_number: "",
  dob: "",
  address: "",
  division: "",
  ward_no: "",
  resident_duration_years: "",
  disability_type: "",
  pancard: "",
  family_annualincome: "",
  family_member_no: "",
  applicant_schoolname: "",
  school_mobileno: "",
  child_study_classroom: "",
  bankname: "",
  branch: "",
  ac_holder_name: "",
  account_no: "",
  ifsc_code: "",
  micr_code: "",

  passport_photo: null,
  upload_adhar: null,
  upload_pancard: null,
  upload_bankpassbook: null,
  fee_reciept: null,
  certificate_of_disability: null,
  birth_or_age_proof: null,
  proof1: null,
  proof2: null,

  self_declaration: null,
  self_attestation_declaration: null,
  recommendation_letter_principal: null,
  expert_recommendation_ot_pt: null,
};

const SchemeFiveEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const user = useSelectAccess("User");
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: initialValues, // Ensure this is defined
    validationSchema: Yup.object({
      fullname: Yup.string().required(t("schemeForms.name_required")),
      mobile: Yup.string()
        .matches(/^\d{10}$/, t("schemeForms.mobile_invalid")) // Assuming mobile number is 10 digits
        .required(t("schemeForms.mobile_required")),
      adhar_number: Yup.string()
        .matches(/^\d{12}$/, t("schemeForms.adhar_required"))
        .required(t("schemeForms.adhar_invalid")),

      dob: Yup.date()
        .required(t("schemeForms.bod_required"))
        .max(new Date(), t("schemeForms.futuredob")),

      address: Yup.string().required(t("schemeForms.address_required")),

      division: Yup.string().required(t("schemeForms.division_required")),

      ward_no: Yup.number()
        .max(300, t("schemeForms.ward_no_required"))
        .required(t("schemeForms.ward_no_required")),
      resident_duration_years: Yup.number()
        .typeError("Resident duration must be a number")
        .max(99, "Resident duration cannot exceed 99 years") // Sets the maximum to 2-digit input (up to 99 years)
        .required(t("schemeForms.resident_duration_required")),
      school_mobileno: Yup.string()
        .matches(/^[6-9]\d{9}$/, t("schemeForms.mobile_invalid"))
        .required(t("schemeForms.schoolnumber_required")),

      child_study_classroom: Yup.string().required(
        t("schemeForms.standard_required")
      ),

      disability_type: Yup.string().required(
        t("schemeForms.disability_required")
      ),

      pancard: Yup.string()
        .required(t("schemeForms.pan_required"))
        .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, t("schemeForms.pancardnumber")),

      applicant_schoolname: Yup.string().required(
        t("schemeForms.school_name_required")
      ),

      family_annualincome: Yup.number()
        .required(t("schemeForms.family_annual_income_required"))
        .min(1000, t("schemeForms.maximum_income")),

      family_member_no: Yup.number()
        .required(t("schemeForms.family_members_validation"))
        .min(1, t("schemeForms.family_members_number_validation")),

      bankname: Yup.string().required(t("schemeForms.bankname_required")),

      branch: Yup.string().required(t("schemeForms.branch_required")),

      ac_holder_name: Yup.string().required(
        t("schemeForms.ac_holder_name_required")
      ),

      ifsc_code: Yup.string().required(t("schemeForms.ifsc_required")),

      micr_code: Yup.string().required(t("schemeForms.micr_required")),

      account_no: Yup.string()
        .matches(/^\d{9,18}$/, t("schemeForms.account_number_validation"))
        .required(t("schemeForms.account_no_required")),

      passport_photo: Yup.mixed(),
      upload_adhar: Yup.mixed(),
      upload_pancard: Yup.mixed(),
      upload_bankpassbook: Yup.mixed(),
      fee_reciept: Yup.mixed(),
      certificate_of_disability: Yup.mixed(),
      birth_or_age_proof: Yup.mixed(),
      proof1: Yup.mixed(),
      proof2: Yup.mixed(),
      self_declaration: Yup.mixed(),
      self_attestation_declaration: Yup.mixed(),
      recommendation_letter_principal: Yup.mixed(),
      expert_recommendation_ot_pt: Yup.mixed(),
    }),

    onSubmit: (values, action) => {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (values[key]) {
          // Append only if a value exists
          formData.append(key, values[key]);
        }
      });
      server
        .put(`/fiveappl/updatefiveappl/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: user.authToken,
          },
        })
        .then(function (response) {
          if (response.status === 200 || response.status === 201) {
            swalalert.fire({
              title: "Success!",
              text: `Application Submitted Successfully.`,
              icon: "success",
            });
            formik.resetForm();
            navigate("/profile/myappliedschemes");
          }
        })
        .catch((error) => {
          toast.error(
            error.response?.data?.message ||
              error.response?.data?.error ||
              "Failed to connect to server"
          );
        })
        .finally(() => {
          setLoading(false); // Hide loader after API call
        });
    },
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    server
      .get(`/fiveappl/fiveapplication/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          formik.setValues(response.data);
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else {
          toast.error(error.response?.data?.error || "An error occurred");
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="container">
      {console.log(formik.values)}
      <Alert severity="info" className="mt-2">
        {t("schemeForms.note")}
      </Alert>

      <Card className="mt-2">
        <Card.Header>
          <Typography variant="h5" gutterBottom style={{ marginTop: "10px" }}>
            {t("schemeForms.scheme_number_5")}
          </Typography>
        </Card.Header>

        <form
          style={{ padding: "20px" }}
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <Typography variant="h6" gutterBottom>
            {t("schemeForms.personal_info")}
          </Typography>

          <Row container spacing={2}>
            <Col md={4} className="mt-4">
              <TextField
                fullWidth
                label={t("schemeForms.full_name")}
                required
                variant="outlined"
                name="fullname"
                onChange={formik.handleChange}
                value={formik.values.fullname}
              />
              {formik.errors.fullname ? (
                <p className="text-danger">{formik.errors.fullname}</p>
              ) : null}
            </Col>

            <Col md={4} className="mt-4">
              <TextField
                fullWidth
                label={t("schemeForms.dob")}
                required
                variant="outlined"
                type="date"
                name="dob"
                value={formik.values.dob}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  max: new Date().toISOString().substring(0, 10), // Restricts to today or earlier
                }}
              />
              {formik.errors.dob ? (
                <p className="text-danger">{formik.errors.dob}</p>
              ) : null}
            </Col>
            <Col md={4} className="mt-4">
              <TextField
                fullWidth
                label={t("schemeForms.type_of_disabilty")}
                required
                variant="outlined"
                name="disability_type"
                onChange={formik.handleChange}
                value={formik.values.disability_type}
              />
              {formik.errors.disability_type ? (
                <p className="text-danger">{formik.errors.disability_type}</p>
              ) : null}
            </Col>

            <Col md={4} className="mt-4">
              <TextField
                label="Mobile No."
                variant="outlined"
                fullWidth
                name="mobile"
                value={formik.values.mobile}
                onChange={(e) => {
                  // Allow only digits and limit to 10 characters
                  const mobile = e.target.value.replace(/\D/g, "").slice(0, 10);
                  formik.setFieldValue("mobile", mobile);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
                required
              />
              {formik.errors.mobile ? (
                <p className="text-danger">{formik.errors.mobile}</p>
              ) : null}
            </Col>
            <Col md={4} className="mt-4">
              <TextField
                fullWidth
                label={t("schemeForms.aadhaar_no")}
                required
                variant="outlined"
                name="adhar_number"
                onChange={(e) => {
                  // Allow only digits and limit to 12 characters
                  const aadhaar = e.target.value
                    .replace(/\D/g, "")
                    .slice(0, 12);
                  formik.setFieldValue("adhar_number", aadhaar);
                }}
                value={formik.values.adhar_number}
              />
              {formik.errors.adhar_number ? (
                <p className="text-danger">{formik.errors.adhar_number}</p>
              ) : null}
            </Col>
            <Col md={4} className="mt-4">
              <TextField
                fullWidth
                label={t("schemeForms.Pan_card_number")}
                required
                variant="outlined"
                name="pancard"
                onChange={(e) => {
                  // Allow only alphanumeric characters and limit to 10 characters
                  const pan = e.target.value
                    .replace(/[^a-zA-Z0-9]/g, "")
                    .slice(0, 10);
                  formik.setFieldValue("pancard", pan);
                }}
                value={formik.values.pancard}
              />
              {formik.errors.pancard ? (
                <p className="text-danger">{formik.errors.pancard}</p>
              ) : null}
            </Col>

            <Col md={4} className="mt-4">
              <TextField
                fullWidth
                label={t("schemeForms.family_income")}
                required
                variant="outlined"
                name="family_annualincome"
                onChange={formik.handleChange}
                value={formik.values.family_annualincome}
              />
              {formik.errors.family_annualincome ? (
                <p className="text-danger">
                  {formik.errors.family_annualincome}
                </p>
              ) : null}
            </Col>
            <Col md={4} className="mt-4">
              <TextField
                fullWidth
                label={t("schemeForms.famiy_members")}
                required
                variant="outlined"
                name="family_member_no"
                value={formik.values.family_member_no}
                onChange={formik.handleChange}
              />
              {formik.errors.family_member_no ? (
                <p className="text-danger">{formik.errors.family_member_no}</p>
              ) : null}
            </Col>
          </Row>
          <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
            {t("schemeForms.resident_info")}
          </Typography>
          <Row container spacing={2}>
            <Col md={4} className="mt-4">
              <TextField
                fullWidth
                label={t("schemeForms.address")}
                required
                variant="outlined"
                name="address"
                onChange={formik.handleChange}
                value={formik.values.address}
              />
              {formik.errors.address ? (
                <p className="text-danger">{formik.errors.address}</p>
              ) : null}
            </Col>
            <Col md={4} className="mt-4">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  {t("schemeForms.division")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Division"
                  variant="outlined"
                  name="division"
                  value={formik.values.division}
                  onChange={formik.handleChange}
                  required
                >
                  <MenuItem value="">
                    <em> {t("schemeForms.selectDivision")}</em>
                  </MenuItem>
                  <MenuItem value="satpur">
                    <em>{t("schemeForms.divisions.satpur")}</em>
                  </MenuItem>
                  <MenuItem value="cidco">
                    <em>{t("schemeForms.divisions.cidco")}</em>
                  </MenuItem>
                  <MenuItem value="nashikroad">
                    <em>{t("schemeForms.divisions.nashikroad")} </em>
                  </MenuItem>
                  <MenuItem value="nashikeast">
                    <em> {t("schemeForms.divisions.nashikeast")} </em>
                  </MenuItem>
                  <MenuItem value="nashikwest">
                    <em> {t("schemeForms.divisions.nashikwest")} </em>
                  </MenuItem>
                  <MenuItem value="panchavati">
                    <em>t{"schemeForms.divisions.panchavati"}</em>
                  </MenuItem>
                </Select>
              </FormControl>
              {formik.errors.division ? (
                <p className="text-danger">{formik.errors.division}</p>
              ) : null}
            </Col>
            <Col md={4} className="mt-4">
              <TextField
                fullWidth
                label={t("schemeForms.ward")}
                required
                variant="outlined"
                name="ward_no"
                onChange={formik.handleChange}
                value={formik.values.ward_no}
              />
              {formik.errors.ward_no ? (
                <p className="text-danger">{formik.errors.ward_no}</p>
              ) : null}
            </Col>
            <Col md={4} className="mt-4">
              <TextField
                fullWidth
                label={t("schemeForms.years_resident")}
                required
                variant="outlined"
                name="resident_duration_years"
                onChange={formik.handleChange}
                value={formik.values.resident_duration_years}
              />
              {formik.errors.resident_duration_years ? (
                <p className="text-danger">
                  {formik.errors.resident_duration_years}
                </p>
              ) : null}
            </Col>
          </Row>
          {/* Step 3: School Information */}
          <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
            {t("schemeForms.school_info")}
          </Typography>
          <Row container spacing={2}>
            <Col md={4} className="mt-4">
              <TextField
                fullWidth
                label={t("schemeForms.university_name")}
                required
                variant="outlined"
                name="applicant_schoolname"
                onChange={formik.handleChange}
                value={formik.values.applicant_schoolname}
              />
              {formik.errors.applicant_schoolname ? (
                <p className="text-danger">
                  {formik.errors.applicant_schoolname}
                </p>
              ) : null}
            </Col>
            <Col md={4} className="mt-4">
              <TextField
                fullWidth
                label={t("schemeForms.school_mobileno")}
                required
                variant="outlined"
                name="school_mobileno"
                onChange={formik.handleChange}
                value={formik.values.school_mobileno}
              />
              {formik.errors.school_mobileno ? (
                <p className="text-danger">{formik.errors.school_mobileno}</p>
              ) : null}
            </Col>
            <Col md={4} className="mt-4">
              <TextField
                fullWidth
                label={t("schemeForms.child_study_classroom")}
                required
                variant="outlined"
                name="child_study_classroom"
                onChange={formik.handleChange}
                value={formik.values.child_study_classroom}
              />
              {formik.errors.child_study_classroom ? (
                <p className="text-danger">
                  {formik.errors.child_study_classroom}
                </p>
              ) : null}
            </Col>
          </Row>

          {/* Step 5: Bank Information */}
          <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
            {t("schemeForms.bank_info")}
          </Typography>
          <hr />
          <Row container spacing={2}>
            <Col md={4} className="mt-4">
              <TextField
                fullWidth
                label={t("schemeForms.bank_name")}
                required
                variant="outlined"
                name="bankname"
                onChange={formik.handleChange}
                value={formik.values.bankname}
              />
              {formik.errors.bankname ? (
                <p className="text-danger">{formik.errors.bankname}</p>
              ) : null}
            </Col>
            <Col md={4} className="mt-4">
              <TextField
                fullWidth
                label={t("schemeForms.bank_branch")}
                required
                variant="outlined"
                name="branch"
                onChange={formik.handleChange}
                value={formik.values.branch}
              />
              {formik.errors.branch ? (
                <p className="text-danger">{formik.errors.branch}</p>
              ) : null}
            </Col>
            <Col md={4} className="mt-4">
              <TextField
                fullWidth
                label={t("schemeForms.account_holder_name")}
                required
                variant="outlined"
                name="ac_holder_name"
                onChange={formik.handleChange}
                value={formik.values.ac_holder_name}
              />
              {formik.errors.ac_holder_name ? (
                <p className="text-danger">{formik.errors.ac_holder_name}</p>
              ) : null}
            </Col>
            <Col md={4} className="mt-4">
              <TextField
                fullWidth
                label={t("schemeForms.account_number")}
                required
                variant="outlined"
                name="account_no"
                onChange={formik.handleChange}
                value={formik.values.account_no}
              />
              {formik.errors.account_no ? (
                <p className="text-danger">{formik.errors.account_no}</p>
              ) : null}
            </Col>
            <Col md={4} className="mt-4">
              <TextField
                fullWidth
                label={t("schemeForms.ifsc_code")}
                required
                variant="outlined"
                name="ifsc_code"
                onChange={formik.handleChange}
                value={formik.values.ifsc_code}
              />
              {formik.errors.ifsc_code ? (
                <p className="text-danger">{formik.errors.ifsc_code}</p>
              ) : null}
            </Col>
            <Col md={4} className="mt-4">
              <TextField
                fullWidth
                label={t("schemeForms.micr_code")}
                required
                variant="outlined"
                name="micr_code"
                onChange={formik.handleChange}
                value={formik.values.micr_code}
              />
              {formik.errors.micr_code ? (
                <p className="text-danger">{formik.errors.micr_code}</p>
              ) : null}
            </Col>
          </Row>
          {/* Step 6: Document Upload */}
          <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
            {t("schemeForms.upload_documents")}
          </Typography>

          <hr />

          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <TextField
                      fullWidth
                      label={t("schemeForms.PassportPhoto")}
                      required
                      variant="outlined"
                      type="file"
                      name="passport_photo"
                      onChange={(event) =>
                        formik.setFieldValue(
                          "passport_photo",
                          event.currentTarget.files[0]
                        )
                      }
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ accept: ".pdf, .png, .jpg, .jpeg" }}
                    />
                    {formik.errors.passport_photo && (
                      <p className="text-danger">
                        {formik.errors.passport_photo}
                      </p>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="contained">
                      <a
                        href={formik.values.passport_photo}
                        className="text-decoration-none text-white"
                        target="_blank"
                      >
                        View
                      </a>
                    </Button>
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      required
                      label={t("schemeForms.AadharCard")}
                      variant="outlined"
                      type="file"
                      name="upload_adhar"
                      onChange={(event) =>
                        formik.setFieldValue(
                          "upload_adhar",
                          event.currentTarget.files[0]
                        )
                      }
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ accept: ".pdf, .png, .jpg, .jpeg" }}
                    />
                    {formik.errors.upload_adhar && (
                      <p className="text-danger">
                        {formik.errors.upload_adhar}
                      </p>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="contained">
                      <a
                        href={formik.values.upload_adhar}
                        className="text-decoration-none text-white"
                        target="_blank"
                      >
                        View
                      </a>
                    </Button>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    <TextField
                      fullWidth
                      label={t("schemeForms.PanCard")}
                      required
                      variant="outlined"
                      type="file"
                      name="upload_pancard"
                      onChange={(event) =>
                        formik.setFieldValue(
                          "upload_pancard",
                          event.currentTarget.files[0]
                        )
                      }
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ accept: ".pdf, .png, .jpg, .jpeg" }}
                    />
                    {formik.errors.upload_pancard && (
                      <p className="text-danger">
                        {formik.errors.upload_pancard}
                      </p>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="contained">
                      <a
                        href={formik.values.upload_pancard}
                        className="text-decoration-none text-white"
                        target="_blank"
                      >
                        View
                      </a>
                    </Button>
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      label={t("schemeForms.BankPassbook")}
                      required
                      variant="outlined"
                      type="file"
                      name="upload_bankpassbook"
                      onChange={(event) =>
                        formik.setFieldValue(
                          "upload_bankpassbook",
                          event.currentTarget.files[0]
                        )
                      }
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ accept: ".pdf, .png, .jpg, .jpeg" }}
                    />
                    {formik.errors.upload_bankpassbook && (
                      <p className="text-danger">
                        {formik.errors.upload_bankpassbook}
                      </p>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="contained">
                      <a
                        href={formik.values.upload_bankpassbook}
                        className="text-decoration-none text-white"
                        target="_blank"
                      >
                        View
                      </a>
                    </Button>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    <TextField
                      fullWidth
                      label={t("schemeForms.disability_certificate")}
                      required
                      variant="outlined"
                      type="file"
                      name="certificate_of_disability"
                      onChange={(event) =>
                        formik.setFieldValue(
                          "certificate_of_disability",
                          event.currentTarget.files[0]
                        )
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                    {formik.errors.certificate_of_disability && (
                      <p className="text-danger">
                        {formik.errors.certificate_of_disability}
                      </p>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="contained">
                      <a
                        href={formik.values.certificate_of_disability}
                        className="text-decoration-none text-white"
                        target="_blank"
                      >
                        View
                      </a>
                    </Button>
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      required
                      label={t("schemeForms.BirthProof")}
                      variant="outlined"
                      type="file"
                      name="birth_or_age_proof"
                      onChange={(event) =>
                        formik.setFieldValue(
                          "birth_or_age_proof",
                          event.currentTarget.files[0]
                        )
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                    {formik.errors.birth_or_age_proof && (
                      <p className="text-danger">
                        {formik.errors.birth_or_age_proof}
                      </p>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="contained">
                      <a
                        href={formik.values.birth_or_age_proof}
                        className="text-decoration-none text-white"
                        target="_blank"
                      >
                        View
                      </a>
                    </Button>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    <TextField
                      fullWidth
                      label={t("schemeForms.fee_receipt")}
                      required
                      variant="outlined"
                      name="fee_reciept"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "fee_reciept",
                          event.currentTarget.files[0]
                        );
                      }}
                      type="file"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.fee_reciept ? (
                      <p className="text-danger">{formik.errors.fee_reciept}</p>
                    ) : null}
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="contained">
                      <a
                        href={formik.values.fee_reciept}
                        className="text-decoration-none text-white"
                        target="_blank"
                      >
                        View
                      </a>
                    </Button>
                  </TableCell>

                  <TableCell>
                    <TextField
                      fullWidth
                      label={t("schemeForms.SelfDeclaration")}
                      required
                      variant="outlined"
                      type="file"
                      name="self_declaration"
                      onChange={(event) =>
                        formik.setFieldValue(
                          "self_declaration",
                          event.currentTarget.files[0]
                        )
                      }
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ accept: ".pdf, .png, .jpg, .jpeg" }}
                    />
                    {formik.errors.self_declaration && (
                      <p className="text-danger">
                        {formik.errors.self_declaration}
                      </p>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="contained">
                      <a
                        href={formik.values.self_declaration}
                        className="text-decoration-none text-white"
                        target="_blank"
                      >
                        View
                      </a>
                    </Button>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    <TextField
                      fullWidth
                      label={t("schemeForms.SelfAttestationDeclaration")}
                      required
                      variant="outlined"
                      type="file"
                      name="self_attestation_declaration"
                      onChange={(event) =>
                        formik.setFieldValue(
                          "self_attestation_declaration",
                          event.currentTarget.files[0]
                        )
                      }
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ accept: ".pdf, .png, .jpg, .jpeg" }}
                    />
                    {formik.errors.self_attestation_declaration && (
                      <p className="text-danger">
                        {formik.errors.self_attestation_declaration}
                      </p>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="contained">
                      <a
                        href={formik.values.self_attestation_declaration}
                        className="text-decoration-none text-white"
                        target="_blank"
                      >
                        View
                      </a>
                    </Button>
                  </TableCell>

                  {/* <TableCell>
                     
                      <TextField
                        fullWidth
                        required
                        label= {t("schemeForms.Proof1")}
                        variant="outlined"
                        type="file"
                        name="proof1"
                        onChange={(event) =>
                          formik.setFieldValue(
                            "proof1",
                            event.currentTarget.files[0]
                          )
                        }
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ accept: ".pdf, .png, .jpg, .jpeg" }}
                      />
                      {formik.errors.proof1 && (
                        <p className="text-danger">{formik.errors.proof1}</p>
                      )}
                    </TableCell> */}
                  {/* <TableCell align="right">
                      <Button variant="contained">View</Button>
                    </TableCell> */}
                </TableRow>

                <TableRow>
                  {/* <TableCell component="th" scope="row">
                      <TextField
                        fullWidth
                        required
                        variant="outlined"
                        type="file"
                        name="proof2"
                        onChange={(event) =>
                          formik.setFieldValue(
                            "proof2",
                            event.currentTarget.files[0]
                          )
                        }
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ accept: ".pdf, .png, .jpg, .jpeg" }}
                      />
                      {formik.errors.proof2 && (
                        <p className="text-danger">{formik.errors.proof2}</p>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">View</Button>
                    </TableCell>  */}
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <TextField
                      fullWidth
                      label={t(
                        "schemeForms.Recommendation_of_the_School_Principal"
                      )}
                      required
                      variant="outlined"
                      name="recommendation_letter_principal"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "recommendation_letter_principal",
                          event.currentTarget.files[0]
                        );
                      }}
                      type="file"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.recommendation_letter_principal ? (
                      <p className="text-danger">
                        {formik.errors.recommendation_letter_principal}
                      </p>
                    ) : null}
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="contained">
                      <a
                        href={formik.values.recommendation_letter_principal}
                        className="text-decoration-none text-white"
                        target="_blank"
                      >
                        View
                      </a>
                    </Button>
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      label={t("schemeForms.recommendation")}
                      required
                      variant="outlined"
                      name="expert_recommendation_ot_pt"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "expert_recommendation_ot_pt",
                          event.currentTarget.files[0]
                        );
                      }}
                      type="file"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.expert_recommendation_ot_pt ? (
                      <p className="text-danger">
                        {formik.errors.expert_recommendation_ot_pt}
                      </p>
                    ) : null}
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="contained">
                      <a
                        href={formik.values.expert_recommendation_ot_pt}
                        className="text-decoration-none text-white"
                        target="_blank"
                      >
                        View
                      </a>
                    </Button>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell component="th" scope="row">
                    <TextField
                      fullWidth
                      required
                      label={t("schemeForms.Proof1")}
                      variant="outlined"
                      type="file"
                      name="proof1"
                      onChange={(event) =>
                        formik.setFieldValue(
                          "proof1",
                          event.currentTarget.files[0]
                        )
                      }
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ accept: ".pdf, .png, .jpg, .jpeg" }}
                    />
                    {formik.errors.proof1 && (
                      <p className="text-danger">{formik.errors.proof1}</p>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="contained">
                      <a
                        href={formik.values.proof1}
                        className="text-decoration-none text-white"
                        target="_blank"
                      >
                        View
                      </a>
                    </Button>
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      type="file"
                      name="proof2"
                      onChange={(event) =>
                        formik.setFieldValue(
                          "proof2",
                          event.currentTarget.files[0]
                        )
                      }
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ accept: ".pdf, .png, .jpg, .jpeg" }}
                    />
                    {formik.errors.proof2 && (
                      <p className="text-danger">{formik.errors.proof2}</p>
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="contained">
                      <a
                        href={formik.values.proof2}
                        className="text-decoration-none text-white"
                        target="_blank"
                      >
                        View
                      </a>
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <div className="card mt-3">
            <div className="card-header">
              {t("download_declaration.download_required_documents")}
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead className="thead-dark"></thead>
                  <tbody>
                    <tr>
                      <td className="text-start">
                        {t("download_declaration.self_declaration_download")}
                      </td>
                      <td className="text-start">
                        <Button variant="contained" color="primary">
                          <a
                            href={doc1}
                            className="text-decoration-none text-white"
                            download
                          >
                            {t("download_declaration.Download")}
                          </a>
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-start">
                        {t(
                          "download_declaration.Self_attested_declaration_download"
                        )}
                      </td>
                      <td className="text-start">
                        <Button variant="contained" color="primary">
                          <a
                            href={doc2}
                            className="text-decoration-none text-white"
                            download
                          >
                            {t("download_declaration.Download")}
                          </a>
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Navigation Buttons */}
          <Button
            variant="contained"
            type="submit"
            color="primary"
            className="mt-2"
            // disabled={step === steps}
          >
            Update
          </Button>
        </form>
      </Card>
    </div>
  );
};

export default SchemeFiveEdit;
