import * as React from "react";
import Graphdashboard from "../Administration/dashboard/GraphDashboard/Graphdashboard";

const ClerkGraph = () => {
  return (
    <>
      <Graphdashboard role="Clerk1" />
    </>
  );
};

export default ClerkGraph;
