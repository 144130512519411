import React from "react";
import SchemeFourapprovedapplications from "../../Administration/SchemeFour/SchemeFourapprovedapplications";

const ShaskiySamiti1SchemeFourApprovedApplications = () => {
    return (
        <div>
            <SchemeFourapprovedapplications role="Shaskiy Samiti1" />
        </div>
    );
};

export default ShaskiySamiti1SchemeFourApprovedApplications;
