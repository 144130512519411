import React from "react";
import SportSchemeOneRejectedApplications from "../../../Administration/SportSchemeOne/SportSchemeOneRejectedApplications";

const SportClerk2SchemeOneRejectedApplications = () => {
  return (
    <div>
      <SportSchemeOneRejectedApplications role="Sport Clerk2" />
    </div>
  );
};

export default SportClerk2SchemeOneRejectedApplications;
