export const loginSportShaskiySamiti3 = (session) => {
  return {
    type: "LOGIN_SPORT_SHASKIY_SAMITI3",
    payload: session,
  };
};

export const logoutSportShaskiySamiti3 = () => {
  return {
    type: "LOGOUT_SPORT_SHASKIY_SAMITI3",
  };
};

export const setSportShaskiysamiti3Dashboard = (dashboard) => {
  return {
    type: "UPDATE_SPORT_SHASKIY3_DASHBOARD",
    payload: dashboard,
  };
};

export const clearSportShaskiysamiti3Dashboard = () => {
  return {
    type: "CLEAR_SPORT_SHASKIY3_DASHBOARD",
  };
};
