import React from "react";
import SportSchemeThreeRejectedApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeRejectedApplications";

const SportChanani2SchemeThreeRejectedApplications = () => {
  return (
    <div>
      <SportSchemeThreeRejectedApplications role="Sport Chanani2" />
    </div>
  );
};

export default SportChanani2SchemeThreeRejectedApplications;
