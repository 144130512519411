import React from "react";
import SchemeTenPendingApplications from "../../Administration/SchemeTen/SchemeTenPendingApplications";

const SuperAdminSchemeTenPendingApplications = () => {
  return (
    <div>
      <SchemeTenPendingApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeTenPendingApplications;
