import React from "react";
import SchemeFourTotalApplications from "../../Administration/SchemeFour/SchemeFourTotalApplications";

const ShaskiySamiti4SchemeFourTotalApplications = () => {
    return (
        <div>
            <SchemeFourTotalApplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeFourTotalApplications;
