import React from "react";
import SportSchemeTwoPendingApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoPendingApplications";

const SportClerk2SchemeTwoPendingApplications = () => {
  return (
    <div>
      <SportSchemeTwoPendingApplications role="Sport Clerk2" />
    </div>
  );
};

export default SportClerk2SchemeTwoPendingApplications;
