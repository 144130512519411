import React from "react";
import UserProfileSidebar from "../profile/Helpandguidelines";
import { Alert } from "react-bootstrap";

const Helpandguidelines = () => {
  return (
    <div>
      <div className="row">
        <div className="col-md-3">
          <UserProfileSidebar />
        </div>
        <div className="col-md-9">
          <Alert variant="info" className="mt-2">
            <ul>
              <li>
                <strong>Cancellation Option</strong>: This feature allows
                applicants to cancel their scheme applications if needed.
              </li>
              <li>
                The application can only be cancelled if it is either pending
                scrutiny by the Institute Clerk or has been returned for
                modification.
              </li>
              <li>
                <strong>Scheme Cancellation</strong>: Cancelling a scheme
                application will automatically reset the scheme preferences
                selected by the applicant.
              </li>
              <li>
                <strong>Application Details</strong>: Applicants can click on
                the Application ID to view the current scrutiny status and other
                relevant details.
              </li>
              <li>
                <strong>OTP Verification</strong>: An OTP verification of the
                registered mobile number is mandatory to proceed with the scheme
                cancellation process.
              </li>
            </ul>
          </Alert>
        </div>
      </div>
    </div>
  );
};

export default Helpandguidelines;
