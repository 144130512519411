import React from "react";
import SportSchemeThreeApprovedApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeApprovedApplications";

const SportChanani1SchemeThreeApprovedApplications = () => {
  return (
    <div>
      <SportSchemeThreeApprovedApplications role="Sport Chanani1" />
    </div>
  );
};

export default SportChanani1SchemeThreeApprovedApplications;
