import React from "react";
import SportSchemeOneTotalApplications from "../../../Administration/SportSchemeOne/SportSchemeOneTotalApplications";

const SportdeputycommissionerSchemeOneTotalApplications = () => {
  return (
    <div>
      <SportSchemeOneTotalApplications role="Sport Deputy" />
    </div>
  );
};

export default SportdeputycommissionerSchemeOneTotalApplications;
