import React from "react";
import SchemeTwoApprovedApplications from "../../Administration/SchemeTwo/SchemeTwoApprovedApplications";

const Chanani3SchemeTwoApprovedApplications = () => {
    return (
        <div>
            <SchemeTwoApprovedApplications role="Chanani Samiti3" />
        </div>
    );
};

export default Chanani3SchemeTwoApprovedApplications;
