import { removeChananisamiti3UserToken } from "../../common";
import { shaskiysamityTokenName3 } from "../../config";

const getshaskiysamitiFromLocalStorage = () => {
  try {
    const shaskiysamiti3 = JSON.parse(
      sessionStorage.getItem(shaskiysamityTokenName3)
    );
    if (
      shaskiysamiti3 &&
      shaskiysamiti3.authToken &&
      shaskiysamiti3.loggedInTime
    ) {
      console.log("Returning Internal User:", shaskiysamiti3);
      return shaskiysamiti3;
    } else {
      removeChananisamiti3UserToken();
      return null;
    }
  } catch (error) {
    removeChananisamiti3UserToken();
    return null;
  }
};

const getInitial = () => {
  return getshaskiysamitiFromLocalStorage();
};

const shaskiySamity3Reducer = (state = getInitial(), action) => {
  switch (action.type) {
    case "LOGIN_SHASKIY_SAMITI3":
      state = {};
      const time = action.payload.loggedInTime;
      if (time) {
        state.loggedInTime = time;
      } else {
        state.loggedInTime = Date.now();
      }
      state.authToken = action.payload.authToken;
      sessionStorage.setItem(shaskiysamityTokenName3, JSON.stringify(state));
      return state;
    case "LOGOUT_SHASKIY_SAMITI3":
      removeChananisamiti3UserToken();
      return null;
    case "UPDATE_SHASKIY3_DASHBOARD":
      if (state) {
        state = {
          ...state,
          dashboard: {
            ...state.dashboard,
            ...action.payload,
          },
        };
      }
      return state;
    case "CLEAR_SHASKIY3_DASHBOARD":
      state.dashboard = null;
    default:
      return state;
  }
};

export default shaskiySamity3Reducer;
