import React from "react";
import SchemeFivePendingApplications from "../../Administration/SchemeFive/SchemeFivePendingApplications";

const ShaskiySamiti4SchemeFivePendingApplications = () => {
    return (
        <div>
            <SchemeFivePendingApplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeFivePendingApplications;
