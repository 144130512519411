import React from "react";
import SchemeFivePendingApplications from "../../Administration/SchemeFive/SchemeFivePendingApplications";

const Chanani4SchemeFivePendingApplications = () => {
    return (
        <div>
            <SchemeFivePendingApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeFivePendingApplications;
