import React from "react";
import SchemeOneApprovedApplications from "../../Administration/SchemeOne/SchemeOneApprovedApplications";

const ShaskiySamiti2SchemeOneApprovedApplications = () => {
    return (
        <div>
            <SchemeOneApprovedApplications role="Shaskiy Samiti2" />
        </div>
    );
};

export default ShaskiySamiti2SchemeOneApprovedApplications;
