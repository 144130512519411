import React from "react";
import SchemeTwoRejectedApplications from "../../Administration/SchemeTwo/SchemeTwoRejectedApplications";

const SuperAdminSchemeTwoRejectedApplications = () => {
  return (
    <div>
      <SchemeTwoRejectedApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeTwoRejectedApplications;
