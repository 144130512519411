import React from "react";
import SchemeSixRejectedApplications from "../../Administration/SchemeSix/SchemeSixRejectedApplications";

const ClerkSchemeSixRejectedApplications = () => {
  return (
    <div>
      <SchemeSixRejectedApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeSixRejectedApplications;
