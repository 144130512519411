import React from "react";
import SchemeSevenTotalApplications from "../../Administration/SchemeSeven/SchemeSevenTotalApplications";

const Chanani4SchemeSevenTotalApplications = () => {
    return (
        <div>
            <SchemeSevenTotalApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeSevenTotalApplications;
