import React from "react";
import SchemeSevenPendingApplications from "../../Administration/SchemeSeven/SchemeSevenPendingApplications";

const Chanani3SchemeSevenPendingApplications = () => {
    return (
        <div>
            <SchemeSevenPendingApplications role="Chanani Samiti3" />
        </div>
    );
};

export default Chanani3SchemeSevenPendingApplications;
