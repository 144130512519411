import React from "react";
import SportSchemeOneTotalApplications from "../../../Administration/SportSchemeOne/SportSchemeOneTotalApplications";

const SportChanani4SchemeOneTotalApplications = () => {
  return (
    <div>
      <SportSchemeOneTotalApplications role="Sport Chanani4" />
    </div>
  );
};

export default SportChanani4SchemeOneTotalApplications;
