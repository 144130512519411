import React from "react";
import SchemeTenTotalApplications from "../../Administration/SchemeTen/SchemeTenTotalApplications";

const ClerkSchemeTenTotalApplications = () => {
  return (
    <div>
      <SchemeTenTotalApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeTenTotalApplications;
