import React from 'react';
import SchemeTenPendingApplications from '../../Administration/SchemeTen/SchemeTenPendingApplications';

const Clerk2SchemeTenPendingApplications = () => {
  return (
    <div><SchemeTenPendingApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeTenPendingApplications;
