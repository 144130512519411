import React from "react";
import SchemeFourapprovedapplications from "../../Administration/SchemeFour/SchemeFourapprovedapplications";

const Chanani1SchemeFourApprovedApplications = () => {
  return (
    <div>
      <SchemeFourapprovedapplications role="Chanani Samiti1" />
    </div>
  );
};

export default Chanani1SchemeFourApprovedApplications;
