import React from "react";
import SchemeFiveRejectedApplications from "../../Administration/SchemeFive/SchemeFiveRejectedApplications";

const Chanani3SchemeFiveRejectedApplications = () => {
    return (
        <div>
            <SchemeFiveRejectedApplications role="Chanani Samiti3" />
        </div>
    );
};

export default Chanani3SchemeFiveRejectedApplications;
