import React from "react";
import SportSchemeTwoApprovedApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoApprovedApplications";

const SportChanani2SchemeTwoApprovedApplications = () => {
  return (
    <div>
      <SportSchemeTwoApprovedApplications role="Sport Chanani2" />
    </div>
  );
};

export default SportChanani2SchemeTwoApprovedApplications;
