import React from "react";
import SchemeSevenRejectedApplications from "../../Administration/SchemeSeven/SchemeSevenRejectedApplications";

const AdditionalCommSchemeSevenRejectedApplications = () => {
  return (
    <div>
      <SchemeSevenRejectedApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeSevenRejectedApplications;
