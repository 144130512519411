import React from 'react';
import SchemeThreeRejectedApplications from '../../Administration/SchemeThree/SchemeThreeRejectedApplications';

const Clerk3SchemeThreeRejectedApplications = () => {
  return (
    <div><SchemeThreeRejectedApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeThreeRejectedApplications;
