import React from "react";
import SchemeTenPendingApplications from "../../Administration/SchemeTen/SchemeTenPendingApplications";

const Chanani1SchemeTenPendingApplications = () => {
  return (
    <div>
      <SchemeTenPendingApplications role="Chanani Samiti1" />
    </div>
  );
};

export default Chanani1SchemeTenPendingApplications;
