import React from "react";
import SportSchemeThreeApprovedApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeApprovedApplications";

const SportChanani4SchemeThreeApprovedApplications = () => {
  return (
    <div>
      <SportSchemeThreeApprovedApplications role="Sport Chanani4" />
    </div>
  );
};

export default SportChanani4SchemeThreeApprovedApplications;
