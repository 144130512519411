import React from "react";
import SchemeTwoApprovedApplications from "../../Administration/SchemeTwo/SchemeTwoApprovedApplications";

const Chanani4SchemeTwoApprovedApplications = () => {
    return (
        <div>
            <SchemeTwoApprovedApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeTwoApprovedApplications;
