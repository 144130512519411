import React from 'react';
import SchemeSevenapprovedapplications from '../../Administration/SchemeSeven/SchemeSevenapprovedapplications';

const Clerk2SchemeSevenApprovedApplications = () => {
  return (
    <div><SchemeSevenapprovedapplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeSevenApprovedApplications;
