import React from "react";
import "./MovingAnnouncement.css";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const StyledAnnouncement = () => {
  const { t } = useTranslation();

  return (
    <div className="container-fluid">
      <div className="row align-items-center banner-section">
        <div className="col-md-3 col-sm-4 announcement-banner text-decoration-none">
          <NavLink
            to="/allschemespage"
            className="text-decoration-none text-white"
          >
           {t("announcement.announcementlabel")}
          </NavLink>
        </div>
        <div className="col-md-9 col-sm-8 text-center apply-message">
          <marquee behavior="" direction="">
            <NavLink
              to="/allschemespage"
              className="text-decoration-none text-#3d5a80"
            >
              {t("announcement.announcements")}
            </NavLink>
          </marquee>
        </div>
      </div>
    </div>
  );
};

export default StyledAnnouncement;
