import React from 'react';
import SchemeEightTotalApplications from '../../Administration/SchemeEight/SchemeEightTotalApplications';

const Clerk3SchemeEightTotalApplications = () => {
  return (
    <div><SchemeEightTotalApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeEightTotalApplications;
