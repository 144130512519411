import React from "react";
import SchemeSevenTotalApplications from "../../Administration/SchemeSeven/SchemeSevenTotalApplications";

const ShaskiySamiti3SchemeSevenTotalApplications = () => {
    return (
        <div>
            <SchemeSevenTotalApplications role="Shaskiy Samiti3" />
        </div>
    );
};

export default ShaskiySamiti3SchemeSevenTotalApplications;
