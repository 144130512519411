import React from "react";
import SportSchemeThreeRejectedApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeRejectedApplications";

const SportShaskiySamiti1SchemeThreeRejectedApplications = () => {
  return (
    <div>
      <SportSchemeThreeRejectedApplications role="Sport Shaskiy1" />
    </div>
  );
};

export default SportShaskiySamiti1SchemeThreeRejectedApplications;
