import React from 'react';
import SchemeOneApprovedApplications from '../../Administration/SchemeOne/SchemeOneApprovedApplications';

const Clerk2SchemeOneApprovedApplications = () => {
  return (
    <div><SchemeOneApprovedApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeOneApprovedApplications;
