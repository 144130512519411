import React from "react";
import SchemeThreeTotalApplications from "../../Administration/SchemeThree/SchemeThreeTotalApplications";

const Chanani2SchemeThreeTotalApplications = () => {
  return (
    <div>
      <SchemeThreeTotalApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeThreeTotalApplications;
