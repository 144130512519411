import React from "react";
import SchemeOneRejectedApplications from "../../Administration/SchemeOne/SchemeOneRejectedApplications";

const SuperAdminSchemeOneRejectedApplications = () => {
  return (
    <div>
      <SchemeOneRejectedApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeOneRejectedApplications;
