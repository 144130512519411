import React from "react";
import SportSchemeThreeApprovedApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeApprovedApplications";

const SportClerk2SchemeThreeApprovedApplications = () => {
  return (
    <div>
      <SportSchemeThreeApprovedApplications role="Sport Clerk2" />{" "}
    </div>
  );
};

export default SportClerk2SchemeThreeApprovedApplications;
