import React from "react";
import SchemeSevenTotalApplications from "../../Administration/SchemeSeven/SchemeSevenTotalApplications";

const ShaskiySamiti1SchemeSevenTotalApplications = () => {
    return (
        <div>
            <SchemeSevenTotalApplications role="Shaskiy Samiti1" />
        </div>
    );
};

export default ShaskiySamiti1SchemeSevenTotalApplications;
