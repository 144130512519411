import React from "react";
import SchemeEightPendingApplications from "../../Administration/SchemeEight/SchemeEightPendingApplications";

const Chanani1SchemeEightPendingApplications = () => {
  return (
    <div>
      <SchemeEightPendingApplications role="Chanani Samiti1" />
    </div>
  );
};

export default Chanani1SchemeEightPendingApplications;
