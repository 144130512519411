import React from "react";
import SchemeThreePendingApplications from "../../Administration/SchemeThree/SchemeThreePendingApplications";

const SuperAdminSchemeThreePendingApplications = () => {
  return (
    <div>
      <SchemeThreePendingApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeThreePendingApplications;
