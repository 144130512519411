import React from "react";
import SchemeOneApprovedApplications from "../../Administration/SchemeOne/SchemeOneApprovedApplications";

const SuperAdminSchemeOneApprovedApplications = () => {
  return (
    <div>
      <SchemeOneApprovedApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeOneApprovedApplications;
