import React from "react";
import SchemeOneApprovedApplications from "../../Administration/SchemeOne/SchemeOneApprovedApplications";

const ShaskiySamiti4SchemeOneApprovedApplications = () => {
    return (
        <div>
            <SchemeOneApprovedApplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeOneApprovedApplications;
