import { removeSportChanani2UserToken } from "../../common";
import { sportchananiTokenName2 } from "../../config";

const getTrafficFromLocalStorage = () => {
  try {
    const sportchanani2 = JSON.parse(
      sessionStorage.getItem(sportchananiTokenName2)
    );
    if (
      sportchanani2 &&
      sportchanani2.authToken &&
      sportchanani2.loggedInTime
    ) {
      return sportchanani2;
    } else {
      removeSportChanani2UserToken();
      return null;
    }
  } catch (error) {
    removeSportChanani2UserToken();
    return null;
  }
};

const getInitial = () => {
  return getTrafficFromLocalStorage();
};

const sportchanani2UserReducers = (state = getInitial(), action) => {
  switch (action.type) {
    case "LOGIN_SPORT_CHANANI2":
      const { loggedInTime, authToken } = action.payload;
      const newState = {
        loggedInTime: loggedInTime || Date.now(),
        authToken: authToken,
      };
      sessionStorage.setItem(sportchananiTokenName2, JSON.stringify(newState));
      return newState;
    case "LOGOUT_SPORT_CHANANI2":
      removeSportChanani2UserToken();
      return null;
    default:
      return state;
  }
};

export default sportchanani2UserReducers;
