import React from "react";
import SportSchemeTwoRejectedApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoRejectedApplications";

const SportChanani3SchemeTwoRejectedApplications = () => {
  return (
    <div>
      <SportSchemeTwoRejectedApplications role="Sport Chanani3" />
    </div>
  );
};

export default SportChanani3SchemeTwoRejectedApplications;
