import React, { useContext, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
  Typography,
  Alert,
} from "@mui/material";
import { Card, Col, Row } from "react-bootstrap";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import Grid from "@mui/material/Grid2";

import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { server } from "../../common";
import Loader from "../../Loader";
import { useSelectAccess } from "../../store/stateFunctions";
import { useNavigate } from "react-router-dom";
const swalalert = withReactContent(Swal);

const initialValues = {
  
  applicant_fullname: "",
  marriedgirl_fullname:"",
  mobile: "",
  adhar_number: "",
  bod: "",
  religion:"",
  cast:"",
  family_income:"",
  address: "",
  division: "",
  ward_no: "",
  resident_duration_years: "",

  bankname: "",
  branch: "",
  ac_holder_name: "",
  account_no: "",
  ifsc_code: "",
  micr_code: "",

  passport_photo: null,
  upload_adhar: null,
  upload_pancard: null,
  upload_bankpassbook: null,
  certificate_of_disability: null,
  birth_or_age_proof: null,
  medical_officer_letter: null,
  proof1: null,
  proof2: null,
   has_taken_benefit: "",  
  benefit_date: "",
  self_declaration: null,
  self_attestation_declaration: null,
};

const SchemeThreeForm = () => {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const user = useSelectAccess("User");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const stepLabels = [
    t("schemeForms.stepLabels.personalInfo"),
    t("schemeForms.stepLabels.residentialInfo"),
    t("Additional Info"),
    // t("schemeForms.stepLabels.hospitalInfo"),
    t("schemeForms.stepLabels.bankInfo"),
    t("schemeForms.stepLabels.uploadDocuments"),
  ];

  const steps = 5;

  const handleNext = () => {
    if (step < steps) setStep(step + 1);
  };

  const handleBack = () => {
    if (step > 0) setStep(step - 1);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      fullname: Yup.string().required( t('schemeForms.note'), ),
      mobile: Yup.string()
        .matches(/^[6-9]\d{9}$/, "कृपया वैध भारतीय मोबाइल नंबर प्रविष्ट करा.")
        .required("कृपया भारतीय मोबाइल नंबर प्रविष्ट करा."),
      adhar_number: Yup.string()
        .matches(/^\d{12}$/, "कृपया वैध 12-अंकी आधार क्रमांक प्रविष्ट करा.")
        .required("कृपया भारतीय आधार क्रमांक प्रविष्ट करा."),
        has_taken_benefit: Yup.string().required("Please specify if you've taken benefit before"),
  benefit_date: Yup.date().when("has_taken_benefit", {
    is: "Yes",
    then: Yup.date().required("Please specify when you took the benefit"),
  }),

    then: Yup.date().required("Please specify when you took the benefit"),
      bod: Yup.string().required("कृपया जन्मतारीख प्रविष्ट करा."),
      address: Yup.string().required("कृपया संपूर्ण पत्ता  प्रविष्ट करा."),
      division: Yup.string().required("कृपया विभाग क्रमांक प्रविष्ट करा."),
      ward_no: Yup.string().required("कृपया प्रभाग क्रमांक प्रविष्ट करा."),
      resident_duration_years: Yup.string().required(
        "कृपया राहण्याचा कालावधी प्रविष्ट करा."
      ),
      school_name: Yup.string().required("कृपया शाळेचे नाव प्रविष्ट करा."),
      school_address: Yup.string().required(
        "कृपया शाळेचा संपूर्ण पत्ता  प्रविष्ट करा."
      ),
     
      bankname: Yup.string().required("कृपया बँकेचे नाव प्रविष्ट करा."),
      branch: Yup.string().required("कृपया शाखेचे नाव प्रविष्ट करा."),
      ac_holder_name: Yup.string().required(
        "कृपया खातेधारकाचे नाव प्रविष्ट करा."
      ),
      ifsc_code: Yup.string().required("कृपया IFSC कोड प्रविष्ट करा."),
      micr_code: Yup.string().required("कृपया MICR कोड प्रविष्ट करा."),
      account_no: Yup.string().required("कृपया बँक खाते क्रमांक प्रविष्ट करा."),
      passport_photo: Yup.mixed()
        .required("कृपया पासपोर्ट फोटो निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      upload_adhar: Yup.mixed()
        .required("कृपया आधार कार्डची प्रत निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      upload_pancard: Yup.mixed()
        .required("कृपया पॅन कार्डची प्रत निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      upload_bankpassbook: Yup.mixed()
        .required("कृपया बँक पासबुकची प्रत निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      certificate_of_disability: Yup.mixed()
        .required("कृपया अपंगत्व प्रमाणपत्र निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      birth_or_age_proof: Yup.mixed()
        .required("कृपया जन्म किंवा वयाचा पुरावा निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      medical_officer_letter: Yup.mixed()
        .required("कृपया वैद्यकीय अधिकाऱ्याचे पत्र निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      proof1: Yup.mixed()
        .required("कृपया पुरावा १ निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      proof2: Yup.mixed()
        .required("कृपया पुरावा २ निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      self_declaration: Yup.mixed().test(
        "fileSize",
        "फाईल आकार खूप मोठा आहे.",
        (value) => {
          return value ? value.size <= 5000000 : true;
        }
      ),
      self_attestation_declaration: Yup.mixed()
        .required("कृपया स्व-प्रमाणित घोषणा निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
    }),

    onSubmit: (values, action) => {
      // Validate images manually before submission
      if (!values.passport_photo) {
        toast.error("Please select an passport_photo image");
        return;
      }
      if (!values.upload_adhar) {
        toast.error("Please select an Aadhar Card image");
        return;
      }
      if (!values.upload_pancard) {
        toast.error("Please select a upload_pancard image");
        return;
      }
      if (!values.upload_bankpassbook) {
        toast.error("Please select a upload_bankpassbook image");
        return;
      }

      if (!values.proof1) {
        toast.error("Please select a proof1 image");
        return;
      }

      // Create FormData and append values
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      swalalert
        .fire({
          title: "Are You Sure To Submit the Permission?",
          text: `Confirm Permission Submission?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          buttonsStyling: true,
        })
        .then((swalObject) => {
          setLoading(true);

          if (swalObject.isConfirmed) {
            server
              .post(`/firstappl/addfirstappl`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: user.authToken,
                },
              })
              .then(function (response) {
                if (response.status === 200 || response.status === 201) {
                  swalalert.fire({
                    title: "Success!",
                    text:  `Application Submitted Successfully`,
                    icon: "success",
                  });
                  formik.resetForm();
                }
              })
              .catch((error) => {
                toast.error(
                  error.response?.data?.message ||
                    error.response?.data?.error ||
                    "Failed to connect to server"
                );
              })
              .finally(() => {
                setLoading(false); // Hide loader after API call
              });
          }
        });
    },
  });

  return (
    <>
      {loading && <Loader loading={loading} />}

      <div className="container">
        {console.log(formik.values)}
        <Alert severity="info" className="mt-2">
          {t("schemeOneForm.note")}
        </Alert>
        {/* Progress Bar Component */}
        <ProgressBar activeStep={step} stepLabels={stepLabels} />
        <Card className="mt-2">
          <Card.Header>
            <Typography variant="h5" gutterBottom style={{ marginTop: "10px" }}>
              {t("Scheme 3")}
            </Typography>
          </Card.Header>

          <form style={{ padding: "20px" }}>
            {step === 0 && (
              <>
                <Typography variant="h6" gutterBottom>
                  {t("schemeForms.personal_info")}
                </Typography><hr/>
                <Row>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label="Applicant Full Name/Organisation Name"
                      required
                      variant="outlined"
                      name="Applicantfullname"
                      value={formik.values.applicant_fullname}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.applicant_fullname ? (
                      <p className="text-danger">{formik.errors.applicant_fullname}</p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label=" Full Name"
                      required
                      variant="outlined"
                      name=" Full Name"
                      value={formik.values.marriedgirl_fullname}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.marriedgirl_fullname ? (
                      <p className="text-danger">{formik.errors.marriedgirl_fullname}</p>
                    ) : null}
                  </Col>

                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.dob")}
                      required
                      variant="outlined"
                      type="date"
                      name="bod"
                      value={formik.values.bod}
                      onChange={formik.handleChange}
                      InputLabelProps={{ shrink: true }}
                    />
                    {formik.errors.bod ? (
                      <p className="text-danger">{formik.errors.bod}</p>
                    ) : null}
                  </Col>

                  {/* <Col md={4}  className="mt-4">
                  <TextField
                    fullWidth
                    label="अपंगत्वाचा प्रकार "
                    required
                    variant="outlined"
                    name="dob"
                    value={formik.values.dob}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.dob ? (
                    <p className="text-danger">{formik.errors.dob}</p>
                  ) : null}
                </Col> */}

                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.mobile_number")}
                      required
                      variant="outlined"
                      name="mobile"
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.mobile ? (
                      <p className="text-danger">{formik.errors.mobile}</p>
                    ) : null}
                  </Col>

                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.aadhaar_no")}
                      required
                      variant="outlined"
                      name="adhar_number"
                      value={formik.values.adhar_number}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.adhar_number ? (
                      <p className="text-danger">
                        {formik.errors.adhar_number}
                      </p>
                    ) : null}
                  </Col>
                 

<Col md={4} className="mt-4">
  <FormControl fullWidth variant="outlined" required>
    <InputLabel>Religion</InputLabel>
    <Select
      label="Religion"
      name="Religion"
      value={formik.values.Religion}
      onChange={formik.handleChange}
      error={formik.touched.Religion && Boolean(formik.errors.Religion)}
    >
      <MenuItem value="">Select Religion</MenuItem>
      <MenuItem value="Hindu">Hindu</MenuItem>
      <MenuItem value="Muslim">Muslim</MenuItem>
      <MenuItem value="Christian">Christian</MenuItem>
      <MenuItem value="Sikh">Sikh</MenuItem>
      <MenuItem value="Buddhist">Buddhist</MenuItem>
      <MenuItem value="Jain">Jain</MenuItem>
      <MenuItem value="Other">Other</MenuItem>
    </Select>
    {formik.touched.Religion && formik.errors.Religion && (
      <Typography color="error" variant="caption">
        {formik.errors.Religion}
      </Typography>
    )}
  </FormControl>
</Col>

                      <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label="Cast"
                      required
                      variant="outlined"
                      name="cast"
                      value={formik.values.cast}
                      onChange={formik.handleChange}
                    />
                  </Col>
                   
            
                </Row>
              </>
            )}
            {step === 1 && (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "20px" }}
                >
                  {t("schemeForms.resident_info")}
                </Typography><hr/>
                <Row>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.address")}
                      required
                      variant="outlined"
                      name="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.address ? (
                      <p className="text-danger">{formik.errors.address}</p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-2">
                    <FormControl variant="outlined" fullWidth margin="normal">
                      <InputLabel id="demo-simple-select-label">
                        {t("schemeForms.division")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Division"
                        variant="outlined"
                        name="division"
                        value={formik.values.division}
                        onChange={formik.handleChange}
                        required
                      >
                        <MenuItem value="">
                          <em> {t("schemeForms.selectDistrict")}</em>
                        </MenuItem>
                        <MenuItem value="Satpur">
                          <em>{t("schemeForms.divisions.satpur")}</em>
                        </MenuItem>
                        <MenuItem value="Cidco">
                          <em>{t("schemeForms.divisions.cidco")}</em>
                        </MenuItem>
                        <MenuItem value="nashikroad">
                          <em>{t("schemeForms.divisions.nashikroad")} </em>
                        </MenuItem>
                        <MenuItem value="newnashik">
                          <em> {t("schemeForms.divisions.newnashik")} </em>
                        </MenuItem>
                        <MenuItem value="nashikwest">
                          <em> {t("schemeForms.divisions.nashikwest")} </em>
                        </MenuItem>
                        <MenuItem value="panchavati">
                          <em>t{"schemeForms.divisions.panchavati"}</em>
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {formik.errors.division ? (
                      <p className="text-danger">{formik.errors.division}</p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.ward")}
                      required
                      variant="outlined"
                      name="ward_no"
                      value={formik.values.ward_no}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.ward_no ? (
                      <p className="text-danger">{formik.errors.ward_no}</p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.years_resident")}
                      required
                      variant="outlined"
                      name="resident_duration_years"
                      value={formik.values.resident_duration_years}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.resident_duration_years ? (
                      <p className="text-danger">
                        {formik.errors.resident_duration_years}
                      </p>
                    ) : null}
                  </Col>
                </Row>
              </>
            )}
            {/* Step 3: School Information */}
          {step === 2 && (
  <>
    <Typography
      variant="h6"
      gutterBottom
      style={{ marginTop: "20px" }}
    >
      {t("Additonal Info")}
    </Typography>
    <hr />
 <Row>
            <Col md={4}>
              <FormControl fullWidth>
                <InputLabel>Have you taken benefit of this scheme?</InputLabel>
                <Select
                  label="Have you taken benefit of this scheme?"
                  name="has_taken_benefit"
                  value={formik.values.has_taken_benefit}
                  onChange={formik.handleChange}
                  error={formik.touched.has_taken_benefit && Boolean(formik.errors.has_taken_benefit)}
                >
                  <MenuItem>Select</MenuItem>
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
                {formik.touched.has_taken_benefit && formik.errors.has_taken_benefit && (
                  <Typography color="error" variant="caption">
                    {formik.errors.has_taken_benefit}
                  </Typography>
                )}
              </FormControl>
            </Col>
            
            {/* Show Date Picker if "Yes" is selected */}
            {formik.values.has_taken_benefit === "Yes" && (
              <Col md={4}>
                <TextField
                  fullWidth
                  label="When did you take the benefit?"
                  name="benefit_date"
                  type="date"
                  value={formik.values.benefit_date}
                  onChange={formik.handleChange}
                  error={formik.touched.benefit_date && Boolean(formik.errors.benefit_date)}
                  helperText={formik.touched.benefit_date && formik.errors.benefit_date}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Col> 
            )}
            
                      {/* Family Income Dropdown */}
         
            <Col md={4} className="">
              <FormControl fullWidth>
                <InputLabel>Family Income</InputLabel>
                <Select
                  label="Family Income"
                  name="family_income"
                  value={formik.values.family_income}
                  onChange={formik.handleChange}
                  error={formik.touched.family_income && Boolean(formik.errors.family_income)}
                >
                  <MenuItem value="">Select Income Range</MenuItem>
                  <MenuItem value="<100000">Below ₹1,00,000</MenuItem>
                  <MenuItem value="100000-300000">₹1,00,000 - ₹3,00,000</MenuItem>
                  <MenuItem value="300000-500000">₹3,00,000 - ₹5,00,000</MenuItem>
                  <MenuItem value="500000+">Above ₹5,00,000</MenuItem>
                </Select>
                {formik.touched.family_income && formik.errors.family_income && (
                  <Typography color="error" variant="caption">
                    {formik.errors.family_income}
                  </Typography>
                )}
              </FormControl>
            </Col>

               <Col md={4} >
                    <TextField
                      fullWidth
                      label={t("Dependents of female applicants")}
                      required
                      variant="outlined"
                      name="Dependents of female applicants"
                      value={formik.values.resident_duration_years}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.resident_duration_years ? (
                      <p className="text-danger">
                        {formik.errors.resident_duration_years}
                      </p>
                    ) : null}
                  </Col>
          </Row>
  </>
)}


        

          

            {/* Step 5: Bank Information */}
            {step === 3  && (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "20px" }}
                >
                  {t("schemeForms.bank_info")}
                </Typography><hr/>
                <Row>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.bank_name")}
                      required
                      variant="outlined"
                      name="bankname"
                      value={formik.values.bankname}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.bankname ? (
                      <p className="text-danger">{formik.errors.bankname}</p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.bank_branch")}
                      required
                      variant="outlined"
                      name="branch"
                      value={formik.values.branch}
                      onChange={formik.handleChange}
                    />
                    {/* {formik.errors.branch ? (
                      <p className="text-danger">{formik.errors.branch}</p>
                    ) : null} */}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.account_holder_name")}
                      required
                      variant="outlined"
                      name="ac_holder_name"
                      value={formik.values.ac_holder_name}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.ac_holder_name ? (
                      <p className="text-danger">
                        {formik.errors.ac_holder_name}
                      </p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.account_number")}
                      required
                      variant="outlined"
                      name="account_no"
                      value={formik.values.account_no}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.account_no ? (
                      <p className="text-danger">{formik.errors.account_no}</p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.ifsc_code")}
                      required
                      variant="outlined"
                      name="ifsc_code"
                      value={formik.values.ifsc_code}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.ifsc_code ? (
                      <p className="text-danger">{formik.errors.ifsc_code}</p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.micr_code")}
                      required
                      variant="outlined"
                      name="micr_code"
                      value={formik.values.micr_code}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.micr_code ? (
                      <p className="text-danger">{formik.errors.micr_code}</p>
                    ) : null}
                  </Col>
                </Row>
              </>
            )}
            {/* Step 6: Document Upload */}
            {step === 4 && (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "20px" }}
                >
                  {t("schemeForms.upload_documents")}
                </Typography><hr/>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={t("schemeForms.PassportPhoto")}
                      required
                      variant="outlined"
                      type="file"
                      name="passport_photo"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "passport_photo",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.passport_photo ? (
                      <p className="text-danger">
                        {formik.errors.passport_photo}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={t("schemeForms.AadharCard")}
                      required
                      variant="outlined"
                      type="file"
                      name="upload_adhar"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "upload_adhar",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.upload_adhar ? (
                      <p className="text-danger">
                        {formik.errors.upload_adhar}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={t("schemeForms.PanCard")}
                      required
                      variant="outlined"
                      type="file"
                      name="upload_pancard"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "upload_pancard",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.upload_pancard ? (
                      <p className="text-danger">
                        {formik.errors.upload_pancard}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={t("schemeForms.BankPassbook")}
                      required
                      variant="outlined"
                      type="file"
                      name="upload_bankpassbook"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "upload_bankpassbook",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.upload_bankpassbook ? (
                      <p className="text-danger">
                        {formik.errors.upload_bankpassbook}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={t("schemeForms.DisabilityCertificate")}
                      required
                      variant="outlined"
                      type="file"
                      name="certificate_of_disability"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "certificate_of_disability",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.certificate_of_disability ? (
                      <p className="text-danger">
                        {formik.errors.certificate_of_disability}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={t("schemeForms.BirthProof")}
                      required
                      variant="outlined"
                      type="file"
                      name="birth_or_age_proof"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "birth_or_age_proof",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.birth_or_age_proof ? (
                      <p className="text-danger">
                        {formik.errors.birth_or_age_proof}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={t("schemeForms.MedicalOfficerLetter")}
                      required
                      variant="outlined"
                      type="file"
                      name="medical_officer_letter"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "medical_officer_letter",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.medical_officer_letter ? (
                      <p className="text-danger">
                        {formik.errors.medical_officer_letter}
                      </p>
                    ) : null}
                  </Grid>
                 
                  <Typography
                  variant="h6"
                  gutterBottom
                 
                >
                 {t("schemeForms.Proof1")}
                </Typography><hr/>
               
                <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                     className="mb-0"
                      required
                      variant="outlined"
                      type="file"
                      name="proof1"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "proof1",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.proof1 ? (
                      <p className="text-danger">{formik.errors.proof1}</p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      type="file"
                      name="proof2"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "proof2",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.proof2 ? (
                      <p className="text-danger">{formik.errors.proof2}</p>
                    ) : null}
                  </Grid>
                </Grid>

                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "20px" }}
                  className="mb-3"
                >
                  {t("schemeForms.DeclarationForm")}
                </Typography><hr/>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={t("schemeForms.SelfDeclaration")}
                      required
                      
                      variant="outlined"
                      type="file"
                      name="self_declaration"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "self_declaration",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.self_declaration ? (
                      <p className="text-danger">
                        {formik.errors.self_declaration}
                      </p>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={t("schemeForms.SelfAttestationDeclaration")}
                      required
                      variant="outlined"
                      type="file"
                      name="self_attestation_declaration"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "self_attestation_declaration",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.self_attestation_declaration ? (
                      <p className="text-danger">
                        {formik.errors.self_attestation_declaration}
                      </p>
                    ) : null}
                  </Grid>
                </Grid>
              </>
            )}

            {/* Navigation Buttons */}
            <Grid
              container
              justifyContent="space-between"
              style={{ marginTop: "20px" }}
            >
              <Button
                variant="contained"
                color="secondary"
                disabled={step === 0}
                onClick={handleBack}
              >
                {t("schemeForms.Back")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={step === steps ? formik.handleSubmit : handleNext}
                // disabled={step === steps}
              >
                {step === steps
                  ? t("schemeForms.Submit")
                  : t("schemeForms.Next")}
              </Button>
            </Grid>
          </form>
        </Card>
      </div>
    </>
  );
};

export default SchemeThreeForm;
