import React from 'react';
import SchemeSixRejectedApplications from '../../Administration/SchemeSix/SchemeSixRejectedApplications';

const Clerk2SchemeSixRejectedApplications = () => {
  return (
    <div><SchemeSixRejectedApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeSixRejectedApplications;
