import React from "react";
import SchemeEightPendingApplications from "../../Administration/SchemeEight/SchemeEightPendingApplications";

const ShaskiySamiti4SchemeEightPendingApplications = () => {
    return (
        <div>
            <SchemeEightPendingApplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeEightPendingApplications;
