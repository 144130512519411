import superAdminReducer from "./superAdminReducers.js";
import deputyCommReducers from "./deputyCommReducers.js";
import additionalCommissionerReducer from "./additionalCommissionerReducer.js";
import userReducer from "./userReducer.js";
import chananiSamiti1Reducer from "./chananiSamiti1Reducer.js";
import chananiSamiti2Reducer from "./chananiSamiti2Reducer.js";
import chananiSamiti3Reducer from "./chananiSamiti3Reducer.js";
import chananiSamiti4Reducer from "./chananiSamiti4Reducer.js";
import shaskiySamity1Reducer from "./shaskiySamity1Reducer.js";
import shaskiySamity2Reducer from "./shaskiySamity2Reducer.js";
import shaskiySamity3Reducer from "./shaskiySamity3Reducer.js";
import shaskiySamity4Reducer from "./shaskiySamity4Reducer.js";
import shaskiySamity5Reducer from "./shaskiySamity5Reducer.js";
import clerk1UserReducers from "./clerk1UserReducers.js";
import clerk2UserReducers from "./clerk2UserReducers.js";
import clerk3UserReducers from "./clerk3UserReducers.js";
import sportclerk1UserReducers from "./sportclerk1UserReducers.js";
import sportclerk2UserReducers from "./sportclerk2UserReducers.js";
import sportchanani1UserReducers from "./sportchanani1UserReducers.js";
import sportchanani2UserReducers from "./sportchanani2UserReducers.js";
import sportchanani3UserReducers from "./sportchanani3UserReducers.js";
import sportchanani4UserReducers from "./sportchanani4UserReducers.js";
import sportshaskiySamity1Reducer from "./sportshaskiySamity1Reducer.js";
import sportshaskiySamity2Reducer from "./sportshaskiySamity2Reducer.js";
import sportshaskiySamity3Reducer from "./sportshaskiySamity3Reducer.js";
import sportshaskiySamity4Reducer from "./sportshaskiySamity4Reducer.js";
import sportshaskiySamity5Reducer from "./sportshaskiySamity5Reducer.js";
import sportchanani5UserReducers from "./sportchanani5UserReducers.js";
import sportdeputyCommReducers from "./sportdeputyCommReducers.js";
import sportadditionalCommissionerReducer from "./sportadditionalCommissionerReducer.js";

const storeReducer = {
  deputycomm: deputyCommReducers,
  superadmin: superAdminReducer,
  user: userReducer,
  addcomm: additionalCommissionerReducer,

  chananisamiti1: chananiSamiti1Reducer,
  chananisamiti2: chananiSamiti2Reducer,
  chananisamiti3: chananiSamiti3Reducer,
  chananisamiti4: chananiSamiti4Reducer,

  shaskiysamiti1: shaskiySamity1Reducer,
  shaskiysamiti2: shaskiySamity2Reducer,
  shaskiysamiti3: shaskiySamity3Reducer,
  shaskiysamiti4: shaskiySamity4Reducer,
  shaskiysamiti5: shaskiySamity5Reducer,

  clerk1: clerk1UserReducers,
  clerk2: clerk2UserReducers,
  clerk3: clerk3UserReducers,

  // sport
  sportclerk1: sportclerk1UserReducers,
  sportclerk2: sportclerk2UserReducers,

  sportchanani1: sportchanani1UserReducers,
  sportchanani2: sportchanani2UserReducers,
  sportchanani3: sportchanani3UserReducers,
  sportchanani4: sportchanani4UserReducers,
  sportchanaji5: sportchanani5UserReducers,

  sportshaskiy1: sportshaskiySamity1Reducer,
  sportshaskiy2: sportshaskiySamity2Reducer,
  sportshaskiy3: sportshaskiySamity3Reducer,
  sportshaskiy4: sportshaskiySamity4Reducer,
  sportshaskiy5: sportshaskiySamity5Reducer,

  sportdeputy: sportdeputyCommReducers,
  sportaddcomm: sportadditionalCommissionerReducer,
};

export default storeReducer;
