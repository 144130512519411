import React from "react";
import SchemeTenTotalApplications from "../../Administration/SchemeTen/SchemeTenTotalApplications";

const Chanani4SchemeTenTotalApplications = () => {
    return (
        <div>
            <SchemeTenTotalApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeTenTotalApplications;
