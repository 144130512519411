import React from "react";
import { Stepper, Step, StepLabel } from "@mui/material";

const ProgressBar = ({ activeStep, stepLabels }) => {
  return (
    <div style={{ marginTop: "15px" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {stepLabels.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default ProgressBar;
