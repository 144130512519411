import React from "react";
import SchemeSixApprovedApplications from "../../Administration/SchemeSix/SchemeSixapprovedapplications";

const SuperAdminSchemeSixApprovedApplications = () => {
  return (
    <div>
      <SchemeSixApprovedApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeSixApprovedApplications;
