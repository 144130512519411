import React, { useState } from "react";
import { Card, Button, CardContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
// import brochure from "../../assets/documents/Funding for Assistive Devices and Technological Know-How for the Disabled.pdf";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelectAccess } from "../../store/stateFunctions";

const SchemeSixDetails = () => {
  const { t } = useTranslation();
  const user = useSelectAccess("User");
  const isLoggedIn = user && user;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleApplyNowClick = (e) => {
    if (!isLoggedIn) {
      e.preventDefault(); // Prevent navigation
      toast.info("Please log in to apply for the Scheme");
      navigate("/login");
    } else {
      setShow(true);
    }
  };

  return (
    <div
      className="container-fluid mt-4"
      style={{ backgroundColor: "#eaf6ff" }}
    >
      <div className="row">
        <div className="col-12">
          <div
            style={{
              backgroundColor: "#ffffff",
              padding: "30px",
              border: "1px solid #e0e0e0",
            }}
          >
            <h4 style={{ fontWeight: "bold", marginBottom: "20px" }}>
              {t("schemesixdetails.title")}
            </h4>

            <div>
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                <li>{t("schemesixdetails.description.0")}</li>
                <li>{t("schemesixdetails.description.1")}</li>
                <li>{t("schemesixdetails.description.2")}</li>
                <li>{t("schemesixdetails.description.3")}</li>
                <li>{t("schemesixdetails.description.4")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Card className="mt-2">
        <CardContent>
          <Typography
            className="text-dark"
            variant="h5"
            style={{ fontWeight: "bold" }}
          >
             {t("TermsandConditions")}
          </Typography>
          <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
            <li>
            {t("ViewDetailsSchemeSix.1")}
            </li>
            <li>{t("ViewDetailsSchemeSix.2")}</li>
            <li>
            {t("ViewDetailsSchemeSix.3")}
            </li>
          </ul>
        </CardContent>
      </Card>

      <Card className="mt-2">
        <CardContent>
          <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
            <li>
            {t("ViewDetailsSchemeSix.4")}
            </li>
            <li>
            {t("ViewDetailsSchemeSix.5")}
            </li>
            <li>
            {t("ViewDetailsSchemeSix.6")}
            </li>
            <li>
            {t("ViewDetailsSchemeSix.7")}
            </li>

            <li>
            {t("ViewDetailsSchemeSix.8")}
            </li>
            <li>
            {t("ViewDetailsSchemeSix.9")}
            </li>
            <li>
            {t("ViewDetailsSchemeSix.10")},
            </li>
            <li>
            {t("ViewDetailsSchemeSix.11")}
            </li>
            <li>
              {" "}
              {t("ViewDetailsSchemeSix.12s")}
            </li>
          </ul>

          <div>
            <NavLink to="/schemesix" onClick={handleApplyNowClick}>
              <Button
                variant="contained"
                className="redbutton"
                color="secondary"
              >
                {t("schemesixdetails.applyNow")}
              </Button>
            </NavLink>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SchemeSixDetails;
