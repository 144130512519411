import React from "react";
import SchemeThreeTotalApplications from "../../Administration/SchemeThree/SchemeThreeTotalApplications";

const ShaskiySamiti3SchemeThreeTotalApplications = () => {
    return (
        <div>
            <SchemeThreeTotalApplications role="Shaskiy Samiti3" />
        </div>
    );
};

export default ShaskiySamiti3SchemeThreeTotalApplications;
