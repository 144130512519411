import React from "react";
import SchemeTenApprovedApplications from "../../Administration/SchemeTen/SchemeTenapprovedapplications";

const SuperAdminSchemeTenApprovedApplications = () => {
  return (
    <div>
      <SchemeTenApprovedApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeTenApprovedApplications;
