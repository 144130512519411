import React from "react";
import SchemeSixTotalApplications from "../../Administration/SchemeSix/SchemeSixTotalApplications";

const ClerkSchemeSixTotalApplications = () => {
  return (
    <div>
      <SchemeSixTotalApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeSixTotalApplications;
