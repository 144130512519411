import React from "react";
import SchemeNineRejectedApplications from "../../Administration/schemeNine/SchemeNineRejectedApplications";

const Chanani1SchemeNineRejectedApplications = () => {
    return (
        <div>
            <SchemeNineRejectedApplications role="Chanani Samiti1" />
        </div>
    );
};

export default Chanani1SchemeNineRejectedApplications;
