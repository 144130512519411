import React from 'react';
import SchemeFiveTotalApplications from '../../Administration/SchemeFive/SchemeFiveTotalApplications';

const Clerk3SchemeFiveTotalApplications = () => {
  return (
    <div><SchemeFiveTotalApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeFiveTotalApplications;
