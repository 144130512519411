import React from "react";
import SchemeOneTotalApplications from "../../Administration/SchemeOne/SchemeOneTotalApplications";

const Chanani4SchemeOneTotalApplications = () => {
    return (
        <div>
            <SchemeOneTotalApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeOneTotalApplications;
