import React from "react";
import SportSchemeOneRejectedApplications from "../../../Administration/SportSchemeOne/SportSchemeOneRejectedApplications";

const SportChanani4SchemeOneRejectedApplications = () => {
  return (
    <div>
      <SportSchemeOneRejectedApplications role="Sport Chanani4" />
    </div>
  );
};

export default SportChanani4SchemeOneRejectedApplications;
