import React from "react";
import SchemeFivePendingApplications from "../../Administration/SchemeFive/SchemeFivePendingApplications";

const ShaskiySamiti3SchemeFivePendingApplications = () => {
    return (
        <div>
            <SchemeFivePendingApplications role="Shaskiy Samiti3" />
        </div>
    );
};

export default ShaskiySamiti3SchemeFivePendingApplications;
