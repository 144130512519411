import store from ".";
import {
  loginAdd_Comm,
  loginChananisamiti3,
  logoutAdd_Comm,
  logoutChananisamiti1,
  logoutChananisamiti2,
  logoutChananisamiti3,
  logoutChananisamiti4,
  logoutclerk,
  logoutclerk1,
  logoutclerk2,
  logoutclerk3,
  logoutDepuComm,
  logoutShaskiySamiti1,
  logoutShaskiySamiti2,
  logoutShaskiySamiti3,
  logoutShaskiySamiti4,
  logoutShaskiySamiti5,
  logoutShaskiySamity,
  logoutsportAdd_Comm,
  logoutsportchanani1,
  logoutsportchanani2,
  logoutsportchanani3,
  logoutsportchanani4,
  logoutsportchanani5,
  logoutsportclerk1,
  logoutsportclerk2,
  logoutSportDepuComm,
  logoutSportShaskiySamiti1,
  logoutSportShaskiySamiti2,
  logoutSportShaskiySamiti3,
  logoutSportShaskiySamiti4,
  logoutSportShaskiySamiti5,
  logoutSuperAdmin,
  logoutUser,
  setAddCommDashboard,
  setChanani1Dashboard,
  setChanani2Dashboard,
  setChanani3Dashboard,
  setChanani4Dashboard,
  setClerk1Dashboard,
  setClerk2Dashboard,
  setClerk3Dashboard,
  setDeputyDashboard,
  setShaskiysamiti1Dashboard,
  setShaskiysamiti2Dashboard,
  setShaskiysamiti3Dashboard,
  setShaskiysamiti4Dashboard,
  setShaskiysamiti5Dashboard,
  setsportclerk1Dashboard,
  setsportclerk2Dashboard,
  setSportShaskiysamiti1Dashboard,
  setSportShaskiysamiti2Dashboard,
  setSportShaskiysamiti3Dashboard,
  setSportShaskiysamiti4Dashboard,
  setSportShaskiysamiti5Dashboard,
  setSuperAdminDashboard,
} from "./actions";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export function updateDashboardState(role, data) {
  switch (role) {
    case "Super Admin":
      store.dispatch(setSuperAdminDashboard(data));
      break;
    case "Clerk1":
      store.dispatch(setClerk1Dashboard(data));
      break;
    case "Clerk2":
      store.dispatch(setClerk2Dashboard(data));
      break;
    case "Clerk3":
      store.dispatch(setClerk3Dashboard(data));
      break;
    case "Chanani Samiti1":
      store.dispatch(setChanani1Dashboard(data));
      break;
    case "Chanani Samiti2":
      store.dispatch(setChanani2Dashboard(data));
      break;
    case "Chanani Samiti3":
      store.dispatch(setChanani3Dashboard(data));
      break;
    case "Chanani Samiti4":
      store.dispatch(setChanani4Dashboard(data));
      break;
    case "Shaskiy Samiti1":
      store.dispatch(setShaskiysamiti1Dashboard(data));
      break;
    case "Shaskiy Samiti2":
      store.dispatch(setShaskiysamiti2Dashboard(data));
      break;
    case "Shaskiy Samiti3":
      store.dispatch(setShaskiysamiti3Dashboard(data));
      break;
    case "Shaskiy Samiti4":
      store.dispatch(setShaskiysamiti4Dashboard(data));
      break;
    case "Shaskiy Samiti5":
      store.dispatch(setShaskiysamiti5Dashboard(data));
      break;
    case "Additional Commissioner":
      store.dispatch(setAddCommDashboard(data));
      break;
    case "Deputy Commissioner":
      store.dispatch(setDeputyDashboard(data));
      break;

    case "Sport Clerk1":
      store.dispatch(setsportclerk1Dashboard(data));
      break;
    case "Sport Clerk2":
      store.dispatch(setsportclerk2Dashboard(data));
      break;

    case "Sport Shaskiy1":
      store.dispatch(setSportShaskiysamiti1Dashboard(data));
      break;
    case "Sport Shaskiy2":
      store.dispatch(setSportShaskiysamiti2Dashboard(data));
      break;
    case "Sport Shaskiy3":
      store.dispatch(setSportShaskiysamiti3Dashboard(data));
      break;
    case "Sport Shaskiy4":
      store.dispatch(setSportShaskiysamiti4Dashboard(data));
      break;
    case "Sport Shaskiy5":
      store.dispatch(setSportShaskiysamiti5Dashboard(data));
      break;
    default:
      break;
  }
}

export function useSelectAccess(role) {
  return useSelector((store) => {
    switch (role) {
      case "Super Admin":
        return store.superadmin;
      case "User":
        return store.user;
      case "Additional Commissioner":
        return store.addcomm;
      case "Deputy Commissioner":
        return store.deputycomm;
      case "Chanani Samiti1":
        return store.chananisamiti1;
      case "Chanani Samiti2":
        return store.chananisamiti2;
      case "Chanani Samiti3":
        return store.chananisamiti3;
      case "Chanani Samiti4":
        return store.chananisamiti4;

      case "Shaskiy Samiti1":
        return store.shaskiysamiti1;
      case "Shaskiy Samiti2":
        return store.shaskiysamiti2;
      case "Shaskiy Samiti3":
        return store.shaskiysamiti3;
      case "Shaskiy Samiti4":
        return store.shaskiysamiti4;
      case "Shaskiy Samiti5":
        return store.shaskiysamiti5;
      case "Clerk1":
        return store.clerk1;
      case "Clerk2":
        return store.clerk2;
      case "Clerk3":
        return store.clerk3;

      case "Sport Clerk1":
        return store.sportclerk1;
      case "Sport Clerk2":
        return store.sportclerk2;

      case "Sport Chanani1":
        return store.sportchanani1;
      case "Sport Chanani2":
        return store.sportchanani2;
      case "Sport Chanani3":
        return store.sportchanani3;
      case "Sport Chanani4":
        return store.sportchanani4;
      case "Sport Chanani5":
        return store.sportchanani5;
      case "Sport Shaskiy1":
        return store.sportshaskiy1;
      case "Sport Shaskiy2":
        return store.sportshaskiy2;
      case "Sport Shaskiy3":
        return store.sportshaskiy3;
      case "Sport Shaskiy4":
        return store.sportshaskiy4;
      case "Sport Shaskiy5":
        return store.sportshaskiy5;

      case "Sport Deputy":
        return store.sportdeputy;

      case "Sport AddComm":
        return store.sportaddcomm;
      default:
        return null;
    }
  });
}

export function logoutAccess(role) {
  switch (role) {
    case "Super Admin":
      store.dispatch(logoutSuperAdmin());
      break;
    case "User":
      store.dispatch(logoutUser());
      break;
    case "Additional Commissioner":
      store.dispatch(logoutAdd_Comm());
      break;
    case "Deputy Commissioner":
      store.dispatch(logoutDepuComm());
      break;

    case "Chanani Samiti1":
      store.dispatch(logoutChananisamiti1());
      break;
    case "Chanani Samiti2":
      store.dispatch(logoutChananisamiti2());
      break;
    case "Chanani Samiti3":
      store.dispatch(logoutChananisamiti3());
      break;
    case "Chanani Samiti4":
      store.dispatch(logoutChananisamiti4());
      break;

    case "Shaskiy Samiti1":
      store.dispatch(logoutShaskiySamiti1());
      break;
    case "Shaskiy Samiti2":
      store.dispatch(logoutShaskiySamiti2());
      break;
    case "Shaskiy Samiti3":
      store.dispatch(logoutShaskiySamiti3());
      break;
    case "Shaskiy Samiti4":
      store.dispatch(logoutShaskiySamiti4());
      break;
    case "Shaskiy Samiti5":
      store.dispatch(logoutShaskiySamiti5());
      break;

    case "Clerk1":
      store.dispatch(logoutclerk1());
      break;
    case "Clerk2":
      store.dispatch(logoutclerk2());
      break;
    case "Clerk3":
      store.dispatch(logoutclerk3());
      break;

    case "Sport Clerk1":
      store.dispatch(logoutsportclerk1());
      break;
    case "Sport Clerk2":
      store.dispatch(logoutsportclerk2());
      break;

    case "Sport Chanani1":
      store.dispatch(logoutsportchanani1());
      break;
    case "Sport Chanani2":
      store.dispatch(logoutsportchanani2());
      break;

    case "Sport Chanani3":
      store.dispatch(logoutsportchanani3());
      break;
    case "Sport Chanani4":
      store.dispatch(logoutsportchanani4());
      break;
    case "Sport Chanani5":
      store.dispatch(logoutsportchanani5());
      break;
    case "Sport Shaskiy1":
      store.dispatch(logoutSportShaskiySamiti1());
      break;
    case "Sport Shaskiy2":
      store.dispatch(logoutSportShaskiySamiti2());
      break;
    case "Sport Shaskiy3":
      store.dispatch(logoutSportShaskiySamiti3());
      break;
    case "Sport Shaskiy4":
      store.dispatch(logoutSportShaskiySamiti4());
      break;
    case "Sport Shaskiy5":
      store.dispatch(logoutSportShaskiySamiti5());
      break;

    case "Sport Deputy":
      store.dispatch(logoutSportDepuComm());
      break;
    case "Sport AddComm":
      store.dispatch(logoutsportAdd_Comm());
      break;
    default:
      return;
  }
  toast.info("Logout successful", { toastId: "LogoutReplacable" });
}
