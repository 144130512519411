import React from "react";
import SchemeEightapprovedapplications from "../../Administration/SchemeEight/SchemeEightapprovedapplications";

const ShaskiySamiti5SchemeEightApprovedApplications = () => {
    return (
        <div>
            <SchemeEightapprovedapplications role="Shaskiy Samiti5" />
        </div>
    );
};

export default ShaskiySamiti5SchemeEightApprovedApplications;
