import React from "react";
import SchemeOneApprovedApplications from "../../Administration/SchemeOne/SchemeOneApprovedApplications";

const Chanani4SchemeOneApprovedApplications = () => {
    return (
        <div>
            <SchemeOneApprovedApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeOneApprovedApplications;
