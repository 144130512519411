import React from "react";
import SportSchemeThreeRejectedApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeRejectedApplications";

const SportShaskiySamiti2SchemethreRejectedApplications = () => {
  return (
    <div>
      <SportSchemeThreeRejectedApplications role="Sport Shaskiy2" />
    </div>
  );
};

export default SportShaskiySamiti2SchemethreRejectedApplications;
