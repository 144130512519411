import React from "react";
import SchemeSevenRejectedApplications from "../../Administration/SchemeSeven/SchemeSevenRejectedApplications";

const SuperAdminSchemeSevenRejectedApplications = () => {
  return (
    <div>
      <SchemeSevenRejectedApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeSevenRejectedApplications;
