import React from "react";
import SchemeNineRejectedApplications from "../../Administration/schemeNine/SchemeNineRejectedApplications";

const Chanani4SchemeNineRejectedApplications = () => {
    return (
        <div>
            <SchemeNineRejectedApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeNineRejectedApplications;
