import React from "react";
import SchemeTenapprovedapplications from "../../Administration/SchemeTen/SchemeTenapprovedapplications";

const AdditionalCommSchemeTenApprovedApplications = () => {
  return (
    <div>
      <SchemeTenapprovedapplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeTenApprovedApplications;
