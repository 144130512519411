import React from "react";
import SchemeSevenapprovedapplications from "../../Administration/SchemeSeven/SchemeSevenapprovedapplications";

const ShaskiySamiti3SchemeSevenApprovedApplications = () => {
    return (
        <div>
            <SchemeSevenapprovedapplications role="Shaskiy Samiti3" />
        </div>
    );
};

export default ShaskiySamiti3SchemeSevenApprovedApplications;
