import React from "react";
import SchemeSixPendingApplications from "../../Administration/SchemeSix/SchemeSixPendingApplications";

const ClerkSchemeSixPendingApplications = () => {
  return (
    <div>
      <SchemeSixPendingApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeSixPendingApplications;
