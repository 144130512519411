import React from "react";
import SchemeNineTotalApplications from "../../Administration/schemeNine/SchemeNineTotalApplications";

const Chanani1SchemeNineTotalApplications = () => {
    return (
        <div>
            <SchemeNineTotalApplications role="Chanani Samiti1" />
        </div>
    );
};

export default Chanani1SchemeNineTotalApplications;
