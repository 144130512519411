import React from "react";
import SchemeEightapprovedapplications from "../../Administration/SchemeEight/SchemeEightapprovedapplications";

const ShaskiySamiti1SchemeEightApprovedApplications = () => {
  return (
    <div>
      <SchemeEightapprovedapplications role="Shaskiy Samiti1" />
    </div>
  );
};

export default ShaskiySamiti1SchemeEightApprovedApplications;
