import React from "react";
import SchemeSevenapprovedapplications from "../../Administration/SchemeSeven/SchemeSevenapprovedapplications";

const Chanani2SchemeSevenApprovedApplications = () => {
  return (
    <div>
      <SchemeSevenapprovedapplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeSevenApprovedApplications;
