import React from "react";
import SchemeOneRejectedApplications from "../../Administration/SchemeOne/SchemeOneRejectedApplications";

const Chanani4SchemeOneRejectedApplications = () => {
    return (
        <div>
            <SchemeOneRejectedApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeOneRejectedApplications;
