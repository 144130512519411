import React from "react";
import SchemeSevenRejectedApplications from "../../Administration/SchemeSeven/SchemeSevenRejectedApplications";

const ShaskiySamiti1SchemeSevenRejectedApplications = () => {
    return (
        <div>
            <SchemeSevenRejectedApplications role="Shaskiy Samiti1" />
        </div>
    );
};

export default ShaskiySamiti1SchemeSevenRejectedApplications;
