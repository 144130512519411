import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { server } from "../common";
import { useSelectAccess } from "../store/stateFunctions";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const initialValues = {
  name: "",
  email: "",
  department: "",
  role: "",
  clerk_role: "",
  division: "",
  password: "",
};

const swalalert = withReactContent(Swal);

export default function ManageUser({ role }) {
  const [data, setData] = useState([]);
  const user = useSelectAccess(role);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setIsEditMode(false);
    setSelectedData(null);
    formik.setValues(initialValues);
    setShow(true);
  };

  const handleShowEdit = (data) => {
    setSelectedData(data);
    setIsEditMode(true);
    setShow(true);
  };

  useEffect(() => {
    getData();
    if (isEditMode && selectedData) {
      formik.setValues({
        name: selectedData.name,
        email: selectedData.email,
        division: selectedData.division,
        department: selectedData.department,
        role: selectedData.role,
        Clerk_role: selectedData.Clerk_role,
        password: selectedData.password,
      });
    }
  }, [isEditMode, selectedData]);

  const getData = () => {
    setLoading(true); // Set loading to true before fetching
    server
      .get(`/admin/getalladmins`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  // Delete data
  const DeleteData = (app) => {
    swalalert
      .fire({
        title: "Delete Confirmation!",
        text: `Are You Sure That You Want To Delete User ${app.name} ?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          server
            .delete(`/admin/delete/${app.id}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: user.authToken,
              },
            })
            .then((response) => {
              if (response.status === 200 || response.status === 201) {
                swalalert.fire(
                  "Deleted!",
                  "Application has been deleted.",
                  "success"
                );
                getData();
              }
            })
            .catch((error) => {
              console.error("Delete error:", error);
              toast.error(
                error.response?.data?.message || "Failed to delete application"
              );
            });
        } else {
          swalalert.fire(
            "Cancelled",
            "Your application was not deleted",
            "info"
          );
        }
      });
  };

  // update
  const handleUpdate = (values) => {
    server
      .put(`/admin/updateadmin/${values.id}`, values, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        formik.resetForm();
        getData();
        toast.success("User Updated successfully");
        handleClose();
      })
      .catch(function (error) {
        if (error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  //add
  const formik = useFormik({
    initialValues: isEditMode ? selectedData : initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required("Enter a name"),
      email: Yup.string().required("Enter a email"),
      division: Yup.string().required("Enter a division"),
      role: Yup.string().required("Enter a role"),
      department: Yup.string().required("Enter a department"),
      password: Yup.string().required("Enter a password"),
      clerk_role: Yup.string(),
    }),
    onSubmit: (values, action) => {
      if (isEditMode) {
        handleUpdate({ ...values, id: selectedData.id });
      } else {
        server
          .post("/admin/register", values, {
            headers: {
              "Content-Type": "application/json",
              Authorization: user.authToken,
            },
          })
          .then(function (response) {
            if (response.status === 200 || response.status === 201) {
              toast.success("User Added successfully");
              formik.resetForm();
              handleClose();
              getData();
            }
          })
          .catch(function (error) {
            if (error.response?.data?.message)
              toast.error(error.response.data.message);
            else if (error.response?.data?.error) {
              toast.error(error.response.data.error);
            } else toast.error("Failed to connect to server");
          });
      }
    },
  });

  return (
    <div className="container">
      {console.log(formik.values)}
      <div className="d-flex position-relative mb-3 justify-content-end ">
        <Button variant="contained" color="info" onClick={handleShow}>
          Add User
        </Button>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{isEditMode ? "Edit User" : "Add User"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-outline mb-2">
                <TextField
                  name="name"
                  margin="dense"
                  type="text"
                  placeholder="Full Name"
                  variant="outlined"
                  label="Full Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  fullWidth
                  required
                ></TextField>
                {formik.errors.name ? (
                  <p className="text-danger">{formik.errors.name}</p>
                ) : null}
              </div>
              <div className="form-outline mb-2">
                <TextField
                  name="email"
                  margin="dense"
                  type="email"
                  placeholder="Email"
                  variant="outlined"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  fullWidth
                  required
                ></TextField>
                {formik.errors.email ? (
                  <p className="text-danger">{formik.errors.email}</p>
                ) : null}
              </div>
              <div>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Role"
                    variant="outlined"
                    name="role"
                    value={formik.values.role}
                    onChange={formik.handleChange}
                    required
                  >
                    <MenuItem value="Clerk">Clerk</MenuItem>
                    <MenuItem value="Chanani1">Chanani-1</MenuItem>
                    <MenuItem value="Chanani2">Chanani-2</MenuItem>
                    <MenuItem value="Chanani3">Chanani-3</MenuItem>
                    <MenuItem value="Chanani4">Chanani-4</MenuItem>
                    <MenuItem value="Deputy Commissioner">
                      Deputy Commissioner
                    </MenuItem>
                    <MenuItem value="Shaskiy1">Shaskiya-1</MenuItem>
                    <MenuItem value="Shaskiy2">Shaskiya-2</MenuItem>
                    <MenuItem value="Shaskiy3">Shaskiya-3</MenuItem>
                    <MenuItem value="Shaskiy4">Shaskiya-4</MenuItem>
                    <MenuItem value="Shaskiy5">Shaskiya-5</MenuItem>
                    <MenuItem value="Additional Commissioner">
                      Additional Commissioner
                    </MenuItem>
                    <MenuItem value="Commissioner">Commissioner</MenuItem>
                  </Select>
                </FormControl>
                {formik.errors.role ? (
                  <p className="text-danger">{formik.errors.role}</p>
                ) : null}
              </div>

              {formik.values.role === "Clerk" && (
                <div>
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel id="demo-simple-select-label">
                      Clerk Role
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Clerk Role"
                      variant="outlined"
                      name="clerk_role"
                      value={formik.values.clerk_role}
                      onChange={formik.handleChange}
                      required
                    >
                      <MenuItem value="Yogesh">Clerk-1</MenuItem>
                      <MenuItem value="Dindorkar">Clerk-2</MenuItem>
                      <MenuItem value="Pingle">Clerk-3</MenuItem>
                    </Select>
                  </FormControl>
                  {formik.errors.clerk_role ? (
                    <p className="text-danger">{formik.errors.clerk_role}</p>
                  ) : null}
                </div>
              )}

              <div className="form-outline mb-2">
                <TextField
                  name="department"
                  margin="dense"
                  type="text"
                  placeholder="Department"
                  variant="outlined"
                  label="Department"
                  value={formik.values.department}
                  onChange={formik.handleChange}
                  fullWidth
                  required
                ></TextField>
                {formik.errors.department ? (
                  <p className="text-danger">{formik.errors.department}</p>
                ) : null}
              </div>

              <div>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel id="demo-simple-select-label">
                    {t("schemeForms.division")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Division"
                    variant="outlined"
                    name="division"
                    value={formik.values.division}
                    onChange={formik.handleChange}
                    required
                  >
                    <MenuItem value="">
                      <em> {t("schemeForms.selectDivision")}</em>
                    </MenuItem>
                    <MenuItem value="satpur">
                      <em>{t("schemeForms.divisions.satpur")}</em>
                    </MenuItem>
                    <MenuItem value="cidco">
                      <em>{t("schemeForms.divisions.cidco")}</em>
                    </MenuItem>
                    <MenuItem value="nashikroad">
                      <em>{t("schemeForms.divisions.nashikroad")} </em>
                    </MenuItem>
                    <MenuItem value="nashikeast">
                      <em> {t("schemeForms.divisions.nashikeast")} </em>
                    </MenuItem>
                    <MenuItem value="nashikwest">
                      <em> {t("schemeForms.divisions.nashikwest")} </em>
                    </MenuItem>
                    <MenuItem value="panchavati">
                      <em>{t("schemeForms.divisions.panchavati")}</em>
                    </MenuItem>
                  </Select>
                </FormControl>
                {formik.errors.division ? (
                  <p className="text-danger">{formik.errors.division}</p>
                ) : null}
              </div>

              <div className="form-outline mb-2">
                <TextField
                  name="password"
                  margin="dense"
                  type="password"
                  placeholder="Password"
                  variant="outlined"
                  label="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  fullWidth
                  required
                ></TextField>
                {formik.errors.password ? (
                  <p className="text-danger">{formik.errors.password}</p>
                ) : null}
              </div>

              <div className="pt-1 mb-2 ">
                <Button variant="contained" type="submit">
                  {isEditMode ? "Update" : "Add"}
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>

      <div className="card mb-3">
        <div className="card-header">Total Admin Users</div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-striped table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th>Sr.No.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Department</th>
                  <th>Division</th>
                  <th>Clerk Role</th>
                  <th>Actions</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {data.map((app, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{app.name}</td>
                    <td>{app.email}</td>
                    <td>{app.role}</td>
                    <td>{app.department}</td>
                    <td>{app.division}</td>
                    <td>{app.clerk_role ? app.clerk_role : "-"}</td>
                    <td>
                      <div className="btn btn-success text-white">
                        <AiFillEdit onClick={() => handleShowEdit(app)} />
                      </div>
                    </td>
                    <td>
                      <div className="btn btn-danger text-white">
                        <AiFillDelete onClick={() => DeleteData(app)} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
