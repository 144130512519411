import React from "react";
import SchemeNineRejectedApplications from "../../Administration/schemeNine/SchemeNineRejectedApplications";

const ShaskiySamiti3SchemeNineRejectedApplications = () => {
    return (
        <div>
            <SchemeNineRejectedApplications role="Shaskiy Samiti3" />
        </div>
    );
};

export default ShaskiySamiti3SchemeNineRejectedApplications;
