import React from 'react';
import SchemeFiveRejectedApplications from '../../Administration/SchemeFive/SchemeFiveRejectedApplications';

const Clerk3SchemeFiveRejectedApplications = () => {
  return (
    <div><SchemeFiveRejectedApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeFiveRejectedApplications;
