import React from "react";
import Schemethreeapprovedapplications from "../../Administration/SchemeThree/Schemethreeapprovedapplications";

const ClerkSchemeThreeApprovedApplications = () => {
  return (
    <div>
      <Schemethreeapprovedapplications role="Clerk1" />{" "}
    </div>
  );
};

export default ClerkSchemeThreeApprovedApplications;
