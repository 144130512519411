import React from "react";
import SportSchemeThreeTotalApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeTotalApplications";

const SportdeputycommissionerSchemeThreeTotalApplications = () => {
  return (
    <div>
      <SportSchemeThreeTotalApplications role="Sport Deputy" />
    </div>
  );
};

export default SportdeputycommissionerSchemeThreeTotalApplications;
