import React from "react";
import SchemeFourRejectedApplications from "../../Administration/SchemeFour/SchemeFourRejectedApplications";

const AdditionalCommSchemeFourRejectedApplications = () => {
  return (
    <div>
      <SchemeFourRejectedApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeFourRejectedApplications;
