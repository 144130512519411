import React from "react";
import SchemeTenRejectedApplications from "../../Administration/SchemeTen/SchemeTenRejectedApplications";

const ShaskiySamiti5SchemeTenRejectedApplications = () => {
    return (
        <div>
            <SchemeTenRejectedApplications role="Shaskiy Samiti5" />
        </div>
    );
};

export default ShaskiySamiti5SchemeTenRejectedApplications;
