// import React, { useEffect, useState } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { dark } from "@mui/material/styles/createPalette";
// import { useParams } from "react-router-dom";
// import { server } from "../../../common";
// import { useSelectAccess } from "../../../store/stateFunctions";

// const FinalPermission = () => {
//   const params = useParams();
//   const [permission, setPermission] = useState(null);
//   const user = useSelectAccess("User");

//   const { id } = params;

//   useEffect(() => {
//     getdata();
//   }, [params]);

//   const getdata = () => {
//     server
//       .get(`/dashboard/getsingleappl/${id}`, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: user.authToken,
//         },
//       })
//       .then(function (response) {
//         console.log("api response", response.data);
//         if (response.status === 200 || response.status === 201) {
//           setPermission(response.data);
//           console.log(response.data);
//         }
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   };

//   return (
//     <div
//       className="container my-5 "
//       style={{ border: "2px solid black", padding: "25px" }}
//     >
//       <div className="d-flex flex-column align-items-center">
//         <div>
//           <h2>नाशिक महानगर पालिका, नाशिक </h2>
//         </div>
//         <div>
//           <p className="text-dark">
//             राज्यस्तरीय स्पर्धेसाठी प्राविण्य मिळवलेले अशा मनपा कार्यक्षेत्रातील
//             खेळाडूंना शिष्यवृत्ती अर्थसहाय्य योजना
//           </p>
//         </div>
//       </div>
//       <hr />
//       <div className="row align-items-center">
//         <div className="col-md-9">
//           <div
//             style={{
//               display: "flex",

//               flexDirection: "column",
//               margin: "0 auto",
//               paddingRight: "20px",
//             }}
//           >
//             <div style={{ display: "flex" }}>
//               <label htmlFor="phone">Full Name :</label>
//               <p style={{ marginLeft: "10px" }}>{permission?.fullname}</p>
//             </div>

//             <div
//               style={{
//                 display: "flex",

//                 justifyContent: "space-between",
//                 marginTop: "16px",
//               }}
//             >
//               <div style={{ display: "flex" }}>
//                 <label htmlFor="phone">Mobile No. :</label>
//                 <p style={{ marginLeft: "10px" }}>{permission?.mobile}</p>
//               </div>

//               <div style={{ display: "flex" }}>
//                 <label htmlFor="address">Address :</label>
//                 <p style={{ marginLeft: "10px" }}>{permission?.address}</p>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* <div className="col-md-3 text-center">
//           <img
//             src="https://bootdey.com/img/Content/avatar/avatar7.png"
//             alt="Admin"
//             className="square"
//             width="160"
//             style={{
//               height: "180px",
//               border: "3px solid black",
//               borderRadius: "4px",
//               margin: "0 auto",
//             }}
//           />
//         </div> */}
//       </div>
//       <hr />
//       <h5>• Documents Upload </h5>
//       <table className="table table-striped table-bordered mt-4 ">
//         <thead className="thead-light" style={{ textAlign: "left" }}>
//           <tr>
//             <th style={{ textAlign: "left" }}>अ.क्र</th>
//             <th style={{ textAlign: "left" }}>कागदपत्राचे तपशील</th>
//             <th style={{ textAlign: "left" }}>सादर केलेले कागदपत्रे</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td>1</td>
//             <td style={{ textAlign: "left" }}>अपंगत्वाचे प्रमाणपत्र</td>
//             <td>
//               {" "}
//               <span className="text-success">&#10004;</span>
//             </td>
//           </tr>
//           <tr>
//             <td>2</td>
//             <td style={{ textAlign: "left" }}>
//               हॉस्पीटलच्या वैद्यकीय अधिका-याचे पत्र
//             </td>
//             <td>
//               {" "}
//               <span className="text-success">&#10004;</span>
//             </td>
//           </tr>

//           <tr>
//             <td>3</td>
//             <td style={{ textAlign: "left" }}>
//               स्वयं साक्षांकनासाठी स्वयं घोषणापत्र
//             </td>
//             <td>
//               {" "}
//               <span className="text-success">&#10004;</span>
//             </td>
//           </tr>
//           <tr>
//             <td>4</td>
//             <td style={{ textAlign: "left" }}>स्वयंघोषणापत्र </td>
//             <td>
//               <span className="text-success">&#10004;</span>
//             </td>
//           </tr>
//           <tr>
//             <td>5</td>
//             <td style={{ textAlign: "left" }}>आधार कार्ड तथा पावती</td>
//             <td>
//               <span className="text-success">&#10004;</span>
//             </td>
//           </tr>
//           <tr>
//             <td>6</td>
//             <td style={{ textAlign: "left" }}>बँक पासबूक</td>
//             <td>
//               <span className="text-success">&#10004;</span>
//             </td>
//           </tr>
//           <tr>
//             <td>7</td>
//             <td style={{ textAlign: "left" }}>पॅन कार्ड</td>
//             <td>
//               <span className="text-success">&#10004;</span>
//             </td>
//           </tr>
//           <tr>
//             <td></td>
//             <td style={{ textAlign: "left" }}>
//               कोणतेही दोन पुरावे (पाणी बिल / वीजबिल / मालमत्ता कर पावती /
//               वास्तव्याचा करारनामा / रेशनकार्ड)
//             </td>
//             <td></td>
//           </tr>
//           <tr>
//             <td>8</td>
//             <td style={{ textAlign: "left" }}>proof1</td>
//             <td>
//               {" "}
//               <span className="text-success">&#10004;</span>
//             </td>
//           </tr>
//           <tr>
//             <td>9</td>
//             <td style={{ textAlign: "left" }}>proof2</td>
//             <td>
//               {" "}
//               <span className="text-success">&#10004;</span>
//             </td>
//           </tr>
//         </tbody>
//       </table>
//       <hr />
//       {/* <h5> • Permissions </h5>
//       <table className="table table-striped table-bordered mt-4 ">
//         <thead className="thead-light" style={{ textAlign: "left" }}>
//           <tr>
//             <th style={{ textAlign: "left" }}>अ.क्र</th>
//             <th style={{ textAlign: "left" }}>Users</th>
//             <th style={{ textAlign: "left" }}>Approved</th>
//           </tr>
//         </thead>
//         <tbody>
//           <tr>
//             <td>1</td>
//             <td style={{ textAlign: "left" }}>Clerk</td>
//             <td>
//               {permission?.statusbyclerk === "Approve" ? (
//                 <span className="text-success">&#10004;</span>
//               ) : (
//                 <span className="text-danger">&#10060;</span>
//               )}{" "}
//             </td>
//           </tr>
//           <tr>
//             <td>2</td>
//             <td style={{ textAlign: "left" }}>Deputy Commissioner</td>
//             <td>
//               {permission?.statusbydeputy === "Approve" ? (
//                 <span className="text-success">&#10004;</span>
//               ) : (
//                 <span className="text-danger">&#10060;</span>
//               )}
//             </td>
//           </tr>
//           <tr>
//             <td>3</td>
//             <td style={{ textAlign: "left" }}>Chanani 1</td>
//             <td>
//               {permission?.statusbychanani1 === "Approve" ? (
//                 <span className="text-success">&#10004;</span>
//               ) : (
//                 <span className="text-danger">&#10060;</span>
//               )}
//             </td>
//           </tr>
//           <tr>
//             <td>4</td>
//             <td style={{ textAlign: "left" }}>Chanani 2 </td>
//             <td>
//               {permission?.statusbychanani2 === "Approve" ? (
//                 <span className="text-success">&#10004;</span>
//               ) : (
//                 <span className="text-danger">&#10060;</span>
//               )}
//             </td>
//           </tr>
//           <tr>
//             <td>5</td>
//             <td style={{ textAlign: "left" }}>Chanani 3 </td>
//             <td>
//               {permission?.statusbychanani3 === "Approve" ? (
//                 <span className="text-success">&#10004;</span>
//               ) : (
//                 <span className="text-danger">&#10060;</span>
//               )}
//             </td>
//           </tr>
//           <tr>
//             <td>6</td>
//             <td style={{ textAlign: "left" }}>Chanani 4 </td>
//             <td>
//               {permission?.statusbychanani4 === "Approve" ? (
//                 <span className="text-success">&#10004;</span>
//               ) : (
//                 <span className="text-danger">&#10060;</span>
//               )}
//             </td>
//           </tr>

//           <tr>
//             <td>8</td>
//             <td style={{ textAlign: "left" }}> Additional Commissioner</td>
//             <td>
//               {permission?.statusbyadditional === "Approve" ? (
//                 <span className="text-success">&#10004;</span>
//               ) : (
//                 <span className="text-danger">&#10060;</span>
//               )}
//             </td>
//           </tr>
//           <tr>
//             <td>9</td>
//             <td style={{ textAlign: "left" }}>Shaskiy Samiti 1</td>
//             <td>
//               {permission?.statusbyshaskiya1 === "Approve" ? (
//                 <span className="text-success">&#10004;</span>
//               ) : (
//                 <span className="text-danger">&#10060;</span>
//               )}
//             </td>
//           </tr>
//           <tr>
//             <td>9</td>
//             <td style={{ textAlign: "left" }}>Shaskiy Samiti 2</td>
//             <td>
//               {permission?.statusbyshaskiya2 === "Approve" ? (
//                 <span className="text-success">&#10004;</span>
//               ) : (
//                 <span className="text-danger">&#10060;</span>
//               )}
//             </td>
//           </tr>
//           <tr>
//             <td>9</td>
//             <td style={{ textAlign: "left" }}>Shaskiy Samiti 3</td>
//             <td>
//               {permission?.statusbyshaskiya3 === "Approve" ? (
//                 <span className="text-success">&#10004;</span>
//               ) : (
//                 <span className="text-danger">&#10060;</span>
//               )}
//             </td>
//           </tr>
//           <tr>
//             <td>9</td>
//             <td style={{ textAlign: "left" }}>Shaskiy Samiti 4</td>
//             <td>
//               {permission?.statusbyshaskiya4 === "Approve" ? (
//                 <span className="text-success">&#10004;</span>
//               ) : (
//                 <span className="text-danger">&#10060;</span>
//               )}
//             </td>
//           </tr>
//           <tr>
//             <td>9</td>
//             <td style={{ textAlign: "left" }}>Shaskiy Samiti 5</td>
//             <td>
//               {permission?.statusbyshaskiya5 === "Approve" ? (
//                 <span className="text-success">&#10004;</span>
//               ) : (
//                 <span className="text-danger">&#10060;</span>
//               )}
//             </td>
//           </tr>
//         </tbody>
//       </table> */}

//       <div>
//         {/* <div className="row mt-4">
//           <div className="col-md-4 d-flex flex-column justify-content-center align-items-center">
//             <p className="text-dark">१.कागदपत्रांची पूर्तता केली आहे.</p>
//             <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
//               <p className="text-dark">क्रीडा अधिकारी ( सदस्य ) </p>
//               <p className="text-dark"> नाशिक महानगर पालिका , नाशिक </p>
//             </div>
//           </div>

//           <div className="col-md-4 d-flex flex-column justify-content-center align-items-center">
//             <p className="text-dark">२.अपूर्ण कागदपत्रांची पूर्तता करून घेणे</p>
//             <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
//               <p className="text-dark">उपलेखा अधिकारी(सदस्य ) </p>
//               <p className="text-dark"> नाशिक महानगर पालिका , नाशिक </p>
//             </div>
//           </div>

//           <div className="col-md-4 d-flex flex-column justify-content-center align-items-center">
//             <p className="text-dark">३.अर्ज निकाली काढण्यात आला आहे .</p>
//             <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
//               <p className="text-dark">उपलेखा परीक्षक (सदस्य ) </p>
//               <p className="text-dark"> नाशिक महानगर पालिका , नाशिक</p>
//             </div>
//           </div>
//         </div> */}

//         <div className="row mt-4 d-flex align-items-center">
//           <div className="col-md-6 mt-5 d-flex flex-column justify-content-center align-items-center">
//             <p className="text-dark">जिल्हा क्रीडा अधिकारी (विशेष सदस्य ) </p>
//             <p className="text-dark">अध्यक्ष महाराष्ट्र्र शासन </p>
//           </div>
//           <div className="col-md-6 mt-5 d-flex flex-column justify-content-center align-items-center">
//             <p className="text-dark">उपआयुक्त (समाजकल्याण ) </p>
//             <p className="text-dark">अध्यक्ष नाशिक महानगर पालिका , नाशिक</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FinalPermission;

import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams } from "react-router-dom";
import { server } from "../../../common";
import { useSelectAccess } from "../../../store/stateFunctions";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Button, Row } from "react-bootstrap";
import logo2 from "../../../assets/images/second-logo.png";

const FinalPermission = () => {
  const params = useParams();
  const [permission, setPermission] = useState(null);
  const user = useSelectAccess("User");

  const { id } = params;

  useEffect(() => {
    getdata();
  }, [params]);

  const getdata = () => {
    server
      .get(`/dashboard/getsingleappl/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          setPermission(response.data);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  return (
    <>
      <div className="py-2 d-flex justify-content-end">
        <Button
          className="btn btn-danger mx-3 d-print-none"
          onClick={() => window.print()}
        >
          Print Document
        </Button>
      </div>
      <div className="container my-2 border p-2">
        {/* Header Section */}
        <div className="text-dark pb-3 mb-3 border-bottom d-flex align-items-center justify-content-center gap-3">
          <img
            src="/images/logo2.png"
            alt="company-logo"
            className="company-logo"
            width="100"
            height="100"
          />
          <div className="w-100 text-center">
            <p className="fs-6 fw-bold text-dark mb-0">
              Nashik Municipal Corporation
            </p>
            <p className="fs-6 mb-1 mt-0">Department of Social Welfare </p>
            <Row className="small justify-content-center">
              <p className="col-md-4 ">GSTIN:&nbsp;27AQRPN2514R1Z2</p>
              <p className="col-md-4 ">State: 27-Maharashtra</p>
            </Row>
            <p>Helpline Number : 9898989898</p>
          </div>
          <img width="100" height="100" src={logo2} alt="" />
        </div>

        {/* Personal Information  Section */}
        <Card className="p-4 mb-4">
          <h5 className="mb-3">Personal Information</h5>
          <div className="row">
            <div className="col-md-6">
              <p>
                <strong>Full Name:</strong> {permission?.fullname || "N/A"}
              </p>
              <p>
                <strong>Mobile No.:</strong> {permission?.mobile || "N/A"}
              </p>
            </div>
            <div className="col-md-6">
              <p>
                <strong>Address:</strong> {permission?.address || "N/A"}
              </p>
              <p>
                <strong>Ward No :</strong> {permission?.ward_no || "N/A"}
              </p>
            </div>
            <div className="col-md-6">
              <p>
                <strong>Division:</strong> {permission?.division || "N/A"}
              </p>
              <p>
                <strong>Residence Duration :</strong>{" "}
                {permission?.resident_duration_years || "N/A"}
              </p>
            </div>
            <div className="col-md-6">
              <p>
                <strong>Aadhar Number :</strong>{" "}
                {permission?.adhar_number || "N/A"}
              </p>
              <p>
                <strong>Permission Status :</strong>{" "}
                <span className="badge bg-success">
                  {permission?.permission_status || "N/A"}
                </span>
              </p>
            </div>
          </div>
        </Card>

        {/* Documents Upload Table */}
        <Card className="p-4 mb-4">
          <h5>Documents Uploaded</h5>
          <table className="table table-striped table-bordered">
            <thead style={{ backgroundColor: "red" }}>
              <tr>
                <th>अ.क्र</th>
                <th>कागदपत्राचे तपशील</th>
                <th>सादर केलेले कागदपत्रे</th>
              </tr>
            </thead>
            <TableBody>
              {[
                { id: 1, name: "अपंगत्वाचे प्रमाणपत्र", status: true },
                {
                  id: 2,
                  name: "हॉस्पीटलच्या वैद्यकीय अधिका-याचे पत्र",
                  status: true,
                },
                {
                  id: 3,
                  name: "स्वयं साक्षांकनासाठी स्वयं घोषणापत्र",
                  status: true,
                },
                { id: 4, name: "स्वयंघोषणापत्र", status: true },
                { id: 5, name: "आधार कार्ड तथा पावती", status: true },
                { id: 6, name: "बँक पासबूक", status: true },
                { id: 7, name: "पॅन कार्ड", status: true },
                { id: 8, name: "proof1", status: true },
                { id: 9, name: "proof2", status: true },
              ].map((doc, index) => (
                <TableRow key={index}>
                  <TableCell>{doc.id}</TableCell>
                  <TableCell>{doc.name}</TableCell>
                  <TableCell>
                    {doc.status ? (
                      <span className="text-success">&#10004;</span>
                    ) : (
                      <span className="text-danger">&#10060;</span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </table>
        </Card>
      </div>
    </>
  );
};

export default FinalPermission;
