export const loginsportAdd_Comm = (session) => {
  return {
    type: "LOGIN_SPORT_ADD_COMM",
    payload: session,
  };
};
export const logoutsportAdd_Comm = () => {
  return {
    type: "LOGOUT_SPORT_ADD_COMM",
  };
};

export const setAddsportCommDashboard = (dashboard) => {
  return {
    type: "UPDATE_SPORT_ADDCOMM_DASHBOARD",
    payload: dashboard,
  };
};

export const clearAddsportCommDashboard = () => {
  return {
    type: "CLEAR_SPORT_ADDCOMM_DASHBOARD",
  };
};
