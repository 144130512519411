import React from "react";
import SchemeEightPendingApplications from "../../Administration/SchemeEight/SchemeEightPendingApplications";

const Chanani4SchemeEightPendingApplications = () => {
    return (
        <div>
            <SchemeEightPendingApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeEightPendingApplications;
