import React from "react";
import SchemeSixTotalApplications from "../../Administration/SchemeSix/SchemeSixTotalApplications";

const Chanani2SchemeSixTotalApplications = () => {
  return (
    <div>
      <SchemeSixTotalApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeSixTotalApplications;
