import React from "react";
import SchemeTenapprovedapplications from "../../Administration/SchemeTen/SchemeTenapprovedapplications";

const ShaskiySamiti4SchemeTenApprovedApplications = () => {
    return (
        <div>
            <SchemeTenapprovedapplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeTenApprovedApplications;
