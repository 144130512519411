import React from "react";
import SchemeFiveTotalApplications from "../../Administration/SchemeFive/SchemeFiveTotalApplications";

const AdditionalCommSchemeFiveTotalApplications = () => {
  return (
    <div>
      <SchemeFiveTotalApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeFiveTotalApplications;
