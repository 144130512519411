import React from "react";
import SchemeNinePendingApplications from "../../Administration/schemeNine/SchemeNinePendingApplications";

const ClerkSchemeNinePendingApplications = () => {
  return (
    <div>
      <SchemeNinePendingApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeNinePendingApplications;
