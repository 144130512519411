import React from "react";
import SportSchemeOneTotalApplications from "../../../Administration/SportSchemeOne/SportSchemeOneTotalApplications";

const SportCommissionerSchemeOneTotalApplications = () => {
  return (
    <div>
      <SportSchemeOneTotalApplications role="Sport AddComm" />
    </div>
  );
};

export default SportCommissionerSchemeOneTotalApplications;
