import React from "react";
import SchemeSevenRejectedApplications from "../../Administration/SchemeSeven/SchemeSevenRejectedApplications";

const ClerkSchemeSevenRejectedApplications = () => {
  return (
    <div>
      <SchemeSevenRejectedApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeSevenRejectedApplications;
