import React, { useContext, useEffect, useState } from "react";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { server } from "../../common";
// import configContext from "../../context/configContext/configContext";

const Herosection = () => {
  const [slider, setSlider] = useState([]);
  const [processing, setProcessing] = useState(false);
  // const { setProgress } = useContext(configContext);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    // setProcessing(true);
    // setProgress(10);
    server
      .get("/slider/getallslider", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200 || response.status === 201) {
          setSlider(response.data);
          // setProgress(100);
        }
        // setProcessing(false);
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to the server");
        // setProcessing(false);
        // setProgress(100);
      });
  };

  return (
    <div
      id="carouselExampleCaptions"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        {slider &&
          slider?.map((_, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to={index}
              className={index === 0 ? "active" : ""}
              aria-current={index === 0 ? "true" : ""}
              aria-label={`Slide ${index + 1}`}
            ></button>
          ))}
      </div>

      <div className="carousel-inner">
        {slider.map((item, index) => (
          <div
            key={index}
            className={`carousel-item ${index === 0 ? "active" : ""}`}
          >
            <img
              src={item.image}
              className="d-block w-100"
              alt={`Slide ${index + 1}`}
            />
          </div>
        ))}
      </div>

      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Herosection;
