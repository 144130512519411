import React, { useState } from "react";
import { Typography, Card, CardContent, Divider, Button } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelectAccess } from "../../store/stateFunctions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
// import brochure from "../../assets/documents/Financial Assistance for Self-Employment of Persons with Disabilities.pdf";

const SchemeTwoDetails = () => {
  const { t } = useTranslation(); // Initialize translation
  const user = useSelectAccess("User");
  const isLoggedIn = user && user;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleApplyNowClick = (e) => {
    if (!isLoggedIn) {
      e.preventDefault(); // Prevent navigation
      toast.info("Please log in to apply for the Scheme");
      navigate("/login");
    } else {
      setShow(true);
    }
  };

  return (
    <div
      className="container-fluid"
      maxWidth="lg"
      style={{ padding: "20px", backgroundColor: "#eaf6ff" }}
    >
      <Card style={{ marginBottom: "20px" }}>
        <CardContent>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            {t("viewdetails.scheme2Title")}
          </Typography>
          <Divider style={{ margin: "10px 0" }} />
          <li className="text-dark" variant="body1" paragraph>
            {t("viewdetails.scheme2Description.point1")}
          </li>
          <li className="text-dark" variant="body1" paragraph>
            {t("viewdetails.scheme2Description.point2")}
          </li>
          <li className="text-dark" variant="body1" paragraph>
            {t("viewdetails.scheme2Description.point3")}
          </li>
          <li className="text-dark" variant="body1" paragraph>
            {t("viewdetails.scheme2Description.point4")}
          </li>
          <li className="text-dark" variant="body1" paragraph>
            {t("viewdetails.scheme2Description.point5")}
          </li>
          <li className="text-dark" variant="body1" paragraph>
            {t("viewdetails.scheme2Description.point6")}
          </li>
        </CardContent>
      </Card>

      <Card style={{ marginBottom: "20px" }}>
        <CardContent>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            {t("viewdetails.benefitsTitle")}
          </Typography>
          <Divider style={{ margin: "10px 0" }} />
          <ul style={{ paddingLeft: "20px" }}>
            <li>{t("viewdetails.benefits.benefit1")}</li>
            <li>{t("viewdetails.benefits.benefit2")}</li>
            <li>{t("viewdetails.benefits.benefit3")}</li>
          </ul>
        </CardContent>
      </Card>

      <Card style={{ marginBottom: "20px" }}>
        <CardContent>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
          {t("TermsandConditions")}
          </Typography>
          <Divider style={{ margin: "10px 0" }} />
          <ul style={{ paddingLeft: "20px" }}>
            <li>
              {t("ViewDetailsSchemeTwo.1")}
            </li>
            <li>
            {t("ViewDetailsSchemeTwo.2")}            </li>
            <li>
            {t("ViewDetailsSchemeTwo.3")}            </li>
            <li>
            {t("ViewDetailsSchemeTwo.4")}            </li>
            <li>
              {" "}
              {t("ViewDetailsSchemeTwo.5")}            </li>
            <li>
            {t("ViewDetailsSchemeTwo.6")}            </li>
            <li>
            {t("ViewDetailsSchemeTwo.7")}            </li>
            <li>
            {t("ViewDetailsSchemeTwo.8")}            </li>
          </ul>

          <NavLink to="/schemetwo" onClick={handleApplyNowClick}>
            {" "}
            <Button variant="contained" className="redbutton" color="secondary">
              {t("viewdetails.scheme1.applynow")}
            </Button>
          </NavLink>
        </CardContent>
      </Card>

      {/* <Card>
        <CardContent>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            {t("viewdetails.applyTitle")}
          </Typography>
          <Divider style={{ margin: "10px 0" }} />
          <ul style={{ paddingLeft: "20px" }}>
            <li>{t("viewdetails.applyInstructions.step1")}</li>
            <li>{t("viewdetails.applyInstructions.step2")}</li>
            <li>{t("viewdetails.applyInstructions.step3")}</li>
          </ul>

          <div>
            <Button variant="contained" className="redbutton" color="secondary">
              {t("viewdetails.scheme1.downloadbrochure")}
            </Button>
          </div>
        </CardContent>
      </Card> */}
    </div>
  );
};

export default SchemeTwoDetails;
