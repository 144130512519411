import React from "react";
import SportSchemeOnePendingApplications from "../../../Administration/SportSchemeOne/SportSchemeOnePendingApplications";

const SportClerk2SchemeOnePendingApplications = () => {
  return (
    <div>
      <SportSchemeOnePendingApplications role="Sport Clerk2" />
    </div>
  );
};

export default SportClerk2SchemeOnePendingApplications;
