import React from "react";
import SportSchemeOnePendingApplications from "../../../Administration/SportSchemeOne/SportSchemeOnePendingApplications";

const SportShaskiySamiti2SchemeOnePendingApplications = () => {
  return (
    <div>
      <SportSchemeOnePendingApplications role="Sport Shaskiy2" />
    </div>
  );
};

export default SportShaskiySamiti2SchemeOnePendingApplications;
