import React from "react";
import SportSchemeThreeRejectedApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeRejectedApplications";

const SportdeputycommissionerSchemeThreeRejectedApplications = () => {
  return (
    <div>
      <SportSchemeThreeRejectedApplications role="Sport Deputy" />
    </div>
  );
};

export default SportdeputycommissionerSchemeThreeRejectedApplications;
