import React from 'react';
import SchemeTwoTotalApplications from '../../Administration/SchemeTwo/SchemeTwoTotalApplications';

const Clerk2SchemeTwoTotalApplications = () => {
  return (
    <div><SchemeTwoTotalApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeTwoTotalApplications;
