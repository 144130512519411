import React from "react";
import SportSchemeTwoRejectedApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoRejectedApplications";

const SportShaskiySamiti4SchemeTwoRejectedApplications = () => {
  return (
    <div>
      <SportSchemeTwoRejectedApplications role="Sport Shaskiy4" />
    </div>
  );
};

export default SportShaskiySamiti4SchemeTwoRejectedApplications;
