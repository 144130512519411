import React from "react";
import SchemeFiveTotalApplications from "../../Administration/SchemeFive/SchemeFiveTotalApplications";

const ClerkSchemeFiveTotalApplications = () => {
  return (
    <div>
      <SchemeFiveTotalApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeFiveTotalApplications;
