import React from "react";
import SchemeFiveApprovedApplications from "../../Administration/SchemeFive/SchemeFiveApprovedApplications";

const ClerkSchemeFiveApprovedApplications = () => {
  return (
    <div>
      <SchemeFiveApprovedApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeFiveApprovedApplications;
