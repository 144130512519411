import React from "react";
import SportSchemeOneApprovedApplications from "../../../Administration/SportSchemeOne/SportSchemeOneApprovedApplications";

const SportShaskiySamiti2SchemeOneApprovedApplications = () => {
  return (
    <div>
      <SportSchemeOneApprovedApplications role="Sport Shaskiy2" />
    </div>
  );
};

export default SportShaskiySamiti2SchemeOneApprovedApplications;
