import React from "react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";
import logo from "../../assets/images/newlogo.png";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="footer noprint">
      <div className="container-footer">
        <div className="section">
          <h3 className="heading">{t("footer.find_us")}</h3>
          <p>{t("footer.address")}</p>
        </div>
        <div className="section">
          <h3 className="heading">{t("footer.call_us")}</h3>
          <p>{t("footer.phone")}</p>
        </div>
        <div className="section">
          <h3 className="heading">{t("footer.mail_us")}</h3>
          <p>{t("footer.email")}</p>
        </div>
      </div>
      <hr className="divider" />
      <div className="container text-center">
        <div className="row bottom-section">
          <div className="col-md-4 column">
            <div className="logo-section">
              <img src={logo} alt="NMC Logo" className="logo" />
              <p className="description">{t("footer.description")}</p>
            </div>
          </div>
          <div className="col-md-4 mt-5 column">
            <div className="links-section">
              <h3 className="heading">{t("footer.useful_links")}</h3>
              <ul className="links-list">
                <a href="#/aboutus" className="text-decoration-none text-white">
                  <li>{t("footer.about_us")}</li>
                </a>
                <a href="#/login" className="text-decoration-none text-white">
                  <li>{t("footer.login")}</li>
                </a>
                <a
                  href="#/registration"
                  className="text-decoration-none text-white"
                >
                  <li>{t("footer.register")}</li>
                </a>
                <a href="#/news" className="text-decoration-none text-white">
                  <li>{t("footer.news")}</li>
                </a>
              </ul>
            </div>
          </div>
          <div className="col-md-4 column">
            <div className="map-section">
              <iframe
                title="Nashik Municipal Corporation"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7498.389377027401!2d73.775504!3d20.000342!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddeba099a18541%3A0x2502d3b07814ecdc!2sRajiv%20Gandhi%20Bhavan%20(NMC)!5e0!3m2!1sen!2sin!4v1731148541454!5m2!1sen!2sin"
                className="map"
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
          <div className="social-section">
            <h3 className="heading">{t("footer.follow_us")}</h3>
            <div className="social-icons">
              <a
                href="https://www.facebook.com/mynashikmc/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook className="icon" />
              </a>
              <a
                href="https://x.com/my_nmc?lang=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter className="icon" />
              </a>
              <a
                href="https://www.instagram.com/my_nmc/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram className="icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
