import {
  removeDeputyCommToken,
  removeStudentTokenFromLocal,
} from "../../common";
import { deputyCommTokenName } from "../../config";

const getPoliceFromLocalStorage = () => {
  try {
    const deputycomm = JSON.parse(sessionStorage.getItem(deputyCommTokenName));
    if (deputycomm && deputycomm.authToken && deputycomm.loggedInTime) {
      return deputycomm;
    } else {
      removeDeputyCommToken();
      return null;
    }
  } catch (error) {
    removeDeputyCommToken();
    return null;
  }
};

const getInitial = () => {
  return getPoliceFromLocalStorage();
};

const deputyCommReducers = (state = getInitial(), action) => {
  switch (action.type) {
    case "LOGIN_DEPU_COMM":
      const { loggedInTime, authToken } = action.payload;
      const newState = {
        loggedInTime: loggedInTime || Date.now(),
        authToken: authToken,
      };
      sessionStorage.setItem(deputyCommTokenName, JSON.stringify(newState));
      return newState;
    case "LOGOUT_DEPU_COMM":
      removeDeputyCommToken();
      return null;
    case "UPDATE_DEPUTY_DASHBOARD":
      if (state) {
        state = {
          ...state,
          dashboard: {
            ...state.dashboard,
            ...action.payload,
          },
        };
      }
      return state;
    case "CLEAR_DEPUTY_DASHBOARD":
      state.dashboard = null;
    default:
      return state;
  }
};

export default deputyCommReducers;
