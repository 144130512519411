import React from "react";
import SchemeTenPendingApplications from "../../Administration/SchemeTen/SchemeTenPendingApplications";

const ShaskiySamiti1SchemeTenPendingApplications = () => {
    return (
        <div>
            <SchemeTenPendingApplications role="Shaskiy Samiti1" />
        </div>
    );
};

export default ShaskiySamiti1SchemeTenPendingApplications;
