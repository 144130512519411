import React from "react";
import SportSchemeThreePendingApplications from "../../../Administration/SportSchemeThree/SportSchemeThreePendingApplications";

const SportShaskiySamiti1SchemeThreePendingApplications = () => {
  return (
    <div>
      <SportSchemeThreePendingApplications role="Sport Shaskiy1" />
    </div>
  );
};

export default SportShaskiySamiti1SchemeThreePendingApplications;
