import React from "react";
import SchemeNineTotalApplications from "../../Administration/schemeNine/SchemeNineTotalApplications";

const ShaskiySamiti4SchemeNineTotalApplications = () => {
    return (
        <div>
            <SchemeNineTotalApplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeNineTotalApplications;
