import React from "react";
import SchemeNineapprovedapplications from "../../Administration/schemeNine/SchemeNineapprovedapplications";

const Chanani2SchemeNineApprovedApplications = () => {
  return (
    <div>
      <SchemeNineapprovedapplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeNineApprovedApplications;
