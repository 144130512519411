import React from 'react';
import SchemeSevenTotalApplications from '../../Administration/SchemeSeven/SchemeSevenTotalApplications';

const Clerk2SchemeSevenTotalApplications = () => {
  return (
    <div><SchemeSevenTotalApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeSevenTotalApplications;
