import React from "react";
import SportSchemeThreeTotalApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeTotalApplications";

const SportShaskiySamiti1SchemeThreeTotalApplications = () => {
  return (
    <div>
      <SportSchemeThreeTotalApplications role="Sport Shaskiy1" />
    </div>
  );
};

export default SportShaskiySamiti1SchemeThreeTotalApplications;
