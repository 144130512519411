import React from "react";
import SchemeTwoApprovedApplications from "../../Administration/SchemeTwo/SchemeTwoApprovedApplications";

const ShaskiySamiti5SchemeTwoApprovedApplications = () => {
    return (
        <div>
            <SchemeTwoApprovedApplications role="Shaskiy Samiti5" />
        </div>
    );
};

export default ShaskiySamiti5SchemeTwoApprovedApplications;
