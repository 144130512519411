import React from "react";
import SchemeSixTotalApplications from "../../Administration/SchemeSix/SchemeSixTotalApplications";

const ShaskiySamiti4SchemeSixTotalApplications = () => {
    return (
        <div>
            <SchemeSixTotalApplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeSixTotalApplications;
