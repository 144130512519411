import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { useSelectAccess } from "../store/stateFunctions";
import SportClerk1Sidebar from "../SportAdminUsers/sportclerck1/SportClerk1Sidebar";
// import { Outlet } from "react-router-dom";

const SportClerk1Container = () => {
  document.title = "Clerk";
  const sportclerk1 = useSelectAccess("Sport Clerk1");

  return (
    <>
      {sportclerk1 ? (
        <div className="row ">
          <SportClerk1Sidebar role="Clerk" />
          <div className="container"></div>
        </div>
      ) : (
        <Navigate to="/sport-clerk1" />
      )}
    </>
  );
};

export default SportClerk1Container;

// import React, { useContext, useEffect, useRef, useState } from "react";
// import { useSelector } from "react-redux";
// import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
// import { updateDashboardState, useSelectAccess } from "../store/stateFunctions";
// import SportClerk1Sidebar from "../SportAdminUsers/sportclerck1/SportClerk1Sidebar";
// import configContext from "../configContext/configContext";
// import { server } from "../common";
// // import { Outlet } from "react-router-dom";

// const SportClerk1Container = () => {
//   document.title = "Clerk";
//   const sportclerk1 = useSelectAccess("Sport Clerk1");

//   const navigate = useNavigate();
//   const location = useLocation();
//   const { setProgress } = useContext(configContext);
//   const [processing, setProcessing] = useState(false);
//   const fetched = useRef(false);
//   const setFetched = (value) => (fetched.current = value);

//   useEffect(() => {
//     // Navigate to "/shaskiy1" if shaskiy1 access is not available
//     if (!sportclerk1) {
//       navigate("/sport-clerk1", {
//         state: { from: location },
//         replace: true,
//       });
//       return;
//     }

//     // If shaskiy1 is valid, fetch admin profile
//     setProcessing(true);
//     setProgress(10);
//     server
//       .get("/sportadmin/sportadminprofile", {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: sportclerk1.authToken,
//         },
//       })
//       .then((response) => {
//         updateDashboardState("Sport Clerk1", response.data);
//         setProgress(100);
//         setProcessing(false);
//         setFetched(true);
//       })
//       .catch((error) => {
//         if (error.response?.status === 401) {
//           navigate("/sport-clerk1", {
//             state: { from: location },
//             replace: true,
//           });
//         } else {
//           setProgress(100);
//           setProcessing(false);
//           console.error(
//             "error",
//             error.message,
//             error.response?.status,
//             error.response?.data,
//             error
//           );
//         }
//       });

//     document.title = "Clerk Dashboard";
//   }, [
//     // sportclerk1,
//     navigate,
//     location,
//     setProgress,
//   ]);

//   return (
//     <>
//       <div className="row ">
//         <SportClerk1Sidebar
//           username={sportclerk1?.dashboard && sportclerk1?.dashboard.name}
//         />
//         {sportclerk1?.dashboard && <Outlet />}
//       </div>
//     </>
//   );
// };

// export default SportClerk1Container;
