import React from "react";
import SchemeFiveRejectedApplications from "../../Administration/SchemeFive/SchemeFiveRejectedApplications";

const SuperAdminSchemeFiveRejectedApplications = () => {
  return (
    <div>
      <SchemeFiveRejectedApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeFiveRejectedApplications;
