import React from "react";
import SchemeFourapprovedapplications from "../../Administration/SchemeFour/SchemeFourapprovedapplications";

const AdditionalCommSchemeFourApprovedApplications = () => {
  return (
    <div>
      <SchemeFourapprovedapplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeFourApprovedApplications;
