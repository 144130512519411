import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelectAccess } from "../../store/stateFunctions";
import { Modal } from "react-bootstrap";
import { server } from "../../common";
import { Button } from "@mui/material";
import UserApprovalTable from "../../Administration/UserApprovalTable";

const ApplicationHistory = ({ show, handleClose, id }) => {
  const [permission, setPermission] = useState(null);
  const user = useSelectAccess("User");

  useEffect(() => {
    getdata();
  }, []);

  const getdata = () => {
    server
      .get(`/dashboard/getsingleappl/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          setPermission(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        {/* <Modal.Title>Application Status</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <UserApprovalTable data={permission} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ApplicationHistory;
