import React from "react";
import SchemeSevenRejectedApplications from "../../Administration/SchemeSeven/SchemeSevenRejectedApplications";

const Chanani2SchemeSevenRejectedApplications = () => {
  return (
    <div>
      <SchemeSevenRejectedApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeSevenRejectedApplications;
