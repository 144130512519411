// import React from "react";
// import { useSelector } from "react-redux";
// import { Navigate, Outlet } from "react-router-dom";
// import { useSelectAccess } from "../store/stateFunctions";
// import ChananiSamiti2Sidebar from "../chananisamiti2/ChananiSamiti2Sidebar";

// const ChananiSamiti2Container = () => {
//   document.title = "Chanani Samiti";
//   const chananisamiti2 = useSelectAccess("Chanani Samiti2");

//   return (
//     <>
//       <div className="row ">
//         <ChananiSamiti2Sidebar role="Chanani Samiti2" />
//         <div className="container">
//           {chananisamiti2 ? <Outlet /> : <Navigate to="/chanani-samiti2" />}
//           {/* <Outlet /> */}
//         </div>
//       </div>
//     </>
//   );
// };

// export default ChananiSamiti2Container;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getCookie, server } from "../common";
import configContext from "../configContext/configContext";

import ChananiSamiti2Sidebar from "../chananisamiti2/ChananiSamiti2Sidebar";
import { updateDashboardState, useSelectAccess } from "../store/stateFunctions";

export default function ChananiSamiti2Container() {
  const navigate = useNavigate();
  const location = useLocation();
  const chanani2 = useSelectAccess("Chanani Samiti2");
  const { setProgress } = useContext(configContext);

  const [processing, setProcessing] = useState(false);
  const fetched = useRef(false);
  const setFetched = (value) => (fetched.current = value);

  useEffect(() => {
    // Navigate to "/chanani2" if chanani2 access is not available
    if (!chanani2) {
      navigate("/chanani-samiti2", {
        state: { from: location },
        replace: true,
      });
      return;
    }

    // If chanani2 is valid, fetch admin profile
    setProcessing(true);
    setProgress(10);
    server
      .get("/admin/adminprofile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: chanani2.authToken,
        },
      })
      .then((response) => {
        updateDashboardState("Chanani Samiti2", response.data);
        setProgress(100);
        setProcessing(false);
        setFetched(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          navigate("/chanani-samiti2", {
            state: { from: location },
            replace: true,
          });
        } else {
          setProgress(100);
          setProcessing(false);
          console.error(
            "error",
            error.message,
            error.response?.status,
            error.response?.data,
            error
          );
        }
      });

    document.title = "Chanani Samiti Dashboard";
  }, [chanani2, navigate, location, setProgress]);

  if (chanani2)
    return (
      <>
        <ChananiSamiti2Sidebar
          username={chanani2.dashboard && chanani2.dashboard.name}
        />
        {chanani2.dashboard && <Outlet />}
      </>
    );

  return null;
}
