import React from "react";
import SchemeTenRejectedApplications from "../../Administration/SchemeTen/SchemeTenRejectedApplications";

const Chanani4SchemeTenRejectedApplications = () => {
    return (
        <div>
            <SchemeTenRejectedApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeTenRejectedApplications;
