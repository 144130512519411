import React from "react";
import SchemeSixapprovedapplications from "../../Administration/SchemeSix/SchemeSixapprovedapplications";

const ShaskiySamiti5SchemeSixApprovedApplications = () => {
    return (
        <div>
            <SchemeSixapprovedapplications role="Shaskiy Samiti5" />
        </div>
    );
};

export default ShaskiySamiti5SchemeSixApprovedApplications;
