import React from "react";
import SchemeTwoTotalApplications from "../../Administration/SchemeTwo/SchemeTwoTotalApplications";

const ClerkSchemeTwoTotalApplications = () => {
  return (
    <div>
      <SchemeTwoTotalApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeTwoTotalApplications;
