import React from 'react';
import SchemeTenRejectedApplications from '../../Administration/SchemeTen/SchemeTenRejectedApplications';

const Clerk3SchemeTenRejectedApplications = () => {
  return (
    <div><SchemeTenRejectedApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeTenRejectedApplications;
