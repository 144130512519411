import React from "react";
import SportSchemeThreeApprovedApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeApprovedApplications";

const SportChanani3SchemeThreeApprovedApplications = () => {
  return (
    <div>
      <SportSchemeThreeApprovedApplications role="Sport Chanani3" />
    </div>
  );
};

export default SportChanani3SchemeThreeApprovedApplications;
