import React from "react";
import SchemeTenTotalApplications from "../../Administration/SchemeTen/SchemeTenTotalApplications";

const Chanani3SchemeTenTotalApplications = () => {
    return (
        <div>
            <SchemeTenTotalApplications role="Chanani Samiti3" />
        </div>
    );
};

export default Chanani3SchemeTenTotalApplications;
