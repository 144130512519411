import React from "react";
import SchemeFiveTotalApplications from "../../Administration/SchemeFive/SchemeFiveTotalApplications";

const Chanani3SchemeFiveTotalApplications = () => {
    return (
        <div>
            <SchemeFiveTotalApplications role="Chanani Samiti3" />
        </div>
    );
};

export default Chanani3SchemeFiveTotalApplications;
