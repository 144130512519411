import React from "react";
import SchemeTwoTotalApplications from "../../Administration/SchemeTwo/SchemeTwoTotalApplications";

const Chanani3SchemeTwoTotalApplications = () => {
    return (
        <div>
            <SchemeTwoTotalApplications role="Chanani Samiti3" />
        </div>
    );
};

export default Chanani3SchemeTwoTotalApplications;
