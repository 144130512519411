import React from "react";
import SportSchemeThreeRejectedApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeRejectedApplications";

const SportChanani4SchemeThreeRejectedApplications = () => {
  return (
    <div>
      <SportSchemeThreeRejectedApplications role="Sport Chanani4" />
    </div>
  );
};

export default SportChanani4SchemeThreeRejectedApplications;
