import React from "react";
import SchemeNineTotalApplications from "../../Administration/schemeNine/SchemeNineTotalApplications";

const ClerkSchemeNineTotalApplications = () => {
  return (
    <div>
      <SchemeNineTotalApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeNineTotalApplications;
