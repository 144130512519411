import React from "react";
import SchemeSixapprovedapplications from "../../Administration/SchemeSix/SchemeSixapprovedapplications";

const Chanani3SchemeSixApprovedApplications = () => {
    return (
        <div>
            <SchemeSixapprovedapplications role="Chanani Samiti3" />
        </div>
    );
};

export default Chanani3SchemeSixApprovedApplications;
