import React from "react";
import SchemeThreeTotalApplications from "../../../Administration/SchemeThree/SchemeThreeTotalApplications";

const SportClerk2SchemeThreeTotalApplications = () => {
  return (
    <div>
      <SchemeThreeTotalApplications role="Sport Clerk2" />
    </div>
  );
};

export default SportClerk2SchemeThreeTotalApplications;
