import React from "react";
import SchemeFourPendingApplications from "../../Administration/SchemeFour/SchemeFourPendingApplications";

const ShaskiySamiti4SchemeFourPendingApplications = () => {
    return (
        <div>
            <SchemeFourPendingApplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeFourPendingApplications;
