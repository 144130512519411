import React from "react";
import SportSchemeTwoApprovedApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoApprovedApplications";

const SportClerk2SchemeTwoApprovedApplications = () => {
  return (
    <div>
      <SportSchemeTwoApprovedApplications role="Sport Clerk2" />
    </div>
  );
};

export default SportClerk2SchemeTwoApprovedApplications;
