import { removeShaskiySamiti1Token } from "../../common";
import { shaskiysamityTokenName1 } from "../../config";

const getshaskiysamitiFromLocalStorage = () => {
  try {
    const shaskiysamiti1 = JSON.parse(
      sessionStorage.getItem(shaskiysamityTokenName1)
    );
    if (
      shaskiysamiti1 &&
      shaskiysamiti1.authToken &&
      shaskiysamiti1.loggedInTime
    ) {
      console.log("Returning Internal User:", shaskiysamiti1);
      return shaskiysamiti1;
    } else {
      removeShaskiySamiti1Token();
      return null;
    }
  } catch (error) {
    removeShaskiySamiti1Token();
    return null;
  }
};

const getInitial = () => {
  return getshaskiysamitiFromLocalStorage();
};

const shaskiySamity1Reducer = (state = getInitial(), action) => {
  switch (action.type) {
    case "LOGIN_SHASKIY_SAMITI1":
      state = {};
      const time = action.payload.loggedInTime;
      if (time) {
        state.loggedInTime = time;
      } else {
        state.loggedInTime = Date.now();
      }
      state.authToken = action.payload.authToken;
      sessionStorage.setItem(shaskiysamityTokenName1, JSON.stringify(state));
      return state;
    case "LOGOUT_SHASKIY_SAMITI1":
      removeShaskiySamiti1Token();
      return null;
    case "UPDATE_SHASKIY1_DASHBOARD":
      if (state) {
        state = {
          ...state,
          dashboard: {
            ...state.dashboard,
            ...action.payload,
          },
        };
      }
      return state;
    case "CLEAR_SHASKIY1_DASHBOARD":
      state.dashboard = null;
    default:
      return state;
  }
};

export default shaskiySamity1Reducer;
