import React from "react";
import SportSchemeThreeRejectedApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeRejectedApplications";

const SportCommissionerSchemeThreeRejectedApplications = () => {
  return (
    <div>
      <SportSchemeThreeRejectedApplications role="Sport AddComm" />
    </div>
  );
};

export default SportCommissionerSchemeThreeRejectedApplications;
