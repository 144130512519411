import React from "react";
import SchemeTenPendingApplications from "../../Administration/SchemeTen/SchemeTenPendingApplications";

const Chanani4SchemeTenPendingApplications = () => {
    return (
        <div>
            <SchemeTenPendingApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeTenPendingApplications;
