import {
  Container,
  Row,
  Col,
  Table,
  Card,
  Form,
  Pagination,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { server } from "../common";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { useSelectAccess } from "../store/stateFunctions";
import * as XLSX from "xlsx";
import { Button } from "@mui/material";

const ChananiSamiti3Dashboard = ({ role }) => {
  const [selectedDivision, setSelectedDivision] = useState("All");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [approved, setApproved] = useState([]);
  const user = useSelectAccess("Chanani Samiti3");

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    server
      .get(`/admindash/chanani3dash`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setData(response.data.departments);
          setApproved(response.data.totals);
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else {
          toast.error(error.response?.data?.error || "An error occurred");
        }
      })
      .finally(() => setLoading(false));
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Dashboard");
    XLSX.writeFile(workbook, "Dashboard.xlsx");
  };

  return (
    <Container>
      <div className="d-flex position-relative mb-3 justify-content-end ">
        <Button
          className="btn col-md-2 btn-sm"
          onClick={exportToExcel}
          variant="contained"
        >
          Export
        </Button>
      </div>

      {/* Table */}
      <h1 className="text-center">Total Schemes Dashboard</h1>
      <Table striped bordered hover responsive style={{ fontSize: "20px" }}>
        <thead>
          <tr>
            <th>Scheme Name</th>
            {/* <th>Division</th> */}
            <th>Total Applications</th>
            <th>Approved</th>
            <th>Pending </th>
            <th>Rejected</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((scheme, index) => (
            <>
              <tr key={index}>
                <td>{scheme.name}</td>
                {/* <td>{scheme.division}</td> */}
                <td className="text-primary">{scheme.Totalappl}</td>
                <td className="text-danger">{scheme.approved}</td>
                <td className="text-success">{scheme.Raised}</td>
                <td className="text-warning">{scheme.rejected}</td>
              </tr>
            </>
          ))}
          <tr>
            {/* <td>{scheme.division}</td> */}
            <td>Total</td>
            <td className="text-primary">{approved.TotalAppl}</td>
            <td className="text-danger">{approved.approved}</td>
            <td className="text-success">{approved.Raised}</td>
            <td className="text-warning">{approved.rejected}</td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

export default ChananiSamiti3Dashboard;
