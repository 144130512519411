import React from "react";
import SchemeTwoPendingApplications from "../../Administration/SchemeTwo/SchemeTwoPendingApplications";

const Chanani3SchemeTwoPendingApplications = () => {
    return (
        <div>
            <SchemeTwoPendingApplications role="Chanani Samiti3" />
        </div>
    );
};

export default Chanani3SchemeTwoPendingApplications;
