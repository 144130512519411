export const loginsportclerk2 = (session) => {
  return {
    type: "LOGIN_SPORT_CLERK2",
    payload: session,
  };
};
export const logoutsportclerk2 = () => {
  return {
    type: "LOGOUT_SPORT_CLERK2",
  };
};

export const setsportclerk2Dashboard = (dashboard) => {
  return {
    type: "UPDATE_SPORT_CLERK2_DASHBOARD",
    payload: dashboard,
  };
};

export const clearsportclerk2Dashboard = () => {
  return {
    type: "CLEAR_SPORT_CLERK2_DASHBOARD",
  };
};
