import React from "react";
import SchemeEightapprovedapplications from "../../Administration/SchemeEight/SchemeEightapprovedapplications";

const ClerkSchemeEightApprovedApplications = () => {
  return (
    <div>
      <SchemeEightapprovedapplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeEightApprovedApplications;
