import React from "react";
import SchemeOneRejectedApplications from "../../Administration/SchemeOne/SchemeOneRejectedApplications";

const ShaskiySamiti4SchemeOneRejectedApplications = () => {
    return (
        <div>
            <SchemeOneRejectedApplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeOneRejectedApplications;
