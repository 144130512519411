import React from "react";
import SportSchemeThreePendingApplications from "../../../Administration/SportSchemeThree/SportSchemeThreePendingApplications";

const SportCommissionerSchemeThreePendingApplications = () => {
  return (
    <div>
      <SportSchemeThreePendingApplications role="Sport AddComm" />
    </div>
  );
};

export default SportCommissionerSchemeThreePendingApplications;
