import React from "react";
import SchemeTenRejectedApplications from "../../Administration/SchemeTen/SchemeTenRejectedApplications";

const ClerkSchemeTenRejectedApplications = () => {
  return (
    <div>
      <SchemeTenRejectedApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeTenRejectedApplications;
