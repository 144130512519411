import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
  Typography,
  Grid,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { NavLink, useNavigate } from "react-router-dom";
import configContext from "../../configContext/configContext";
import { server } from "../../common";
import { AxiosError } from "axios";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import Loader from "../../Loader";
import { Row, Col } from "react-bootstrap";

const initialValues = {
  fullname: "",
  email: "",
  mobile: "",
  ward_no: "",
  // municipal_council: "",
  division: "",
  password: "",
  cpassword: "",
  adhar_number: "",
  pancard: "",
  dob: "",
};

const SignUpForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setProgress } = useContext(configContext);

  const {
    values,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    errors,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      fullname: Yup.string()

        .min(2, "Fullname must be at least 2 characters long")
        .matches(/^[\u0900-\u097F\sA-Za-z]+$/, "Enter Valid Details")
        .required("Fullname is required"),

      mobile: Yup.string()
        .matches(/^\d{10}$/, t("Registration.validation.mobileInvalid")) // Assuming mobile number is 10 digits
        .required(t("Registration.validation.mobileRequired")),

      ward_no: Yup.number()
        .min(1, t("Registration.validation.ward"))
        .required(t("Registration.validation.wardrequired")),

      adhar_number: Yup.string()
        .matches(/^\d{12}$/, t("Registration.validation.aadharInvalid")) // Assuming Aadhar number is 12 digits
        .required(t("Registration.validation.aadharRequired")),

      dob: Yup.date()
        .max(new Date(), t("Registration.validation.dobInvalid")) // Validating date of birth
        .required(t("Registration.validation.dobRequired")),
      pancard: Yup.string()
        .matches(
          /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
          t("Registration.validation.panInvalid")
        ) // Validating PAN card format
        .required(t("Registration.validation.panRequired")),

      division: Yup.string()
        .min(2, t("Registration.validation.divisionMin"))
        .required(t("Registration.validation.divisionRequired")),

      // municipal_council: Yup.string()
      //   .min(2, "Municipal Council must be at least 2 characters long")
      //   .required("Municipal Council is required"),

      email: Yup.string()
        .email(t("Registration.validation.emailInvalid"))
        .required(t("Registration.validation.emailRequired")),

      password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/\d/, "Password must contain at least one number")
        .matches(
          /[@$!%*?&]/,
          "Password must contain at least one special character"
        )
        .required("Password is required"),
      cpassword: Yup.string()
        .oneOf(
          [Yup.ref("password"), null],
          t("Registration.validation.confirmPasswordMismatch")
        )
        .required(t("Registration.validation.confirmPasswordRequired")),
    }),

    onSubmit: (values, action) => {
      setLoading(true);
      server
        .post("/user/register", values, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          if (response.status === 200 || response.status === 201) {
            if (response.data) {
              toast.success(
                "Account created successfully please login with mobile Number and password which you have created."
              );
              navigate("/login");
              resetForm();
            }
          }
        })
        .catch(function (error) {
          if (error instanceof AxiosError && error.response?.data?.message)
            toast.error(error.response.data.message);
          else if (error.response?.data?.error) {
            toast.error(error.response.data.error);
          } else toast.error("Failed to connect to server");
        })
        .finally(() => {
          setLoading(false); // Hide loader after API call
        });
    },
  });

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Container
        maxWidth="md"
        style={{
          marginTop: "40px",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          {t("Registration.formTitle")}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <TextField
                label={t("Registration.fullNameLabel")}
                variant="outlined"
                fullWidth
                margin="normal"
                name="fullname"
                value={values.fullname}
                onChange={handleChange}
                required
              />
              {errors.fullname ? (
                <p className="text-danger">{errors.fullname}</p>
              ) : null}
              <TextField
                label={t("Registration.emailLabel")}
                variant="outlined"
                fullWidth
                margin="normal"
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                required
              />
              {errors.email ? (
                <p className="text-danger">{errors.email}</p>
              ) : null}
            </Col>
            <Col md={6}>
              <TextField
                label={t("Registration.mobileLabel")}
                variant="outlined"
                fullWidth
                margin="normal"
                name="mobile"
                value={values.mobile}
                onChange={(e) => {
                  // Allow only digits and limit to 10 characters
                  const mobile = e.target.value.replace(/\D/g, "").slice(0, 10);
                  setFieldValue("mobile", mobile);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
                required
              />
              {errors.mobile && <p className="text-danger">{errors.mobile}</p>}

              <TextField
                label={t("Registration.aadharLabel")}
                variant="outlined"
                fullWidth
                margin="normal"
                name="adhar_number"
                value={values.adhar_number}
                onChange={(e) => {
                  // Allow only digits and limit to 12 characters
                  const aadhaar = e.target.value
                    .replace(/\D/g, "")
                    .slice(0, 12);
                  setFieldValue("adhar_number", aadhaar);
                }}
                required
              />
              {errors.adhar_number && (
                <p className="text-danger">{errors.adhar_number}</p>
              )}
            </Col>

            <Col md={6}>
              <TextField
                label={t("profile.pancard")}
                variant="outlined"
                fullWidth
                name="pancard"
                margin="normal"
                value={values.pancard}
                onChange={(e) => {
                  // Allow only alphanumeric characters and limit to 10 characters
                  const pan = e.target.value
                    .replace(/[^a-zA-Z0-9]/g, "")
                    .slice(0, 10);
                  setFieldValue("pancard", pan);
                }}
                required
              />
              {errors.pancard && (
                <p className="text-danger">{errors.pancard}</p>
              )}

              <TextField
                fullWidth
                label={t("Registration.dobLabel")}
                required
                variant="outlined"
                name="dob"
                onChange={handleChange}
                value={values.dob}
                className="mt-4"
                type="date"
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  max: new Date().toISOString().substring(0, 10), // Restricts to today or earlier
                }}
              />
              {errors.dob ? <p className="text-danger">{errors.dob}</p> : null}
            </Col>
            <Col md={6}>
              <TextField
                label={t("Registration.wardLabel")}
                variant="outlined"
                fullWidth
                margin="normal"
                type="number"
                name="ward_no"
                value={values.ward_no}
                onChange={handleChange}
                required
              />
              {errors.ward_no ? (
                <p className="text-danger">{errors.ward_no}</p>
              ) : null}

              <FormControl
                variant="outlined"
                fullWidth
                margin="normal"
                className="mt-4"
              >
                <InputLabel id="demo-simple-select-label">
                  {t("Registration.divisionLabel")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={t("Registration.divisionLabel")}
                  variant="outlined"
                  name="division"
                  value={values.division}
                  onChange={handleChange}
                  required
                >
                  <MenuItem value="">
                    {t("Registration.selectDivision")}
                  </MenuItem>
                  <MenuItem value="Satpur">
                    {t("Registration.divisionOptions.satpur")}
                  </MenuItem>
                  <MenuItem value="Cidco">
                    {t("Registration.divisionOptions.cidco")}
                  </MenuItem>
                  <MenuItem value="nashikroad">
                    {t("Registration.divisionOptions.nashikroad")}
                  </MenuItem>
                  <MenuItem value="nashikeast">
                    {t("Registration.divisionOptions.nashikeast")}
                  </MenuItem>
                  <MenuItem value="nashikwest">
                    {t("Registration.divisionOptions.nashikwest")}
                  </MenuItem>
                  <MenuItem value="panchavati">
                    {t("Registration.divisionOptions.panchavati")}
                  </MenuItem>
                </Select>
              </FormControl>
              {errors.division ? (
                <p className="text-danger">{errors.division}</p>
              ) : null}
            </Col>
            <Col md={6}>
              <FormControl variant="outlined" fullWidth margin="normal">
                <TextField
                  type={showPassword ? "text" : "password"}
                  label={t("Registration.passwordLabel")}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
              {errors.password ? (
                <p className="text-danger">{errors.password}</p>
              ) : null}
            </Col>
            <Col md={6}>
              <FormControl variant="outlined" fullWidth margin="normal">
                <TextField
                  type={showPassword ? "text" : "password"}
                  label={t("Registration.confirmPasswordLabel")}
                  name="cpassword"
                  value={values.cpassword}
                  onChange={handleChange}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.cpassword ? (
                  <p className="text-danger">{errors.cpassword}</p>
                ) : null}
              </FormControl>
            </Col>
          </Row>

          <FormControlLabel
            control={<Checkbox name="acceptedTerms" color="primary" required />}
            label={t("Registration.termsLabel")}
          />
          <Button
            type="submit"
            variant="contained"
            className="bluebutton"
            fullWidth
          >
            {t("Registration.formTitle")}
          </Button>
        </form>

        <Typography
          variant="body2"
          align="center"
          style={{ marginTop: "16px", color: "black" }}
        >
          {t("Registration.loginPrompt")}
          <NavLink
            to="/login"
            style={{ textDecoration: "none", color: "blue" }}
          >
            {t("Registration.loginLink")}
          </NavLink>
        </Typography>
      </Container>
    </>
  );
};

export default SignUpForm;
