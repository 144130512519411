import React from "react";
import SchemeFourTotalApplications from "../../Administration/SchemeFour/SchemeFourTotalApplications";

const ShaskiySamiti3SchemeFourTotalApplications = () => {
    return (
        <div>
            <SchemeFourTotalApplications role="Shaskiy Samiti3" />
        </div>
    );
};

export default ShaskiySamiti3SchemeFourTotalApplications;
