export const loginChananisamiti4 = (session) => {
  return {
    type: "LOGIN_CHANANI_SAMITY4",
    payload: session,
  };
};

export const logoutChananisamiti4 = () => {
  return {
    type: "LOGOUT_CHANANI_SAMITY4",
  };
};

export const setChanani4Dashboard = (dashboard) => {
  return {
    type: "UPDATE_CHANANI4_DASHBOARD",
    payload: dashboard,
  };
};

export const clearChanani4Dashboard = () => {
  return {
    type: "CLEAR_CHANANI4_DASHBOARD",
  };
};
