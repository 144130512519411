import React from "react";
import SchemeThreeRejectedApplications from "../../Administration/SchemeThree/SchemeThreeRejectedApplications";

const Chanani2SchemeThreeRejectedApplications = () => {
  return (
    <div>
      <SchemeThreeRejectedApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeThreeRejectedApplications;
