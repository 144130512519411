import React from "react";
import SchemeSixTotalApplications from "../../Administration/SchemeSix/SchemeSixTotalApplications";

const ShaskiySamiti5SchemeSixTotalApplications = () => {
    return (
        <div>
            <SchemeSixTotalApplications role="Shaskiy Samiti5" />
        </div>
    );
};

export default ShaskiySamiti5SchemeSixTotalApplications;
