import { removeSportaddcommUserToken } from "../../common";
import { sportAddCommiTokenName } from "../../config";

const getAdditionalCommissionerFromLocalStorage = () => {
  try {
    const sportaddcomm = JSON.parse(
      sessionStorage.getItem(sportAddCommiTokenName)
    );
    if (sportaddcomm && sportaddcomm.authToken && sportaddcomm.loggedInTime) {
      return sportaddcomm;
    } else {
      removeSportaddcommUserToken();
      return null;
    }
  } catch (error) {
    removeSportaddcommUserToken();
    return null;
  }
};

const getInitial = () => {
  return getAdditionalCommissionerFromLocalStorage();
};

const sportadditionalCommissionerReducer = (state = getInitial(), action) => {
  switch (action.type) {
    case "LOGIN_SPORT_ADD_COMM":
      const { loggedInTime, authToken } = action.payload;
      const newState = {
        loggedInTime: loggedInTime || Date.now(),
        authToken: authToken,
      };
      sessionStorage.setItem(sportAddCommiTokenName, JSON.stringify(newState));
      return newState;
    case "LOGOUT_SPORT_ADD_COMM":
      removeSportaddcommUserToken();
      return null;
    case "UPDATE_SPORT_ADDCOMM_DASHBOARD":
      if (state) {
        state = {
          ...state,
          dashboard: {
            ...state.dashboard,
            ...action.payload,
          },
        };
      }
      return state;
    case "CLEAR_SPORT_ADDCOMM_DASHBOARD":
      state.dashboard = null;
    default:
      return state;
  }
};

export default sportadditionalCommissionerReducer;
