import React from "react";
import SchemeEightPendingApplications from "../../Administration/SchemeEight/SchemeEightPendingApplications";

const ShaskiySamiti1SchemeEightPendingApplications = () => {
    return (
        <div>
            <SchemeEightPendingApplications role="Shaskiy Samiti1" />
        </div>
    );
};

export default ShaskiySamiti1SchemeEightPendingApplications;
