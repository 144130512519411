import React from "react";
import SchemeSixTotalApplications from "../../Administration/SchemeSix/SchemeSixTotalApplications";

const ShaskiySamiti2SchemeSixTotalApplications = () => {
    return (
        <div>
            <SchemeSixTotalApplications role="Shaskiy Samiti2" />
        </div>
    );
};

export default ShaskiySamiti2SchemeSixTotalApplications;
