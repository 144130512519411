export const loginclerk1 = (session) => {
  return {
    type: "LOGIN_CLERK1",
    payload: session,
  };
};
export const logoutclerk1 = () => {
  return {
    type: "LOGOUT_CLERK1",
  };
};

export const setClerk1Dashboard = (dashboard) => {
  return {
    type: "UPDATE_CLERK1_DASHBOARD",
    payload: dashboard,
  };
};

export const clearClerk1Dashboard = () => {
  return {
    type: "CLEAR_CLERK1_DASHBOARD",
  };
};
