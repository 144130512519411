import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@mui/material";
import { server } from "../../common";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { useSelectAccess } from "../../store/stateFunctions";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

const EditProfile = () => {
  const user = useSelectAccess("User");
  const navigate = useNavigate();

  const [formError, setFormError] = useState(null);
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      fullname: "",
      email: "",
      mobile: "",
      ward_no: "",
      division: "",
      adhar_number: "",
      pancard: "",
      dob: "",
    },

    validationSchema: Yup.object({
      fullname: Yup.string()
        .min(2, t("editProfile.validation.fullname.min"))
        .required(t("editProfile.validation.fullname.required")),
      mobile: Yup.string()
        .min(2, t("editProfile.validation.mobile.min"))
        .required(t("editProfile.validation.mobile.required")),
      ward_no: Yup.string()
        .min(1, t("editProfile.validation.wardNo.min"))
        .required(t("editProfile.validation.wardNo.required")),
      dob: Yup.string()
        .min(1, t("editProfile.validation.wardNo.min"))
        .required(t("editProfile.validation.wardNo.required")),
      adhar_number: Yup.string()
        .min(1, t("editProfile.validation.municipalCouncil.min"))
        .required(t("editProfile.validation.municipalCouncil.required")),
      pancard: Yup.string()
        .min(1, t("editProfile.validation.municipalCouncil.min"))
        .required(t("editProfile.validation.municipalCouncil.required")),
      division: Yup.string()
        .min(2, t("editProfile.validation.division.min"))
        .required(t("editProfile.validation.division.required")),
      email: Yup.string()
        .email(t("editProfile.validation.email.invalid"))
        .required(t("editProfile.validation.email.required")),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const response = await server.put("/user/updateuser", values, {
          headers: {
            Authorization: user.authToken,
          },
        });
        toast.success("Profile updated successfully");
        navigate("/profile/profilepage");
      } catch (error) {
        if (error instanceof AxiosError && error.response?.data?.message) {
          toast.error(error.response.data.message);
        } else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else {
          toast.error("Failed to update profile");
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    // Fetch user data when the component mounts
    const fetchData = async () => {
      try {
        const response = await server.get("/user/userprofile", {
          headers: {
            Authorization: user.authToken,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        // Populate the form with user data
        formik.setValues(response.data);
      } catch (error) {
        if (error.response?.status === 401) {
          navigate("/login");
        }
        console.error("Error fetching user data:", error);
      }
    };

    if (!formik.values.name) {
      fetchData();
    }
  }, [formik.values.name, navigate, user.authToken, formik.setValues]);

  return (
    <div>
      <div className="mt-2">
        <div className="row">
          <div className="col">
            <form
              className="needs-validation d-flex flex-column gap-3"
              onSubmit={formik.handleSubmit}
            >
              <div className="card">
                <h5 className="p-3 fs-5 data-name mb-0">
                  {t("editProfile.personalDetails")}
                </h5>
                <div className="row px-5">
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <TextField
                        name="fullname"
                        type="text"
                        value={formik.values.fullname}
                        onChange={formik.handleChange}
                        placeholder="Lorem"
                        variant="outlined"
                        label={t("editProfile.fullnameLabel")}
                        fullWidth
                        required
                      ></TextField>
                      {formik.errors.fullname ? (
                        <p className="text-danger">{formik.errors.fullname}</p>
                      ) : null}
                    </div>

                    <div className="col-md-6 mb-3">
                      <TextField
                        name="mobile"
                        type="number"
                        value={formik.values.mobile}
                        onChange={formik.handleChange}
                        placeholder="9876789087"
                        variant="outlined"
                        label={t("editProfile.mobileLabel")}
                        disabled
                        fullWidth
                        required
                      ></TextField>
                      {formik.errors.mobile ? (
                        <p className="text-danger">{formik.errors.mobile}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <TextField
                        name="adhar_number"
                        type="text"
                        value={formik.values.adhar_number}
                        onChange={formik.handleChange}
                        placeholder="Lorem"
                        variant="outlined"
                        label={t("editProfile.adhar")}
                        fullWidth
                        disabled
                        required
                      ></TextField>
                      {formik.errors.adhar_number ? (
                        <p className="text-danger">
                          {formik.errors.adhar_number}
                        </p>
                      ) : null}
                    </div>

                    <div className="col-md-6 mb-3">
                      <TextField
                        name="pancard"
                        type="text"
                        value={formik.values.pancard}
                        onChange={formik.handleChange}
                        placeholder="9876789087"
                        variant="outlined"
                        label={t("editProfile.pancard")}
                        fullWidth
                        disabled
                        required
                      ></TextField>
                      {formik.errors.pancard ? (
                        <p className="text-danger">{formik.errors.pancard}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <TextField
                        name="email"
                        type="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        placeholder="loremipsum@gmail.com"
                        variant="outlined"
                        label={t("editProfile.emailLabel")}
                        disabled
                        fullWidth
                        required
                      ></TextField>
                      {formik.errors.email ? (
                        <p className="text-danger">{formik.errors.email}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6 mb-3">
                      <TextField
                        name="ward_no"
                        type="text"
                        value={formik.values.ward_no}
                        onChange={formik.handleChange}
                        placeholder="Ward No"
                        variant="outlined"
                        label={t("editProfile.wardNoLabel")}
                        fullWidth
                        required
                      ></TextField>
                      {formik.errors.ward_no ? (
                        <p className="text-danger">{formik.errors.ward_no}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          {t("editProfile.divisionLabel")}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label={t("editProfile.divisionLabel")}
                          variant="outlined"
                          name="division"
                          value={formik.values.division}
                          onChange={formik.handleChange}
                          required
                        >
                          <MenuItem value="">Select District</MenuItem>
                          <MenuItem value="satpur">Satpur</MenuItem>
                          <MenuItem value="cidco">Cidco</MenuItem>
                          <MenuItem value="nashikroad">Nashik Road</MenuItem>
                          <MenuItem value="nashikeast">Nashik East</MenuItem>
                          <MenuItem value="nashikwest">Nashik West</MenuItem>
                          <MenuItem value="panchavati">Panchavati</MenuItem>
                        </Select>
                      </FormControl>
                      {formik.errors.division ? (
                        <p className="text-danger">{formik.errors.division}</p>
                      ) : null}
                    </div>

                    <div className="col-md-6 ">
                      <TextField
                        fullWidth
                        label={t("editProfile.dob")}
                        required
                        variant="outlined"
                        name="dob"
                        value={formik.values.dob}
                        onChange={formik.handleChange}
                        type="date"
                        InputLabelProps={{ shrink: true }}
                      />
                      {formik.errors.dob ? (
                        <p className="text-danger">{formik.errors.dob}</p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <Button
                type="submit"
                variant="contained"
                className="bluebutton mb-4"
              >
                {t("editProfile.saveButton")}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
