import React from "react";
import SchemeTenRejectedApplications from "../../Administration/SchemeTen/SchemeTenRejectedApplications";

const Chanani2SchemeTenRejectedApplications = () => {
  return (
    <div>
      <SchemeTenRejectedApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeTenRejectedApplications;
