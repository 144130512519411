import React from 'react'
import { Typography, Card, CardContent, Divider, Button } from "@mui/material";
export default function WelfareSchemeTwoDetails() {
  return (
    <div>
      <div
        className="container-fluid"
        style={{
          height: "100px",
          backgroundColor: "#98c1d9",
          color: "black",
          fontSize: "27px",
          fontWeight: "500",
          padding: "0px 20px",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
   विधवा /घटस्फोटित महिलांच्या मुलांना तसेच निराधार मुलांना शिष्यवृत्ती  देणे
      </div>

      <div style={{ padding: "20px", backgroundColor: "#eaf6ff" }}>
        <Card style={{ marginBottom: "20px" }}>
          <CardContent>
            <Typography variant="h5" style={{ fontWeight: "bold"}}>
              प्रस्तावना
            </Typography>
            <Divider style={{ margin: "10px 0" }} />
            
       
           <Typography variant="body1" style={{ color: "#1a1a1a" }}>
                   विधवा /घटस्फोटित महिलांच्या मुलांना तसेच निराधार मुलांना शिष्यवृत्ती  देणे
            </Typography>
          </CardContent>
        </Card>

       

        <Card style={{ marginBottom: "20px" }}>
          <CardContent>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
             अटी व शर्तीं
            </Typography>
            <Divider style={{ margin: "10px 0" }} />
            <ul style={{ paddingLeft: "20px", fontWeight:" semi-bold" }}>
              <li>वार्षिक उत्पन्न प्रमाणपत्र: अर्जदाराचे वार्षिक उत्पन्न ₹३.५ लाखांपेक्षा कमी असल्याचे प्रमाणपत्र, तहसीलदार/ग्रामसेवक यांच्या सहीसह.</li>
              <li>मालमत्ता कर भरणा: मालमत्ता असलेल्या अर्जदाराने मागील तीन वर्षांतील मालमत्ता कर पावत्या जोडणे आवश्यक आहे.</li>
              <li>ओळख पत्र: अर्जदाराने आधार कार्ड, मतदार ओळखपत्र, पॅन कार्ड, पासपोर्ट यापैकी कुठलेही एक ओळखपत्र जोडावे.</li>
              <li>मुलगी/मुलाचे शैक्षणिक कागदपत्रे: मुलगी/मुलगा शाळेत जात असल्यास, त्याच्या शिक्षणाचे प्रमाणपत्र, तसेच मागील शैक्षणिक वर्षाचे गुणपत्रक जोडणे आवश्यक आहे.</li>
              <li>विधवा असल्यास: अर्जदार विधवा असल्यास पतीच्या मृत्यूचे प्रमाणपत्र तसेच विवाहाचे प्रमाणपत्र जोडावे. </li>
              <li> घटस्फोट घेतल्यास: अर्जदार घटस्फोटित असल्यास न्यायालयाचा निर्णय किंवा घटस्फोट प्रमाणपत्र आवश्यक आहे. </li>
             
              <li>पासपोर्ट साइज फोटो: अर्जदार आणि मुलीचे/मुलाचे  पासपोर्ट साइज फोटो अर्जासोबत जोडणे आवश्यक आहे.</li>
              <li> अर्जदाराचे निवासाचे कागदपत्र: रहिवासी दाखला, निवासाचा पुरावा जोडणे अनिवार्य आहे.</li>
              
              <li>शैक्षणिक पात्रता: नाशिक महानगरपालिकेच्या शाळामध्ये इयत्ता १ ली ते ७ वि पर्यंत शिक्षण घेत असलेल्या विध्यार्थी  या योजनेकरिता अर्ज करू शकत नाही. </li>

            </ul>
            
            <div style={{ marginTop: "20px" }}>
              <Button
                variant="contained"
                className="bluebutton"
                href="/path-to-brochure.pdf"
                download
                style={{ marginRight: "10px" }}
              >
                Download Brochure
              </Button>
              <Button
                variant="contained"
                className="redbutton"
                color="secondary"
              >
                Apply Now
              </Button>
            </div>
          </CardContent>
        </Card>

     

       
      </div>
    </div>
  )
}
