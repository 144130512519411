import React from "react";
import SportSchemeThreeApprovedApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeApprovedApplications";

const SportdeputycommissionerSchemeThreeApprovedApplications = () => {
  return (
    <div>
      <SportSchemeThreeApprovedApplications />
    </div>
  );
};

export default SportdeputycommissionerSchemeThreeApprovedApplications;
