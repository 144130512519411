import React from "react";
import SportSchemeTwoPendingApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoPendingApplications";

const SportChanani4SchemeTwoPendingApplications = () => {
  return (
    <div>
      <SportSchemeTwoPendingApplications role="Sport Chanani4" />
    </div>
  );
};

export default SportChanani4SchemeTwoPendingApplications;
