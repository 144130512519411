import React from "react";
import SchemeOnePendingApplications from "../../Administration/SchemeOne/SchemeOnePendingApplications";

const ShaskiySamiti3SchemeOnePendingApplications = () => {
    return (
        <div>
            <SchemeOnePendingApplications role="Shaskiy Samiti3" />
        </div>
    );
};

export default ShaskiySamiti3SchemeOnePendingApplications;
