import React from "react";
import SchemeSixPendingApplications from "../../Administration/SchemeSix/SchemeSixPendingApplications";

const Chanani2SchemeSixPendingApplications = () => {
  return (
    <div>
      <SchemeSixPendingApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeSixPendingApplications;
