import React from "react";
import SportSchemeOneRejectedApplications from "../../../Administration/SportSchemeOne/SportSchemeOneRejectedApplications";

const SportShaskiySamiti1SchemeOneRejectedApplications = () => {
  return (
    <div>
      <SportSchemeOneRejectedApplications role="Sport Shaskiy1" />
    </div>
  );
};

export default SportShaskiySamiti1SchemeOneRejectedApplications;
