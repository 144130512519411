import React from "react";
import SchemeTwoApprovedApplications from "../../Administration/SchemeTwo/SchemeTwoApprovedApplications";

const Chanani2SchemeTwoApprovedApplications = () => {
  return (
    <div>
      <SchemeTwoApprovedApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeTwoApprovedApplications;
