import React from 'react'
import SchemeThreeTotalApplications from '../../Administration/SchemeThree/SchemeThreeTotalApplications'

const DeputyCommSchemeThreeTotalApplications = () => {
  return (
    <div>
      <SchemeThreeTotalApplications role="Deputy Commissioner"/>
    </div>
  )
}

export default DeputyCommSchemeThreeTotalApplications