import React from "react";
import SchemeEightTotalApplications from "../../Administration/SchemeEight/SchemeEightTotalApplications";

const AdditionalCommSchemeEightTotalApplications = () => {
  return (
    <div>
      <SchemeEightTotalApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeEightTotalApplications;
