import React from 'react';
import SchemeFourapprovedapplications from '../../Administration/SchemeFour/SchemeFourapprovedapplications';

const Clerk3SchemeFourApprovedApplications = () => {
  return (
    <div><SchemeFourapprovedapplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeFourApprovedApplications;
