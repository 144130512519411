import React from "react";
import SportSchemeThreeRejectedApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeRejectedApplications";

const SportShaskiySamiti3SchemeThreRejectedApplications = () => {
  return (
    <div>
      <SportSchemeThreeRejectedApplications role="Sport Shaskiy3" />
    </div>
  );
};

export default SportShaskiySamiti3SchemeThreRejectedApplications;
