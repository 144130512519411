export const loginsportclerk1 = (session) => {
  return {
    type: "LOGIN_SPORT_CLERK1",
    payload: session,
  };
};
export const logoutsportclerk1 = () => {
  return {
    type: "LOGOUT_SPORT_CLERK1",
  };
};

export const setsportclerk1Dashboard = (dashboard) => {
  return {
    type: "UPDATE_SPORT_CLERK1_DASHBOARD",
    payload: dashboard,
  };
};

export const clearsportclerk1Dashboard = () => {
  return {
    type: "CLEAR_SPORT_CLERK1_DASHBOARD",
  };
};
