import React from 'react';
import SchemeNineTotalApplications from '../../Administration/schemeNine/SchemeNineTotalApplications';

const Clerk3SchemeNineTotalApplications = () => {
  return (
    <div><SchemeNineTotalApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeNineTotalApplications;
