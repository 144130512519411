import React from "react";
import SchemeFiveRejectedApplications from "../../Administration/SchemeFive/SchemeFiveRejectedApplications";

const Chanani1SchemeFiveRejectedApplications = () => {
    return (
        <div>
            <SchemeFiveRejectedApplications role="Chanani Samiti1" />
        </div>
    );
};

export default Chanani1SchemeFiveRejectedApplications;
