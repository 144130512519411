import React from "react";
import SchemeEightTotalApplications from "../../Administration/SchemeEight/SchemeEightTotalApplications";

const ShaskiySamiti4SchemeEightTotalApplications = () => {
    return (
        <div>
            <SchemeEightTotalApplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeEightTotalApplications;
