import React from "react";
import SchemeTenapprovedapplications from "../../Administration/SchemeTen/SchemeTenapprovedapplications";

const Chanani1SchemeTenApprovedApplications = () => {
    return (
        <div>
            <SchemeTenapprovedapplications role="Chanani Samiti1" />
        </div>
    );
};

export default Chanani1SchemeTenApprovedApplications;
