import React from "react";
import SchemeTwoRejectedApplications from "../../Administration/SchemeTwo/SchemeTwoRejectedApplications";

const AdditionalCommSchemeTwoRejectedApplications = () => {
  return (
    <div>
      <SchemeTwoRejectedApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeTwoRejectedApplications;
