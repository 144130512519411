import React from "react";
import SchemeTenRejectedApplications from "../../Administration/SchemeTen/SchemeTenRejectedApplications";

const ShaskiySamiti3SchemeTenRejectedApplications = () => {
    return (
        <div>
            <SchemeTenRejectedApplications role="Shaskiy Samiti3" />
        </div>
    );
};

export default ShaskiySamiti3SchemeTenRejectedApplications;
