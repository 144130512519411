import React from "react";
import SportSchemeThreePendingApplications from "../../../Administration/SportSchemeThree/SportSchemeThreePendingApplications";

const SportShaskiySamiti2SchemethrePendingApplications = () => {
  return (
    <div>
      <SportSchemeThreePendingApplications role="Sport Shaskiy2" />
    </div>
  );
};

export default SportShaskiySamiti2SchemethrePendingApplications;
