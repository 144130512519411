import React from "react";
import SportSchemeThreeApprovedApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeApprovedApplications";

const SportShaskiySamiti2SchemeThreeApprovedApplications = () => {
  return (
    <div>
      <SportSchemeThreeApprovedApplications role="Sport Shaskiy2" />
    </div>
  );
};

export default SportShaskiySamiti2SchemeThreeApprovedApplications;
