// import React from "react";
// import { useSelector } from "react-redux";
// import { Navigate, Outlet } from "react-router-dom";
// import { useSelectAccess } from "../store/stateFunctions";
// import ChananiSamiti1Sidebar from "../chananisamiti1/ChananiSamiti1Sidebar";

// const ChananiSamiti1Container = () => {
//   document.title = "Chanani Samiti";
//   const chananisamiti1 = useSelectAccess("Chanani Samiti1");

//   return (
//     <>
//       <div className="row ">
//         <ChananiSamiti1Sidebar role="Chanani Samiti1" />
//         <div className="container">
//           {chananisamiti1 ? <Outlet /> : <Navigate to="/chanani-samiti1" />}
//           {/* <Outlet /> */}
//         </div>
//       </div>
//     </>
//   );
// };

// export default ChananiSamiti1Container;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getCookie, server } from "../common";
import configContext from "../configContext/configContext";

import ChananiSamiti1Sidebar from "../chananisamiti1/ChananiSamiti1Sidebar";
import { updateDashboardState, useSelectAccess } from "../store/stateFunctions";

export default function ChananiSamiti1Container() {
  const navigate = useNavigate();
  const location = useLocation();
  const chanani1 = useSelectAccess("Chanani Samiti1");
  const { setProgress } = useContext(configContext);

  const [processing, setProcessing] = useState(false);
  const fetched = useRef(false);
  const setFetched = (value) => (fetched.current = value);

  useEffect(() => {
    // Navigate to "/chanani1" if chanani1 access is not available
    if (!chanani1) {
      navigate("/chanani-samiti1", {
        state: { from: location },
        replace: true,
      });
      return;
    }

    // If chanani1 is valid, fetch admin profile
    setProcessing(true);
    setProgress(10);
    server
      .get("/admin/adminprofile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: chanani1.authToken,
        },
      })
      .then((response) => {
        updateDashboardState("Chanani Samiti1", response.data);
        setProgress(100);
        setProcessing(false);
        setFetched(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          navigate("/chanani-samiti1", {
            state: { from: location },
            replace: true,
          });
        } else {
          setProgress(100);
          setProcessing(false);
          console.error(
            "error",
            error.message,
            error.response?.status,
            error.response?.data,
            error
          );
        }
      });

    document.title = "Chanani Samiti Dashboard";
  }, [chanani1, navigate, location, setProgress]);

  if (chanani1)
    return (
      <>
        <ChananiSamiti1Sidebar
          username={chanani1.dashboard && chanani1.dashboard.name}
        />
        {chanani1.dashboard && <Outlet />}
      </>
    );

  return null;
}
