import React from "react";
import Dashboard from "../../Administration/dashboard/TableDashboard/Dashboard";

const SportClerk2Dashboard = () => {
  return (
    <>
      <Dashboard role="Clerk1" />
    </>
  );
};

export default SportClerk2Dashboard;
