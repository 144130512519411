import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Dropdown from "@mui/joy/Dropdown";
import { NavLink, useNavigate } from "react-router-dom";

const LoginPath = () => {
  return (
    <>
      <div className="background p-3">
        <div className="text-end ">
          <Dropdown>
            <MenuButton className="bg-white ms-3" style={{ width: "6rem" }}>
              LOGIN
            </MenuButton>
            <Menu className="mt-4">
              {/* <MenuItem>
                <NavLink
                  to="/super-admin"
                  className="dropdown-item text-uppercase "
                >
                  Commissioner
                </NavLink>
              </MenuItem> */}
              <MenuItem>
                <NavLink className="dropdown-item text-uppercase" to="/clerk">
                  Clerk - 1
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink className="dropdown-item text-uppercase" to="/clerk2">
                  Clerk - 2
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink className="dropdown-item text-uppercase" to="/clerk3">
                  Clerk - 3
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/deputy-commissioner"
                >
                  Deputy Commissioner
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/chanani-samiti1"
                >
                  Chanani Samiti - 1
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/chanani-samiti2"
                >
                  Chanani Samiti - 2
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/chanani-samiti3"
                >
                  Chanani Samiti - 3
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/chanani-samiti4"
                >
                  Chanani Samiti - 4
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/additional-commissioner"
                  className="dropdown-item text-uppercase "
                >
                  Additional Commissioner
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/shaskiy-samiti1"
                >
                  Shaskiy Samiti - 1
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/shaskiy-samiti2"
                >
                  Shaskiy Samiti - 2
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/shaskiy-samiti3"
                >
                  Shaskiy Samiti - 3
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/shaskiy-samiti4"
                >
                  Shaskiy Samiti - 4
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/shaskiy-samiti5"
                >
                  Shaskiy Samiti - 5
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/super-admin"
                >
                  Commissioner
                </NavLink>
              </MenuItem>
            </Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default LoginPath;
