export const loginsportchanani3 = (session) => {
  return {
    type: "LOGIN_SPORT_CHANANI3",
    payload: session,
  };
};
export const logoutsportchanani3 = () => {
  return {
    type: "LOGOUT_SPORT_CHANANI3",
  };
};
