import React from 'react';
import SchemeSixapprovedapplications from '../../Administration/SchemeSix/SchemeSixapprovedapplications';

const Clerk3SchemeSixApprovedApplications = () => {
  return (
    <div><SchemeSixapprovedapplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeSixApprovedApplications;
