import React from 'react'
import { Typography, Card, CardContent, Divider, Button } from "@mui/material";
function WelfareSchemeThreeDetails() {
  return (
     <div>
      <div
        className="container-fluid"
        style={{
          height: "100px",
          backgroundColor: "#98c1d9",
          color: "black",
          fontSize: "27px",
          fontWeight: "500",
          padding: "0px 20px",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
अनाथ/ निराश्रित मुलांकरिता वार्षिक शैक्षणिक शुल्कासाठी (फी) अर्थसहाय्य मिळणे बाबत
      </div>

      <div style={{ padding: "20px", backgroundColor: "#eaf6ff" }}>
        <Card style={{ marginBottom: "20px" }}>
          <CardContent>
            <Typography variant="h5" style={{ fontWeight: "bold"}}>
              प्रस्तावना
            </Typography>
            <Divider style={{ margin: "10px 0" }} />
            
       
           <Typography variant="body1" style={{ color: "#1a1a1a" }}>
                  अनाथ/ निराश्रित मुलांकरिता वार्षिक शैक्षणिक शुल्कासाठी (फी) अर्थसहाय्य मिळणे बाबत
            </Typography>
          </CardContent>
        </Card>

       

        <Card style={{ marginBottom: "20px" }}>
          <CardContent>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
             अटी व शर्तीं
            </Typography>
            <Divider style={{ margin: "10px 0" }} />
            <ul style={{ paddingLeft: "20px", fontWeight:" semi-bold" }}>
            <li>मालमत्ता कर भरणा: मालमत्ता असलेल्या अर्जदाराने मागील तीन वर्षांतील मालमत्ता कर पावत्या जोडणे आवश्यक आहे.</li>
              <li>ओळख पत्र: अर्जदाराने आधार कार्ड, मतदार ओळखपत्र, पॅन कार्ड, पासपोर्ट यापैकी कुठलेही एक ओळखपत्र जोडावे.</li>
              <li>स्वयंसेवी संस्थेमध्ये असल्यास संस्थेचे प्रतिज्ञापत्र (आफिडेव्हिट)</li>
              <li>पासपोर्ट साइज फोटो: अर्जदार आणि मुलीचे/मुलाचे  पासपोर्ट साइज फोटो अर्जासोबत जोडणे आवश्यक आहे.</li>
              <li>अर्जदाराचे निवासाचे कागदपत्र: रहिवासी दाखला, निवासाचा पुरावा जोडणे अनिवार्य आहे.</li>
               <li>अनाथ/निराश्रित असल्याबाबतचा दाखला सादर करणे बंधनकारक राहील </li>
              <li>अनाथ/निराश्रित  असल्याबाबतचा दाखला सादर करणे बंधनकारक राहील..</li>
              <li>ज्या सामाजिक संस्था/मंडळे अनाथ/निराश्रित मुलांचे संगोपन करीत आहेत व ज्या सामाजीक संस्था/मंडळांना शासनाचे अनुदान मिळत नाही अशा सामाजिक संस्था/मंडळे अनाथ/निराश्रित मुलांचे या योजनेकरिता अर्ज करू शकता. </li>
            <li>शाळेत प्रवेश घेतल्याबाबतचा पुरावा म्हणून बोनाफाईड सर्टिफिकेट किंवा फि भरणा केल्याबाबतची पावती सादर करणे बंधनकारक राहील  </li>
            <li>निराधार मुलांच्या संदर्भात आई-वडील मयत असल्याबाबत दोघांच्याही मृत्यूचा दाखला सादर करणे बंधनकारक राहील </li>

            </ul>
            
            <div style={{ marginTop: "20px" }}>
              <Button
                variant="contained"
                className="bluebutton"
                href="/path-to-brochure.pdf"
                download
                style={{ marginRight: "10px" }}
              >
                Download Brochure
              </Button>
              <Button
                variant="contained"
                className="redbutton"
                color="secondary"
              >
                Apply Now
              </Button>
            </div>
          </CardContent>
        </Card>

     

       
      </div>
    </div>
  )
}

export default WelfareSchemeThreeDetails