import React from "react";
import SportSchemeTwoPendingApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoPendingApplications";

const SportChanani1SchemeTwoPendingApplications = () => {
  return (
    <div>
      <SportSchemeTwoPendingApplications role="Sport Chanani1" />
    </div>
  );
};

export default SportChanani1SchemeTwoPendingApplications;
