import React, { useState } from "react";
import { Container, Grid, Button, Typography, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";
// import brochure from "../../assets/documents/Financial Assistance for Disabled Individuals with Special Needs.pdf";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelectAccess } from "../../store/stateFunctions";
import { toast } from "react-toastify";

const SchemeSevenDetails = () => {
  const { t } = useTranslation();
  const user = useSelectAccess("User");
  const isLoggedIn = user && user;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleApplyNowClick = (e) => {
    if (!isLoggedIn) {
      e.preventDefault(); // Prevent navigation
      toast.info("Please log in to apply for the Scheme");
      navigate("/login");
    } else {
      setShow(true);
    }
  };

  return (
    <div className="container-fluid">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            style={{
              backgroundColor: "#ffffff",
              padding: "30px",
              border: "1px solid #e0e0e0",
            }}
          >
            <Typography
              variant="h4"
              component="div"
              style={{ fontWeight: "bold", marginBottom: "20px" }}
              className="text-dark"
            >
              {t("schemeSevenDetails.title")}
            </Typography>
         
              <ul
                style={{
                  listStyleType: "disc",
                  paddingLeft: "20px",
                  marginBottom: "20px",
                }}
              >
                <li>{t("schemeSevenDetails.point1")}</li>
                <li>{t("schemeSevenDetails.point2")}</li>
              </ul>
          
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper
            style={{
              backgroundColor: "#ffffff",
              padding: "30px",
              border: "1px solid #e0e0e0",
            }}
          >
            <li
              variant="h4"
              component="div"
              style={{ fontWeight: "bold", marginBottom: "20px" }}
              className="text-dark"
            >
                {t("TermsandConditions")}
            </li>
           
              <ul
                style={{
                  listStyleType: "disc",
                  paddingLeft: "20px",
                  marginBottom: "20px",
                }}
              >
                <li>
                {t("ViewDetailsSchemeSeven.1")}
                </li>

                <ol>
                  <li>
                  {t("ViewDetailsSchemeSeven.2")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.3")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.4")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.5")}
                  </li>
                </ol>
                <li> {t("ViewDetailsSchemeSeven.6")} </li>
                <ol>
                  <li>
                  {t("ViewDetailsSchemeSeven.7")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.8")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.9")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.10")}
                  </li>
                </ol>
                <li>
                {t("ViewDetailsSchemeSeven.11")}
                </li>
                <ol>
                  <li>
                  {t("ViewDetailsSchemeSeven.12")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.13")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.14")}
                  </li>
                </ol>
                <li>
                {t("ViewDetailsSchemeSeven.15")}
                </li>
                <ol>
                  <li>
                  {t("ViewDetailsSchemeSeven.16")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.17")}
                  </li>
                </ol>
                <li>
                {t("ViewDetailsSchemeSeven.18")}
                </li>
                <ol>
                  <li>
                  {t("ViewDetailsSchemeSeven.19")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.20")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.21")}
                  </li>
                </ol>

                <li>
                {t("ViewDetailsSchemeSeven.22")}
                </li>
                <ol>
                  <li>
                  {t("ViewDetailsSchemeSeven.23")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.24")}
                  </li>
                </ol>
                <li>
                {t("ViewDetailsSchemeSeven.25")}
                </li>
                <ol>
                  <li>
                  {t("ViewDetailsSchemeSeven.26")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.27")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.28")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.29")}
                  </li>
                </ol>
                <li>
                {t("ViewDetailsSchemeSeven.30")}
                </li>
                <ol>
                  <li>
                  {t("ViewDetailsSchemeSeven.31")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.32")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.33")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.34")}
                  </li>
                </ol>
                <li> {t("ViewDetailsSchemeSeven.35")}</li>
                <ol>
                  <li>
                  {t("ViewDetailsSchemeSeven.36")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.37")}.
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.38")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.39")}
                  </li>
                </ol>
                <li>
                {t("ViewDetailsSchemeSeven.40")}
                </li>
                <ol>
                  <li>
                  {t("ViewDetailsSchemeSeven.41")}
                  </li>
                </ol>
                <li>
                {t("ViewDetailsSchemeSeven.42")}
                </li>
                <ol>
                  <li>
                  {t("ViewDetailsSchemeSeven.43")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.44")}
                  </li>
                  <li>
                  {t("ViewDetailsSchemeSeven.45")}
                  </li>
                </ol>
                <li>
                {t("ViewDetailsSchemeSeven.46")}.
                </li>
                <li>
                {t("ViewDetailsSchemeSeven.47")}
                </li>
              </ul>
            
            <div>
              <NavLink to="/schemeseven" onClick={handleApplyNowClick}>
                <Button
                  variant="contained"
                  className="redbutton"
                  color="secondary"
                >
                  Apply Now
                </Button>
              </NavLink>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default SchemeSevenDetails;
