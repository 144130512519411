import React from "react";
import SchemeFourRejectedApplications from "../../Administration/SchemeFour/SchemeFourRejectedApplications";

const ShaskiySamiti1SchemeFourRejectedApplications = () => {
    return (
        <div>
            <SchemeFourRejectedApplications role="Shaskiy Samiti1" />
        </div>
    );
};

export default ShaskiySamiti1SchemeFourRejectedApplications;
