import React, { useContext, useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AxiosError } from "axios";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { loginUser } from "../../store/actions";
import { server } from "../../common";
import configContext from "../../configContext/configContext";
import Loader from "../../Loader";

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const {
    values,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      mobile: "",
      password: "",
    },
    validationSchema: Yup.object({
      mobile: Yup.string().required(t("login.errors.mobileRequired")),
      password: Yup.string().required(t("login.errors.passwordRequired")),
    }),
    onSubmit: (values, action) => {
      setLoading(true);
      server
        .post("/user/userlogin", values, {
          headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            dispatch(loginUser({ authToken: response.data.token }));
            toast.success(t("login.Successmessage"));
            navigate("/profile/profilepage");
            resetForm();
          }
        })
        .catch((error) => {
          if (error instanceof AxiosError && error.response?.data?.message)
            toast.error(error.response.data.message);
          else if (error.response?.data?.error) {
            toast.error(error.response.data.error);
          } else toast.error("Failed to connect to server");
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <>
      {loading && <Loader loading={loading} />}
      <Container
        maxWidth="sm"
        style={{
          marginTop: "40px",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          {t("login.title")}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label={t("login.mobileNumber")}
            variant="outlined"
            margin="normal"
            name="mobile"
            value={values.mobile}
            onChange={handleChange}
            type="number"
            fullWidth
            required
          />
          {errors.mobile && <p className="text-danger">{errors.mobile}</p>}

          <FormControl variant="outlined" fullWidth margin="normal">
            <TextField
              type={showPassword ? "text" : "password"}
              label={t("login.password")}
              name="password"
              value={values.password}
              onChange={handleChange}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          {errors.password && <p className="text-danger">{errors.password}</p>}
          <Button
            type="submit"
            variant="contained"
            fullWidth
            style={{ marginTop: "16px" }}
            className="bluebutton"
          >
            {t("login.loginButton")}
          </Button>
        </form>

        <Typography
          variant="body2"
          align="center"
          style={{ marginTop: "16px", color: "black" }}
        >
          {t("login.noAccount")}{" "}
          <NavLink to="/registration" style={{ color: "blue" }}>
            {t("login.createAccount")}
          </NavLink>
        </Typography>
        <Typography variant="body2" align="center" style={{ color: "black" }}>
          {t("login.forgotPassword")}{" "}
          <NavLink to="/forgotpassword" style={{ color: "blue" }}>
            {t("login.clickHere")}
          </NavLink>
        </Typography>
      </Container>
    </>
  );
};

export default LoginForm;
