import React from "react";
import SchemeThreePendingApplications from "../../Administration/SchemeThree/SchemeThreePendingApplications";

const ShaskiySamiti4SchemeThreePendingApplications = () => {
    return (
        <div>
            <SchemeThreePendingApplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeThreePendingApplications;
