import React from "react";
import ContactEntries from "../Administration/ContactEntries";

const SuperAdminContactEnquiries = () => {
  return (
    <div>
      <ContactEntries role="Super Admin" />
    </div>
  );
};

export default SuperAdminContactEnquiries;
