import React from "react";
import SchemeFourPendingApplications from "../../Administration/SchemeFour/SchemeFourPendingApplications";

const Chanani1SchemeFourPendingApplications = () => {
  return (
    <div>
      <SchemeFourPendingApplications role="Chanani Samiti1" />
    </div>
  );
};

export default Chanani1SchemeFourPendingApplications;
