import React from "react";
import SchemeTenPendingApplications from "../../Administration/SchemeTen/SchemeTenPendingApplications";

const Chanani3SchemeTenPendingApplications = () => {
    return (
        <div>
            <SchemeTenPendingApplications role="Chanani Samiti3" />
        </div>
    );
};

export default Chanani3SchemeTenPendingApplications;
