import React from 'react';
import SchemeEightRejectedApplications from '../../Administration/SchemeEight/SchemeEightRejectedApplications';

const Clerk3SchemeEightRejectedApplications = () => {
  return (
    <div><SchemeEightRejectedApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeEightRejectedApplications;
