import React from 'react';
import SchemeSevenPendingApplications from '../../Administration/SchemeSeven/SchemeSevenPendingApplications';

const Clerk3SchemeSevenPendingApplications = () => {
  return (
    <div><SchemeSevenPendingApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeSevenPendingApplications;
