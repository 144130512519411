import React from "react";
import SchemeSevenTotalApplications from "../../Administration/SchemeSeven/SchemeSevenTotalApplications";

const AdditionalCommSchemeSevenTotalApplications = () => {
  return (
    <div>
      <SchemeSevenTotalApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeSevenTotalApplications;
