import React from "react";
import SportSchemeTwoPendingApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoPendingApplications";

const SportChanani2SchemeTwoPendingApplications = () => {
  return (
    <div>
      <SportSchemeTwoPendingApplications role="Sport Chanani2" />
    </div>
  );
};

export default SportChanani2SchemeTwoPendingApplications;
