import React from "react";
import SchemeTenRejectedApplications from "../../Administration/SchemeTen/SchemeTenRejectedApplications";

const ShaskiySamiti1SchemeTenRejectedApplications = () => {
    return (
        <div>
            <SchemeTenRejectedApplications role="Shaskiy Samiti1" />
        </div>
    );
};

export default ShaskiySamiti1SchemeTenRejectedApplications;
