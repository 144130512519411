import React from 'react';
import SchemeOneTotalApplications from '../../Administration/SchemeOne/SchemeOneTotalApplications';

const Clerk2SchemeOneTotalApplications = () => {
  return (
    <div><SchemeOneTotalApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeOneTotalApplications;
