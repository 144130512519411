import React from "react";
import SchemeSixRejectedApplications from "../../Administration/SchemeSix/SchemeSixRejectedApplications";

const AdditionalCommSchemeSixRejectedApplications = () => {
  return (
    <div>
      <SchemeSixRejectedApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeSixRejectedApplications;
