import React from "react";
import SchemeFourapprovedapplications from "../../Administration/SchemeFour/SchemeFourapprovedapplications";

const Chanani2SchemeFourApprovedApplications = () => {
  return (
    <div>
      <SchemeFourapprovedapplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeFourApprovedApplications;
