import React from 'react';
import SchemeFivePendingApplications from '../../Administration/SchemeFive/SchemeFivePendingApplications';

const Clerk3SchemeFivePendingApplications = () => {
  return (
    <div><SchemeFivePendingApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeFivePendingApplications;
