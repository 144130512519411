import React from 'react';
import SchemeFourPendingApplications from '../../Administration/SchemeFour/SchemeFourPendingApplications';

const Clerk3SchemeFourPendingApplications = () => {
  return (
    <div><SchemeFourPendingApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeFourPendingApplications;
