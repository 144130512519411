import React from "react";
import SchemeSevenRejectedApplications from "../../Administration/SchemeSeven/SchemeSevenRejectedApplications";

const Chanani1SchemeSevenRejectedApplications = () => {
    return (
        <div>
            <SchemeSevenRejectedApplications role="Chanani Samiti1" />
        </div>
    );
};

export default Chanani1SchemeSevenRejectedApplications;
