import React from "react";
import SchemeFivePendingApplications from "../../Administration/SchemeFive/SchemeFivePendingApplications";

const ClerkSchemeFivePendingApplications = () => {
  return (
    <div>
      <SchemeFivePendingApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeFivePendingApplications;
