export const BACKEND_SERVER_URL = process.env.REACT_APP_BE_URL;
export const PAYMENT_KEY = process.env.REACT_APP_PAYMENT_KEY;
// export const backend = "https://mernbackend-jyf4.onrender.com/api"

export const superAdminTokenName = "supATH";

export const userTokenName = "usrATH";

export const AddCommiTokenName = "addcommATH";

export const deputyCommTokenName = "deputyCommATH";

export const chananisamitiTokenName = "chananisamityATH";
export const chananisamitiTokenName1 = "chananisamityATH1";
export const chananisamitiTokenName2 = "chananisamityATH2";
export const chananisamitiTokenName3 = "chananisamityATH3";
export const chananisamitiTokenName4 = "chananisamityATH4";

export const shaskiysamityTokenName = "shaskiysamityATH";
export const shaskiysamityTokenName1 = "shaskiysamityATH1";
export const shaskiysamityTokenName2 = "shaskiysamityATH2";
export const shaskiysamityTokenName3 = "shaskiysamityATH3";
export const shaskiysamityTokenName4 = "shaskiysamityATH4";
export const shaskiysamityTokenName5 = "shaskiysamityATH5";

export const clerkUserTokenName1 = "clerkATH1";
export const clerkUserTokenName2 = "clerkATH2";
export const clerkUserTokenName3 = "clerkATH3";

// sport users
export const sportclerkTokenName1 = "sportclerkATH1";
export const sportclerkTokenName2 = "sportclerkATH2";

export const sportchananiTokenName1 = "sportchananiATH1";
export const sportchananiTokenName2 = "sportchananiATH2";
export const sportchananiTokenName3 = "sportchananiATH3";
export const sportchananiTokenName4 = "sportclerkATH4";
export const sportchananiTokenName5 = "sportclerkATH5";

export const sportshaskiysamityTokenName1 = "shaskiysamityATH1";
export const sportshaskiysamityTokenName2 = "shaskiysamityATH2";
export const sportshaskiysamityTokenName3 = "shaskiysamityATH3";
export const sportshaskiysamityTokenName4 = "shaskiysamityATH4";
export const sportshaskiysamityTokenName5 = "shaskiysamityATH5";

export const sportAddCommiTokenName = "sportaddcommATH";

export const sportdeputyCommTokenName = "sportdeputyCommATH";

export const shareurl = process.env.SHARE_URL;

export const ToastConfig = {
  position: "bottom-right",
  autoClose: 4000,
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: true,
  rtl: false,
  draggable: true,
  pauseOnHover: true,
  // theme: "colored",
  limit: 3,
  bodyClassName: "toastContainerBody",
};
