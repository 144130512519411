import React from "react";
import SchemeThreeRejectedApplications from "../../Administration/SchemeThree/SchemeThreeRejectedApplications";

const Chanani1SchemeThreeRejectedApplications = () => {
    return (
        <div>
            <SchemeThreeRejectedApplications role="Chanani Samiti1" />
        </div>
    );
};

export default Chanani1SchemeThreeRejectedApplications;
