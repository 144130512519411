import React from "react";
import SchemeFourapprovedapplications from "../../Administration/SchemeFour/SchemeFourapprovedapplications";

const ShaskiySamiti5SchemeFourApprovedApplications = () => {
    return (
        <div>
            <SchemeFourapprovedapplications role="Shaskiy Samiti5" />
        </div>
    );
};

export default ShaskiySamiti5SchemeFourApprovedApplications;
