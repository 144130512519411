import React from "react";
import SchemeThreeRejectedApplications from "../../Administration/SchemeThree/SchemeThreeRejectedApplications";

const AdditionalCommSchemeThreeRejectedApplications = () => {
  return (
    <div>
      <SchemeThreeRejectedApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeThreeRejectedApplications;
