export const loginsportchanani4 = (session) => {
  return {
    type: "LOGIN_SPORT_CHANANI4",
    payload: session,
  };
};
export const logoutsportchanani4 = () => {
  return {
    type: "LOGOUT_SPORT_CHANANI4",
  };
};
