import React from "react";
import SchemeSevenapprovedapplications from "../../Administration/SchemeSeven/SchemeSevenapprovedapplications";

const ShaskiySamiti4SchemeSevenApprovedApplications = () => {
    return (
        <div>
            <SchemeSevenapprovedapplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeSevenApprovedApplications;
