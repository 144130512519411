import React from "react";
import SportSchemeTwoPendingApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoPendingApplications";

const SportCommissionerSchemeTwoApprovedApplications = () => {
  return (
    <div>
      <SportSchemeTwoPendingApplications role="Sport AddComm" />
    </div>
  );
};

export default SportCommissionerSchemeTwoApprovedApplications;
