import React from "react";
import SchemeFourRejectedApplications from "../../Administration/SchemeFour/SchemeFourRejectedApplications";

const ShaskiySamiti3SchemeFourRejectedApplications = () => {
    return (
        <div>
            <SchemeFourRejectedApplications role="Shaskiy Samiti3" />
        </div>
    );
};

export default ShaskiySamiti3SchemeFourRejectedApplications;
