import React from 'react'
import { Typography, Card, CardContent, Divider, Button } from "@mui/material";
export default function WelfareSchemeFourDetails() {
  return (
     <div>
      <div
        className="container-fluid"
        style={{
          height: "100px",
          backgroundColor: "#98c1d9",
          color: "black",
          fontSize: "27px",
          fontWeight: "500",
          padding: "0px 20px",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
अनाथ/ निराश्रित मुलांना दत्तक घेतलेल्या पालकांना प्रोत्साहनात्मक  अनुदान मिळणे बाबत
      </div>

      <div style={{ padding: "20px", backgroundColor: "#eaf6ff" }}>
        <Card style={{ marginBottom: "20px" }}>
          <CardContent>
            <Typography variant="h5" style={{ fontWeight: "bold"}}>
              प्रस्तावना
            </Typography>
            <Divider style={{ margin: "10px 0" }} />
            
       
           <Typography variant="body1" style={{ color: "#1a1a1a" }}>
                  अनाथ/ निराश्रित मुलांना दत्तक घेतलेल्या पालकांना प्रोत्साहनात्मक  अनुदान मिळणे बाबत
            </Typography>
          </CardContent>
        </Card>

       

        <Card style={{ marginBottom: "20px" }}>
          <CardContent>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
             अटी व शर्तीं
            </Typography>
            <Divider style={{ margin: "10px 0" }} />
            <ul style={{ paddingLeft: "20px", fontWeight:" semi-bold" }}>
            
              
              <li>मालमत्ता कर भरणा: मालमत्ता असलेल्या अर्जदाराने मागील तीन वर्षांतील मालमत्ता कर पावत्या जोडणे आवश्यक आहे.</li>
              <li>ओळख पत्र: अर्जदाराने आधार कार्ड, मतदार ओळखपत्र, पॅन कार्ड, पासपोर्ट यापैकी कुठलेही एक ओळखपत्र जोडावे.</li>
              <li>दत्तक मुलांसाठी न्यायालयाचा दत्तक आदेश.</li>
              <li>पासपोर्ट साइज फोटो: अर्जदार आणि मुलीचे/मुलाचे  पासपोर्ट साइज फोटो अर्जासोबत जोडणे आवश्यक आहे.</li>
              <li> अर्जदाराचे निवासाचे कागदपत्र: रहिवासी दाखला, निवासाचा पुरावा जोडणे अनिवार्य आहे.</li>
              <li>लाभ घेण्यासाठी मूळ राशन कार्ड आवश्यक आहे.</li>
              <li>शैक्षणिक पात्रता: नाशिक महानगरपालिकेच्या शाळामध्ये इयत्ता १ ली ते ७ वि पर्यंत शिक्षण घेत असलेल्या विध्यार्थी  या योजनेकरिता अर्ज करू शकत नाही. </li>
            </ul>
            
            <div style={{ marginTop: "20px" }}>
              <Button
                variant="contained"
                className="bluebutton"
                href="/path-to-brochure.pdf"
                download
                style={{ marginRight: "10px" }}
              >
                Download Brochure
              </Button>
              <Button
                variant="contained"
                className="redbutton"
                color="secondary"
              >
                Apply Now
              </Button>
            </div>
          </CardContent>
        </Card>

     

       
      </div>
    </div>
  )
}
