import React from "react";
import SchemeEightRejectedApplications from "../../Administration/SchemeEight/SchemeEightRejectedApplications";

const Chanani1SchemeEightRejectedApplications = () => {
  return (
    <div>
      <SchemeEightRejectedApplications role="Chanani Samiti1" />
    </div>
  );
};

export default Chanani1SchemeEightRejectedApplications;
