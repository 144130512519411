import React from "react";
import SchemeTenPendingApplications from "../../Administration/SchemeTen/SchemeTenPendingApplications";

const Chanani2SchemeTenPendingApplications = () => {
  return (
    <div>
      <SchemeTenPendingApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeTenPendingApplications;
