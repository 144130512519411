import React from "react";
import SchemeTwoPendingApplications from "../../Administration/SchemeTwo/SchemeTwoPendingApplications";

const Chanani1SchemeTwoPendingApplications = () => {
    return (
        <div>
            <SchemeTwoPendingApplications role="Chanani Samiti1" />
        </div>
    );
};

export default Chanani1SchemeTwoPendingApplications;
