export const loginChananisamiti1 = (session) => {
  return {
    type: "LOGIN_CHANANI_SAMITY1",
    payload: session,
  };
};

export const logoutChananisamiti1 = () => {
  return {
    type: "LOGOUT_CHANANI_SAMITY1",
  };
};

export const setChanani1Dashboard = (dashboard) => {
  return {
    type: "UPDATE_CHANANI1_DASHBOARD",
    payload: dashboard,
  };
};

export const clearChanani1Dashboard = () => {
  return {
    type: "CLEAR_CHANANI1_DASHBOARD",
  };
};
