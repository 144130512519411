import React from "react";
import SchemeNinePendingApplications from "../../Administration/schemeNine/SchemeNinePendingApplications";

const Chanani2SchemeNinePendingApplications = () => {
  return (
    <div>
      <SchemeNinePendingApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeNinePendingApplications;
