import React from "react";
import SchemeOnePendingApplications from "../../Administration/SchemeOne/SchemeOnePendingApplications";

const AdditionalCommSchemeOnePendingApplications = () => {
  return (
    <div>
      <SchemeOnePendingApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeOnePendingApplications;
