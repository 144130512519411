import React from 'react';
import SchemeEightPendingApplications from '../../Administration/SchemeEight/SchemeEightPendingApplications';

const Clerk3SchemeEightPendingApplications = () => {
  return (
    <div><SchemeEightPendingApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeEightPendingApplications;
