import React from "react";
import SchemeFiveApprovedApplications from "../../Administration/SchemeFive/SchemeFiveApprovedApplications";

const ShaskiySamiti4SchemeFiveApprovedApplications = () => {
    return (
        <div>
            <SchemeFiveApprovedApplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeFiveApprovedApplications;
