// import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import { initReactI18next } from "react-i18next";
// import FeaturingSchemes from "./Components/FeaturingSchemes/FeaturingSchemes";
// import { Description } from "@mui/icons-material";

// i18n
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     debug: true,
//     lng: "mr", 
//     resources: {
//       en: {
//         translation: {
//           header: {
//             title: "Nashik Municipal Corporation, Nashik",
//             department: "Social Welfare Department",
//           },
//           navbar: {
//             home: "Home",
//             schemes: "Schemes",
//             applicationStatus: "Application Status",
//             profile: "Profile",
//             logout: "Logout",
//             login: "Login",
//             signup: "Sign up",
//           },
//         featuringschemes:{
//             title:"Support Scheme for Persons with Disabilities",
//             description:"Find a financial plan to provide critical tools for the independence and advancement of individuals with disabilities.",
//             cardonetitle:"Funding Scheme for Cochlear Implant Surgery for the Deaf",
//             cardonedescription:"Financial Assistance for Cochlear Implant Surgery for Deaf Persons Funding for hearing related medical treatment."
//         }
//         },
//       },
//       mr: {
//         translation: {
//           header: {
//             title: "नाशिक महानगरपालिका, नाशिक",
//             department: "समाजकल्याण विभाग",
//           },
//           navbar: {
//             home: "मुख्यपृष्ठ",
//             schemes: "योजना",
//             applicationStatus: "आवेदन स्थिती",
//             profile: "प्रोफाइल",
//             logout: "लॉगआउट",
//             login: "लॉगिन",
//             signup: "साइन अप",
//           },
          
//         featuringschemes:{
//           title:" दिव्यांग व्यक्तींसाठी सहाय्यक योजना",
//           description:"दिव्यांग व्यक्तींच्या स्वतंत्रता आणि प्रगतीसाठी महत्त्वपूर्ण साधने उपलब्ध करून देण्यासाठी आर्थिक योजना शोधा.",
//           cardonetitle:"कर्णबधिरांसाठी Cochlear Implant शस्त्रक्रियेसाठी अर्थसहाय्य योजना",
//           cardonedescription:"कर्णबधिर व्यक्तींसाठी Cochlear Implant शस्त्रक्रियेसाठी आर्थिक सहाय्य श्रवण संबंधित वैद्यकीय उपचारांसाठी निधी."
//       }
//         },
//       },
//     },
//     fallbackLng: 'en', 
//     interpolation: {
//       escapeValue: false, 
//     },
//   });

// export default i18n;


// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: require('./locales/en/translation.json'),
      },
      mr: {
        translation: require('./locales/mr/translation.json'),
      },
    },
    lng: 'en', // default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // React already safes from XSS
    },
  });

export default i18n;
