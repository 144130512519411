import React from "react";
import SchemeEightRejectedApplications from "../../Administration/SchemeEight/SchemeEightRejectedApplications";

const Chanani2SchemeEightRejectedApplications = () => {
  return (
    <div>
      <SchemeEightRejectedApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeEightRejectedApplications;
