import React from "react";
import SchemeTwoApprovedApplications from "../../Administration/SchemeTwo/SchemeTwoApprovedApplications";

const ShaskiySamiti2SchemeTwoApprovedApplications = () => {
    return (
        <div>
            <SchemeTwoApprovedApplications role="Shaskiy Samiti2" />
        </div>
    );
};

export default ShaskiySamiti2SchemeTwoApprovedApplications;
