import React from "react";
import SchemeFivePendingApplications from "../../Administration/SchemeFive/SchemeFivePendingApplications";

const Chanani1SchemeFivePendingApplications = () => {
  return (
    <div>
      <SchemeFivePendingApplications role="Chanani Samiti1" />
    </div>
  );
};

export default Chanani1SchemeFivePendingApplications;
