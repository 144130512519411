import React from "react";
import SchemeEightRejectedApplications from "../../Administration/SchemeEight/SchemeEightRejectedApplications";

const Chanani3SchemeEightRejectedApplications = () => {
  return (
    <div>
      <SchemeEightRejectedApplications role="Chanani Samiti3" />
    </div>
  );
};

export default Chanani3SchemeEightRejectedApplications;
