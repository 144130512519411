import React from "react";
import SchemeSixApprovedApplications from "../../Administration/SchemeSix/SchemeSixapprovedapplications";

const ClerkSchemeSixApprovedApplications = () => {
  return (
    <div>
      <SchemeSixApprovedApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeSixApprovedApplications;
