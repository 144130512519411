import React from "react";
import SchemeThreePendingApplications from "../../Administration/SchemeThree/SchemeThreePendingApplications";

const Chanani4SchemeThreePendingApplications = () => {
    return (
        <div>
            <SchemeThreePendingApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeThreePendingApplications;
