import React from "react";
import SchemeTwoPendingApplications from "../../Administration/SchemeTwo/SchemeTwoPendingApplications";

const ClerkSchemeTwoPendingApplications = () => {
  return (
    <div>
      <SchemeTwoPendingApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeTwoPendingApplications;
