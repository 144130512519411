import React from "react";
import SchemeThreePendingApplications from "../../Administration/SchemeThree/SchemeThreePendingApplications";

const ShaskiySamiti3SchemeThreePendingApplications = () => {
    return (
        <div>
            <SchemeThreePendingApplications role="Shaskiy Samiti3" />
        </div>
    );
};

export default ShaskiySamiti3SchemeThreePendingApplications;
