import React from "react";
import SchemeSixRejectedApplications from "../../Administration/SchemeSix/SchemeSixRejectedApplications";

const ShaskiySamiti4SchemeSixRejectedApplications = () => {
    return (
        <div>
            <SchemeSixRejectedApplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeSixRejectedApplications;
