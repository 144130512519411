import React from "react";
import SchemeNineapprovedapplications from "../../Administration/schemeNine/SchemeNineapprovedapplications";

const ShaskiySamiti3SchemeNineApprovedApplications = () => {
    return (
        <div>
            <SchemeNineapprovedapplications role="Shaskiy Samiti3" />
        </div>
    );
};

export default ShaskiySamiti3SchemeNineApprovedApplications;
