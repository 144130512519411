import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Dropdown from "@mui/joy/Dropdown";
import { NavLink, useNavigate } from "react-router-dom";

const SportLoginPath = () => {
  return (
    <>
      <div className="background p-3">
        <div className="text-end ">
          <Dropdown>
            <MenuButton className="bg-white ms-3" style={{ width: "6rem" }}>
              LOGIN
            </MenuButton>
            <Menu className="mt-4">
              {/* <MenuItem>
                <NavLink
                  to="/super-admin"
                  className="dropdown-item text-uppercase "
                >
                  Commissioner
                </NavLink>
              </MenuItem> */}
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/sport-clerk1"
                >
                  Clerk - 1
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/sport-clerk2"
                >
                  Clerk - 2
                </NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/sport-deputy"
                >
                  Deputy Commissioner
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/sport-chanani1"
                >
                  Chanani Samiti - 1
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/sport-chanani2"
                >
                  Chanani Samiti - 2
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/sport-chanani3"
                >
                  Chanani Samiti - 3
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/sport-chanani4"
                >
                  Chanani Samiti - 4
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to="/sport-additional"
                  className="dropdown-item text-uppercase "
                >
                  Additional Commissioner
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/sport-shaskiy1"
                >
                  Shaskiy Samiti - 1
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/sport-shaskiy2"
                >
                  Shaskiy Samiti - 2
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/sport-shaskiy3"
                >
                  Shaskiy Samiti - 3
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/sportshaskiy4"
                >
                  Shaskiy Samiti - 4
                </NavLink>
              </MenuItem>

              <MenuItem>
                <NavLink
                  className="dropdown-item text-uppercase"
                  to="/super-admin"
                >
                  Commissioner
                </NavLink>
              </MenuItem>
            </Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default SportLoginPath;
