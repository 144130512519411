import React from "react";
import SchemeEightapprovedapplications from "../../Administration/SchemeEight/SchemeEightapprovedapplications";

const SuperAdminSchemeEightApprovedApplications = () => {
  return (
    <div>
      <SchemeEightapprovedapplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeEightApprovedApplications;
