import React from "react";
import SchemeNineRejectedApplications from "../../Administration/schemeNine/SchemeNineRejectedApplications";

const Chanani2SchemeNineRejectedApplications = () => {
  return (
    <div>
      <SchemeNineRejectedApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeNineRejectedApplications;
