import React from "react";
import SchemeNineRejectedApplications from "../../Administration/schemeNine/SchemeNineRejectedApplications";

const SuperAdminSchemeNineRejectedApplications = () => {
  return (
    <div>
      <SchemeNineRejectedApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeNineRejectedApplications;
