import React from "react";
import SchemeEightPendingApplications from "../../Administration/SchemeEight/SchemeEightPendingApplications";

const Chanani2SchemeEightPendingApplications = () => {
  return (
    <div>
      <SchemeEightPendingApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeEightPendingApplications;
