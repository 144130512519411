import React from "react";
import SchemeSevenApprovedapplications from "../../Administration/SchemeSeven/SchemeSevenapprovedapplications";

const SuperAdminSchemeSevenApprovedApplications = () => {
  return (
    <div>
      <SchemeSevenApprovedapplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeSevenApprovedApplications;
