import React from "react";
import SchemeSixapprovedapplications from "../../Administration/SchemeSix/SchemeSixapprovedapplications";

const AdditionalCommSchemeSixApprovedApplications = () => {
  return (
    <div>
      <SchemeSixapprovedapplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeSixApprovedApplications;
