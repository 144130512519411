import React from "react";
import ManageUser from "../Administration/ManageUser";

const AdditionalCommissionerManageUser = () => {
  return (
    <div>
      <ManageUser role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommissionerManageUser;
