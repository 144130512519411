import React from "react";
import SchemeTwoRejectedApplications from "../../Administration/SchemeTwo/SchemeTwoRejectedApplications";

const Chanani2SchemeTwoRejectedApplications = () => {
  return (
    <div>
      <SchemeTwoRejectedApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeTwoRejectedApplications;
