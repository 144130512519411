import React from 'react'
import SchemeFourapprovedapplications from '../../Administration/SchemeFour/SchemeFourapprovedapplications'

const DeputyCommSchemeFourApprovedApplications = () => {
  return (
    <div><SchemeFourapprovedapplications role="Deputy Commissioner"/></div>
  
  )
}

export default DeputyCommSchemeFourApprovedApplications