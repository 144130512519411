import React from "react";
import SchemeFourRejectedApplications from "../../Administration/SchemeFour/SchemeFourRejectedApplications";

const ShaskiySamiti2SchemeFourRejectedApplications = () => {
    return (
        <div>
            <SchemeFourRejectedApplications role="Shaskiy Samiti2" />
        </div>
    );
};

export default ShaskiySamiti2SchemeFourRejectedApplications;
