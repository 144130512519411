import { Button, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AxiosError } from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { server } from "../common";
import configContext from "../configContext/configContext";
import { loginclerk, loginclerk2 } from "../store/actions";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Dropdown from "@mui/joy/Dropdown";
import LoginPath from "../Components/LoginsPath";

const initialValues = {
  email: "",
  password: "",
};

const Clerk2Login = () => {
  document.title = "Clerk";
  // const [processing, setProcessing] = useState(false);
  // const { setProgress } = useContext(configContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    values,
    handleBlur,
    touched,
    handleChange,
    handleSubmit,
    errors,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Enter a valid email")
        .required("Please Enter Your Email"),
      password: Yup.string()
        .min(2, "Enter details")
        .required("Please Enter Your password"),
    }),

    onSubmit: (values, action) => {
      // setProcessing(true);
      // setProgress(10);
      console.log(values);
      server
        .post(
          "/admin/clerk",
          values,

          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          console.log("api response", response.data);
          if (response.status === 200 || response.status === 201) {
            if (response.data) {
              dispatch(loginclerk2({ authToken: response.data.token }));
              toast.success("Clerk Login successfully");
              navigate("/clerk2/schemefivetotalapplications");
              resetForm();
              // setProgress(100);
            }
            // setProcessing(false);
          }
        })
        .catch(function (error) {
          if (error instanceof AxiosError && error.response?.data?.message)
            toast.error(error.response.data.message);
          else if (error.response?.data?.error) {
            toast.error(error.response.data.error);
          } else toast.error("Failed to connect to server");
          // setProcessing(false);
          // setProgress(100);
        });
    },
  });

  return (
    <section className="vh-100">
      <LoginPath />
      <div className="container py-3 h-100 ">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card shadow rounded-3">
              <div className="card-body text-center p-5">
                <img
                  src="/images/logo2.png"
                  className="img-fluid"
                  width="130"
                  alt=""
                />
                <h2 className="mb-2text-center ">Clerk-2 Login</h2>
                <form onSubmit={handleSubmit}>
                  <div className="mb-2">
                    <TextField
                      name="email"
                      margin="dense"
                      type="email"
                      placeholder="Email Id"
                      variant="outlined"
                      label="Email Id"
                      value={values.email}
                      onChange={handleChange}
                      fullWidth
                      required
                    ></TextField>
                    {errors.email ? (
                      <p className="text-danger">{errors.email}</p>
                    ) : null}
                  </div>

                  <div className="mb-2">
                    <TextField
                      name="password"
                      margin="dense"
                      type="password"
                      placeholder="Password"
                      variant="outlined"
                      label="Password"
                      value={values.password}
                      onChange={handleChange}
                      fullWidth
                      required
                    ></TextField>
                    {errors.password ? (
                      <p className="text-danger">{errors.password}</p>
                    ) : null}
                  </div>
                  <Button
                    variant="contained"
                    className="bluebutton w-100"
                    type="submit"
                  >
                    Login
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Clerk2Login;
