import React from "react";
import SchemeTwoApprovedApplications from "../../Administration/SchemeTwo/SchemeTwoApprovedApplications";

const ClerkSchemeTwoApprovedApplications = () => {
  return (
    <div>
      <SchemeTwoApprovedApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeTwoApprovedApplications;
