import React from "react";
import SportSchemeTwoApprovedApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoApprovedApplications";

const SportShaskiySamiti2SchemetwoApprovedApplication = () => {
  return (
    <div>
      <SportSchemeTwoApprovedApplications role="Sport Shaskiy2" />
    </div>
  );
};

export default SportShaskiySamiti2SchemetwoApprovedApplication;
