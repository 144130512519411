import React from "react";
import SchemeTenPendingApplications from "../../Administration/SchemeTen/SchemeTenPendingApplications";

const ShaskiySamiti3SchemeTenPendingApplications = () => {
    return (
        <div>
            <SchemeTenPendingApplications role="Shaskiy Samiti3" />
        </div>
    );
};

export default ShaskiySamiti3SchemeTenPendingApplications;
