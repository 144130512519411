import React from 'react';
import SchemeSixTotalApplications from '../../Administration/SchemeSix/SchemeSixTotalApplications';

const Clerk2SchemeSixTotalApplications = () => {
  return (
    <div><SchemeSixTotalApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeSixTotalApplications;
