import React from "react";
import SchemeTenApprovedApplications from "../../Administration/SchemeTen/SchemeTenapprovedapplications";

const ClerkSchemeTenApprovedApplications = () => {
  return (
    <div>
      <SchemeTenApprovedApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeTenApprovedApplications;
