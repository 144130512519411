export const loginSportShaskiySamiti4 = (session) => {
  return {
    type: "LOGIN_SPORT_SHASKIY_SAMITI4",
    payload: session,
  };
};

export const logoutSportShaskiySamiti4 = () => {
  return {
    type: "LOGOUT_SPORT_SHASKIY_SAMITI4",
  };
};

export const setSportShaskiysamiti4Dashboard = (dashboard) => {
  return {
    type: "UPDATE_SPORT_SHASKIY4_DASHBOARD",
    payload: dashboard,
  };
};

export const clearSportShaskiysamiti4Dashboard = () => {
  return {
    type: "CLEAR_SPORT_SHASKIY4_DASHBOARD",
  };
};
