import React from "react";
import SchemeTenRejectedApplications from "../../Administration/SchemeTen/SchemeTenRejectedApplications";

const Chanani1SchemeTenRejectedApplications = () => {
    return (
        <div>
            <SchemeTenRejectedApplications role="Chanani Samiti1" />
        </div>
    );
};

export default Chanani1SchemeTenRejectedApplications;
