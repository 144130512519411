import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelectAccess } from "../store/stateFunctions";
import UserProfileSidebar from "../pages/profile/UserProfileSidebar";
import MyNavbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";
// import { Outlet } from "react-router-dom";

const UserContainer = () => {
  document.title = "User Profile";
  const user = useSelectAccess("User");

  return (
    <>
      <MyNavbar />
      <div className="row">
        <div className="col-md-3 text-center">
          <UserProfileSidebar role="User" />
        </div>
        <div className="col-md-9">
          <div className="container">
            {user ? <Outlet /> : <Navigate to="/login" />}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserContainer;
