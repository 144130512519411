import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../node_modules/bootstrap/dist/js/bootstrap.bundle"
import { HashRouter } from 'react-router-dom';
import { Provider } from "react-redux"
import store from './store';
import ConfigContextProvider from './configContext/ConfigContextProvider';
import i18n from './i18n';

import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //hash router
  <HashRouter>
    <ConfigContextProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigContextProvider>
  </HashRouter>
);

