import React, { useContext, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
  Typography,
  Alert,
} from "@mui/material";
import { Card, Col, Row } from "react-bootstrap";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import Grid from "@mui/material/Grid2";

import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { server } from "../../common";
import Loader from "../../Loader";
import { useSelectAccess } from "../../store/stateFunctions";
import { useNavigate } from "react-router-dom";
const swalalert = withReactContent(Swal);

const initialValues = {
  fullname: "",
  parent_name: "",
  family_annual_income: "",
  mobile: "",
  adhar_number: "",
  birth_date: "",
  address: "",
  division: "",
  ward_no: "",
  resident_duration_years: "",
  school_name: "",
  study_class: "",
  school_address: "",
  competition_name: "",
  // international_level: "",
  competition_address: "",
  comphost_agency: "",
  agency_address: "",
  sport_name: "",
  rank_hold: "",
  bankname: "",
  branch: "",
  ac_holder_name: "",
  account_number: "",
  ifsc_code: "",
  micr: "",

  disability_photo: null,
  upload_adhar: null,
  upload_pan: null,
  upload_passbook: null,
  association_declaration_certificate: null,
  cert_registration_certificate: null,
  guarantee_letter: null,
  proof1: null,
  proof2: null,
  paralympic_proficiency_certificate: null,
  self_declaration: null,
  self_attestation_declaration: null,
};

function SportSchemeThreeForm() {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const user = useSelectAccess("User");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const stepLabels = [
    t("schemeForms.stepLabels.personalInfo"),
    t("schemeForms.stepLabels.residentialInfo"),
    t("schemeForms.stepLabels.schoolInfo"),
    t(".Competition Info"),
    t("schemeForms.stepLabels.bankInfo"),
    t("schemeForms.stepLabels.uploadDocuments"),
  ];
  const steps = 5;

  const handleNext = () => {
    if (step < steps) setStep(step + 1);
  };

  const handleBack = () => {
    if (step > 0) setStep(step - 1);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      fullname: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Fullname should not contain numbers")
        .required("Fullname is required"),
      parent_name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Fullname should not contain numbers")
        .required("Parent Name is required"),
      mobile: Yup.string()
        .matches(/^[6-9]\d{9}$/, "कृपया वैध भारतीय मोबाइल नंबर प्रविष्ट करा.")
        .required("कृपया भारतीय मोबाइल नंबर प्रविष्ट करा."),
      adhar_number: Yup.string()
        .matches(/^\d{12}$/, "कृपया वैध 12-अंकी आधार क्रमांक प्रविष्ट करा.")
        .required("कृपया भारतीय आधार क्रमांक प्रविष्ट करा."),
      birth_date: Yup.string().required("कृपया जन्मतारीख प्रविष्ट करा."),
      address: Yup.string().required("कृपया संपूर्ण पत्ता  प्रविष्ट करा."),
      division: Yup.string().required("कृपया विभाग क्रमांक प्रविष्ट करा."),
      ward_no: Yup.string().required("कृपया प्रभाग क्रमांक प्रविष्ट करा."),
      resident_duration_years: Yup.string().required(
        "कृपया राहण्याचा कालावधी प्रविष्ट करा."
      ),
      family_annual_income: Yup.string().required(
        "कृपया राहण्याचा कालावधी प्रविष्ट करा."
      ),
      school_name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Schoolname should not contain numbers")
        .required("कृपया शाळेचे नाव प्रविष्ट करा."),
      study_class: Yup.string().required("कृपया शाळेचे नाव प्रविष्ट करा."),
      school_address: Yup.string().required(
        "कृपया शाळेचा संपूर्ण पत्ता  प्रविष्ट करा."
      ),
      competition_name: Yup.string().required(
        "कृपया शाळेचा संपूर्ण पत्ता  प्रविष्ट करा."
      ),
      competition_address: Yup.string().required(
        "कृपया शाळेचा संपूर्ण पत्ता  प्रविष्ट करा."
      ),
      comphost_agency: Yup.string().required(
        "कृपया शाळेचा संपूर्ण पत्ता  प्रविष्ट करा."
      ),
      agency_address: Yup.string().required(
        "कृपया शाळेचा संपूर्ण पत्ता  प्रविष्ट करा."
      ),
      sport_name: Yup.string().required(
        "कृपया शाळेचा संपूर्ण पत्ता  प्रविष्ट करा."
      ),
      rank_hold: Yup.string().required(
        "कृपया शाळेचा संपूर्ण पत्ता  प्रविष्ट करा."
      ),

      bankname: Yup.string().required("कृपया बँकेचे नाव प्रविष्ट करा."),
      branch: Yup.string().required("कृपया शाखेचे नाव प्रविष्ट करा."),
      ac_holder_name: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Fullname should not contain numbers")
        .required("कृपया खातेधारकाचे नाव प्रविष्ट करा."),
      ifsc_code: Yup.string().required("कृपया IFSC कोड प्रविष्ट करा."),
      micr: Yup.string().required("कृपया MICR कोड प्रविष्ट करा."),
      account_number: Yup.string()
        .required(t("schemeForms.account_no_required"))
        .min(9, t("schemeForms.account_no_too_short")) // Minimum length of 9
        .max(18, t("schemeForms.account_no_too_long")) // Maximum length of 18
        .matches(/^\d+$/, t("schemeForms.account_no_numeric")),
      disability_photo: Yup.mixed()
        .required("कृपया पासपोर्ट फोटो निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      upload_adhar: Yup.mixed()
        .required("कृपया आधार कार्डची प्रत निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      upload_pan: Yup.mixed()
        .required("कृपया पॅन कार्डची प्रत निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      upload_passbook: Yup.mixed()
        .required("कृपया बँक पासबुकची प्रत निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      association_declaration_certificate: Yup.mixed()
        .required("कृपया अपंगत्व प्रमाणपत्र निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      cert_registration_certificate: Yup.mixed()
        .required("कृपया जन्म किंवा वयाचा पुरावा निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      guarantee_letter: Yup.mixed()
        .required("कृपया वैद्यकीय अधिकाऱ्याचे पत्र निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      paralympic_proficiency_certificate: Yup.mixed()
        .required("कृपया वैद्यकीय अधिकाऱ्याचे पत्र निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      proof1: Yup.mixed()
        .required("कृपया पुरावा १ निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      proof2: Yup.mixed()
        .required("कृपया पुरावा २ निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
      self_declaration: Yup.mixed().test(
        "fileSize",
        "फाईल आकार खूप मोठा आहे.",
        (value) => {
          return value ? value.size <= 5000000 : true;
        }
      ),
      self_attestation_declaration: Yup.mixed()
        .required("कृपया स्व-प्रमाणित घोषणा निवडा.")
        .test("fileSize", "फाईल आकार खूप मोठा आहे.", (value) => {
          return value ? value.size <= 5000000 : true;
        }),
    }),

    onSubmit: (values, action) => {
      if (!values.upload_adhar) {
        toast.error("Please select an Aadhar Card image");
        return;
      }
      if (!values.upload_pan) {
        toast.error("Please select a upload_pancard image");
        return;
      }
      if (!values.upload_passbook) {
        toast.error("Please select a upload_bankpassbook image");
        return;
      }

      if (!values.paralympic_proficiency_certificate) {
        toast.error("Please select a birth_or_age_proof image");
        return;
      }

      if (!values.association_declaration_certificate) {
        toast.error("Please select a medical_officer_letter image");
        return;
      }

      if (!values.proof1) {
        toast.error("Please select a proof1 image");
        return;
      }

      // Create FormData and append values
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      swalalert
        .fire({
          title: "Are You Sure To Submit the Permission?",
          text: `Confirm Permission Submission?`,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          buttonsStyling: true,
        })
        .then((swalObject) => {
          setLoading(true);

          if (swalObject.isConfirmed) {
            server
              .post(`/sportthirdappl/addthirdsportappl`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: user.authToken,
                },
              })
              .then(function (response) {
                if (response.status === 200 || response.status === 201) {
                  swalalert.fire({
                    title: "Success!",
                    text: `Application Submitted Successfully`,
                    icon: "success",
                  });
                  formik.resetForm();
                  navigate("/");
                }
              })
              .catch((error) => {
                toast.error(
                  error.response?.data?.message ||
                    error.response?.data?.error ||
                    "Failed to connect to server"
                );
              })
              .finally(() => {
                setLoading(false); // Hide loader after API call
              });
          }
        });
    },
  });

  return (
    <>
      {loading && <Loader loading={loading} />}

      <div className="container">
        {console.log(formik.values)}
        {console.log(formik.errors)}

        <Alert severity="info" className="mt-2">
          {t("schemeForms.note")}
        </Alert>
        {/* Progress Bar Component */}
        <ProgressBar activeStep={step} stepLabels={stepLabels} />
        <Card className="mt-2">
          <Card.Header>
            <Typography variant="h5" gutterBottom style={{ marginTop: "10px" }}>
              {t("schemeForms.scheme_number_1")}
            </Typography>
          </Card.Header>

          <form style={{ padding: "20px" }}>
            {step === 0 && (
              <>
                <Typography variant="h6" gutterBottom>
                  {t("schemeForms.personal_info")}
                </Typography>
                <hr />
                <Row>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.full_name")}
                      required
                      variant="outlined"
                      name="fullname"
                      value={formik.values.fullname}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.fullname ? (
                      <p className="text-danger">{formik.errors.fullname}</p>
                    ) : null}
                  </Col>

                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("Parent full name")}
                      required
                      variant="outlined"
                      name="parent_name"
                      value={formik.values.parent_name}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.parent_name ? (
                      <p className="text-danger">{formik.errors.parent_name}</p>
                    ) : null}
                  </Col>

                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.dob")}
                      required
                      variant="outlined"
                      type="date"
                      name="birth_date"
                      value={formik.values.birth_date}
                      onChange={formik.handleChange}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        max: new Date().toISOString().substring(0, 10), // Restricts to today or earlier
                      }}
                    />
                    {formik.errors.birth_date ? (
                      <p className="text-danger">{formik.errors.birth_date}</p>
                    ) : null}
                  </Col>

                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.mobile_number")}
                      required
                      variant="outlined"
                      name="mobile"
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.mobile ? (
                      <p className="text-danger">{formik.errors.mobile}</p>
                    ) : null}
                  </Col>

                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.aadhaar_no")}
                      required
                      variant="outlined"
                      name="adhar_number"
                      value={formik.values.adhar_number}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.adhar_number ? (
                      <p className="text-danger">
                        {formik.errors.adhar_number}
                      </p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label="Family Annual Income"
                      required
                      variant="outlined"
                      name="family_annual_income"
                      value={formik.values.family_annual_income}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.family_annual_income ? (
                      <p className="text-danger">
                        {formik.errors.family_annual_income}
                      </p>
                    ) : null}
                  </Col>
                </Row>
              </>
            )}
            {step === 1 && (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "20px" }}
                >
                  {t("schemeForms.resident_info")}
                </Typography>
                <hr />
                <Row>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.address")}
                      required
                      variant="outlined"
                      name="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.address ? (
                      <p className="text-danger">{formik.errors.address}</p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-2">
                    <FormControl variant="outlined" fullWidth margin="normal">
                      <InputLabel id="demo-simple-select-label">
                        {t("schemeForms.division")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Division"
                        variant="outlined"
                        name="division"
                        value={formik.values.division}
                        onChange={formik.handleChange}
                        required
                      >
                        <MenuItem value="">
                          {t("schemeForms.selectDivision")}
                        </MenuItem>
                        <MenuItem value="Satpur">
                          {t("schemeForms.divisions.satpur")}
                        </MenuItem>
                        <MenuItem value="Cidco">
                          {t("schemeForms.divisions.cidco")}
                        </MenuItem>
                        <MenuItem value="nashikroad">
                          {t("schemeForms.divisions.nashikroad")}
                        </MenuItem>
                        <MenuItem value="nashikeast">
                          {t("schemeForms.divisions.nashikeast")}
                        </MenuItem>
                        <MenuItem value="nashikwest">
                          {t("schemeForms.divisions.nashikwest")}
                        </MenuItem>
                        <MenuItem value="panchavati">
                          {t("schemeForms.divisions.panchavati")}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {formik.errors.division ? (
                      <p className="text-danger">{formik.errors.division}</p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.ward")}
                      required
                      variant="outlined"
                      name="ward_no"
                      value={formik.values.ward_no}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.ward_no ? (
                      <p className="text-danger">{formik.errors.ward_no}</p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.years_resident")}
                      required
                      variant="outlined"
                      name="resident_duration_years"
                      value={formik.values.resident_duration_years}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.resident_duration_years ? (
                      <p className="text-danger">
                        {formik.errors.resident_duration_years}
                      </p>
                    ) : null}
                  </Col>
                </Row>
              </>
            )}
            {/* Step 3: School Information */}
            {step === 2 && (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "20px" }}
                >
                  {t("schemeForms.school_info")}
                </Typography>
                <hr />
                <Row>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.school_name")}
                      required
                      variant="outlined"
                      name="school_name"
                      value={formik.values.school_name}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.school_name ? (
                      <p className="text-danger">{formik.errors.school_name}</p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("study class")}
                      required
                      variant="outlined"
                      name="study_class"
                      value={formik.values.study_class}
                      onChange={formik.handleChange}
                    />
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.school_address")}
                      required
                      variant="outlined"
                      name="school_address"
                      value={formik.values.school_address}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.school_address ? (
                      <p className="text-danger">
                        {formik.errors.school_address}
                      </p>
                    ) : null}
                  </Col>
                </Row>
              </>
            )}

            {/* competition Information */}

            {step === 3 && (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "20px" }}
                >
                  {t("competition Info")}
                </Typography>
                <hr />

                {/* First Row */}
                <Row>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("competition name")}
                      required
                      variant="outlined"
                      name="competition_name"
                      value={formik.values.competition_name}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.competition_name ? (
                      <p className="text-danger">
                        {formik.errors.competition_name}
                      </p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("competition address")}
                      required
                      variant="outlined"
                      name="competition_address"
                      value={formik.values.competition_address}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.competition_address ? (
                      <p className="text-danger">
                        {formik.errors.competition_address}
                      </p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("competition hosting agency name")}
                      required
                      variant="outlined"
                      name="comphost_agency"
                      value={formik.values.comphost_agency}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.comphost_agency ? (
                      <p className="text-danger">
                        {formik.errors.comphost_agency}
                      </p>
                    ) : null}
                  </Col>
                </Row>

                {/* Second Row */}
                <Row>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("agency address")}
                      required
                      variant="outlined"
                      name="agency_address"
                      value={formik.values.agency_address}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.agency_address ? (
                      <p className="text-danger">
                        {formik.errors.agency_address}
                      </p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("participated sport name")}
                      required
                      variant="outlined"
                      name="sport_name"
                      value={formik.values.sport_name}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.sport_name ? (
                      <p className="text-danger">{formik.errors.sport_name}</p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("Rank you hold")}
                      required
                      variant="outlined"
                      name="rank_hold"
                      value={formik.values.rank_hold}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.rank_hold ? (
                      <p className="text-danger">{formik.errors.rank_hold}</p>
                    ) : null}
                  </Col>
                </Row>
              </>
            )}

            {/* Step 5: Bank Information */}
            {step === 4 && (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "20px" }}
                >
                  {t("schemeForms.bank_info")}
                </Typography>
                <hr />
                <Row>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.bank_name")}
                      required
                      variant="outlined"
                      name="bankname"
                      value={formik.values.bankname}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.bankname ? (
                      <p className="text-danger">{formik.errors.bankname}</p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.bank_branch")}
                      required
                      variant="outlined"
                      name="branch"
                      value={formik.values.branch}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.branch ? (
                      <p className="text-danger">{formik.errors.branch}</p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.account_holder_name")}
                      required
                      variant="outlined"
                      name="ac_holder_name"
                      value={formik.values.ac_holder_name}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.ac_holder_name ? (
                      <p className="text-danger">
                        {formik.errors.ac_holder_name}
                      </p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.account_number")}
                      required
                      variant="outlined"
                      name="account_number"
                      value={formik.values.account_number}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.account_number ? (
                      <p className="text-danger">
                        {formik.errors.account_number}
                      </p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.ifsc_code")}
                      required
                      variant="outlined"
                      name="ifsc_code"
                      value={formik.values.ifsc_code}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.ifsc_code ? (
                      <p className="text-danger">{formik.errors.ifsc_code}</p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.micr_code")}
                      required
                      variant="outlined"
                      name="micr"
                      value={formik.values.micr}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.micr ? (
                      <p className="text-danger">{formik.errors.micr}</p>
                    ) : null}
                  </Col>
                </Row>
              </>
            )}
            {/* Step 6: Document Upload */}
            {step === 5 && (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "20px" }}
                >
                  {t("schemeForms.upload_documents")}
                </Typography>
                <hr />
                <Row container spacing={2}>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.PassportPhoto")}
                      required
                      variant="outlined"
                      type="file"
                      name="disability_photo"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "disability_photo",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.disability_photo ? (
                      <p className="text-danger">
                        {formik.errors.disability_photo}
                      </p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.AadharCard")}
                      required
                      variant="outlined"
                      type="file"
                      name="upload_adhar"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "upload_adhar",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.upload_adhar ? (
                      <p className="text-danger">
                        {formik.errors.upload_adhar}
                      </p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.PanCard")}
                      required
                      variant="outlined"
                      type="file"
                      name="upload_pan"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "upload_pan",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.upload_pan ? (
                      <p className="text-danger">{formik.errors.upload_pan}</p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.BankPassbook")}
                      required
                      variant="outlined"
                      type="file"
                      name="upload_passbook"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "upload_passbook",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.upload_passbook ? (
                      <p className="text-danger">
                        {formik.errors.upload_passbook}
                      </p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label="Declaration letter of association / organization"
                      required
                      variant="outlined"
                      type="file"
                      name="association_declaration_certificate"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "association_declaration_certificate",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.association_declaration_certificate ? (
                      <p className="text-danger">
                        {formik.errors.association_declaration_certificate}
                      </p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label="Certificate of Proficiency in a Paralympic Recognized Event"
                      required
                      variant="outlined"
                      type="file"
                      name="paralympic_proficiency_certificate"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "paralympic_proficiency_certificate",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.paralympic_proficiency_certificate ? (
                      <p className="text-danger">
                        {formik.errors.paralympic_proficiency_certificate}
                      </p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label="Organization Registration Certificate"
                      required
                      variant="outlined"
                      type="file"
                      name="cert_registration_certificate"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "cert_registration_certificate",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.cert_registration_certificate ? (
                      <p className="text-danger">
                        {formik.errors.cert_registration_certificate}
                      </p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label="Letter of Guarantee Association / Institution"
                      required
                      variant="outlined"
                      type="file"
                      name="guarantee_letter"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "guarantee_letter",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.guarantee_letter ? (
                      <p className="text-danger">
                        {formik.errors.guarantee_letter}
                      </p>
                    ) : null}
                  </Col>

                  <Typography variant="h6" gutterBottom>
                    {t("schemeForms.Proof1")}
                  </Typography>
                  <hr />

                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      className="mb-0"
                      required
                      variant="outlined"
                      type="file"
                      name="proof1"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "proof1",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.proof1 ? (
                      <p className="text-danger">{formik.errors.proof1}</p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      type="file"
                      name="proof2"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "proof2",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.proof2 ? (
                      <p className="text-danger">{formik.errors.proof2}</p>
                    ) : null}
                  </Col>
                </Row>

                <Typography
                  variant="h6"
                  gutterBottom
                  style={{ marginTop: "20px" }}
                  className="mb-3"
                >
                  {t("schemeForms.DeclarationForm")}
                </Typography>
                <hr />
                <Row container spacing={2}>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.SelfDeclaration")}
                      required
                      variant="outlined"
                      type="file"
                      name="self_declaration"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "self_declaration",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.self_declaration ? (
                      <p className="text-danger">
                        {formik.errors.self_declaration}
                      </p>
                    ) : null}
                  </Col>
                  <Col md={4} className="mt-4">
                    <TextField
                      fullWidth
                      label={t("schemeForms.SelfAttestationDeclaration")}
                      required
                      variant="outlined"
                      type="file"
                      name="self_attestation_declaration"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "self_attestation_declaration",
                          event.currentTarget.files[0]
                        );
                      }}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        accept: ".pdf, .png, .jpg, .jpeg",
                      }}
                    />
                    {formik.errors.self_attestation_declaration ? (
                      <p className="text-danger">
                        {formik.errors.self_attestation_declaration}
                      </p>
                    ) : null}
                  </Col>
                </Row>
              </>
            )}

            {/* Navigation Buttons */}
            <Grid
              container
              justifyContent="space-between"
              style={{ marginTop: "20px" }}
            >
              <Button
                variant="contained"
                color="secondary"
                disabled={step === 0}
                onClick={handleBack}
              >
                {t("schemeForms.Back")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={step === steps ? formik.handleSubmit : handleNext}
                // disabled={step === steps}
              >
                {step === steps
                  ? t("schemeForms.Submit")
                  : t("schemeForms.Next")}
              </Button>
            </Grid>
          </form>
        </Card>
      </div>
    </>
  );
}

export default SportSchemeThreeForm;
