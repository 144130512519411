import React from 'react';
import SchemeSixTotalApplications from '../../Administration/SchemeSix/SchemeSixTotalApplications';

const Clerk3SchemeSixTotalApplications = () => {
  return (
    <div><SchemeSixTotalApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeSixTotalApplications;
