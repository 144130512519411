import React from "react";
import SchemeNineTotalApplications from "../../Administration/schemeNine/SchemeNineTotalApplications";

const AdditionalCommSchemeNineTotalApplications = () => {
  return (
    <div>
      <SchemeNineTotalApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeNineTotalApplications;
