import React from "react";
import ManageUser from "../Administration/ManageUser";

const SuperAdminManageUser = () => {
  return (
    <div>
      <ManageUser role="Super Admin" />
    </div>
  );
};

export default SuperAdminManageUser;
