import React from "react";
import SchemeOneApprovedApplications from "../../Administration/SchemeOne/SchemeOneApprovedApplications";

const Chanani2SchemeOneApprovedApplications = () => {
  return (
    <div>
      <SchemeOneApprovedApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeOneApprovedApplications;
