import React from "react";
import SchemeTwoRejectedApplications from "../../Administration/SchemeTwo/SchemeTwoRejectedApplications";

const Chanani4SchemeTwoRejectedApplications = () => {
    return (
        <div>
            <SchemeTwoRejectedApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeTwoRejectedApplications;
