import React from 'react';
import SchemeTwoRejectedApplications from '../../Administration/SchemeTwo/SchemeTwoRejectedApplications';

const Clerk3SchemeTwoRejectedApplications = () => {
  return (
    <div><SchemeTwoRejectedApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeTwoRejectedApplications;
