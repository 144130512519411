import React from "react";
import SchemeFourapprovedapplications from "../../Administration/SchemeFour/SchemeFourapprovedapplications";

const ShaskiySamiti4SchemeFourApprovedApplications = () => {
    return (
        <div>
            <SchemeFourapprovedapplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeFourApprovedApplications;
