import React from "react";
import SchemeFourPendingApplications from "../../Administration/SchemeFour/SchemeFourPendingApplications";

const ShaskiySamiti1SchemeFourPendingApplications = () => {
    return (
        <div>
            <SchemeFourPendingApplications role="Shaskiy Samiti1" />
        </div>
    );
};

export default ShaskiySamiti1SchemeFourPendingApplications;
