import React from "react";
import SchemeFiveRejectedApplications from "../../Administration/SchemeFive/SchemeFiveRejectedApplications";

const Chanani2SchemeFiveRejectedApplications = () => {
  return (
    <div>
      <SchemeFiveRejectedApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeFiveRejectedApplications;
