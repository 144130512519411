import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelectAccess } from "../store/stateFunctions";
import UserProfileSidebar from "../pages/profile/UserProfileSidebar";
import MyNavbar from "../Components/Navbar/Navbar";
import Footer from "../Components/Footer/Footer";

const SchemeContainer = () => {
  document.title = "User Profile";
  const user = useSelectAccess("User");

  // Redirect to login if no user is found
  if (!user) {
    return (
      <>
        <Navigate to="/login" replace />
      </>
    );
  }

  return (
    <>
      <MyNavbar />
      <div className="row">
        <div className="container">
          <Outlet /> {/* Renders the nested routes */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SchemeContainer;
