import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Card, CardContent, Divider, Button } from "@mui/material";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    // <div>
    //   <div className="container">
    //     <div className="text-center mt-4">
    //       <h4>{t("aboutUs.title")}</h4>
    //     </div>
    //     <div className="mt-4">
    //       <p>{t("aboutUs.intro")}</p>
    //       <p>{t("aboutUs.objective")}</p>
    //       <p>{t("aboutUs.informationalHub")}</p>
    //       <p>{t("aboutUs.commitment")}</p>
    //     </div>
    //   </div>
    // </div>
    <div className="container mt-4">
      <Card>
        <CardContent>
          <Typography
            variant="h5"
            style={{ fontWeight: "bold", textAlign: "center" }}
          >
            <h4>{t("aboutUs.title")}</h4>
          </Typography>
          <hr/>
          <Divider  />
          <p>{t("aboutUs.intro")}</p>
          <p>{t("aboutUs.objective")}</p>
          <p>{t("aboutUs.informationalHub")}</p>
          <p>{t("aboutUs.commitment")}</p>
        </CardContent>
      </Card>
    </div>
  );
};

export default AboutUs;
