import React from "react";
import Dashboard from "../../Administration/dashboard/TableDashboard/Dashboard";

const SportChananiSamiti1Dashboard = () => {
  return (
    <>
      <h1>
        <Dashboard role="Sport Chanani1" />
      </h1>
    </>
  );
};

export default SportChananiSamiti1Dashboard;
