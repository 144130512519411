import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Table, FormControl, InputGroup } from "react-bootstrap";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelectAccess } from "../../store/stateFunctions";
import { toast } from "react-toastify";

function SportsFormsTable() {
  const { t } = useTranslation();
  const user = useSelectAccess("User");
  const isLoggedIn = user && user;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState(""); // Add search state

  const handleApplyNowClick = (e) => {
    if (!isLoggedIn) {
      e.preventDefault();
      toast.info("Please log in to apply for the Scheme");
      navigate("/login");
    } else {
      setShow(true);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="container mt-4">
<h2 className="text-center" style={{ fontWeight: "700" }}>{t('sportschemetable.header')}</h2>
<InputGroup className="my-3">
  <FormControl
    type="text"
    placeholder={t('searchPlaceholder')}
    value={searchQuery}
    onChange={handleSearchChange}
  />
  <Button variant="contained">{t('sportschemetable.searchPlaceholder')}</Button>
</InputGroup>

      <Table striped bordered hover responsive="md" className="mt-4">
        <thead>
          <tr>
            <th style={{ width: "50px", textAlign: "center" }}>अनु क्र.</th>
            <th>{t('sportschemetable.scheme')}</th>
            <th style={{ textAlign: "center", verticalAlign: "middle" }}>{t('sportschemetable.applyNow')}</th>
            <th style={{ textAlign: "center", verticalAlign: "middle" }}>{t('sportschemetable.viewDetails')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ width: "50px", textAlign: "center" }}>1</td>
            <td style={{ textAlign: "left" }}>{t('sportschemetable.internationalScheme')}</td>
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <NavLink to="/schemeform" onClick={handleApplyNowClick}>
                <Button variant="contained" className="bluebutton">{t('sportschemetable.applyNow')}</Button>
              </NavLink>
            </td>
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <NavLink to="/sportschemeonedetails">
                <Button variant="contained" className="redbutton">{t('sportschemetable.viewDetails')}</Button>
              </NavLink>
            </td>
          </tr>
          <tr>
            <td style={{ width: "50px", textAlign: "center" }}>2</td>
            <td style={{ textAlign: "left" }}>{t('sportschemetable.nationalScheme')}</td>
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <NavLink to="/sportschemetwoform" onClick={handleApplyNowClick}>
                <Button variant="contained" className="bluebutton">{t('sportschemetable.applyNow')}</Button>
              </NavLink>
            </td>
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <NavLink to="/sportschemetwodetails">
                <Button variant="contained" className="redbutton">{t('sportschemetable.viewDetails')}</Button>
              </NavLink>
            </td>
          </tr>
          <tr>
            <td style={{ width: "50px", textAlign: "center" }}>3</td>
            <td style={{ textAlign: "left" }}>{t('sportschemetable.stateScheme')}</td>
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <NavLink to="/sportschemethreeform" onClick={handleApplyNowClick}>
                <Button variant="contained" className="bluebutton">{t('sportschemetable.applyNow')}</Button>
              </NavLink>
            </td>
            <td style={{ textAlign: "center", verticalAlign: "middle" }}>
              <NavLink to="/sportschemethreedetails">
                <Button variant="contained" className="redbutton">{t('sportschemetable.viewDetails')}</Button>
              </NavLink>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default SportsFormsTable;
