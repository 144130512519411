import React from "react";
import SportSchemeOneTotalApplications from "../../../Administration/SportSchemeOne/SportSchemeOneTotalApplications";

const SportShaskiySamiti4SchemeOneTotalApplications = () => {
  return (
    <div>
      <SportSchemeOneTotalApplications role="Sport Shaskiy4" />
    </div>
  );
};

export default SportShaskiySamiti4SchemeOneTotalApplications;
