import React from 'react';
import SchemeFourPendingApplications from '../../Administration/SchemeFour/SchemeFourPendingApplications';

const Clerk2SchemeFourPendingApplications = () => {
  return (
    <div><SchemeFourPendingApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeFourPendingApplications;
