import React from "react";
import SchemeSixPendingApplications from "../../Administration/SchemeSix/SchemeSixPendingApplications";

const ShaskiySamiti4SchemeSixPendingApplications = () => {
    return (
        <div>
            <SchemeSixPendingApplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeSixPendingApplications;
