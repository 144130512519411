import React from "react";
import SchemeFiveTotalApplications from "../../Administration/SchemeFive/SchemeFiveTotalApplications";

const ShaskiySamiti4SchemeFiveTotalApplications = () => {
    return (
        <div>
            <SchemeFiveTotalApplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeFiveTotalApplications;
