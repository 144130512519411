import React from "react";
import SchemeOnePendingApplications from "../../Administration/SchemeOne/SchemeOnePendingApplications";

const ClerkSchemeOnePendingApplications = () => {
  return (
    <div>
      <SchemeOnePendingApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeOnePendingApplications;
