import React from "react";
import SchemeFiveTotalApplications from "../../Administration/SchemeFive/SchemeFiveTotalApplications";

const Chanani1SchemeFiveTotalApplications = () => {
  return (
    <div>
      <SchemeFiveTotalApplications role="Chanani Samiti1" />
    </div>
  );
};

export default Chanani1SchemeFiveTotalApplications;
