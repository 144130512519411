import React from "react";
import SchemeThreeRejectedApplications from "../../Administration/SchemeThree/SchemeThreeRejectedApplications";

const SuperAdminSchemeThreeRejectedApplications = () => {
  return (
    <div>
      <SchemeThreeRejectedApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeThreeRejectedApplications;
