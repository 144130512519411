// import React from "react";
// import { useSelector } from "react-redux";
// import { Navigate, Outlet } from "react-router-dom";
// import { useSelectAccess } from "../store/stateFunctions";
// import SportChananiSamiti1Sidebar from "../SportAdminUsers/chananisamiti1/SportChananiSamiti1Sidebar";
// import SportChananiSamiti2Sidebar from "../SportAdminUsers/chananisamiti2/SportChananiSamiti2Sidebar";

// const SportChananiSamiti2Container = () => {
//   document.title = "Chanani Samiti";
//   const chananisamiti2 = useSelectAccess("Sport Chanani2");

//   return (
//     <>
//       <div className="row ">
//         <SportChananiSamiti2Sidebar role="Sport Chanani2" />
//         <div className="container">
//           {chananisamiti2 ? <Outlet /> : <Navigate to="/sport-chanani2" />}
//           {/* <Outlet /> */}
//         </div>
//       </div>
//     </>
//   );
// };

// export default SportChananiSamiti2Container;

import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { updateDashboardState, useSelectAccess } from "../store/stateFunctions";
import SportChananiSamiti1Sidebar from "../SportAdminUsers/chananisamiti1/SportChananiSamiti1Sidebar";
import SportChananiSamiti2Sidebar from "../SportAdminUsers/chananisamiti2/SportChananiSamiti2Sidebar";
import configContext from "../configContext/configContext";
import { server } from "../common";

const SportChananiSamiti2Container = () => {
  document.title = "Chanani Samiti";
  const sportchanani1 = useSelectAccess("Sport Chanani2");

  const navigate = useNavigate();
  const location = useLocation();
  const { setProgress } = useContext(configContext);
  const [processing, setProcessing] = useState(false);
  const fetched = useRef(false);
  const setFetched = (value) => (fetched.current = value);

  useEffect(() => {
    // Navigate to "/shaskiy1" if shaskiy1 access is not available
    if (!sportchanani1) {
      navigate("/sport-chanani2", {
        state: { from: location },
        replace: true,
      });
      return;
    }

    // If chananisamiti2 is valid, fetch admin profile
    setProcessing(true);
    setProgress(10);
    server
      .get("/sportadmin/sportadminprofile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: sportchanani1.authToken,
        },
      })
      .then((response) => {
        updateDashboardState("Sport Chanani2", response.data);
        setProgress(100);
        setProcessing(false);
        setFetched(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          navigate("/sport-chanani2", {
            state: { from: location },
            replace: true,
          });
        } else {
          setProgress(100);
          setProcessing(false);
          console.error(
            "error",
            error.message,
            error.response?.status,
            error.response?.data,
            error
          );
        }
      });

    document.title = "Chanani Samiti Dashboard";
  }, [sportchanani1, navigate, location, setProgress]);

  return (
    <>
      <div className="row ">
        <SportChananiSamiti2Sidebar role="Sport Chanani2" />
        <div className="container">
          {sportchanani1 ? <Outlet /> : <Navigate to="/sport-chanani2" />}
          {/* <Outlet /> */}
        </div>
      </div>
    </>
  );
};

export default SportChananiSamiti2Container;
