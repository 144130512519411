import React from "react";
import SchemeTenTotalApplications from "../../Administration/SchemeTen/SchemeTenTotalApplications";

const AdditionalCommSchemeTenTotalApplications = () => {
  return (
    <div>
      <SchemeTenTotalApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeTenTotalApplications;
