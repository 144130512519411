import React from "react";
import SportSchemeThreeApprovedApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeApprovedApplications";

const SportShaskiySamiti5SchemeThreeApprovedApplications = () => {
  return (
    <div>
      <SportSchemeThreeApprovedApplications role="Sport Shaskiy5" />
    </div>
  );
};

export default SportShaskiySamiti5SchemeThreeApprovedApplications;
