import React from "react";
import SchemeEightTotalApplications from "../../Administration/SchemeEight/SchemeEightTotalApplications";

const ShaskiySamiti2SchemeEightTotalApplications = () => {
    return (
        <div>
            <SchemeEightTotalApplications role="Shaskiy Samiti2" />
        </div>
    );
};

export default ShaskiySamiti2SchemeEightTotalApplications;
