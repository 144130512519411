import React from "react";
import SchemeOnePendingApplications from "../../Administration/SchemeOne/SchemeOnePendingApplications";

const Chanani2SchemeOnePendingApplications = () => {
    return (
        <div>
            <SchemeOnePendingApplications role="Chanani Samiti2" />
        </div>
    );
};

export default Chanani2SchemeOnePendingApplications;
