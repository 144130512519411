import React from "react";
import SchemeSevenPendingApplications from "../../Administration/SchemeSeven/SchemeSevenPendingApplications";

const ClerkSchemeSevenPendingApplications = () => {
  return (
    <div>
      <SchemeSevenPendingApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeSevenPendingApplications;
