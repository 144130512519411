import React from "react";
import SchemeSevenPendingApplications from "../../Administration/SchemeSeven/SchemeSevenPendingApplications";

const ShaskiySamiti2SchemeSevenPendingApplications = () => {
    return (
        <div>
            <SchemeSevenPendingApplications role="Shaskiy Samiti2" />
        </div>
    );
};

export default ShaskiySamiti2SchemeSevenPendingApplications;
