import React from "react";
import SchemeOneRejectedApplications from "../../Administration/SchemeOne/SchemeOneRejectedApplications";

const Chanani2SchemeOneRejectedApplications = () => {
  return (
    <div>
      <SchemeOneRejectedApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeOneRejectedApplications;
