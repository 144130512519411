import React from "react";
import SchemeOneTotalApplications from "../../Administration/SchemeOne/SchemeOneTotalApplications";

const Chanani2SchemeOneTotalApplications = () => {
  return (
    <div>
      <SchemeOneTotalApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeOneTotalApplications;
