import React from "react";
import SchemeThreePendingApplications from "../../Administration/SchemeThree/SchemeThreePendingApplications";

const AdditionalCommSchemeThreePendingApplications = () => {
  return (
    <div>
      <SchemeThreePendingApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeThreePendingApplications;
