import React from "react";
import AddSlider from "../Administration/AddSlider";

const ClerkAddSlider = () => {
  return (
    <div>
      <AddSlider />
    </div>
  );
};

export default ClerkAddSlider;
