import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
  Typography,
  Alert,
  TableRow,
  TableContainer,
  Table,
  TableBody,
  TableCell,
} from "@mui/material";
import { Card, Col, Row } from "react-bootstrap";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import Grid from "@mui/material/Grid2";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios, { AxiosError } from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { server } from "../../common";
import Loader from "../../Loader";
import { useSelectAccess } from "../../store/stateFunctions";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import doc1 from "../../assets/doc/declaration1.pdf";
import doc2 from "../../assets/doc/selfdeclaration1.pdf";
const swalalert = withReactContent(Swal);

const initialValues = {
  Fullname: "",
  mobile: "",
  adhar_number: "",
  dob: "",
  address: "",
  division: "",
  ward_no: "",
  resident_period: "",
  disability_type: "",
  pancard: "",
  education_eligibility: "",
  family_annualincome: "",
  family_business_job: "",
  competition_name: "",
  rank_number: "",
  rank_game: "",
  Fathername: "",
  Mothername: "",

  bankname: "",
  branch: "",
  ac_holder_name: "",
  account_no: "",
  ifsc_code: "",
  micr_code: "",

  passport_photo: null,
  upload_adhar: null,
  upload_pancard: null,
  upload_bankpassbook: null,
  Certificate_of_Proficiency_Sports: null,

  certificate_of_disability: null,
  birth_or_age_proof: null,
  proof1: null,
  proof2: null,

  self_declaration: null,
  self_attestation_declaration: null,
};

const SchemeTenEdit = () => {
  const [loading, setLoading] = useState(false);
  const user = useSelectAccess("User");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const formik = useFormik({
    initialValues: initialValues, // Ensure this is defined
    validationSchema: Yup.object({
      Fullname: Yup.string().required(t("schemeForms.name_required")),
      mobile: Yup.string()
        .matches(/^\d{10}$/, t("schemeForms.mobile_number_validation")) // Assuming mobile number is 10 digits
        .required(t("schemeForms.mobile_number_required")),
      adhar_number: Yup.string()
        .required(t("schemeForms.adhar_required"))
        .matches(/^\d{12}$/, t("schemeForms.adhar_invalid")),
      dob: Yup.date()
        .required(t("schemeForms.bod_required"))
        .max(new Date(), t("schemeForms.futuredob")),

      address: Yup.string().required(t("schemeForms.address_required")),
      division: Yup.string().required(t("schemeForms.division_required")),
      Fathername: Yup.string().required(t("schemeForms.fathers_name_required")),
      Mothername: Yup.string().required(t("schemeForms.mothers_name_required")),
      family_annualincome: Yup.number()
        .required(t("schemeForms.family_annual_income_required"))
        .min(0, t("schemeForms.income_vaidation_0")),
      family_business_job: Yup.string().required(
        t("schemeForms.family_job_info_required")
      ),
      ward_no: Yup.number()
        .max(300, t("schemeForms.division_required"))
        .required(t("schemeForms.division_required")),
      resident_period: Yup.number()
        .typeError(t("schemeForms.resident_duration"))
        .max(99, t("schemeForms.resident_duration_10")) // Sets the maximum to 2-digit input (up to 99 years)
        .required(t("schemeForms.resident_duration_required")),
      competition_name: Yup.string().required(
        t("schemeForms.compitition_name_required")
      ),
      education_eligibility: Yup.string().required(
        t("schemeForms.applicant_educational_qualification_required")
      ),
      disability_type: Yup.string().required(
        t("schemeForms.type_of_disabilty_required")
      ),
      pancard: Yup.string()
        .required(t("schemeForms.pan_required"))
        .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, t("schemeForms.pancardnumber")),

      rank_game: Yup.string().required(t("schemeForms.sports_info")),
      rank_number: Yup.string()
        .required(t("schemeForms.proficiency"))
        .matches(/^\d+$/, t("schemeForms.income_vaidation_0")),

      bankname: Yup.string().required(t("schemeForms.bankname_required")),
      branch: Yup.string().required(t("schemeForms.branch_required")),
      ac_holder_name: Yup.string().required(
        t("schemeForms.ac_holder_name_required")
      ),
      ifsc_code: Yup.string().required(t("schemeForms.ifsc_required")),
      micr_code: Yup.string().required(t("schemeForms.micr_required")),
      account_no: Yup.string()
        .required(t("schemeForms.ac_holder_name_required"))
        .matches(/^\d{9,18}$/, t("schemeForms.account_number_validation")),

      passport_photo: Yup.mixed(),
      upload_adhar: Yup.mixed(),
      upload_pancard: Yup.mixed(),
      upload_bankpassbook: Yup.mixed(),
      certificate_of_disability: Yup.mixed(),
      birth_or_age_proof: Yup.mixed(),
      proof1: Yup.mixed(),
      proof2: Yup.mixed(),
      self_declaration: Yup.mixed(),
      self_attestation_declaration: Yup.mixed(),
      Certificate_of_Proficiency_Sports: Yup.mixed(),
    }),

    onSubmit: (values, action) => {
      setLoading(true);
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (values[key]) {
          // Append only if a value exists
          formData.append(key, values[key]);
        }
      });

      server
        .put(`/tenappl/updatetenappl/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: user.authToken,
          },
        })
        .then(function (response) {
          if (response.status === 200 || response.status === 201) {
            swalalert.fire({
              title: "Success!",
              text: `Application Submitted Successfully.`,
              icon: "success",
            });
            formik.resetForm();
            navigate("/");
          }
        })
        .catch((error) => {
          toast.error(
            error.response?.data?.message ||
              error.response?.data?.error ||
              "Failed to connect to server"
          );
        })
        .finally(() => {
          setLoading(false); // Hide loader after API call
        });
    },
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    server
      .get(`/tenappl/tenapplication/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          formik.setValues(response.data);
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else {
          toast.error(error.response?.data?.error || "An error occurred");
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {console.log(formik.values)}
      {console.log(formik.errors)}
      {loading && <Loader loading={loading} />}
      <div className="container">
        <Alert severity="info" className="mt-2">
          {t("schemeForms.note")}
        </Alert>

        <Card className="mt-2">
          <Card.Header>
            <Typography variant="h5" gutterBottom style={{ marginTop: "10px" }}>
              {t("schemeForms.scheme_number_10")}
            </Typography>
          </Card.Header>

          <form
            style={{ padding: "20px" }}
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <Typography variant="h6" gutterBottom>
              {t("schemeForms.personal_info")}
            </Typography>
            <hr />
            <Row container spacing={2}>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.Applicants_full_name")}
                  required
                  variant="outlined"
                  name="Fullname"
                  onChange={formik.handleChange}
                  value={formik.values.Fullname}
                />
                {formik.errors.Fullname ? (
                  <p className="text-danger">{formik.errors.Fullname}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.fathers_or_husband_name")}
                  required
                  variant="outlined"
                  name="Fathername"
                  onChange={formik.handleChange}
                  value={formik.values.Fathername}
                />
                {formik.errors.Fathername ? (
                  <p className="text-danger">{formik.errors.Fathername}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.mothers_name")}
                  required
                  variant="outlined"
                  name="Mothername"
                  onChange={formik.handleChange}
                  value={formik.values.Mothername}
                />
                {formik.errors.Mothername ? (
                  <p className="text-danger">{formik.errors.Mothername}</p>
                ) : null}
              </Col>

              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.dob")}
                  required
                  variant="outlined"
                  type="date"
                  name="dob"
                  value={formik.values.dob}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: new Date().toISOString().substring(0, 10), // Restricts to today or earlier
                  }}
                />
                {formik.errors.dob ? (
                  <p className="text-danger">{formik.errors.dob}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.type_of_disabilty")}
                  required
                  variant="outlined"
                  name="disability_type"
                  onChange={formik.handleChange}
                  value={formik.values.disability_type}
                />
                {formik.errors.disability_type ? (
                  <p className="text-danger">{formik.errors.disability_type}</p>
                ) : null}
              </Col>

              <Col md={4} className="mt-4">
                <TextField
                  label="Mobile No."
                  variant="outlined"
                  fullWidth
                  name="mobile"
                  value={formik.values.mobile}
                  onChange={(e) => {
                    // Allow only digits and limit to 10 characters
                    const mobile = e.target.value
                      .replace(/\D/g, "")
                      .slice(0, 10);
                    formik.setFieldValue("mobile", mobile);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                  }}
                  required
                />
                {formik.errors.mobile ? (
                  <p className="text-danger">{formik.errors.mobile}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.aadhaar_no")}
                  required
                  variant="outlined"
                  name="adhar_number"
                  onChange={(e) => {
                    // Allow only digits and limit to 12 characters
                    const aadhaar = e.target.value
                      .replace(/\D/g, "")
                      .slice(0, 12);
                    formik.setFieldValue("adhar_number", aadhaar);
                  }}
                  value={formik.values.adhar_number}
                />
                {formik.errors.adhar_number ? (
                  <p className="text-danger">{formik.errors.adhar_number}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.Pan_card_number")}
                  required
                  variant="outlined"
                  name="pancard"
                  onChange={(e) => {
                    // Allow only alphanumeric characters and limit to 10 characters
                    const pan = e.target.value
                      .replace(/[^a-zA-Z0-9]/g, "")
                      .slice(0, 10);
                    formik.setFieldValue("pancard", pan);
                  }}
                  value={formik.values.pancard}
                />
                {formik.errors.pancard ? (
                  <p className="text-danger">{formik.errors.pancard}</p>
                ) : null}
              </Col>

              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.family_income")}
                  required
                  variant="outlined"
                  name="family_annualincome"
                  onChange={formik.handleChange}
                  value={formik.values.family_annualincome}
                />
                {formik.errors.family_annualincome ? (
                  <p className="text-danger">
                    {formik.errors.family_annualincome}
                  </p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.family_occupation")}
                  required
                  variant="outlined"
                  name="family_business_job"
                  onChange={formik.handleChange}
                  value={formik.values.family_business_job}
                />
                {formik.errors.family_business_job ? (
                  <p className="text-danger">
                    {formik.errors.family_business_job}
                  </p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.proficiency_game")}
                  required
                  variant="outlined"
                  name="rank_game"
                  onChange={formik.handleChange}
                  value={formik.values.rank_game}
                />
                {formik.errors.rank_game ? (
                  <p className="text-danger">{formik.errors.rank_game}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.proficiency")}
                  required
                  variant="outlined"
                  name="rank_number"
                  onChange={formik.handleChange}
                  value={formik.values.rank_number}
                />
                {formik.errors.rank_number ? (
                  <p className="text-danger">{formik.errors.rank_number}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.compitition_name")}
                  required
                  variant="outlined"
                  name="competition_name"
                  onChange={formik.handleChange}
                  value={formik.values.competition_name}
                />
                {formik.errors.competition_name ? (
                  <p className="text-danger">
                    {formik.errors.competition_name}
                  </p>
                ) : null}
              </Col>
            </Row>
            <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
              {t("schemeForms.resident_info")}
            </Typography>
            <hr />
            <Row container spacing={2}>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.address")}
                  required
                  variant="outlined"
                  name="address"
                  onChange={formik.handleChange}
                  value={formik.values.address}
                />
                {formik.errors.address ? (
                  <p className="text-danger">{formik.errors.address}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {t("schemeForms.division")}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Division"
                    variant="outlined"
                    name="division"
                    value={formik.values.division}
                    onChange={formik.handleChange}
                    required
                  >
                    <MenuItem value="">
                      <em> {t("schemeForms.selectDivision")}</em>
                    </MenuItem>
                    <MenuItem value="satpur">
                      <em>{t("schemeForms.divisions.satpur")}</em>
                    </MenuItem>
                    <MenuItem value="cidco">
                      <em>{t("schemeForms.divisions.cidco")}</em>
                    </MenuItem>
                    <MenuItem value="nashikroad">
                      <em>{t("schemeForms.divisions.nashikroad")} </em>
                    </MenuItem>
                    <MenuItem value="nashikeast">
                      <em> {t("schemeForms.divisions.nashikeast")} </em>
                    </MenuItem>
                    <MenuItem value="nashikwest">
                      <em> {t("schemeForms.divisions.nashikwest")} </em>
                    </MenuItem>
                    <MenuItem value="panchavati">
                      <em>{t("schemeForms.divisions.Panchvati")}</em>
                    </MenuItem>
                  </Select>
                </FormControl>
                {formik.errors.division ? (
                  <p className="text-danger">{formik.errors.division}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.ward")}
                  required
                  variant="outlined"
                  name="ward_no"
                  onChange={formik.handleChange}
                  value={formik.values.ward_no}
                />
                {formik.errors.ward_no ? (
                  <p className="text-danger">{formik.errors.ward_no}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.years_resident")}
                  required
                  variant="outlined"
                  name="resident_period"
                  onChange={formik.handleChange}
                  value={formik.values.resident_period}
                />
                {formik.errors.resident_period ? (
                  <p className="text-danger">{formik.errors.resident_period}</p>
                ) : null}
              </Col>
            </Row>
            {/* Step 3: School Information */}
            <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
              {t("schemeForms.school_info")}
            </Typography>
            <hr />
            <Row container spacing={2}>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.educational_qualification")}
                  required
                  variant="outlined"
                  name="education_eligibility"
                  sx={{ width: "400" }}
                  onChange={formik.handleChange}
                  value={formik.values.education_eligibility}
                />{" "}
                {formik.errors.education_eligibility ? (
                  <p className="text-danger">
                    {formik.errors.education_eligibility}
                  </p>
                ) : null}
              </Col>
            </Row>

            {/* Step 5: Bank Information */}
            <Typography variant="h6" gutterBottom style={{ marginTop: "20px" }}>
              {t("schemeForms.bank_info")}
            </Typography>
            <hr />
            <Row container spacing={2}>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.bank_name")}
                  required
                  variant="outlined"
                  name="bankname"
                  onChange={formik.handleChange}
                  value={formik.values.bankname}
                />
                {formik.errors.bankname ? (
                  <p className="text-danger">{formik.errors.bankname}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.bank_branch")}
                  required
                  variant="outlined"
                  name="branch"
                  onChange={formik.handleChange}
                  value={formik.values.branch}
                />
                {formik.errors.branch ? (
                  <p className="text-danger">{formik.errors.branch}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.account_holder_name")}
                  required
                  variant="outlined"
                  name="ac_holder_name"
                  onChange={formik.handleChange}
                  value={formik.values.ac_holder_name}
                />
                {formik.errors.ac_holder_name ? (
                  <p className="text-danger">{formik.errors.ac_holder_name}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.account_number")}
                  required
                  variant="outlined"
                  name="account_no"
                  onChange={formik.handleChange}
                  value={formik.values.account_no}
                />
                {formik.errors.account_no ? (
                  <p className="text-danger">{formik.errors.account_no}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.ifsc_code")}
                  required
                  variant="outlined"
                  name="ifsc_code"
                  onChange={formik.handleChange}
                  value={formik.values.ifsc_code}
                />
                {formik.errors.ifsc_code ? (
                  <p className="text-danger">{formik.errors.ifsc_code}</p>
                ) : null}
              </Col>
              <Col md={4} className="mt-4">
                <TextField
                  fullWidth
                  label={t("schemeForms.micr_code")}
                  required
                  variant="outlined"
                  name="micr_code"
                  onChange={formik.handleChange}
                  value={formik.values.micr_code}
                />
                {formik.errors.micr_code ? (
                  <p className="text-danger">{formik.errors.micr_code}</p>
                ) : null}
              </Col>
            </Row>

            <TableContainer>
              <Table>
                <TableBody>
                  {/* Step 6: Document Upload */}
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ marginTop: "20px" }}
                  >
                    {t("schemeForms.upload_documents")}
                  </Typography>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField
                        fullWidth
                        label={t("schemeForms.PassportPhoto")}
                        required
                        variant="outlined"
                        name="passport_photo"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "passport_photo",
                            event.currentTarget.files[0]
                          );
                        }}
                        type="file"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          accept: ".pdf, .png, .jpg, .jpeg",
                        }}
                      />
                      {formik.errors.passport_photo ? (
                        <p className="text-danger">
                          {formik.errors.passport_photo}
                        </p>
                      ) : null}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.passport_photo}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <TextField
                        fullWidth
                        label={t("schemeForms.AadharCard")}
                        required
                        variant="outlined"
                        name="upload_adhar"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "upload_adhar",
                            event.currentTarget.files[0]
                          );
                        }}
                        type="file"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          accept: ".pdf, .png, .jpg, .jpeg",
                        }}
                      />
                      {formik.errors.upload_adhar ? (
                        <p className="text-danger">
                          {formik.errors.upload_adhar}
                        </p>
                      ) : null}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.passport_photo}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField
                        fullWidth
                        label={t("schemeForms.PanCard")}
                        required
                        variant="outlined"
                        name="upload_pancard"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "upload_pancard",
                            event.currentTarget.files[0]
                          );
                        }}
                        type="file"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          accept: ".pdf, .png, .jpg, .jpeg",
                        }}
                      />
                      {formik.errors.upload_pancard ? (
                        <p className="text-danger">
                          {formik.errors.upload_pancard}
                        </p>
                      ) : null}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.passport_photo}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <TextField
                        fullWidth
                        label={t("schemeForms.BankPassbook")}
                        required
                        variant="outlined"
                        name="upload_bankpassbook"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "upload_bankpassbook",
                            event.currentTarget.files[0]
                          );
                        }}
                        type="file"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          accept: ".pdf, .png, .jpg, .jpeg",
                        }}
                      />
                      {formik.errors.upload_bankpassbook ? (
                        <p className="text-danger">
                          {formik.errors.upload_bankpassbook}
                        </p>
                      ) : null}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.passport_photo}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField
                        fullWidth
                        label={t("schemeForms.disability_certificate")}
                        required
                        variant="outlined"
                        name="certificate_of_disability"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "certificate_of_disability",
                            event.currentTarget.files[0]
                          );
                        }}
                        type="file"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          accept: ".pdf, .png, .jpg, .jpeg",
                        }}
                      />
                      {formik.errors.certificate_of_disability ? (
                        <p className="text-danger">
                          {formik.errors.certificate_of_disability}
                        </p>
                      ) : null}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.passport_photo}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <TextField
                        fullWidth
                        label={t("schemeForms.BirthProof")}
                        required
                        variant="outlined"
                        name="birth_or_age_proof"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "birth_or_age_proof",
                            event.currentTarget.files[0]
                          );
                        }}
                        type="file"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          accept: ".pdf, .png, .jpg, .jpeg",
                        }}
                      />
                      {formik.errors.birth_or_age_proof ? (
                        <p className="text-danger">
                          {formik.errors.birth_or_age_proof}
                        </p>
                      ) : null}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.passport_photo}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField
                        fullWidth
                        label="प्राविण्य मिळविलेल्या खेळाचे प्रमाणपत्र.( जिल्हा क्रिडा अधिकारी यांचे कडून प्रमाणित केलेली )"
                        required
                        variant="outlined"
                        name="Certificate_of_Proficiency_Sports"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "Certificate_of_Proficiency_Sports",
                            event.currentTarget.files[0]
                          );
                        }}
                        type="file"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          accept: ".pdf, .png, .jpg, .jpeg",
                        }}
                      />
                      {formik.errors.Certificate_of_Proficiency_Sports ? (
                        <p className="text-danger">
                          {formik.errors.Certificate_of_Proficiency_Sports}
                        </p>
                      ) : null}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.passport_photo}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>
                    </TableCell>
                  </TableRow>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ marginTop: "20px" }}
                  >
                    Proofs
                  </Typography>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField
                        fullWidth
                        label={t("proof1")}
                        required
                        variant="outlined"
                        name="proof1"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "proof1",
                            event.currentTarget.files[0]
                          );
                        }}
                        type="file"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          accept: ".pdf, .png, .jpg, .jpeg",
                        }}
                      />
                      {formik.errors.proof1 ? (
                        <p className="text-danger">{formik.errors.proof1}</p>
                      ) : null}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.passport_photo}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <TextField
                        fullWidth
                        required
                        variant="outlined"
                        name="proof2"
                        label={t("proof2")}
                        onChange={(event) => {
                          formik.setFieldValue(
                            "proof2",
                            event.currentTarget.files[0]
                          );
                        }}
                        type="file"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          accept: ".pdf, .png, .jpg, .jpeg",
                        }}
                      />
                      {formik.errors.proof2 ? (
                        <p className="text-danger">{formik.errors.proof2}</p>
                      ) : null}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.passport_photo}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>
                    </TableCell>
                  </TableRow>
                  <Typography
                    variant="h6"
                    gutterBottom
                    style={{ marginTop: "20px" }}
                  >
                    {t("schemeForms.DeclarationForm")}
                  </Typography>

                  <TableRow>
                    <TableCell component="th" scope="row">
                      <TextField
                        fullWidth
                        label={t("schemeForms.SelfDeclaration")}
                        required
                        variant="outlined"
                        name="self_declaration"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "self_declaration",
                            event.currentTarget.files[0]
                          );
                        }}
                        type="file"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          accept: ".pdf, .png, .jpg, .jpeg",
                        }}
                      />
                      {formik.errors.self_declaration ? (
                        <p className="text-danger">
                          {formik.errors.self_declaration}
                        </p>
                      ) : null}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.passport_photo}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <TextField
                        fullWidth
                        label={t("schemeForms.SelfAttestationDeclaration")}
                        required
                        variant="outlined"
                        name="self_attestation_declaration"
                        onChange={(event) => {
                          formik.setFieldValue(
                            "self_attestation_declaration",
                            event.currentTarget.files[0]
                          );
                        }}
                        type="file"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          accept: ".pdf, .png, .jpg, .jpeg",
                        }}
                      />
                      {formik.errors.self_attestation_declaration ? (
                        <p className="text-danger">
                          {formik.errors.self_attestation_declaration}
                        </p>
                      ) : null}
                    </TableCell>
                    <TableCell align="right">
                      <Button variant="contained">
                        <a
                          href={formik.values.passport_photo}
                          className="text-decoration-none text-white"
                          target="_blank"
                        >
                          View
                        </a>
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <div className="card mt-3">
              <div className="card-header">
                {t("download_declaration.download_required_documents")}
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-striped table-bordered">
                    <thead className="thead-dark"></thead>
                    <tbody>
                      <tr>
                        <td className="text-start">
                          {t("download_declaration.self_declaration_download")}
                        </td>
                        <td className="text-start">
                          <Button variant="contained" color="primary">
                            <a
                              href={doc1}
                              className="text-decoration-none text-white"
                              download
                            >
                              {t("download_declaration.Download")}
                            </a>
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-start">
                          {t(
                            "download_declaration.Self_attested_declaration_download"
                          )}
                        </td>
                        <td className="text-start">
                          <Button variant="contained" color="primary">
                            <a
                              href={doc2}
                              className="text-decoration-none text-white"
                              download
                            >
                              {t("download_declaration.Download")}
                            </a>
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Navigation Buttons */}
            <Button
              variant="contained"
              type="submit"
              color="primary"
              className="mt-2"
              // disabled={step === steps}
            >
              Resubmit
            </Button>
          </form>
        </Card>
      </div>
    </>
  );
};

export default SchemeTenEdit;
