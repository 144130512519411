import React from "react";
import SchemeFourRejectedApplications from "../../Administration/SchemeFour/SchemeFourRejectedApplications";

const SuperAdminSchemeFourRejectedApplications = () => {
  return (
    <div>
      <SchemeFourRejectedApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeFourRejectedApplications;
