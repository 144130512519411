import React from "react";
import SchemeNineApprovedApplications from "../../Administration/schemeNine/SchemeNineapprovedapplications";

const ClerkSchemeNineApprovedApplications = () => {
  return (
    <div>
      <SchemeNineApprovedApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeNineApprovedApplications;
