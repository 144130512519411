import React from "react";
import SportSchemeTwoTotalApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoTotalApplications";

const SportShaskiySamiti4SchemeTwoTotalApplications = () => {
  return (
    <div>
      <SportSchemeTwoTotalApplications role="Sport Shaskiy4" />
    </div>
  );
};

export default SportShaskiySamiti4SchemeTwoTotalApplications;
