import React from 'react';
import SchemeTenTotalApplications from '../../Administration/SchemeTen/SchemeTenTotalApplications';

const Clerk3SchemeTenTotalApplications = () => {
  return (
    <div><SchemeTenTotalApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeTenTotalApplications;
