import React from "react";
import SchemeFourApprovedApplications from "../../Administration/SchemeFour/SchemeFourapprovedapplications";

const SuperAdminSchemeFourApprovedApplications = () => {
  return (
    <div>
      <SchemeFourApprovedApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeFourApprovedApplications;
