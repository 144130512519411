import React, { useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Divider,
  Button,
  Container,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";

// import brochure from "../../assets/documents/Financial Assistance for Unemployed Adults with Disabilities.pdf";
import { useSelectAccess } from "../../store/stateFunctions";
import { toast } from "react-toastify";

const SchemeThreeDetails = () => {
  const { t } = useTranslation();
  const user = useSelectAccess("User");
  const isLoggedIn = user && user;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleApplyNowClick = (e) => {
    if (!isLoggedIn) {
      e.preventDefault(); // Prevent navigation
      toast.info("Please log in to apply for the Scheme");
      navigate("/login");
    } else {
      setShow(true);
    }
  };

  return (
    <div
      className="container-fluid"
      maxWidth="lg"
      style={{ padding: "20px", backgroundColor: "#eaf6ff" }}
    >
      <Card style={{ marginBottom: "20px" }}>
        <CardContent>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            {t("viewdetails.scheme3Title")}
          </Typography>
          <Divider style={{ margin: "10px 0" }} />
          <li className="text-dark" paragraph>
            {t("viewdetails.scheme3intro")}
          </li>
          <li className="text-dark" paragraph>
            {t("viewdetails.scheme3point1")}
          </li>
          <li className="text-dark" paragraph>
            {t("viewdetails.scheme3point2")}
          </li>
          <li className="text-dark" paragraph>
            {t("viewdetails.scheme3point3")}
          </li>
          <li className="text-dark" paragraph>
            {t("viewdetails.scheme3point4")}
          </li>
        </CardContent>
      </Card>

      <Card style={{ marginBottom: "20px" }}>
        <CardContent>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
         {t("TermsandConditions")}
          </Typography>
          <Divider style={{ margin: "10px 0" }} />

          <li>
          {t("ViewDetailsSchemeThree.1")}
          </li>
          <li> {t("ViewDetailsSchemeThree.2")}.</li>
          <li>
          {t("ViewDetailsSchemeThree.3")}
          </li>
          <li>
          {t("ViewDetailsSchemeThree.4")}
          </li>
          <li>
          {t("ViewDetailsSchemeThree.5")}
          </li>
          <li>
          {t("ViewDetailsSchemeThree.6")}
          </li>
          <NavLink to="/schemethree" onClick={handleApplyNowClick}>
            <Button
              variant="contained"
              className="redbutton mt-2"
              color="secondary"
            >
              {t("viewdetails.scheme1.applynow")}
            </Button>
          </NavLink>
        </CardContent>
      </Card>

      {/* 
      <Card>
        <CardContent>
          <Typography variant="h5" style={{ fontWeight: "bold" }}>
            {t("viewdetails.scheme3applicationTitle")}
          </Typography>
          <Divider style={{ margin: "10px 0" }} />
          <ul style={{ paddingLeft: "20px" }}>
            <li> {t("viewdetails.scheme3applicationStep1")}</li>
            <li> {t("viewdetails.scheme3applicationStep2")}</li>
            <li> {t("viewdetails.scheme3applicationStep3")}</li>
          </ul>

          <div>
            <Button
                variant="contained"
                className="redbutton"
                color="secondary"
              >
                {t("viewdetails.scheme1.downloadbrochure")}
              </Button>
           
          </div>
        </CardContent>
      </Card> */}
    </div>
  );
};

export default SchemeThreeDetails;
