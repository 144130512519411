import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getCookie, server } from "../common";
import configContext from "../configContext/configContext";

import Clerk2Sidebar from "../clerk2/Clerk2Sidebar";
import { updateDashboardState, useSelectAccess } from "../store/stateFunctions";

export default function Clerk2Container() {
  const navigate = useNavigate();
  const location = useLocation();
  const clerk2 = useSelectAccess("Clerk2");
  const { setProgress } = useContext(configContext);

  const [processing, setProcessing] = useState(false);
  const fetched = useRef(false);
  const setFetched = (value) => (fetched.current = value);

  useEffect(() => {
    // Navigate to "/clerk2" if clerk2 access is not available
    if (!clerk2) {
      navigate("/clerk2", { state: { from: location }, replace: true });
      return;
    }

    // If clerk2 is valid, fetch admin profile
    setProcessing(true);
    setProgress(10);
    server
      .get("/admin/adminprofile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: clerk2.authToken,
        },
      })
      .then((response) => {
        updateDashboardState("Clerk2", response.data);
        setProgress(100);
        setProcessing(false);
        setFetched(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          navigate("/clerk2", {
            state: { from: location },
            replace: true,
          });
        } else {
          setProgress(100);
          setProcessing(false);
          console.error(
            "error",
            error.message,
            error.response?.status,
            error.response?.data,
            error
          );
        }
      });

    document.title = "Clerk Dashboard";
  }, [clerk2, navigate, location, setProgress]);

  if (clerk2)
    return (
      <>
        <Clerk2Sidebar username={clerk2.dashboard && clerk2.dashboard.name} />
        {clerk2.dashboard && <Outlet />}
      </>
    );

  return null;
}
