import React from 'react';
import SchemeEightTotalApplications from '../../Administration/SchemeEight/SchemeEightTotalApplications';

const Clerk2SchemeEightTotalApplications = () => {
  return (
    <div><SchemeEightTotalApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeEightTotalApplications;
