import React from "react";
import SportSchemeTwoApprovedApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoApprovedApplications";

const SportdeputycommissionerSchemeTwoApprovedApplications = () => {
  return (
    <div>
      <SportSchemeTwoApprovedApplications role="Sport Deputy" />
    </div>
  );
};

export default SportdeputycommissionerSchemeTwoApprovedApplications;
