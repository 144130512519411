import { removeSportShaskiy4UserToken } from "../../common";
import { sportshaskiysamityTokenName4 } from "../../config";

const getshaskiysamitiFromLocalStorage = () => {
  try {
    const sportshaskiysamiti4 = JSON.parse(
      sessionStorage.getItem(sportshaskiysamityTokenName4)
    );
    if (
      sportshaskiysamiti4 &&
      sportshaskiysamiti4.authToken &&
      sportshaskiysamiti4.loggedInTime
    ) {
      console.log("Returning Internal User:", sportshaskiysamiti4);
      return sportshaskiysamiti4;
    } else {
      removeSportShaskiy4UserToken();
      return null;
    }
  } catch (error) {
    removeSportShaskiy4UserToken();
    return null;
  }
};

const getInitial = () => {
  return getshaskiysamitiFromLocalStorage();
};

const sportshaskiySamity4Reducer = (state = getInitial(), action) => {
  switch (action.type) {
    case "LOGIN_SPORT_SHASKIY_SAMITI4":
      state = {};
      const time = action.payload.loggedInTime;
      if (time) {
        state.loggedInTime = time;
      } else {
        state.loggedInTime = Date.now();
      }
      state.authToken = action.payload.authToken;
      sessionStorage.setItem(
        sportshaskiysamityTokenName4,
        JSON.stringify(state)
      );
      return state;
    case "LOGOUT_SPORT_SHASKIY_SAMITI4":
      removeSportShaskiy4UserToken();
      return null;
    case "UPDATE_SPORT_SHASKIY4_DASHBOARD":
      if (state) {
        state = {
          ...state,
          dashboard: {
            ...state.dashboard,
            ...action.payload,
          },
        };
      }
      return state;
    case "CLEAR_SPORT_SHASKIY4_DASHBOARD":
      state.dashboard = null;
    default:
      return state;
  }
};

export default sportshaskiySamity4Reducer;
