import React from 'react';
import SchemeOneRejectedApplications from '../../Administration/SchemeOne/SchemeOneRejectedApplications';

const Clerk2SchemeOneRejectedApplications = () => {
  return (
    <div><SchemeOneRejectedApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeOneRejectedApplications;
