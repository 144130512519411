import React from "react";
import SchemeNineTotalApplications from "../../Administration/schemeNine/SchemeNineTotalApplications";

const ShaskiySamiti2SchemeNineTotalApplications = () => {
    return (
        <div>
            <SchemeNineTotalApplications role="Shaskiy Samiti2" />
        </div>
    );
};

export default ShaskiySamiti2SchemeNineTotalApplications;
