import React from 'react';
import Schemethreeapprovedapplications from '../../Administration/SchemeThree/Schemethreeapprovedapplications';

const Clerk3SchemeThreeApprovedApplications = () => {
  return (
    <div><Schemethreeapprovedapplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeThreeApprovedApplications;
