import React from 'react';
import SchemeFiveTotalApplications from '../../Administration/SchemeFive/SchemeFiveTotalApplications';

const Clerk2SchemeFiveTotalApplications = () => {
  return (
    <div><SchemeFiveTotalApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeFiveTotalApplications;
