import React from "react";
import SchemeSevenTotalApplications from "../../Administration/SchemeSeven/SchemeSevenTotalApplications";

const ShaskiySamiti4SchemeSevenTotalApplications = () => {
    return (
        <div>
            <SchemeSevenTotalApplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeSevenTotalApplications;
