import React from "react";
import SchemeThreeTotalApplications from "../../Administration/SchemeThree/SchemeThreeTotalApplications";

const AdditionalCommSchemeThreeTotalApplications = () => {
  return (
    <div>
      <SchemeThreeTotalApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeThreeTotalApplications;
