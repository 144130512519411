import React from "react";
import SportSchemeThreeTotalApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeTotalApplications";

const SportShaskiySamiti3SchemeThreTotalApplications = () => {
  return (
    <div>
      <SportSchemeThreeTotalApplications role="Sport Shaskiy3" />
    </div>
  );
};

export default SportShaskiySamiti3SchemeThreTotalApplications;
