export const loginChananisamiti2 = (session) => {
  return {
    type: "LOGIN_CHANANI_SAMITY2",
    payload: session,
  };
};

export const logoutChananisamiti2 = () => {
  return {
    type: "LOGOUT_CHANANI_SAMITY2",
  };
};

export const setChanani2Dashboard = (dashboard) => {
  return {
    type: "UPDATE_CHANANI2_DASHBOARD",
    payload: dashboard,
  };
};

export const clearChanani2Dashboard = () => {
  return {
    type: "CLEAR_CHANANI2_DASHBOARD",
  };
};
