import React from "react";
import SportSchemeThreeTotalApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeTotalApplications";

const SportChanani1SchemeThreeTotalApplications = () => {
  return (
    <div>
      <SportSchemeThreeTotalApplications role="Sport Chanani1" />
    </div>
  );
};

export default SportChanani1SchemeThreeTotalApplications;
