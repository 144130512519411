import React from "react";
import SportSchemeTwoTotalApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoTotalApplications";

const SportdeputycommissionerSchemeTwoTotalApplications = () => {
  return (
    <div>
      <SportSchemeTwoTotalApplications role="Sport Deputy" />
    </div>
  );
};

export default SportdeputycommissionerSchemeTwoTotalApplications;
