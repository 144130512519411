import React from 'react';
import SchemeOneTotalApplications from '../../Administration/SchemeOne/SchemeOneTotalApplications';

const Clerk3SchemeOneTotalApplications = () => {
  return (
    <div><SchemeOneTotalApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeOneTotalApplications;
