export const loginAdd_Comm = (session) => {
  return {
    type: "LOGIN_ADD_COMM",
    payload: session,
  };
};
export const logoutAdd_Comm = () => {
  return {
    type: "LOGOUT_ADD_COMM",
  };
};

export const setAddCommDashboard = (dashboard) => {
  return {
    type: "UPDATE_ADDCOMM_DASHBOARD",
    payload: dashboard,
  };
};

export const clearAddCommDashboard = () => {
  return {
    type: "CLEAR_ADDCOMM_DASHBOARD",
  };
};
