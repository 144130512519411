import React from "react";
import SchemeOneTotalApplications from "../../Administration/SchemeOne/SchemeOneTotalApplications";

const ShaskiySamiti2SchemeOneTotalApplications = () => {
    return (
        <div>
            <SchemeOneTotalApplications role="Shaskiy Samiti2" />
        </div>
    );
};

export default ShaskiySamiti2SchemeOneTotalApplications;
