import React from "react";
import SchemeFourPendingApplications from "../../Administration/SchemeFour/SchemeFourPendingApplications";

const Chanani3SchemeFourPendingApplications = () => {
    return (
        <div>
            <SchemeFourPendingApplications role="Chanani Samiti3" />
        </div>
    );
};

export default Chanani3SchemeFourPendingApplications;
