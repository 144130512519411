import React from 'react';
import SchemeEightapprovedapplications from '../../Administration/SchemeEight/SchemeEightapprovedapplications';

const Clerk3SchemeEightApprovedApplications = () => {
  return (
    <div><SchemeEightapprovedapplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeEightApprovedApplications;
