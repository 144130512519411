import React from 'react';
import SchemeFivePendingApplications from '../../Administration/SchemeFive/SchemeFivePendingApplications';

const Clerk2SchemeFivePendingApplications = () => {
  return (
    <div><SchemeFivePendingApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeFivePendingApplications;
