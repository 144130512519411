import React from "react";
import SportSchemeThreePendingApplications from "../../../Administration/SportSchemeThree/SportSchemeThreePendingApplications";

const SportChanani2SchemeThreePendingApplications = () => {
  return (
    <div>
      <SportSchemeThreePendingApplications role="Sport Chanani2" />
    </div>
  );
};

export default SportChanani2SchemeThreePendingApplications;
