import React from "react";
import SchemeTwoApprovedApplications from "../../Administration/SchemeTwo/SchemeTwoApprovedApplications";

const AdditionalCommSchemeTwoApprovedApplications = () => {
  return (
    <div>
      <SchemeTwoApprovedApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeTwoApprovedApplications;
