import React from "react";
import SchemeOneTotalApplications from "../../Administration/SchemeOne/SchemeOneTotalApplications";

const ClerkSchemeOneTotalApplications = () => {
  return (
    <div>
      <SchemeOneTotalApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeOneTotalApplications;
