import React from "react";
import ContactEntries from "../Administration/ContactEntries";

const AdditionalCommissionerContactEnquiries = () => {
  return (
    <div>
      <ContactEntries role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommissionerContactEnquiries;
