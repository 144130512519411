import React from "react";
import SchemeOneTotalApplications from "../../Administration/SchemeOne/SchemeOneTotalApplications";

const ShaskiySamiti1SchemeOneTotalApplications = () => {
    return (
        <div>
            <SchemeOneTotalApplications role="Shaskiy Samiti1" />
        </div>
    );
};

export default ShaskiySamiti1SchemeOneTotalApplications;
