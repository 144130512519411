import React from "react";
import SchemeSixRejectedApplications from "../../Administration/SchemeSix/SchemeSixRejectedApplications";

const ShaskiySamiti2SchemeSixRejectedApplications = () => {
    return (
        <div>
            <SchemeSixRejectedApplications role="Shaskiy Samiti2" />
        </div>
    );
};

export default ShaskiySamiti2SchemeSixRejectedApplications;
