import React from 'react';
import SchemeEightPendingApplications from '../../Administration/SchemeEight/SchemeEightPendingApplications';

const Clerk2SchemeEightPendingApplications = () => {
  return (
    <div><SchemeEightPendingApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeEightPendingApplications;
