import React from "react";
import SchemeOnePendingApplications from "../../Administration/SchemeOne/SchemeOnePendingApplications";

const SuperAdminSchemeOnePendingApplications = () => {
  return (
    <div>
      <SchemeOnePendingApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeOnePendingApplications;
