import React from "react";
import SchemeOnePendingApplications from "../../Administration/SchemeOne/SchemeOnePendingApplications";

const ShaskiySamiti1SchemeOnePendingApplications = () => {
    return (
        <div>
            <SchemeOnePendingApplications role="Shaskiy Samiti1" />
        </div>
    );
};

export default ShaskiySamiti1SchemeOnePendingApplications;
