import React from 'react';
import SchemeNineapprovedapplications from '../../Administration/schemeNine/SchemeNineapprovedapplications';

const Clerk2SchemeNineApprovedApplications = () => {
  return (
    <div><SchemeNineapprovedapplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeNineApprovedApplications;
