import React from "react";
import SportSchemeOneApprovedApplications from "../../../Administration/SportSchemeOne/SportSchemeOneApprovedApplications";

const SportShaskiySamiti5SchemeOneApprovedApplications = () => {
  return (
    <div>
      <SportSchemeOneApprovedApplications role="Sport Shaskiy5" />
    </div>
  );
};

export default SportShaskiySamiti5SchemeOneApprovedApplications;
