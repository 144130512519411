import React from "react";
import SchemeThreePendingApplications from "../../Administration/SchemeThree/SchemeThreePendingApplications";

const ClerkSchemeThreePendingApplications = () => {
  return (
    <div>
      <SchemeThreePendingApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeThreePendingApplications;
