import React from 'react';
import SchemeTwoPendingApplications from '../../Administration/SchemeTwo/SchemeTwoPendingApplications';

const Clerk3SchemeTwoPendingApplications = () => {
  return (
    <div><SchemeTwoPendingApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeTwoPendingApplications;
