import React from "react";
import SchemeFourTotalApplications from "../../Administration/SchemeFour/SchemeFourTotalApplications";

const Chanani4SchemeFourTotalApplications = () => {
    return (
        <div>
            <SchemeFourTotalApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeFourTotalApplications;
