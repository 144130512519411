import React from "react";
import Herosection from "../../Components/Herosection/Herosection";
import FeaturingSchemes from "../../Components/FeaturingSchemes/FeaturingSchemes";
import InfoCard from "../../Components/Card/InfoCard";
// import Eligibility from "../../Components/Eligibility/Eligibility";
import MovingAnnouncement from "../../Components/MovingAnnouncement/MovingAnnouncement";



const Home = () => {
  return (
    <div >
    
      <Herosection />
      <MovingAnnouncement/>
      <FeaturingSchemes />
      {/* <Eligibility /> */}
      <InfoCard />
     
    </div>
  );
};

export default Home;
