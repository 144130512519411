import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { useSelectAccess } from "../../../store/stateFunctions";
import { server } from "../../../common";

const Graphdashboard = ({ role }) => {
  const [data, setData] = useState([]);
  const [donate, setDonate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bar, setBar] = useState([]);
  const user = useSelectAccess(role);

  useEffect(() => {
    getData();
    getDonate();
    getDashboard();
  }, []);

  const getData = () => {
    server
      .get(`/admindash/stackchart`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          console.log("API Data:", response.data);
          setData(response.data);
        }
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.message ||
            error.response?.data?.error ||
            "An error occurred"
        );
      })
      .finally(() => setLoading(false));
  };

  const getDonate = () => {
    server
      .get(`/admindash/donutchart`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          console.log("Donut Chart Data:", response.data);
          setDonate(response.data.data);
        }
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.message ||
            error.response?.data?.error ||
            "An error occurred"
        );
      })
      .finally(() => setLoading(false));
  };

  const getDashboard = () => {
    server
      .get("/admindash/divisionwise", {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
        params: {
          filters: [],
        },
      })
      .then(function (response) {
        console.log("api response 1", response.data);
        // setDashboard(response.data);
        setBar(response.data);
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  const categories = [
    "First Application",
    "Second Application",
    "Third Application",
    "Fourth Application",
    "Fifth Application",
    "Sixth Application",
    "Seven Application",
    "Eight Application",
    "Nine Application",
    "Ten Application",
  ];

  const donutLabels = donate ? Object.keys(donate) : [];
  const donutSeries = donate
    ? Object.keys(donate).map((region) =>
        Object.values(donate[region]).reduce((sum, value) => sum + value, 0)
      )
    : [];

  if (loading) return <div>Loading...</div>;

  return (
    <>
      <div className="row text-center align-item-center">
        <div className="col-md-6 mt-4">
          <h2>Bar Chart</h2>
          <Chart
            options={{
              chart: {
                type: "bar",
                toolbar: { show: true },
              },
              plotOptions: {
                bar: { horizontal: false, columnWidth: "50%" },
              },
              xaxis: { categories },
              yaxis: { title: { text: "Application Counts" } },
              legend: { position: "top" },
              dataLabels: { enabled: false },
              colors: ["#008FFB", "#00E396", "#FEB019", "#FF4560", "#775DD0"],
            }}
            series={data.map((item) => ({
              name: item.year || "Unknown Year",
              data: [
                item.first_application || 0,
                item.second_application || 0,
                item.third_application || 0,
                item.fourth_application || 0,
                item.five_application || 0,
                item.six_application || 0,
                item.seven_application || 0,
                item.eight_application || 0,
                item.nine_application || 0,
                item.ten_application || 0,
              ],
            }))}
            type="bar"
            height={350}
          />
        </div>

        <div className="col-md-6 mt-4">
          <h2>Donut Chart</h2>
          <Chart
            options={{
              chart: {
                type: "donut",
              },
              labels: donutLabels,
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 300,
                    },
                    legend: {
                      position: "bottom",
                    },
                  },
                },
              ],
            }}
            series={donutSeries}
            type="donut"
            width="500"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <Chart
            type="bar"
            width={550}
            height={500}
            series={[
              {
                name: `Panchavati`,
                data: bar.map((item) => item.division),
                colors: "#539165",
              },
              {
                name: `First Application`,
                data: bar.map((item) => item.totalfirst_application),
                colors: "#539165",
              },
              {
                name: `Second Application`,
                data: bar.map((item) => item.totalsecond_application),
                colors: "#539165",
              },
              {
                name: `Third Application`,
                data: bar.map((item) => item.totalthird_application),
                colors: "#539165",
              },
              {
                name: `Fourth Application`,
                data: bar.map((item) => item.totalfourth_application),
                colors: "#539165",
              },
              {
                name: `Fourth Application`,
                data: bar.map((item) => item.totalfourth_application),
                colors: "#539165",
              },
              {
                name: `Fifth Application`,
                data: bar.map((item) => item.totalfive),
                colors: "#539165",
              },
              {
                name: `Sixth Application`,
                data: bar.map((item) => item.totalsix_application),
                colors: "#539165",
              },
              {
                name: `Seventh Application`,
                data: bar.map((item) => item.totalseven_application),
                colors: "#539165",
              },
              {
                name: `Eight Application`,
                data: bar.map((item) => item.totaleight_application),
                colors: "#539165",
              },
              {
                name: `Ninth Application`,
                data: bar.map((item) => item.totalnine_application),
                colors: "#539165",
              },
              {
                name: `Tenth Application`,
                data: bar.map((item) => item.totalten_application),
                colors: "#539165",
              },
            ]}
            options={{
              title: { text: "Division Wise Applications" },
              labels: bar.map((item) => item.division),
              chart: {
                stacked: true,
              },
              xaxis: {
                title: { text: "Division Wise Applications" },
              },
            }}
          ></Chart>
        </div>
      </div>
    </>
  );
};

export default Graphdashboard;
