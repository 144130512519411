import React from "react";
import SchemeFiveTotalApplications from "../../Administration/SchemeFive/SchemeFiveTotalApplications";

const ShaskiySamiti2SchemeFiveTotalApplications = () => {
    return (
        <div>
            <SchemeFiveTotalApplications role="Shaskiy Samiti2" />
        </div>
    );
};

export default ShaskiySamiti2SchemeFiveTotalApplications;
