import React from "react";
import SportSchemeTwoPendingApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoPendingApplications";

const SportChanani3SchemeTwoPendingApplications = () => {
  return (
    <div>
      <SportSchemeTwoPendingApplications role="Sport Chanani3" />
    </div>
  );
};

export default SportChanani3SchemeTwoPendingApplications;
