import React from "react";
import SchemeEightPendingApplications from "../../Administration/SchemeEight/SchemeEightPendingApplications";

const SuperAdminSchemeEightPendingApplications = () => {
  return (
    <div>
      <SchemeEightPendingApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeEightPendingApplications;
