import React from "react";
import SportSchemeOneApprovedApplications from "../../../Administration/SportSchemeOne/SportSchemeOneApprovedApplications";

const SportCommissionerSchemeOneApprovedApplications = () => {
  return (
    <div>
      <SportSchemeOneApprovedApplications role="Sport AddComm" />
    </div>
  );
};

export default SportCommissionerSchemeOneApprovedApplications;
