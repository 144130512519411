import React from "react";
import SportSchemeTwoTotalApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoTotalApplications";

const SportChanani3SchemeTwoTotalApplications = () => {
  return (
    <div>
      <SportSchemeTwoTotalApplications role="Sport Chanani3" />
    </div>
  );
};

export default SportChanani3SchemeTwoTotalApplications;
