import React from "react";
import SchemeEightapprovedapplications from "../../Administration/SchemeEight/SchemeEightapprovedapplications";

const ShaskiySamiti2SchemeEightApprovedApplications = () => {
    return (
        <div>
            <SchemeEightapprovedapplications role="Shaskiy Samiti 2" />
        </div>
    );
};

export default ShaskiySamiti2SchemeEightApprovedApplications;
