import React from "react";
import SchemeFourPendingApplications from "../../Administration/SchemeFour/SchemeFourPendingApplications";

const Chanani4SchemeFourPendingApplications = () => {
    return (
        <div>
            <SchemeFourPendingApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeFourPendingApplications;
