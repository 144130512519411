import React from "react";
import SchemeTwoApprovedApplications from "../../Administration/SchemeTwo/SchemeTwoApprovedApplications";

const ShaskiySamiti3SchemeTwoApprovedApplications = () => {
    return (
        <div>
            <SchemeTwoApprovedApplications role="Shaskiy Samiti3" />
        </div>
    );
};

export default ShaskiySamiti3SchemeTwoApprovedApplications;
