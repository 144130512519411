import React from "react";
import SchemeTenapprovedapplications from "../../Administration/SchemeTen/SchemeTenapprovedapplications";

const Chanani2SchemeTenApprovedApplications = () => {
  return (
    <div>
      <SchemeTenapprovedapplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeTenApprovedApplications;
