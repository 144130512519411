import React from "react";
import SchemeSevenPendingApplications from "../../Administration/SchemeSeven/SchemeSevenPendingApplications";

const Chanani1SchemeSevenPendingApplications = () => {
    return (
        <div>
            <SchemeSevenPendingApplications role="Chanani Samiti1" />
        </div>
    );
};

export default Chanani1SchemeSevenPendingApplications;
