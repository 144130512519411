import React from "react";
import SchemeNinePendingApplications from "../../Administration/schemeNine/SchemeNinePendingApplications";

const ShaskiySamiti2SchemeNinePendingApplications = () => {
    return (
        <div>
            <SchemeNinePendingApplications role="Shaskiy Samiti2" />
        </div>
    );
};

export default ShaskiySamiti2SchemeNinePendingApplications;
