import React, { useEffect, useState } from "react";
import { useSelectAccess } from "../../../store/stateFunctions";
import { isRejected, server } from "../../../common";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import ApplicationHistory from "../ApplicationHistory";
import { FiEdit2 } from "react-icons/fi";
import { FaRegEye } from "react-icons/fa6";
import { FaHistory } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const swalalert = withReactContent(Swal);

function MyAppliedSchemes() {
  const [data, setData] = useState([]);
  const [applicationNumber, setApplicationNumber] = useState("");
  const user = useSelectAccess("User");
  const [selectedAppId, setSelectedAppId] = useState(null);

  const [show, setShow] = useState(false);

  const handleShow = (id) => {
    setSelectedAppId(id);
    setShow(true);
  };

  const handleClose = () => setSelectedAppId(null);

  useEffect(() => {
    getData();
  }, []);

  // Get data
  const getData = () => {
    server
      .get("/dashboard/getallappl", {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        if (error instanceof AxiosError && error.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error(error.response?.data?.error || "An error occurred");
        }
      });
  };

  // Delete data
  const DeleteData = (app) => {
    swalalert
      .fire({
        title: "Delete Confirmation!",
        text: `Are You Sure That You Want To Delete Application ${app.id} ?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      })
      .then((result) => {
        if (result.isConfirmed) {
          server
            .delete(`/dashboard/deleteappl/${app.id}`, {
              headers: {
                "Content-Type": "application/json",
                Authorization: user.authToken,
              },
            })
            .then((response) => {
              if (response.status === 200 || response.status === 201) {
                swalalert.fire(
                  "Deleted!",
                  "Application has been deleted.",
                  "success"
                );
                getData();
              }
            })
            .catch((error) => {
              console.error("Delete error:", error);
              toast.error(
                error.response?.data?.message || "Failed to delete application"
              );
            });
        } else {
          swalalert.fire(
            "Cancelled",
            "Your application was not deleted",
            "info"
          );
        }
      });
  };

  // Filter data
  const FilterData = () => {
    server
      .get(`/dashboard/searchappl?q=${applicationNumber}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch((error) => {
        console.error("Search error:", error);
        toast.error(
          error.response?.data?.message || "Failed to connect to server"
        );
      });
  };

  return (
    <div className="container-fluid">
      <div className="table-container rounded">
        <div className="row">
          <div className="col-md-8 mt-2">
            <TextField
              label="Application ID"
              variant="outlined"
              value={applicationNumber}
              onChange={(e) => setApplicationNumber(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && FilterData()}
              style={{ marginBottom: "20px", width: "100%" }}
            />
          </div>
        </div>

        <div className="table-responsive table-bordered">
          <table className="table table-bordered table-hover table-striped align-middle">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Application ID</th>
                <th>Applicant Name</th>
                <th>Aadhaar Number</th>
                <th>Mobile</th>
                <th>Date</th>
                <th>Status</th>
                <th>Action</th>
                {/* <th>Comment</th> */}
                <th>Cancel</th>
              </tr>
            </thead>
            <tbody>
              {data.map((app, index) => (
                <>
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{app.id}</td>
                    <td>
                      {(app.fullname && app.fullname) ||
                        (app.Fullname && app.Fullname)}
                    </td>
                    <td>{app.adhar_number}</td>
                    <td>{app.mobile}</td>
                    <td>{new Date(app.created_at).toLocaleDateString()}</td>
                    <td>
                      <span
                        className={`status-badge ${
                          app.permission_status === "Approved"
                            ? "badge bg-success"
                            : app.permission_status === "Rejected"
                            ? "badge bg-danger"
                            : app.permission_status === "Raised"
                            ? "badge bg-info"
                            : app.permission_status === "Re-Submitted"
                            ? "badge bg-warning text-dark"
                            : "badge bg-info"
                        }`}
                      >
                        {app.permission_status}
                      </span>
                    </td>
                    <td>
                      {app.permission_status === "Approved" && (
                        <NavLink
                          to={`/profile/scheme/${app.id}`}
                          className="m-2 btn btn-primary btn-sm text-decoration-none text-white"
                        >
                          <FaRegEye />
                        </NavLink>
                      )}
                      {app.id.startsWith("SCH1N") && isRejected(app) && (
                        <NavLink
                          to={`/schemeoneedit/${app.id}`}
                          className="m-2 btn btn-warning btn-sm text-decoration-none text-dark"
                        >
                          <FiEdit2 />
                        </NavLink>
                      )}

                      {app.id.startsWith("SCH2N") && isRejected(app) && (
                        <NavLink
                          to={`/schemetwoedit/${app.id}`}
                          className="m-2 btn btn-warning btn-sm text-decoration-none text-dark"
                        >
                          <FiEdit2 />
                        </NavLink>
                      )}
                      {app.id.startsWith("SCH3N") && isRejected(app) && (
                        <NavLink
                          to={`/schemethreeedit/${app.id}`}
                          className="m-2 btn btn-warning btn-sm text-decoration-none text-dark"
                        >
                          <FiEdit2 />
                        </NavLink>
                      )}
                      {app.id.startsWith("SCH4N") && isRejected(app) && (
                        <NavLink
                          to={`/schemefouredit/${app.id}`}
                          className="m-2 btn btn-warning btn-sm text-decoration-none text-dark"
                        >
                          <FiEdit2 />
                        </NavLink>
                      )}
                      {app.id.startsWith("SCH5N") && isRejected(app) && (
                        <NavLink
                          to={`/schemefiveedit/${app.id}`}
                          className="m-2 btn btn-warning btn-sm text-decoration-none text-dark"
                        >
                          <FiEdit2 />
                        </NavLink>
                      )}
                      {app.id.startsWith("SCH6N") && isRejected(app) && (
                        <NavLink
                          to={`/schemesixeedit/${app.id}`}
                          className="m-2 btn btn-warning btn-sm text-decoration-none text-dark"
                        >
                          <FiEdit2 />
                        </NavLink>
                      )}
                      {app.id.startsWith("SCH7N") && isRejected(app) && (
                        <NavLink
                          to={`/schemesevenedit/${app.id}`}
                          className="m-2 btn btn-warning btn-sm text-decoration-none text-dark"
                        >
                          <FiEdit2 />
                        </NavLink>
                      )}
                      {app.id.startsWith("SCH8N") && isRejected(app) && (
                        <NavLink
                          to={`/schemeeightedit/${app.id}`}
                          className="m-2 btn btn-warning btn-sm text-decoration-none text-dark"
                        >
                          <FiEdit2 />
                        </NavLink>
                      )}
                      {app.id.startsWith("SCH9N") && isRejected(app) && (
                        <NavLink
                          to={`/schemenineedit/${app.id}`}
                          className="m-2 btn btn-warning btn-sm text-decoration-none text-dark"
                        >
                          <FiEdit2 />
                        </NavLink>
                      )}
                      {app.id.startsWith("SCH10N") && isRejected(app) && (
                        <NavLink
                          to={`/schemetenedit/${app.id}`}
                          className="m-2 btn btn-warning btn-sm text-decoration-none text-dark"
                        >
                          <FiEdit2 />
                        </NavLink>
                      )}

                      {/* SEFAD202424 */}
                      {/*<button
                        onClick={() => handleShow(app.id)}
                        className="m-2 btn btn-info btn-sm text-decoration-none text-white"
                      >
                        <FaHistory />
                      </button>*/}
                    </td>
                    {/* <td>{app.rejection_reason}</td> */}
                    <td>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => DeleteData(app)}
                      >
                        <AiFillDelete />
                      </button>
                    </td>
                  </tr>
                  <ApplicationHistory
                    show={selectedAppId === app.id}
                    handleClose={handleClose}
                    id={app.id}
                  />
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default MyAppliedSchemes;
