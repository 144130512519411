import React from "react";
import SchemeEightTotalApplications from "../../Administration/SchemeEight/SchemeEightTotalApplications";

const ClerkSchemeEightTotalApplications = () => {
  return (
    <div>
      <SchemeEightTotalApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeEightTotalApplications;
