import React from "react";
import SportSchemeThreeTotalApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeTotalApplications";

const SportClerk1SchemeThreeApprovedApplications = () => {
  return (
    <div>
      <SportSchemeThreeTotalApplications role="Sport Clerk1" />{" "}
    </div>
  );
};

export default SportClerk1SchemeThreeApprovedApplications;
