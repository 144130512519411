import React, { useState } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import UserProfileSidebar from "./UserProfileSidebar";
import { server } from "../../common";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { NavLink } from "react-router-dom";
import { useSelectAccess } from "../../store/stateFunctions";

const TrackApplication = () => {
  const [dashboard, setDashboard] = useState([]);
  const [applicationNumber, setApplicationNumber] = useState("");
  const user = useSelectAccess("User");

  // search filter wise
  const FilterData = () => {
    server
      .get(`/dashboard/searchappl?q=${applicationNumber}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        console.log("api response", response.data);
        if (response.status === 200 || response.status === 201) {
          setDashboard(response.data); // Store the response data in an array
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  return (
    <div className="container-fluid mt-4">
      <div>
        <div>
          <Typography variant="h4" gutterBottom>
            Track Your Application
          </Typography>
          <div>
            <TextField
              label="Application ID"
              variant="outlined"
              value={applicationNumber}
              onChange={(e) => setApplicationNumber(e.target.value)}
              style={{ marginBottom: "20px", width: "100%" }}
            />
            <Button
              variant="contained"
              className="bluebutton"
              onClick={FilterData}
            >
              Check Status
            </Button>
          </div>
        </div>
      </div>

      {dashboard.length > 0 && (
        <div className="card mt-4">
          <div className="card-header">Applications List</div>
          <div className="card-body">
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Application ID</th>
                  <th>Full Name</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {dashboard.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>
                      {(item.fullname && item.fullname) ||
                        (item.Fullname && item.Fullname)}
                    </td>
                    <td>{item.permission_status}</td>
                    <td>
                      {item.permission_status === "Approved" ? (
                        <NavLink
                          className="btn btn-info"
                          to={`/profile/scheme/${item.id}`}
                        >
                          Download
                        </NavLink>
                      ) : item.permission_status === "Rejected" ? (
                        <div>{item.rejection_reason}</div>
                      ) : (
                        "In Process"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrackApplication;
