import React from 'react';
import SchemeNineRejectedApplications from '../../Administration/schemeNine/SchemeNineRejectedApplications';

const Clerk2SchemeNineRejectedApplications = () => {
  return (
    <div><SchemeNineRejectedApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeNineRejectedApplications;
