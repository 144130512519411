// import React from "react";
// import { useSelector } from "react-redux";
// import { Navigate, Outlet } from "react-router-dom";
// import AddissionalCommissionerSidebar from "../AdditionalCommissioner/AddissionalCommissionerSidebar";
// import { useSelectAccess } from "../store/stateFunctions";
// // import { Outlet } from "react-router-dom";

// const AddissionalCommissionerContainer = () => {
//   document.title = "Additional Commissioner";
//   const addcom = useSelectAccess("Additional Commissioner");

//   return (
//     <>
//       <div className="row ">
//         <AddissionalCommissionerSidebar role="Additional Commissioner" />
//         <div className="container">
//           {addcom ? <Outlet /> : <Navigate to="/additional-commissioner" />}
//         </div>
//       </div>
//     </>
//   );
// };

// export default AddissionalCommissionerContainer;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getCookie, server } from "../common";
import configContext from "../configContext/configContext";

import AddissionalCommissionerSidebar from "../AdditionalCommissioner/AddissionalCommissionerSidebar";
import { updateDashboardState, useSelectAccess } from "../store/stateFunctions";

export default function AddissionalCommissionerContainer() {
  const navigate = useNavigate();
  const location = useLocation();
  const addcomm = useSelectAccess("Additional Commissioner");
  const { setProgress } = useContext(configContext);

  const [processing, setProcessing] = useState(false);
  const fetched = useRef(false);
  const setFetched = (value) => (fetched.current = value);

  useEffect(() => {
    // Navigate to "/addcomm" if addcomm access is not available
    if (!addcomm) {
      navigate("/additional-commissioner", {
        state: { from: location },
        replace: true,
      });
      return;
    }

    // If addcomm is valid, fetch admin profile
    setProcessing(true);
    setProgress(10);
    server
      .get("/admin/adminprofile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: addcomm.authToken,
        },
      })
      .then((response) => {
        updateDashboardState("Additional Commissioner", response.data);
        setProgress(100);
        setProcessing(false);
        setFetched(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          navigate("/additional-commissioner", {
            state: { from: location },
            replace: true,
          });
        } else {
          setProgress(100);
          setProcessing(false);
          console.error(
            "error",
            error.message,
            error.response?.status,
            error.response?.data,
            error
          );
        }
      });

    document.title = "Additional Commissioner Dashboard";
  }, [addcomm, navigate, location, setProgress]);

  if (addcomm)
    return (
      <>
        <AddissionalCommissionerSidebar
          username={addcomm.dashboard && addcomm.dashboard.name}
        />
        {addcomm.dashboard && <Outlet />}
      </>
    );

  return null;
}
