import React from "react";
import SchemeEightRejectedApplications from "../../Administration/SchemeEight/SchemeEightRejectedApplications";

const ShaskiySamiti1SchemeEightRejectedApplications = () => {
    return (
        <div>
            <SchemeEightRejectedApplications role="Shaskiy Samiti1" />
        </div>
    );
};

export default ShaskiySamiti1SchemeEightRejectedApplications;
