import React from "react";
import SchemeNineRejectedApplications from "../../Administration/schemeNine/SchemeNineRejectedApplications";

const ClerkSchemeNineRejectedApplications = () => {
  return (
    <div>
      <SchemeNineRejectedApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeNineRejectedApplications;
