import React from "react";
import Dashboard from "../../Administration/dashboard/TableDashboard/Dashboard";

const SportChananiSamiti3Dashboard = () => {
  return (
    <>
      <h1>Hello</h1>
    </>
  );
};

export default SportChananiSamiti3Dashboard;
