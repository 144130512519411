import React from "react";
import SchemeSevenPendingApplications from "../../Administration/SchemeSeven/SchemeSevenPendingApplications";

const AdditionalCommSchemeSevenPendingApplications = () => {
  return (
    <div>
      <SchemeSevenPendingApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeSevenPendingApplications;
