import React from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
} from "@mui/material";
import UserProfileSidebar from "./UserProfileSidebar";

const CanceledForm = () => {
  const canceledForms = [
    {
      id: 1,
      name: "Name of the canceled scheme ",
      status: "Application Canceled",
      date: "2024-09-01",
    },
  ];

  const handleView = (id) => {
    console.log(`Viewing form with ID: ${id}`);
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div>
          <Typography variant="h4" gutterBottom>
            Canceled Applications
          </Typography>
          <TableContainer component={Paper}>
            <Table className="table table-bordered table-striped">
              <TableHead>
                <TableRow>
                  <TableCell>Form ID</TableCell>
                  <TableCell>Form Name</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {canceledForms.map((form) => (
                  <TableRow key={form.id}>
                    <TableCell>{form.id}</TableCell>
                    <TableCell>{form.name}</TableCell>
                    <TableCell>{form.date}</TableCell>
                    <TableCell>{form.status}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleView(form.id)}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                {canceledForms.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={4} className="text-center">
                      No Declaration Forms Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default CanceledForm;
