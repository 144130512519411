import React from "react";
import SportSchemeThreeRejectedApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeRejectedApplications";

const SportShaskiySamiti5SchemeThreeRejectedApplications = () => {
  return (
    <div>
      <SportSchemeThreeRejectedApplications role="Sport Shaskiy5" />
    </div>
  );
};

export default SportShaskiySamiti5SchemeThreeRejectedApplications;
