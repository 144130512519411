import React from "react";
import SportSchemeOneRejectedApplications from "../../../Administration/SportSchemeOne/SportSchemeOneRejectedApplications";

const SportShaskiySamiti2SchemeOneRejectedApplications = () => {
  return (
    <div>
      <SportSchemeOneRejectedApplications role="Sport Shaskiy2" />
    </div>
  );
};

export default SportShaskiySamiti2SchemeOneRejectedApplications;
