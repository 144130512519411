import React from "react";
import SchemeThreeRejectedApplications from "../../Administration/SchemeThree/SchemeThreeRejectedApplications";

const ShaskiySamiti3SchemeThreeRejectedApplications = () => {
    return (
        <div>
            <SchemeThreeRejectedApplications role="Shaskiy Samiti3" />
        </div>
    );
};

export default ShaskiySamiti3SchemeThreeRejectedApplications;
