import React from "react";
import SchemeTwoPendingApplications from "../../Administration/SchemeTwo/SchemeTwoPendingApplications";

const ShaskiySamiti4SchemeTwoPendingApplications = () => {
    return (
        <div>
            <SchemeTwoPendingApplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeTwoPendingApplications;
