import React from "react";
import SchemeSevenApprovedapplications from "../../Administration/SchemeSeven/SchemeSevenapprovedapplications";

const ClerkSchemeSevenApprovedApplications = () => {
  return (
    <div>
      <SchemeSevenApprovedapplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeSevenApprovedApplications;
