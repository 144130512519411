import React from "react";
import SchemeSevenPendingApplications from "../../Administration/SchemeSeven/SchemeSevenPendingApplications";

const Chanani2SchemeSevenPendingApplications = () => {
    return (
        <div>
            <SchemeSevenPendingApplications role="Chanani Samiti2" />
        </div>
    );
};

export default Chanani2SchemeSevenPendingApplications;
