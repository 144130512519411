import React from "react";
import SchemeFiveTotalApplications from "../../Administration/SchemeFive/SchemeFiveTotalApplications";

const Chanani4SchemeFiveTotalApplications = () => {
    return (
        <div>
            <SchemeFiveTotalApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeFiveTotalApplications;
