import React from "react";
import SchemeThreePendingApplications from "../../Administration/SchemeThree/SchemeThreePendingApplications";

const Chanani2SchemeThreePendingApplications = () => {
  return (
    <div>
      <SchemeThreePendingApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeThreePendingApplications;
