import React from "react";
import SchemeFivePendingApplications from "../../Administration/SchemeFive/SchemeFivePendingApplications";

const Chanani2SchemeFivePendingApplications = () => {
  return (
    <div>
      <SchemeFivePendingApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeFivePendingApplications;
