import React from 'react';
import SchemeTenapprovedapplications from '../../Administration/SchemeTen/SchemeTenapprovedapplications';

const Clerk3SchemeTenApprovedApplications = () => {
  return (
    <div><SchemeTenapprovedapplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeTenApprovedApplications;
