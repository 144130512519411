import React from "react";
import SportSchemeOneTotalApplications from "../../../Administration/SportSchemeOne/SportSchemeOneTotalApplications";

const SportShaskiySamiti1SchemeOneTotalApplications = () => {
  return (
    <div>
      <SportSchemeOneTotalApplications role="Sport Shaskiy1" />
    </div>
  );
};

export default SportShaskiySamiti1SchemeOneTotalApplications;
