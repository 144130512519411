import React from "react";
import Schemethreeapprovedapplications from "../../Administration/SchemeThree/Schemethreeapprovedapplications";

const ShaskiySamiti3SchemeThreeApprovedApplications = () => {
    return (
        <div>
            <Schemethreeapprovedapplications role="Shaskiy Samiti3" />
        </div>
    );
};

export default ShaskiySamiti3SchemeThreeApprovedApplications;
