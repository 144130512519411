import React from "react";
import SchemeTwoRejectedApplications from "../../Administration/SchemeTwo/SchemeTwoRejectedApplications";

const ShaskiySamiti1SchemeTwoRejectedApplications = () => {
    return (
        <div>
            <SchemeTwoRejectedApplications role="Shaskiy Samiti1" />
        </div>
    );
};

export default ShaskiySamiti1SchemeTwoRejectedApplications;
