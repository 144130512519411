import React from "react";
import SportSchemeOnePendingApplications from "../../../Administration/SportSchemeOne/SportSchemeOnePendingApplications";

const SportChanani2SchemeOnePendingApplications = () => {
  return (
    <div>
      <SportSchemeOnePendingApplications role="Sport Chanani2" />
    </div>
  );
};

export default SportChanani2SchemeOnePendingApplications;
