// import React from "react";
// import { useSelector } from "react-redux";
// import { Navigate, Outlet } from "react-router-dom";
// import { useSelectAccess } from "../store/stateFunctions";
// import Clerk3Sidebar from "../clerk3/Clerk3Sidebar";
// // import { Outlet } from "react-router-dom";

// const Clerk3Container = () => {
//   document.title = "Clerk";
//   const clerk3 = useSelectAccess("Clerk3");

//   return (
//     <>
//       <div className="row ">
//         <Clerk3Sidebar role="Clerk" />
//         <div className="container">
//           {clerk3 ? <Outlet /> : <Navigate to="/clerk" />}
//           {/* <Outlet /> */}
//         </div>
//       </div>
//     </>
//   );
// };

// export default Clerk3Container;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getCookie, server } from "../common";
import configContext from "../configContext/configContext";

import Clerk3Sidebar from "../clerk3/Clerk3Sidebar";
import { updateDashboardState, useSelectAccess } from "../store/stateFunctions";

export default function Clerk3Container() {
  const navigate = useNavigate();
  const location = useLocation();
  const clerk3 = useSelectAccess("Clerk3");
  const { setProgress } = useContext(configContext);

  const [processing, setProcessing] = useState(false);
  const fetched = useRef(false);
  const setFetched = (value) => (fetched.current = value);

  useEffect(() => {
    // Navigate to "/clerk3" if clerk3 access is not available
    if (!clerk3) {
      navigate("/clerk3", { state: { from: location }, replace: true });
      return;
    }

    // If clerk3 is valid, fetch admin profile
    setProcessing(true);
    setProgress(10);
    server
      .get("/admin/adminprofile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: clerk3.authToken,
        },
      })
      .then((response) => {
        updateDashboardState("Clerk3", response.data);
        setProgress(100);
        setProcessing(false);
        setFetched(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          navigate("/clerk3", {
            state: { from: location },
            replace: true,
          });
        } else {
          setProgress(100);
          setProcessing(false);
          console.error(
            "error",
            error.message,
            error.response?.status,
            error.response?.data,
            error
          );
        }
      });

    document.title = "Clerk Dashboard";
  }, [clerk3, navigate, location, setProgress]);

  if (clerk3)
    return (
      <>
        <Clerk3Sidebar username={clerk3.dashboard && clerk3.dashboard.name} />
        {clerk3.dashboard && <Outlet />}
      </>
    );

  return null;
}
