import React from "react";
import SchemeFourPendingApplications from "../../Administration/SchemeFour/SchemeFourPendingApplications";

const ClerkSchemeFourPendingApplications = () => {
  return (
    <div>
      <SchemeFourPendingApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeFourPendingApplications;
