import React from "react";
import SchemeOneApprovedApplications from "../../Administration/SchemeOne/SchemeOneApprovedApplications";

const AdditionalCommSchemeOneApprovedApplications = () => {
  return (
    <div>
      <SchemeOneApprovedApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeOneApprovedApplications;
