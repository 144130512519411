import React from 'react';
import SchemeFourapprovedapplications from '../../Administration/SchemeFour/SchemeFourapprovedapplications';

const Clerk2SchemeFourApprovedApplications = () => {
  return (
    <div><SchemeFourapprovedapplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeFourApprovedApplications;
