import React from 'react';
import SchemeFourRejectedApplications from '../../Administration/SchemeFour/SchemeFourRejectedApplications';

const Clerk2SchemeFourRejectedApplications = () => {
  return (
    <div><SchemeFourRejectedApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeFourRejectedApplications;
