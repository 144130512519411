import { removeSportShaskiy2UserToken } from "../../common";
import { sportshaskiysamityTokenName2 } from "../../config";

const getshaskiysamitiFromLocalStorage = () => {
  try {
    const sportshaskiysamiti2 = JSON.parse(
      sessionStorage.getItem(sportshaskiysamityTokenName2)
    );
    if (
      sportshaskiysamiti2 &&
      sportshaskiysamiti2.authToken &&
      sportshaskiysamiti2.loggedInTime
    ) {
      console.log("Returning Internal User:", sportshaskiysamiti2);
      return sportshaskiysamiti2;
    } else {
      removeSportShaskiy2UserToken();
      return null;
    }
  } catch (error) {
    removeSportShaskiy2UserToken();
    return null;
  }
};

const getInitial = () => {
  return getshaskiysamitiFromLocalStorage();
};

const sportshaskiySamity2Reducer = (state = getInitial(), action) => {
  switch (action.type) {
    case "LOGIN_SPORT_SHASKIY_SAMITI2":
      state = {};
      const time = action.payload.loggedInTime;
      if (time) {
        state.loggedInTime = time;
      } else {
        state.loggedInTime = Date.now();
      }
      state.authToken = action.payload.authToken;
      sessionStorage.setItem(
        sportshaskiysamityTokenName2,
        JSON.stringify(state)
      );
      return state;
    case "LOGOUT_SPORT_SHASKIY_SAMITI2":
      removeSportShaskiy2UserToken();
      return null;
    case "UPDATE_SPORT_SHASKIY2_DASHBOARD":
      if (state) {
        state = {
          ...state,
          dashboard: {
            ...state.dashboard,
            ...action.payload,
          },
        };
      }
      return state;
    case "CLEAR_SPORT_SHASKIY2_DASHBOARD":
      state.dashboard = null;
    default:
      return state;
  }
};

export default sportshaskiySamity2Reducer;
