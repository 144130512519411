import React from "react";
import SportSchemeOneTotalApplications from "../../../Administration/SportSchemeOne/SportSchemeOneTotalApplications";

const SportChanani1SchemeOneTotalApplications = () => {
  return (
    <div>
      <SportSchemeOneTotalApplications role="Sport Chanani1" />
    </div>
  );
};

export default SportChanani1SchemeOneTotalApplications;
