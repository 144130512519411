import React from "react";
import SchemeTwoTotalApplications from "../../Administration/SchemeTwo/SchemeTwoTotalApplications";

const ShaskiySamiti3SchemeTwoTotalApplications = () => {
    return (
        <div>
            <SchemeTwoTotalApplications role="Shaskiy Samiti3" />
        </div>
    );
};

export default ShaskiySamiti3SchemeTwoTotalApplications;
