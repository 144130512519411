export const loginsportchanani1 = (session) => {
  return {
    type: "LOGIN_SPORT_CHANANI1",
    payload: session,
  };
};
export const logoutsportchanani1 = () => {
  return {
    type: "LOGOUT_SPORT_CHANANI1",
  };
};
