import React from "react";
import SchemeEightRejectedApplications from "../../Administration/SchemeEight/SchemeEightRejectedApplications";

const ShaskiySamiti4SchemeEightRejectedApplications = () => {
    return (
        <div>
            <SchemeEightRejectedApplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeEightRejectedApplications;
