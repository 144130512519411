import React from "react";
import SchemeNineTotalApplications from "../../Administration/schemeNine/SchemeNineTotalApplications";

const Chanani3SchemeNineTotalApplications = () => {
    return (
        <div>
            <SchemeNineTotalApplications role="Chanani Samiti3" />
        </div>
    );
};

export default Chanani3SchemeNineTotalApplications;
