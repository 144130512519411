import React, { useState } from "react";
import { Grid, Button, Typography, Paper } from "@mui/material"; // Keep using Material-UI for layout
import { useTranslation } from "react-i18next";
// import brochure from "../../assets/documents/Financial Assistance for Disabled Athletes to Participate in Various Sports Competitions.pdf";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelectAccess } from "../../store/stateFunctions";
import { toast } from "react-toastify";

const SchemeTenDetails = () => {
  const { t } = useTranslation();
  const user = useSelectAccess("User");
  const isLoggedIn = user && user;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleApplyNowClick = (e) => {
    if (!isLoggedIn) {
      e.preventDefault(); // Prevent navigation
      toast.info("Please log in to apply for the Scheme");
      navigate("/login");
    } else {
      setShow(true);
    }
  };

  return (
    <div className="container-fluid">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            style={{
              backgroundColor: "#ffffff",
              padding: "30px",
              border: "1px solid #e0e0e0",
            }}
          >
            <Typography
              variant="h5"
              component="div"
              style={{ fontWeight: "bold", marginBottom: "20px" }}
              className="text-dark"
            >
              {t("schemeTenDetails.title")}
            </Typography>
            <Typography
              className="text-dark"
              variant="h6"
              component="div"
              style={{ marginBottom: "20px" }}
            >
              {t("schemeTenDetails.termsLabel")}
            </Typography>

            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>{t("schemeTenDetails.noLabel")}</th>
                  <th>{t("schemeTenDetails.rankLabel")}</th>
                  <th>{t("schemeTenDetails.scholarshipAmountLabel")}</th>
                  <th>{t("schemeTenDetails.internationalLevelLabel")}</th>
                  <th>{t("schemeTenDetails.NationalLevelLabel")}</th>
                  <th>{t("schemeTenDetails.stateLevelLabel")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t("schemeTenDetails.terms.0.no")}</td>
                  <td>{t("schemeTenDetails.terms.0.rank")}</td>
                  <td>{t("schemeTenDetails.terms.0.scholarshipAmount")}</td>
                  <td>{t("schemeTenDetails.terms.0.internationalLevel")}</td>
                  <td>{t("schemeTenDetails.terms.0.NationalLevel")}</td>
                  <td>{t("schemeTenDetails.terms.0.stateLevel")}</td>
                </tr>
                <tr>
                  <td>{t("schemeTenDetails.terms.1.no")}</td>
                  <td>{t("schemeTenDetails.terms.1.rank")}</td>
                  <td>{t("schemeTenDetails.terms.1.scholarshipAmount")}</td>
                  <td>{t("schemeTenDetails.terms.1.internationalLevel")}</td>
                  <td>{t("schemeTenDetails.terms.1.NationalLevel")}</td>
                  <td>{t("schemeTenDetails.terms.1.stateLevel")}</td>
                </tr>
                <tr>
                  <td>{t("schemeTenDetails.terms.2.no")}</td>
                  <td>{t("schemeTenDetails.terms.2.rank")}</td>
                  <td>{t("schemeTenDetails.terms.2.scholarshipAmount")}</td>
                  <td>{t("schemeTenDetails.terms.2.internationalLevel")}</td>
                  <td>{t("schemeTenDetails.terms.2.NationalLevel")}</td>
                  <td>{t("schemeTenDetails.terms.2.stateLevel")}</td>
                </tr>
                <tr>
                  <td>{t("schemeTenDetails.terms.3.no")}</td>
                  <td>{t("schemeTenDetails.terms.3.rank")}</td>
                  <td>{t("schemeTenDetails.terms.3.scholarshipAmount")}</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
            <div>
              {/* <Button
                variant="contained"
                className="bluebutton"
                href={brochure}
                download
                style={{ marginRight: "10px" }}
              >
                {t("viewdetails.scheme1.downloadbrochure")}
              </Button> */}

              <NavLink to="/schemeten" onClick={handleApplyNowClick}>
                {" "}
                <Button
                  variant="contained"
                  className="redbutton"
                  color="secondary"
                >
                  {t("applyNow")}
                </Button>
              </NavLink>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default SchemeTenDetails;
