import React from "react";
import SchemeEightTotalApplications from "../../Administration/SchemeEight/SchemeEightTotalApplications";

const Chanani1SchemeEightTotalApplications = () => {
  return (
    <div>
      <SchemeEightTotalApplications role="Chanani Samiti1" />
    </div>
  );
};

export default Chanani1SchemeEightTotalApplications;
