import React from "react";
import SportSchemeTwoApprovedApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoApprovedApplications";

const SportShaskiySamiti3SchemeTwoApprovedApplications = () => {
  return (
    <div>
      <SportSchemeTwoApprovedApplications role="Sport Shaskiy3" />
    </div>
  );
};

export default SportShaskiySamiti3SchemeTwoApprovedApplications;
