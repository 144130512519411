import React from "react";
import SchemeNineapprovedapplications from "../../Administration/schemeNine/SchemeNineapprovedapplications";

const ShaskiySamiti5SchemeNineApprovedApplications = () => {
    return (
        <div>
            <SchemeNineapprovedapplications role="Shaskiy Samiti5" />
        </div>
    );
};

export default ShaskiySamiti5SchemeNineApprovedApplications;
