import React from "react";
import SchemeFourPendingApplications from "../../Administration/SchemeFour/SchemeFourPendingApplications";

const ShaskiySamiti5SchemeFourPendingApplications = () => {
    return (
        <div>
            <SchemeFourPendingApplications role="Shaskiy Samiti5" />
        </div>
    );
};

export default ShaskiySamiti5SchemeFourPendingApplications;
