import React from "react";
import SchemeEightapprovedapplications from "../../Administration/SchemeEight/SchemeEightapprovedapplications";

const Chanani1SchemeEightApprovedApplications = () => {
  return (
    <div>
      <SchemeEightapprovedapplications role="Chanani Samiti1" />
    </div>
  );
};

export default Chanani1SchemeEightApprovedApplications;
