import React, { useState } from "react";
import {
  Typography,
  Card,
  CardContent,
  Divider,
  Button,
  Container,
} from "@mui/material";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
// import brochure from "../../assets/documents/Financial Assistance for Education/Training for Parents of Disabled Students.pdf";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelectAccess } from "../../store/stateFunctions";
import { toast } from "react-toastify";

const SchemeFourDetails = () => {
  const { t } = useTranslation();
  const user = useSelectAccess("User");
  const isLoggedIn = user && user;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleApplyNowClick = (e) => {
    if (!isLoggedIn) {
      e.preventDefault(); // Prevent navigation
      toast.info("Please log in to apply for the Scheme");
      navigate("/login");
    } else {
      setShow(true);
    }
  };

  return (
    <div className="container-fluid" maxWidth="lg" style={{ padding: "20px" }}>
      <Card style={{ marginBottom: "20px" }}>
        <CardContent>
          <Typography
            className="text-dark"
            variant="h5"
            style={{ fontWeight: "bold" }}
          >
            {t("schemeFourDetails.title")}
          </Typography>
          <Divider style={{ margin: "10px 0" }} />
          <li className="text-dark" variant="body1" paragraph>
            {t("schemeFourDetails.introPoints.0")}
          </li>
          <li className="text-dark" variant="body1" paragraph>
            {t("schemeFourDetails.introPoints.1")}
          </li>
          <li className="text-dark" variant="body1" paragraph>
            {t("schemeFourDetails.introPoints.2")}
          </li>
          <li className="text-dark" variant="body1" paragraph>
            {t("schemeFourDetails.introPoints.3")}
          </li>
          <li className="text-dark" variant="body1" paragraph>
            {t("schemeFourDetails.introPoints.4")}
          </li>
          <li className="text-dark" variant="body1" paragraph>
            {t("schemeFourDetails.introPoints.5")}
          </li>
        </CardContent>
      </Card>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{t("schemeFourDetails.noLabel")}</th>
            <th>{t("schemeFourDetails.beneficiaryLabel")}</th>
            <th> {t("ViewDetailsSchemeFour.1")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t("schemeFourDetails.benefits.0.no")}</td>
            <td>{t("schemeFourDetails.benefits.0.description")}</td>
            <td> {t("ViewDetailsSchemeFour.2")}</td>
          </tr>
          <tr>
            <td>{t("schemeFourDetails.benefits.1.no")}</td>
            <td>{t("schemeFourDetails.benefits.1.description")}</td>
            <td>  {t("ViewDetailsSchemeFour.3")} </td>
          </tr>
          <tr>
            <td>{t("schemeFourDetails.benefits.2.no")}</td>
            <td>
            {t("ViewDetailsSchemeFour.4")}
            </td>
            <td> {t("ViewDetailsSchemeFour.5")}</td>
          </tr>
        </tbody>
      </Table>

      <Card style={{ marginBottom: "20px" }}>
        <CardContent>
          <Typography
            className="text-dark"
            variant="h5"
            style={{ fontWeight: "bold" }}
          >
              {t("TermsandConditions")}
          </Typography>
          <Divider style={{ margin: "10px 0" }} />
          <li className="text-dark" variant="body1" paragraph>
          {t("ViewDetailsSchemeFour.6")}
          </li>
          <li>
          {t("ViewDetailsSchemeFour.7")}
          </li>
        </CardContent>
      </Card>
      <div>
        {/* <Button
                variant="contained"
                className="bluebutton"
                href={brochure} 
                download 
                style={{ marginRight: "10px" }}
              >
                {t("viewdetails.scheme1.downloadbrochure")}
              </Button> */}
        <NavLink to="/schemefour" onClick={handleApplyNowClick}>
          <Button variant="contained" className="redbutton" color="secondary">
            {t("schemeEightDetails.applyNow")}
          </Button>
        </NavLink>
      </div>
    </div>
  );
};

export default SchemeFourDetails;
