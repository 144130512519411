import React from "react";
import SchemeFourRejectedApplications from "../../Administration/SchemeFour/SchemeFourRejectedApplications";

const Chanani2SchemeFourRejectedApplications = () => {
  return (
    <div>
      <SchemeFourRejectedApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeFourRejectedApplications;
