import React from "react";
import SchemeFivePendingApplications from "../../Administration/SchemeFive/SchemeFivePendingApplications";

const AdditionalCommSchemeFivePendingApplications = () => {
  return (
    <div>
      <SchemeFivePendingApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeFivePendingApplications;
