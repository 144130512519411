import React from 'react';
import SchemeFourTotalApplications from '../../Administration/SchemeFour/SchemeFourTotalApplications';

const Clerk2SchemeFourTotalApplications = () => {
  return (
    <div><SchemeFourTotalApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeFourTotalApplications;
