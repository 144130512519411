import React from "react";
import SchemeSixPendingApplications from "../../Administration/SchemeSix/SchemeSixPendingApplications";

const ShaskiySamiti5SchemeSixPendingApplications = () => {
    return (
        <div>
            <SchemeSixPendingApplications role="Shaskiy Samiti5" />
        </div>
    );
};

export default ShaskiySamiti5SchemeSixPendingApplications;
