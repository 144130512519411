import React from "react";
import SchemeSevenRejectedApplications from "../../Administration/SchemeSeven/SchemeSevenRejectedApplications";

const Chanani4SchemeSevenRejectedApplications = () => {
    return (
        <div>
            <SchemeSevenRejectedApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeSevenRejectedApplications;
