import React from "react";
import SchemeTwoPendingApplications from "../../Administration/SchemeTwo/SchemeTwoPendingApplications";

const Chanani2SchemeTwoPendingApplications = () => {
  return (
    <div>
      <SchemeTwoPendingApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeTwoPendingApplications;
