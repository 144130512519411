import React from 'react';
import SchemeNineapprovedapplications from '../../Administration/schemeNine/SchemeNineapprovedapplications';

const Clerk3SchemeNineApprovedApplications = () => {
  return (
    <div><SchemeNineapprovedapplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeNineApprovedApplications;
