export const loginShaskiySamiti4 = (session) => {
  return {
    type: "LOGIN_SHASKIY_SAMITI4",
    payload: session,
  };
};

export const logoutShaskiySamiti4 = () => {
  return {
    type: "LOGOUT_SHASKIY_SAMITI4",
  };
};

export const setShaskiysamiti4Dashboard = (dashboard) => {
  return {
    type: "UPDATE_SHASKIY4_DASHBOARD",
    payload: dashboard,
  };
};

export const clearShaskiysamiti4Dashboard = () => {
  return {
    type: "CLEAR_SHASKIY4_DASHBOARD",
  };
};
