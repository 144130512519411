import React from "react";
import SchemeThreeTotalApplications from "../../Administration/SchemeThree/SchemeThreeTotalApplications";

const ClerkSchemeThreeTotalApplications = () => {
  return (
    <div>
      <SchemeThreeTotalApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeThreeTotalApplications;
