import React from "react";
import SchemeTenRejectedApplications from "../../Administration/SchemeTen/SchemeTenRejectedApplications";

const Chanani3SchemeTenRejectedApplications = () => {
    return (
        <div>
            <SchemeTenRejectedApplications role="Chanani Samiti3" />
        </div>
    );
};

export default Chanani3SchemeTenRejectedApplications;
