import React from "react";
import SchemeFourapprovedapplications from "../../Administration/SchemeFour/SchemeFourapprovedapplications";

const Chanani3SchemeFourApprovedApplications = () => {
    return (
        <div>
            <SchemeFourapprovedapplications role="Chanani Samiti3" />
        </div>
    );
};

export default Chanani3SchemeFourApprovedApplications;
