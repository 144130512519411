import React from "react";
import SportSchemeTwoRejectedApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoRejectedApplications";

const SportClerk1SchemeTwoRejectedApplications = () => {
  return (
    <div>
      <SportSchemeTwoRejectedApplications role="Sport Clerk1" />
    </div>
  );
};

export default SportClerk1SchemeTwoRejectedApplications;
