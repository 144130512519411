import React from "react";
import SchemeSixTotalApplications from "../../Administration/SchemeSix/SchemeSixTotalApplications";

const AdditionalCommSchemeSixTotalApplications = () => {
  return (
    <div>
      <SchemeSixTotalApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeSixTotalApplications;
