import React from 'react'
import { Typography, Card, CardContent, Divider, Button } from "@mui/material";
export default function WelfareSchemeOneDetails() {
  return (
     <div>
      <div
        className="container-fluid"
        style={{
          height: "100px",
          backgroundColor: "#98c1d9",
          color: "black",
          fontSize: "27px",
          fontWeight: "500",
          padding: "0px 20px",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
      निराधार/विधवा/परित्यकता/घटस्फोटित महिलांच्या मुलींचे विवाह करिता  अर्धसहाय्य  देणे
      </div>

      <div style={{ padding: "20px", backgroundColor: "#eaf6ff" }}>
        <Card style={{ marginBottom: "20px" }}>
          <CardContent>
            <Typography variant="h5" style={{ fontWeight: "bold"}}>
              प्रस्तावना
            </Typography>
            <Divider style={{ margin: "10px 0" }} />
            
       
           <Typography variant="body1" style={{ color: "#1a1a1a" }}>
                   निराधार/विधवा/परित्यकता/घटस्फोटित महिलांच्या मुलींचे विवाह करिता  अर्धसहाय्य  देणे.
            </Typography>
          </CardContent>
        </Card>

       

        <Card style={{ marginBottom: "20px" }}>
          <CardContent>
            <Typography variant="h6" style={{ fontWeight: "bold" }}>
             अटी व शर्तीं
            </Typography>
            <Divider style={{ margin: "10px 0" }} />
            <ul style={{ paddingLeft: "20px", fontWeight:" semi-bold" }}>
              <li>वार्षिक उत्पन्न प्रमाणपत्र: अर्जदाराचे वार्षिक उत्पन्न ₹३.५ लाखांपेक्षा कमी असल्याचे प्रमाणपत्र, तहसीलदार/ग्रामसेवक यांच्या सहीसह.</li>
              <li>मालमत्ता कर भरणा: मालमत्ता असलेल्या अर्जदाराने मागील तीन वर्षांतील मालमत्ता कर पावत्या जोडणे आवश्यक आहे.</li>
              <li>ओळख पत्र: अर्जदाराने आधार कार्ड, मतदार ओळखपत्र, पॅन कार्ड, पासपोर्ट यापैकी कुठलेही एक ओळखपत्र जोडावे.</li>
              <li>मुलीचे कागदपत्रे: जन्म प्रमाणपत्र, जातीचे प्रमाणपत्र, शाळा सोडल्याचा दाखला, आधार कार्ड आणि बँक खाते क्रमांक जोडणे आवश्यक आहे.</li>
              <li>विधवा असल्यास: अर्जदार विधवा असल्यास पतीच्या मृत्यूचे प्रमाणपत्र तसेच विवाहाचे प्रमाणपत्र जोडावे. </li>
              <li> घटस्फोट घेतल्यास: अर्जदार घटस्फोटित असल्यास न्यायालयाचा निर्णय किंवा घटस्फोट प्रमाणपत्र आवश्यक आहे. </li>
              <li>मुलीच्या विवाहाचे कागदपत्र: मुलीच्या विवाहाचे नोंदणी प्रमाणपत्र, तसेच लग्नाचे छायाचित्र.</li>
              <li>पासपोर्ट साइज फोटो: अर्जदार आणि मुलीचे पासपोर्ट साइज फोटो अर्जासोबत जोडणे आवश्यक आहे.</li>
              <li> अर्जदाराचे निवासाचे कागदपत्र: रहिवासी दाखला, निवासाचा पुरावा जोडणे अनिवार्य आहे.</li>
              
              <li>इतर शर्ती: अर्जदाराने मागील तीन वर्षांपासून नाशिक महानगरपालिका क्षेत्रात राहिलेले असावे. जर अर्जदार मुलगी अनाथ असेल तर तिच्यासाठी संस्थेचे अधीकृत कागदपत्र जोडणे गरजेचे आहे.</li>

            </ul>
            
            <div style={{ marginTop: "20px" }}>
              <Button
                variant="contained"
                className="bluebutton"
                href="/path-to-brochure.pdf"
                download
                style={{ marginRight: "10px" }}
              >
                Download Brochure
              </Button>
              <Button
                variant="contained"
                className="redbutton"
                color="secondary"
              >
                Apply Now
              </Button>
            </div>
          </CardContent>
        </Card>

     

       
      </div>
    </div>
  )
}
