import React from "react";
import SportSchemeTwoTotalApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoTotalApplications";

const SportChanani4SchemeTwoTotalApplications = () => {
  return (
    <div>
      <SportSchemeTwoTotalApplications role="Sport Chanani4" />
    </div>
  );
};

export default SportChanani4SchemeTwoTotalApplications;
