import React from 'react';
import SchemeFourTotalApplications from '../../Administration/SchemeFour/SchemeFourTotalApplications';

const Clerk3SchemeFourTotalApplications = () => {
  return (
    <div><SchemeFourTotalApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeFourTotalApplications;
