import React from "react";
import SchemeThreeApprovedApplications from "../../Administration/SchemeThree/Schemethreeapprovedapplications";

const SuperAdminSchemeThreeApprovedApplications = () => {
  return (
    <div>
      <SchemeThreeApprovedApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeThreeApprovedApplications;
