import React from "react";
import Graphdashboard from "../../Administration/dashboard/GraphDashboard/Graphdashboard";

const DeputycommissionerGraphs = () => {
  return (
    <div>
      <Graphdashboard role="Deputy Commissioner" />
    </div>
  );
};

export default DeputycommissionerGraphs;
