import React from "react";
import SchemeThreeTotalApplications from "../../Administration/SchemeThree/SchemeThreeTotalApplications";

const Chanani4SchemeThreeTotalApplications = () => {
    return (
        <div>
            <SchemeThreeTotalApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeThreeTotalApplications;
