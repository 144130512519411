import React from "react";
import SportSchemeThreePendingApplications from "../../../Administration/SportSchemeThree/SportSchemeThreePendingApplications";

const SportChanani3SchemeThreePendingApplications = () => {
  return (
    <div>
      <SportSchemeThreePendingApplications role="Sport Chanani3" />
    </div>
  );
};

export default SportChanani3SchemeThreePendingApplications;
