import React from 'react';
import SchemeTwoRejectedApplications from '../../Administration/SchemeTwo/SchemeTwoRejectedApplications';

const Clerk2SchemeTwoRejectedApplications = () => {
  return (
    <div><SchemeTwoRejectedApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeTwoRejectedApplications;
