import React from "react";
import SchemeFourRejectedApplications from "../../Administration/SchemeFour/SchemeFourRejectedApplications";

const ClerkSchemeFourRejectedApplications = () => {
  return (
    <div>
      <SchemeFourRejectedApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeFourRejectedApplications;
