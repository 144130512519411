import React from "react";
import SchemeOnePendingApplications from "../../Administration/SchemeOne/SchemeOnePendingApplications";

const Chanani4SchemeOnePendingApplications = () => {
    return (
        <div>
            <SchemeOnePendingApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeOnePendingApplications;
