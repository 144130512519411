import React from "react";
import SchemeSixPendingApplications from "../../Administration/SchemeSix/SchemeSixPendingApplications";

const AdditionalCommSchemeSixPendingApplications = () => {
  return (
    <div>
      <SchemeSixPendingApplications role="Additional Commissioner" />
    </div>
  );
};

export default AdditionalCommSchemeSixPendingApplications;
