import React from 'react';
import SchemeNinePendingApplications from '../../Administration/schemeNine/SchemeNinePendingApplications';

const Clerk3SchemeNinePendingApplications = () => {
  return (
    <div><SchemeNinePendingApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeNinePendingApplications;
