import React from "react";
import SchemeSixTotalApplications from "../../Administration/SchemeSix/SchemeSixTotalApplications";

const Chanani4SchemeSixTotalApplications = () => {
    return (
        <div>
            <SchemeSixTotalApplications role="Chanani Samiti4" />
        </div>
    );
};

export default Chanani4SchemeSixTotalApplications;
