import React from "react";
import SchemeFivePendingApplications from "../../Administration/SchemeFive/SchemeFivePendingApplications";

const SuperAdminSchemeFivePendingApplications = () => {
  return (
    <div>
      <SchemeFivePendingApplications role="Super Admin" />
    </div>
  );
};

export default SuperAdminSchemeFivePendingApplications;
