import React from 'react';
import SchemeTenTotalApplications from '../../Administration/SchemeTen/SchemeTenTotalApplications';

const Clerk2SchemeTenTotalApplications = () => {
  return (
    <div><SchemeTenTotalApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeTenTotalApplications;
