import React from "react";
import SportSchemeOnePendingApplications from "../../../Administration/SportSchemeOne/SportSchemeOnePendingApplications";

const SportChanani1SchemeOnePendingApplications = () => {
  return (
    <div>
      <SportSchemeOnePendingApplications role="Sport Chanani1" />
    </div>
  );
};

export default SportChanani1SchemeOnePendingApplications;
