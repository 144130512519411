import React from "react";
import SportSchemeTwoTotalApplications from "../../../Administration/SportSchemeTwo/SportSchemeTwoTotalApplications";

const SportShaskiySamiti3SchemeTwoTotalApplications = () => {
  return (
    <div>
      <SportSchemeTwoTotalApplications role="Sport Shaskiy3" />
    </div>
  );
};

export default SportShaskiySamiti3SchemeTwoTotalApplications;
