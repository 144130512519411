export const loginShaskiySamiti1 = (session) => {
  return {
    type: "LOGIN_SHASKIY_SAMITI1",
    payload: session,
  };
};

export const logoutShaskiySamiti1 = () => {
  return {
    type: "LOGOUT_SHASKIY_SAMITI1",
  };
};

export const setShaskiysamiti1Dashboard = (dashboard) => {
  return {
    type: "UPDATE_SHASKIY1_DASHBOARD",
    payload: dashboard,
  };
};

export const clearShaskiysamiti1Dashboard = () => {
  return {
    type: "CLEAR_SHASKIY1_DASHBOARD",
  };
};
