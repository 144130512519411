import React from 'react';
import SchemeFiveApprovedApplications from '../../Administration/SchemeFive/SchemeFiveApprovedApplications';

const Clerk3SchemeFiveApprovedApplications = () => {
  return (
    <div><SchemeFiveApprovedApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeFiveApprovedApplications;
