import React from "react";
import SchemeSixRejectedApplications from "../../Administration/SchemeSix/SchemeSixRejectedApplications";

const Chanani2SchemeSixRejectedApplications = () => {
  return (
    <div>
      <SchemeSixRejectedApplications role="Chanani Samiti2" />
    </div>
  );
};

export default Chanani2SchemeSixRejectedApplications;
