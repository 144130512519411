import { removeUserToken } from "../../common";
import { userTokenName } from "../../config";

const getUserFromLocalStorage = () => {
  try {
    const user = JSON.parse(localStorage.getItem(userTokenName)); // Changed to localStorage
    if (user && user.authToken && user.loggedInTime) {
      console.log("Returning User:", user);
      return user;
    } else {
      removeUserToken();
      return null;
    }
  } catch (error) {
    removeUserToken();
    return null;
  }
};

const getInitial = () => {
  return getUserFromLocalStorage();
};

const userReducer = (state = getInitial(), action) => {
  switch (action.type) {
    case "LOGIN_USER":
      state = {};
      const time = action.payload.loggedInTime;
      if (time) {
        state.loggedInTime = time;
      } else {
        state.loggedInTime = Date.now();
      }
      state.authToken = "Bearer" + " " + action.payload.authToken;
      localStorage.setItem(userTokenName, JSON.stringify(state)); // Changed to localStorage
      return state;
    case "LOGOUT_USER":
      removeUserToken();
      return null;
    default:
      return state;
  }
};

export default userReducer;

// import { removeUserToken } from "../../common";
// import { userTokenName } from "../../config";

// const TOKEN_EXPIRATION_TIME = 2 * 1000; // 10 minutes in milliseconds

// const getUserFromLocalStorage = () => {
//   try {
//     const user = JSON.parse(localStorage.getItem(userTokenName)); // Changed to localStorage
//     if (user && user.authToken && user.loggedInTime) {
//       const currentTime = Date.now();
//       const loggedInTime = new Date(user.loggedInTime).getTime();

//       if (currentTime - loggedInTime < TOKEN_EXPIRATION_TIME) {
//         console.log("Returning User:", user);
//         return user;
//       } else {
//         console.log("Token expired. Removing user.");
//         removeUserToken();
//         return null;
//       }
//     } else {
//       removeUserToken();
//       return null;
//     }
//   } catch (error) {
//     console.error("Error retrieving user:", error);
//     removeUserToken();
//     return null;
//   }
// };

// const getInitial = () => {
//   return getUserFromLocalStorage();
// };

// const userReducer = (state = getInitial(), action) => {
//   switch (action.type) {
//     case "LOGIN_USER":
//       state = {};
//       const time = action.payload.loggedInTime || Date.now();
//       state.loggedInTime = time;
//       state.authToken = "Bearer" + " " + action.payload.authToken;
//       sessionStorage.setItem(userTokenName, JSON.stringify(state)); // Changed to localStorage
//       return state;
//     case "LOGOUT_USER":
//       removeUserToken();
//       return null;
//     default:
//       return state;
//   }
// };

// export default userReducer;
