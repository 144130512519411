import React from "react";
import SportSchemeOnePendingApplications from "../../../Administration/SportSchemeOne/SportSchemeOnePendingApplications";

const SportCommissionerSchemeOnePendingApplications = () => {
  return (
    <div>
      <SportSchemeOnePendingApplications role="Sport AddComm" />
    </div>
  );
};

export default SportCommissionerSchemeOnePendingApplications;
