import React from "react";
import SportSchemeOneRejectedApplications from "../../../Administration/SportSchemeOne/SportSchemeOneRejectedApplications";

const SportdeputycommissionerSchemeOneRejectedApplications = () => {
  return (
    <div>
      <SportSchemeOneRejectedApplications role="Sport Deputy" />
    </div>
  );
};

export default SportdeputycommissionerSchemeOneRejectedApplications;
