import React from "react";
import SchemeEightPendingApplications from "../../Administration/SchemeEight/SchemeEightPendingApplications";

const ClerkSchemeEightPendingApplications = () => {
  return (
    <div>
      <SchemeEightPendingApplications role="Clerk1" />
    </div>
  );
};

export default ClerkSchemeEightPendingApplications;
