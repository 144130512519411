import React from "react";
import SchemeSixapprovedapplications from "../../Administration/SchemeSix/SchemeSixapprovedapplications";

const ShaskiySamiti4SchemeSixApprovedApplications = () => {
    return (
        <div>
            <SchemeSixapprovedapplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeSixApprovedApplications;
