import React, { useState } from "react";
import DisabilityFormsTable from "./DisabilityFormsTable";
import SportsFormsTable from "./SportsFormsTable";
import Womenwelfare from "./Womenwelfare";

import { useTranslation } from "react-i18next";

const Allschemespage = () => {
  const [activeComponent, setActiveComponent] = useState("disability");
  const { t } = useTranslation();
  const handleButtonClick = (component) => {
    setActiveComponent(component);
  };

  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <style>
        {`
          .button-group {
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap; /* Ensure buttons wrap on smaller screens */
            gap: 10px; /* Add gap between buttons */
          }

          .button-group button {
            padding: 10px 20px;
            margin: 0;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
            transition: background-color 0.3s, transform 0.3s;
            width: auto;
          }

          .button-group button:hover {
            transform: scale(1.05);
          }

          .active {
            background-color: #007bff;
            color: #fff; 
          }

          .inactive {
            background-color: #e7e7e7; 
            color: #000;
          }

          /* Responsive styles */
          @media (max-width: 768px) {
            .button-group {
              flex-direction: column; /* Stack buttons vertically on smaller screens */
            }
            .button-group button {
              width: 100%; /* Make buttons take full width */
              margin-bottom: 10px; /* Add margin between stacked buttons */
            }
          }

          @media (max-width: 480px) {
            .button-group button {
              font-size: 14px; /* Slightly smaller font for smaller screens */
            }
          }
        `}
      </style>

      <div className="button-group">
        <button
          onClick={() => handleButtonClick("disability")}
          className={activeComponent === "disability" ? "active" : "inactive"}
        >
          {t("selectschemebuttons.disability")}
        </button>
        <button
          onClick={() => handleButtonClick("sports")}
          className={activeComponent === "sports" ? "active" : "inactive"}
        >
          {t("selectschemebuttons.sports")}
        </button>

        <button
          onClick={() => handleButtonClick("welfare")}
          className={activeComponent === "welfare" ? "active" : "inactive"}
        >
          {t("selectschemebuttons.womenwelfare")}
        </button>
      </div>

      <div>
        {activeComponent === "disability" && <DisabilityFormsTable />}
        {activeComponent === "sports" && <SportsFormsTable />}
        {activeComponent === "welfare" && <Womenwelfare />}
      </div>
    </div>
  );
};

export default Allschemespage;
