import React from "react";
import SchemeFiveApprovedApplications from "../../Administration/SchemeFive/SchemeFiveApprovedApplications";

const ShaskiySamiti1SchemeFiveApprovedApplications = () => {
    return (
        <div>
            <SchemeFiveApprovedApplications role="Shaskiy Samiti1" />
        </div>
    );
};

export default ShaskiySamiti1SchemeFiveApprovedApplications;
