import React, { useContext, useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { server } from "../common";
import configContext from "../configContext/configContext";
import Clerk1Sidebar from "../clerck/ClerkSidebar";
import { updateDashboardState, useSelectAccess } from "../store/stateFunctions";

export default function Clerk2Container() {
  const navigate = useNavigate();
  const location = useLocation();
  const clerk1 = useSelectAccess("Clerk1"); // Watch for Clerk1 updates
  const { setProgress } = useContext(configContext);

  const [processing, setProcessing] = useState(false);
  const fetched = useRef(false);
  const setFetched = (value) => (fetched.current = value);

  useEffect(() => {
    // Redirect to login if clerk1 is unavailable
    if (!clerk1) {
      navigate("/clerk", { state: { from: location }, replace: true });
      return;
    }

    if (fetched.current) return; // Prevent redundant API calls

    setProcessing(true);
    setProgress(10);

    server
      .get("/admin/adminprofile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: clerk1.authToken,
        },
      })
      .then((response) => {
        updateDashboardState("Clerk1", response.data);
        setProgress(100);
        setProcessing(false);
        setFetched(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          navigate("/clerk", { state: { from: location }, replace: true });
        } else {
          setProgress(100);
          setProcessing(false);
          console.error(
            "error",
            error.message,
            error.response?.status,
            error.response?.data,
            error
          );
        }
      });

    document.title = "Clerk Dashboard";
  }, [clerk1, navigate, location, setProgress]);

  if (clerk1)
    return (
      <>
        <Clerk1Sidebar username={clerk1.dashboard && clerk1.dashboard.name} />
        {clerk1.dashboard && <Outlet />}
      </>
    );

  return null;
}
