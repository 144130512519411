import React from "react";
import SchemeThreePendingApplications from "../../Administration/SchemeThree/SchemeThreePendingApplications";

const ShaskiySamiti1SchemeThreePendingApplications = () => {
    return (
        <div>
            <SchemeThreePendingApplications role="Shaskiy Samiti1" />
        </div>
    );
};

export default ShaskiySamiti1SchemeThreePendingApplications;
