import React from "react";
import SchemeEightPendingApplications from "../../Administration/SchemeEight/SchemeEightPendingApplications";

const ShaskiySamiti2SchemeEightPendingApplications = () => {
    return (
        <div>
            <SchemeEightPendingApplications role="Shaskiy Samiti2" />
        </div>
    );
};

export default ShaskiySamiti2SchemeEightPendingApplications;
