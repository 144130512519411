import React from "react";
import ContactEntries from "../Administration/ContactEntries";

const DeputyCommissionerContactEnquiries = () => {
  return (
    <div>
      <ContactEntries role="Deputy Commissioner" />
    </div>
  );
};

export default DeputyCommissionerContactEnquiries;
