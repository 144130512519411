import React from "react";
import SportSchemeThreeRejectedApplications from "../../../Administration/SportSchemeThree/SportSchemeThreeRejectedApplications";

const SportClerk1SchemeThreeRejectedApplications = () => {
  return (
    <div>
      <SportSchemeThreeRejectedApplications role="Sport Clerk1" />
    </div>
  );
};

export default SportClerk1SchemeThreeRejectedApplications;
