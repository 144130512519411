import React from "react";
import SchemeEightapprovedapplications from "../../Administration/SchemeEight/SchemeEightapprovedapplications";

const ShaskiySamiti4SchemeEightApprovedApplications = () => {
    return (
        <div>
            <SchemeEightapprovedapplications role="Shaskiy Samiti4" />
        </div>
    );
};

export default ShaskiySamiti4SchemeEightApprovedApplications;
