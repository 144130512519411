import React from 'react';
import SchemeSevenPendingApplications from '../../Administration/SchemeSeven/SchemeSevenPendingApplications';

const Clerk2SchemeSevenPendingApplications = () => {
  return (
    <div><SchemeSevenPendingApplications role="Clerk2" /></div>
  );
};

export default Clerk2SchemeSevenPendingApplications;
