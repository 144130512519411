import React from "react";
import SchemeTenTotalApplications from "../../Administration/SchemeTen/SchemeTenTotalApplications";

const Chanani1SchemeTenTotalApplications = () => {
    return (
        <div>
            <SchemeTenTotalApplications role="Chanani Samiti1" />
        </div>
    );
};

export default Chanani1SchemeTenTotalApplications;
