import React from 'react';
import SchemeNineRejectedApplications from '../../Administration/schemeNine/SchemeNineRejectedApplications';

const Clerk3SchemeNineRejectedApplications = () => {
  return (
    <div><SchemeNineRejectedApplications role="Clerk3" /></div>
  );
};

export default Clerk3SchemeNineRejectedApplications;
