import React from "react";
import SchemeThreeTotalApplications from "../../Administration/SchemeThree/SchemeThreeTotalApplications";

const ShaskiySamiti5SchemeThreeTotalApplications = () => {
    return (
        <div>
            <SchemeThreeTotalApplications role="Shaskiy Samiti5" />
        </div>
    );
};

export default ShaskiySamiti5SchemeThreeTotalApplications;
