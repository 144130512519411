import { removeSportChanani3UserToken } from "../../common";
import { sportchananiTokenName3 } from "../../config";

const getTrafficFromLocalStorage = () => {
  try {
    const sportchanani3 = JSON.parse(
      sessionStorage.getItem(sportchananiTokenName3)
    );
    if (
      sportchanani3 &&
      sportchanani3.authToken &&
      sportchanani3.loggedInTime
    ) {
      return sportchanani3;
    } else {
      removeSportChanani3UserToken();
      return null;
    }
  } catch (error) {
    removeSportChanani3UserToken();
    return null;
  }
};

const getInitial = () => {
  return getTrafficFromLocalStorage();
};

const sportchanani3UserReducers = (state = getInitial(), action) => {
  switch (action.type) {
    case "LOGIN_SPORT_CHANANI3":
      const { loggedInTime, authToken } = action.payload;
      const newState = {
        loggedInTime: loggedInTime || Date.now(),
        authToken: authToken,
      };
      sessionStorage.setItem(sportchananiTokenName3, JSON.stringify(newState));
      return newState;
    case "LOGOUT_SPORT_CHANANI3":
      removeSportChanani3UserToken();
      return null;
    default:
      return state;
  }
};

export default sportchanani3UserReducers;
