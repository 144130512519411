export const loginDepuComm = (session) => {
  return {
    type: "LOGIN_DEPU_COMM",
    payload: session,
  };
};
export const logoutDepuComm = () => {
  return {
    type: "LOGOUT_DEPU_COMM",
  };
};

export const setDeputyDashboard = (dashboard) => {
  return {
    type: "UPDATE_DEPUTY_DASHBOARD",
    payload: dashboard,
  };
};

export const clearDeputyDashboard = () => {
  return {
    type: "CLEAR_DEPUTY_DASHBOARD",
  };
};
